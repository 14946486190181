import { FC, Fragment, useCallback, useEffect } from "react"

import { CircularProgress } from "@mui/material"

import { NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"

import NoCamera from "../assets/NoCamera.svg?react"
import NoHistoryLogs from "../assets/NoHistoryLogs.svg?react"

import styles from "./Placeholder.module.scss"

interface Props {
  alertMessage?: string
  openSnackbar?: boolean
  selectionType?: "history" | "camera"
  description?: string
  isScreenPlaceholder?: boolean
  svgHeight?: string
  svgWidth?: string
  isLoading?: boolean
}
const Placeholder: FC<Props> = ({
  alertMessage,
  selectionType = "history",
  description,
  isScreenPlaceholder,
  svgWidth = "182px",
  svgHeight = "220px",
  openSnackbar,
  isLoading,
}) => {
  const EmptyStates = {
    history: <NoHistoryLogs className={styles.placeholder} height={svgHeight} width={svgWidth ?? ""} />,
    camera: <NoCamera className={styles.placeholder} height={svgHeight} width={svgWidth ?? ""} />,
  }

  const showAlert = useCallback(() => {
    if (alertMessage && openSnackbar) {
      NotificationUtils.toast(alertMessage, {
        severity: "warning",
      })
    }
  }, [alertMessage, openSnackbar])

  useEffect(() => {
    showAlert()
  }, [showAlert])

  return (
    <div
      className={styles.placeholderWrapper}
      style={{
        height: isScreenPlaceholder ? "70vh" : "100%",
        background: isScreenPlaceholder ? "var(--base-background-3)" : "var(--base-background-1)",
      }}
    >
      {isLoading ? (
        <CircularProgress size={70} />
      ) : (
        <Fragment>
          {EmptyStates[selectionType]}
          <Typography variant={isScreenPlaceholder ? "h2-bold" : "label-bold"} variantColor={2}>
            {description}
          </Typography>
        </Fragment>
      )}
    </div>
  )
}
export default Placeholder
