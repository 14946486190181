import React, { FC, useEffect, useState } from "react"

import { Pagination } from "@synapse-analytics/synapse-ui"

import { useDebounce } from "../../../hooks/useDebounce"

import styles from "./CustomPagination.module.scss"

/**
 * Interface for the properties of the CustomPagination component.
 */
interface CustomPaginationProps {
  /**
   * The variant of the pagination component. Defaults to "complex".
   */
  variant?: "simple" | "complex"
  /**
   * The size of the pagination component. Defaults to "default".
   */
  size?: "small" | "default"
  /**
   * The total count of items for pagination.
   */
  count: number
  /**
   * The current page number.
   */
  page: number
  /**
   * Callback function to handle page change.
   */
  onPageChange: (event: any, page: number) => void
  /**
   * The number of rows per page.
   */
  rowsPerPage: number
  /**
   * Callback function to set the number of rows per page.
   */
  setRowsPerPage: (rowsPerPage: number) => void
  /**
   * Indicates if the row count should be hidden. Defaults to false.
   */
  hideRowCount?: boolean
  /**
   * The debounce time for the page change callback. Defaults to 800ms.
   */
  debounceTime?: number
  /**
   * Indicates if debouncing should be applied. Defaults to true.
   */
  enableDebounce?: boolean
}

/**
 * CustomPagination component for handling pagination in tables.
 *
 * @param {CustomPaginationProps} props - The properties for the component.
 * @returns {React.ReactElement | null} - The rendered pagination component or null.
 */
const CustomPagination: FC<CustomPaginationProps> = ({
  variant = "complex",
  size = "default",
  count,
  page,
  onPageChange: externalOnPageChange,
  rowsPerPage,
  setRowsPerPage,
  hideRowCount = false,
  debounceTime = 800,
  enableDebounce = true,
}): React.ReactElement | null => {
  const [internalPage, setInternalPage] = useState<number>(page + 1)

  const debouncedOnPageChange = useDebounce(externalOnPageChange, debounceTime)

  const onPageChange = (newPage: number): void => {
    setInternalPage(newPage)
    if (enableDebounce) {
      debouncedOnPageChange(undefined, newPage - 1)
    } else {
      externalOnPageChange(undefined, newPage - 1)
    }
  }

  // to handle if page is forced to change from outside without calling local onPageChange so internalPage must be updated
  useEffect(() => {
    setInternalPage(page + 1)
  }, [page])

  return (
    <td className={styles.pagination}>
      <Pagination
        variant={variant}
        totalCount={count}
        page={internalPage}
        onPageChange={onPageChange}
        pageSize={rowsPerPage}
        onPageSizeChange={setRowsPerPage}
        hidePageSize={hideRowCount}
        size={size}
      />
    </td>
  )
}
export default CustomPagination
