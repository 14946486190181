import { FC, useState, ChangeEvent } from "react"

import StackedBarChartIcon from "@mui/icons-material/StackedBarChart"
import TableViewIcon from "@mui/icons-material/TableView"
import { Box, Card, Grid } from "@mui/material"

import { Select, Typography } from "@synapse-analytics/synapse-ui"

import { LineGraphData, TableColumn } from "../../types/Custom/Types"
import ButtonSwitch from "../Buttons/ButtonSwitch"
import DailyLineGraph from "../graphs/DailyLineGraph"
import HourlyLineGraph from "../graphs/HourlyLineGraph"
import GenericTable from "../tables/GenericTable"

import styles from "./DataOverTimeCard.module.scss"

/**
 * Interface for the properties of the graph in the DataOverTimeCard component
 */
interface GraphProps {
  /**
   * The data to be displayed in the graph.
   */
  data?: LineGraphData[]
  /**
   * Indicates if the data includes age and gender.
   */
  shouldDisplayDistribution?: boolean
  /**
   * The interval of the date range, used to determine the frequency of data points.
   */
  interval?: Duration
  /**
   * Determines if checkboxes for filtering data should be displayed.
   */
  hasCheckbox?: boolean
}

/**
 * Interface for the properties of the table in the DataOverTimeCard component
 */
interface TableProps {
  /**
   * The data to be displayed in the table.
   */
  data?: any[]
  /**
   * The columns to be displayed in the table.
   */
  columns: TableColumn[]
}

/**
 * Interface for the properties of the DataOverTimeCard component
 */
/**
 * Interface for the properties of the DataOverTimeCard component
 */
interface Props {
  /**
   * Optional properties for the graph component
   */
  graphProps?: GraphProps
  /**
   * Optional properties for the table component
   */
  tableProps?: TableProps
  /**
   * The time grain for the data, either "hour" or "day"
   */
  timeGrain?: "hour" | "day" | null
  /**
   * Indicates if the data is currently loading
   */
  isLoading: boolean
  /**
   * A reference to the component or its container
   */
  reference: any
  /**
   * Optional height for the content area
   */
  contentHeight?: number
  /**
   * Optional flag to include missing data in the graph or table
   */
  shouldIncludeMissingData?: boolean
  /**
   * Optional flag to include a select for data type
   */
  hasDataTypeSelect?: boolean
  /**
   * Optional array of data type options for the select
   */
  dataOptions?: string[]
  /**
   * Optional function to set the data type
   */
  setDataType?: (type: string) => void
  /**
   * Optional current data type
   */
  dataType?: string
}

/**
 * Function to get the initial active tab based on the presence of graph and table properties
 * @param graphProps - The properties of the graph
 * @param tableProps - The properties of the table
 * @returns The initial active tab index
 */
const getInitialActiveTab = ({
  graphProps,
  tableProps,
}: {
  graphProps?: GraphProps
  tableProps?: TableProps
}): number => {
  if (graphProps && tableProps) {
    return 0 // Default to graph if both are present
  } else if (graphProps) {
    return 0 // Graph only
  } else if (tableProps) {
    return 1 // Table only
  }
  return 0 // Default to graph if none are present
}

/**
 * The DataOverTimeCard component is designed to display data over a specified time period, allowing users to switch between a graph and table view.
 * It also includes features such as a data type selector, optional inclusion of missing data, and customizable content height.
 */
const DataOverTimeCard: FC<Props> = ({
  timeGrain,
  isLoading,
  reference,
  shouldIncludeMissingData,
  graphProps,
  tableProps,
  hasDataTypeSelect,
  dataType = "Gates counts",
  setDataType,
  dataOptions = ["Gates counts", "Dwelling counts", "Dwelling time"],
  contentHeight = 320,
}) => {
  const [activeTab, setActiveTab] = useState(getInitialActiveTab({ graphProps, tableProps }))

  const handleDataTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDataType?.(event.target.value as "Gates counts" | "Dwelling counts" | "Dwelling time")
  }

  const graphTitle = `${timeGrain === "hour" ? "Hourly " : "Daily "}${dataType}`
  return (
    <Card className={styles.wrapper} ref={reference}>
      <Grid
        container
        className={styles.header}
        style={{
          marginBottom: shouldIncludeMissingData ? 0 : "16px",
        }}
      >
        <Grid item xs={12} md={6} className={styles.titleAndSelect}>
          <Typography variant="h2-bold" variantColor={2} noWrap textTransform="lowercase">
            {graphTitle} {activeTab === 0 ? "Line Graph" : "Table"}
          </Typography>
          {hasDataTypeSelect && <Select options={dataOptions} value={dataType} handleChange={handleDataTypeChange} />}
        </Grid>
        <Grid item xs={12} md={6} display="flex" justifyContent="flex-end">
          {graphProps && tableProps && (
            <ButtonSwitch
              activePage={activeTab}
              pages={["Graph", "Table"]}
              handleSelectButton={setActiveTab}
              disabled={isLoading}
              pagesIcons={[<StackedBarChartIcon />, <TableViewIcon />]}
            />
          )}
        </Grid>
      </Grid>
      {shouldIncludeMissingData && (
        <Box display="flex" alignItems="center" mb={1}>
          <span className={styles.colorSquare} />
          <Typography variant="p">Missing data generated by the admin</Typography>
        </Box>
      )}
      {activeTab === 0 && graphProps ? (
        timeGrain === "hour" ? (
          <HourlyLineGraph
            data={graphProps.data || [{ id: "", data: [{ x: new Date(), y: 0 }] }]}
            graphHeight={contentHeight}
            isLoading={isLoading}
            hasCheckbox={graphProps.hasCheckbox && !graphProps.shouldDisplayDistribution}
            hasGenderAge={graphProps.shouldDisplayDistribution}
          />
        ) : (
          <DailyLineGraph
            data={graphProps.data || [{ id: "", data: [{ x: new Date(), y: 0 }] }]}
            graphHeight={contentHeight}
            isLoading={isLoading}
            hasCheckbox={graphProps.hasCheckbox && !graphProps.shouldDisplayDistribution}
            hasGenderAge={graphProps.shouldDisplayDistribution}
            interval={graphProps.interval}
          />
        )
      ) : activeTab === 1 && tableProps ? (
        <GenericTable
          columns={tableProps.columns || []}
          data={tableProps.data}
          isLoading={isLoading}
          title={graphTitle}
          height={contentHeight + 40}
        />
      ) : null}
    </Card>
  )
}
export default DataOverTimeCard
