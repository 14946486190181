import { FC, useCallback, useEffect, useMemo, useState } from "react"

import TotalIcon from "@mui/icons-material/AnalyticsOutlined"
import PeakIcon from "@mui/icons-material/TrendingUpOutlined"

import { Tag, Typography } from "@synapse-analytics/synapse-ui"
import { isEqual } from "lodash"
import moment, { Moment } from "moment"

import ButtonSwitch from "../../../components/Buttons/ButtonSwitch"
import HeatmapPlayer from "../../../components/HeatmapPlayer"
import Placeholder from "../../../components/Placeholder"
import { definitions } from "../../../types/Generated/apiTypes"
import { generateHourMarks } from "../../../utils/HeatmapV2Utils"

import styles from "./HeatmapTimeline.module.scss"

type HeatMapHourly = definitions["HeatMapHourly"]
type FloorPlanVersionList = definitions["FloorPlanVersionList"]

/**
 * Props for the HeatmapTimeline component.
 */
interface HeatmapTimelineProps {
  /** Grouped prop containing data about selected camera. */
  selectedCamera?: {
    /** The name of the selected camera. */
    name?: string
    /** The image URL to display as the background of the heatmap. */
    image?: string
  }
  /** Grouped prop containing data about selected floor */
  selectedFloor?: {
    setActiveFloorPlan?: (floor: FloorPlanVersionList) => void
    /** The version of the selected floor. */
    floorPlanVersions?: FloorPlanVersionList[]
    /** The first active version that has logs (initial state). */
    activeFloorPlan?: FloorPlanVersionList
  }
  /** Grouped prop containing state and state setter for aggregation mode. */
  aggregation: {
    /** The current heatmap aggregation mode (e.g., 0 for "total", 1 for "peak"). */
    aggregationMode: number
    /** Callback function to set the heatmap aggregation mode (e.g., "total" or "peak"). */
    setAggregationMode: (mode: number) => void
  }
  /** Whether the component is in a loading state. */
  isLoading?: boolean
  /** The heatmap data to display. */
  data?: HeatMapHourly[]
  /** No floor plan version or camera frame placeholder . */
  emptySelectionPlaceholder?: string
  /** selected day to fetch versions and logs */
  selectedDay?: Moment | null
}

/**
 * A component to display a heatmap timeline with interactive controls.
 */
const HeatmapTimeline: FC<HeatmapTimelineProps> = ({
  selectedCamera,
  emptySelectionPlaceholder,
  isLoading,
  data,
  aggregation,
  selectedDay,
  selectedFloor,
}) => {
  /**
   * Generates marks for the heatmap player based on the heatmap data.
   * This prevents unnecessary recalculations unless `data` changes.
   *
   * @returns {Array} - Array of heatmap marks for the timeline.
   */
  const marks = useMemo(() => {
    if (data && data.length > 0) return generateHourMarks(data)
    return []
  }, [data])

  /**
   * Calculates the maximum heatmap value from the logs.
   * It iterates through all available logs and finds the highest `val`.
   *
   * @returns {number} - The highest value in the heatmap logs or `0` if no data.
   */
  const maxValue = useMemo(() => {
    if (!data || data.length === 0) return 0

    return data.reduce(
      (maxLogValue, log) =>
        log.logs.reduce((maxHeatMapValue, heatMapLog) => Math.max(maxHeatMapValue, heatMapLog.val), maxLogValue),
      -Infinity
    )
  }, [data])

  /**
   * Determines if the component should display an empty state.
   * The component is empty if there is no camera/floor selection or no data logs.
   */
  const isEmpty = [selectedCamera || selectedFloor, data && data.length > 0].some((item) => !item)

  const [isPlaying, setIsPlaying] = useState(false)
  const [sliderValue, setSliderValue] = useState(0)
  const [heatmapInstance, setHeatmapInstance] = useState<any>()

  /**
   * Updates the heatmap visualization based on the selected slider position.
   * - Clears existing heatmap data.
   * - Finds relevant logs corresponding to the selected hour.
   * - Updates the heatmap instance with the new data.
   *
   * @param {number} sliderValue - The selected hour on the slider.
   */
  const drawHeatmapData = useCallback(
    (sliderValue: number): void => {
      if (heatmapInstance?.getData()?.data?.length > 0) {
        heatmapInstance.setData({ data: [] })
      }

      if (data && data.length > 0) {
        const dataToDraw = data.find((log) => log.hour === marks[sliderValue]?.value)?.logs || []

        const heatmapInstanceData = {
          max: maxValue * (marks?.length === 24 ? 0.2 : 1),
          data: dataToDraw,
        }

        if (dataToDraw.length > 0) {
          heatmapInstance?.setData(heatmapInstanceData)
        }
      }
    },
    [data, heatmapInstance, marks, maxValue]
  )

  /**
   * Handles updating the slider value.
   * If a floor heatmap is used, it finds and sets the appropriate floor version.
   *
   * @param {number} newSliderValue - The new selected slider value.
   */
  const updateSliderValue = useCallback(
    (newSliderValue: number): void => {
      if (
        Boolean(selectedFloor) &&
        selectedFloor?.floorPlanVersions &&
        selectedFloor?.floorPlanVersions.length > 0 &&
        selectedDay
      ) {
        const selectedHour = marks[newSliderValue]?.value
        const targetDateTime = moment(selectedDay).set("hour", selectedHour)

        const newActiveVersion = selectedFloor.floorPlanVersions.find((version) => {
          const versionCreatedAt = moment(version.created_at)
          const versionArchivedAt = version.archived_at ? moment(version.archived_at) : null

          return (
            versionCreatedAt.isSameOrBefore(targetDateTime) &&
            (!versionArchivedAt || versionArchivedAt.isAfter(targetDateTime))
          )
        })

        if (newActiveVersion && !isEqual(newActiveVersion, selectedFloor?.activeFloorPlan)) {
          selectedFloor?.setActiveFloorPlan?.(newActiveVersion)
        }
      }

      setSliderValue(newSliderValue)
    },
    [marks, selectedDay, selectedFloor]
  )

  /**
   * Effect: Updates the heatmap data when the heatmap instance initializes or slider changes.
   */
  useEffect(() => {
    if (heatmapInstance) drawHeatmapData(sliderValue)
  }, [drawHeatmapData, sliderValue, heatmapInstance])

  /**
   * Effect: Handles autoplay functionality for the heatmap timeline.
   * Plays the timeline by incrementing the slider value at a set interval.
   * Resets to the beginning if the timeline reaches the last mark.
   */
  useEffect(() => {
    if (isPlaying && marks.length > 0) {
      const timer = setInterval(() => {
        if (sliderValue === marks.length - 1) {
          updateSliderValue(0)
        } else {
          updateSliderValue(sliderValue + 1)
        }
      }, 700)

      return () => clearInterval(timer)
    }
  }, [marks, isPlaying, sliderValue, updateSliderValue])

  /**
   * Handles moving to the previous mark in the timeline.
   */
  const handlePreviousMark = () => updateSliderValue(sliderValue === 0 ? marks.length - 1 : sliderValue - 1)

  /**
   * Toggles the play/pause state of the heatmap timeline.
   * If data exists, toggles the playback and resets the slider if at the end.
   */
  const handlePlayPause = () => {
    if (data && data.length > 0) {
      setIsPlaying((prevValue) => !prevValue)
      if (sliderValue === 24 || sliderValue >= 30) updateSliderValue(0)
    }
  }

  /**
   * Handles moving to the next mark in the timeline.
   */
  const handleNextMark = () => updateSliderValue(sliderValue === marks.length - 1 ? 0 : sliderValue + 1)

  /**
   * Handles direct slider value changes (e.g., clicking a specific mark).
   *
   * @param {number} value - The selected value from the slider.
   */
  const handleSliderChange = (value: number) => {
    const newIndex = marks.findIndex((mark) => mark.value === value)
    if (newIndex !== -1) updateSliderValue(newIndex)
  }

  const emptyStateDescription = emptySelectionPlaceholder
    ? emptySelectionPlaceholder
    : data && data?.length < 1
    ? "No Data Found"
    : ""

  return (
    <div className={styles.container}>
      <div className={styles.timelineHeader}>
        <div className={styles.nameAndVersion}>
          <Typography variant="h2-bold">
            {selectedFloor?.activeFloorPlan?.floor_name || selectedCamera?.name}
          </Typography>
          {Boolean(selectedFloor) && selectedFloor?.activeFloorPlan && (
            <Tag color="gray">Version {selectedFloor?.activeFloorPlan?.version_no}</Tag>
          )}
        </div>
        <ButtonSwitch
          activePage={aggregation?.aggregationMode}
          pages={["total", "peak"]}
          handleSelectButton={aggregation?.setAggregationMode}
          disabled={isLoading}
          pagesIcons={[<TotalIcon />, <PeakIcon />]}
        />
      </div>
      {!isEmpty ? (
        <HeatmapPlayer
          maxValue={maxValue.toLocaleString()}
          image={selectedFloor ? selectedFloor?.activeFloorPlan?.image : selectedCamera?.image}
          isFloorsHeatmap={Boolean(selectedFloor)}
          slider={{
            isPlaying: isPlaying,
            onPlayPause: handlePlayPause,
            onPreviousMark: handlePreviousMark,
            onNextMark: handleNextMark,
            marks: marks,
            value: sliderValue,
            onChange: handleSliderChange,
          }}
          heatmapInstance={heatmapInstance}
          setHeatmapInstance={setHeatmapInstance}
        />
      ) : (
        <Placeholder
          isScreenPlaceholder
          isLoading={isLoading}
          selectionType="history"
          description={emptyStateDescription}
        />
      )}
    </div>
  )
}

export default HeatmapTimeline
