export const gridTheme = {
  fontSize: 12,
  fontFamily: "Inter",
  fontWeight: 700,
  textColor: "var(--base-text-3)",
  color: "var(--base-text-3)",
  ticks: {
    text: {
      color: "var(--base-text-3)",
      fontSize: 12,
      fontWeight: 700,
    },
  },
}
