import { useState, FC, useEffect, Fragment } from "react"

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined"
import { TableCell } from "@mui/material"
import IconButton from "@mui/material/IconButton"

import { InputText } from "@synapse-analytics/synapse-ui"

import styles from "./EditableTableCell.module.scss"

type OnEditFunction = (editedValue?: number) => void
interface Props {
  value?: number
  onEdit?: OnEditFunction
}
const EditableCell: FC<Props> = ({ value, onEdit }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedValue, setEditedValue] = useState(value)

  // ensure default value for input text is the actual current value of the cell
  useEffect(() => {
    setEditedValue(value)
  }, [value])

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleSave = () => {
    setIsEditing(false)
    onEdit?.(editedValue)
  }

  return (
    <TableCell
      classes={{
        head: styles.head,
        body: styles.body,
        root: styles.cellRoot,
      }}
      align="left"
    >
      {isEditing ? (
        <div className={styles.inputContainer}>
          <IconButton onClick={handleSave} size="small" color="success">
            <CheckOutlinedIcon />
          </IconButton>
          <InputText
            type="number"
            hideDescription
            value={editedValue}
            min={0}
            width={50}
            handleChange={(e) => {
              const targetValue = e.target as HTMLInputElement
              setEditedValue(targetValue.valueAsNumber)
            }}
          />
        </div>
      ) : (
        <Fragment>
          <IconButton onClick={handleEdit} size="small">
            <ModeEditOutlinedIcon />
          </IconButton>
          {value}
        </Fragment>
      )}
    </TableCell>
  )
}

export default EditableCell
