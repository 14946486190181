import React, { FC } from "react"

import CircularProgress from "@mui/material/CircularProgress"

import { Typography } from "@synapse-analytics/synapse-ui"

import useCarBrand from "./useCarBrand"

interface Props {
  carBrand: string
}

const CarBrandIcon: FC<Props> = ({ carBrand }) => {
  const { loading, error, image } = useCarBrand(carBrand)

  if (error)
    return (
      <Typography
        variant="p"
        style={{
          marginRight: "8px",
        }}
      >
        {carBrand}
      </Typography>
    )

  return (
    <>
      {loading ? (
        <CircularProgress size={12} style={{ margin: "4px 6px 0px 0px" }} />
      ) : (
        <img
          src={image!}
          alt={carBrand}
          style={{
            borderRadius: "4px",
            width: "24px",
            height: "24px",
            boxSizing: "border-box",
            marginRight: "8px",
          }}
        />
      )}
    </>
  )
}

export default CarBrandIcon
