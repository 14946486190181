import Lottie from "react-lottie"

import animationData from "./assets/loadingAnimation.json"

import styles from "./GraphLoadingState.module.scss"

const GraphLoadingState = () => {
  return (
    <div className={styles.loadingContainer}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={200}
        width={200}
        isClickToPauseDisabled
      />
    </div>
  )
}
export default GraphLoadingState
