import React, { FC, useEffect, useState, Fragment, ReactNode } from "react"
import { Route, Routes, useNavigate, useLocation } from "react-router-dom"

import HistoryIcon from "@mui/icons-material/History"
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled"

import ButtonSwitch from "./Buttons/ButtonSwitch"

interface Props {
  pages: string[]
  firstPage: React.ReactElement
  firstPath: string
  pathsParent: string
  secondPage?: React.ReactElement
  secondPath?: string
  usePageIndex?: boolean
  getPageIndex?: (index: number) => void
  liveHistory?: boolean
  setLiveHistoryStatus?: (page: string) => void
  headerItems?: ReactNode
}

const TogglePages: FC<Props> = ({
  pages,
  firstPage,
  firstPath,
  pathsParent,
  secondPage,
  secondPath,
  getPageIndex,
  usePageIndex,
  liveHistory,
  setLiveHistoryStatus,
  headerItems,
}) => {
  const [activePage, setActivePage] = useState(0)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  useEffect(() => {
    setActivePage(0)
  }, [])
  useEffect(() => {
    const firstPageMatch = pathname.includes(`${firstPath}`)
    const secondPageMatch = pathname.includes(`${secondPath}`)
    if (firstPageMatch) {
      setActivePage(0)
      if (usePageIndex) getPageIndex!(0)
    } else if (secondPageMatch) {
      setActivePage(1)
      if (usePageIndex) getPageIndex!(1)
    }
  }, [firstPath, secondPath, pathname, usePageIndex, getPageIndex])

  const handleActivePage = (i: number) => {
    setActivePage(i)
  }

  const handleSelectButton = (i: number) => {
    if (i === 0) {
      navigate(`/${pathsParent}/${firstPath}`, { replace: true })
      if (liveHistory && setLiveHistoryStatus) {
        setLiveHistoryStatus("live")
      }
    } else if (i === 1) {
      navigate(`/${pathsParent}/${secondPath}`, { replace: true })
      if (liveHistory && setLiveHistoryStatus) {
        setLiveHistoryStatus("history")
      }
    }
    handleActivePage(i)

    if (usePageIndex) getPageIndex!(i)
  }

  return (
    <Fragment>
      <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <ButtonSwitch
          liveHistory={liveHistory!}
          activePage={activePage}
          pages={pages}
          handleSelectButton={handleSelectButton}
          pagesIcons={[<PlayCircleFilledIcon />, <HistoryIcon />]}
        />
        {headerItems}
      </div>

      <Routes>
        <Route path={`/${firstPath}`} element={firstPage} />

        <Route path={`/${secondPath}`} element={secondPage} />
      </Routes>
    </Fragment>
  )
}

export default TogglePages
