import { FC } from "react"
import { useNavigate } from "react-router-dom"

import NodesIcon from "@mui/icons-material/DnsOutlined"
import UnreadIcon from "@mui/icons-material/FiberManualRecord"
import UCIcon from "@mui/icons-material/SentimentVeryDissatisfied"
import CameraDownIcon from "@mui/icons-material/VideocamOffOutlined"
import CVCIcon from "@mui/icons-material/VideocamOutlined"

import { Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { format } from "date-fns"
import moment from "moment"

import { routes } from "../../../routes/routes"
import { setIsMultipleAnalytics } from "../../../store"
import { definitions } from "../../../types/Generated/apiTypes"
import auth from "../../../utils/auth"

import styles from "./NotificationCard.module.scss"

type Notification = definitions["Notification"]

interface Props {
  notification?: Notification
  handleCloseNotificationsMenu: () => void
  handleReadNotification: ({ notificationId }: { notificationId?: number }) => void
}

const notificationIcons = {
  CVC: <CVCIcon className={styles.notificationIcon} />,
  CD: <CameraDownIcon className={styles.notificationIcon} />,
  NSC: <NodesIcon className={styles.notificationIcon} />,
  UC: <UCIcon className={styles.notificationIcon} />,
}

const NotificationCard: FC<Props> = ({ notification, handleCloseNotificationsMenu, handleReadNotification }) => {
  const navigate = useNavigate()

  const handleNotificationAction = () => {
    handleReadNotification({ notificationId: notification?.id })
    const { type, extra } = notification || {}

    const notificationRoutes = {
      CD: () => {
        if (auth.getCamerasPermission()) {
          navigate(
            extra?.id
              ? `/${routes.cameraList}/admin-camera-details/${extra.id}?branch=${notification?.branch}`
              : `/${routes.cameraList}?branch=${notification?.branch}&filterStatus=camerasDown%3Atrue`,
            {
              state: { id: extra?.id },
            }
          )
        }
      },
      CVC: () => {
        if (auth.getCamerasPermission()) {
          navigate(`/${routes.cameraViewChanges}?branch=${notification?.branch}`)
        }
      },
      NSC: () => {
        if (auth.getNodesPermission()) {
          if (notification?.extra?.state === "down") {
            navigate(`/${routes.nodesList}?branch=${notification?.branch}`)
          } else {
            navigate(`/${routes.nodeDownAnalytics}/${extra?.id}?branch=${notification?.branch}`, {
              state: {
                name: extra?.name,
                id: extra?.id,
              },
            })
          }
        }
      },
    }

    notificationRoutes[type as keyof typeof notificationRoutes]?.()

    setIsMultipleAnalytics(false)
    handleCloseNotificationsMenu()
  }

  const renderNotificationText = () => {
    const typeText = {
      CD: "is down",
      NSC: notification?.extra?.state === "down" ? "is down" : "is now up",
      CVC: "view has changed",
      UC: "has unserved customer",
    }

    const nameFallback = {
      CVC: "A Camera",
      CD: "A Camera",
      NSC: "A Node",
      UC: "An Entity",
    }

    const entityName = notification?.extra?.name || nameFallback[notification?.type as keyof typeof nameFallback]

    return (
      <Typography variant="p" variantColor={notification?.read ? 2 : 1}>
        <b>{entityName}</b> from <b>{notification?.branch_name} </b>
        {typeText[notification?.type as keyof typeof typeText]}
      </Typography>
    )
  }

  return (
    <div className={styles.wrapper} onClick={handleNotificationAction}>
      <div className={styles.iconAndContent}>
        {notification?.type && <div>{notificationIcons[notification.type]}</div>}

        <div className={styles.contentWrapper}>
          {renderNotificationText()}
          {notification?.timestamp && (
            <Tooltip title={format(new Date(notification.timestamp), "dd/MM/yyyy, p")} placement="left">
              <Typography variant="span-bold" variantColor={2} style={{ width: "fit-content" }}>
                {moment(notification.timestamp).fromNow()}
              </Typography>
            </Tooltip>
          )}
        </div>
      </div>

      {!notification?.read && <UnreadIcon className={styles.unreadIcon} />}
    </div>
  )
}

export default NotificationCard
