import { useState } from "react"
import { useQuery } from "react-query"

import { NotificationUtils, Select, SingleDayPicker } from "@synapse-analytics/synapse-ui"
import { NumberParam, useQueryParam, withDefault } from "use-query-params"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../API/VisionAPI"
import { useDateQuery } from "../../hooks/useDateQuery"
import { useBranchesStore } from "../../store"
import { definitions } from "../../types/Generated/apiTypes"
import HeatmapTimeline from "./components/HeatmapTimeline"

import styles from "./Heatmap.module.scss"

type Floors = definitions["FloorPlanList"]
type HeatMapHourly = definitions["HeatMapHourly"]
type FloorPlanVersionList = definitions["FloorPlanVersionList"]
/**
 * FloorsHeatmap component displays a heatmap for selected floor and date.
 * It allows users to select a branch, date, and floor to view corresponding heatmap data.
 * The component fetches floor plans, floor plan versions, and heatmap logs using the VisionAPI.
 * It also provides a date picker and a floor selection dropdown for user interaction.
 */
const FloorsHeatmap = () => {
  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  const [selectedDate, setSelectedDate] = useDateQuery({ isDayPicker: true })

  const [selectedFloor, setSelectedFloor] = useQueryParam("floor", NumberParam)
  const [aggregationMode, setAggregationMode] = useQueryParam("aggregationMode", withDefault(NumberParam, 0))
  const [activeVersion, setActiveVersion] = useState<FloorPlanVersionList>()

  const { data: floors, isLoading: isFloorsLoading } = useQuery<Floors[]>(
    ["fetchFloors", selectedBranch],
    ({ queryKey }) => VisionAPI.fetchFloorPlans({ branch: queryKey[1] as number }),
    {
      enabled: !!selectedBranch,
    }
  )

  const { data: floorPlanVersions = [], isLoading: isFloorVersionsLoading } = useQuery<FloorPlanVersionList[]>(
    [
      "fetchFloorPlanVersions",
      selectedFloor,
      selectedDate?.startOf("day")?.toISOString(),
      selectedDate?.endOf("day")?.toISOString(),
    ],
    async ({ queryKey }) =>
      VisionAPI.fetchFloorPlanVersions({
        floor_id: queryKey[1] as number,
        from_dt: queryKey[2] as string,
        to_dt: queryKey[3] as string,
      }),
    {
      onSuccess: (data) => {
        if ((data && data.length < 1) || !data) {
          NotificationUtils.toast("No Floor versions to display in selected date range", {
            severity: "info",
          })
        }
      },
      enabled: !isFloorsLoading && !!selectedFloor && !!selectedDate,
    }
  )

  const { data: heatmapData, isLoading: isHeatmapDataLoading } = useQuery<HeatMapHourly[]>(
    ["fetchFloorHeatmapV2Logs", floorPlanVersions, selectedDate?.format("YYYY-MM-DD"), aggregationMode === 1],
    async ({ queryKey }) => {
      const floorPlanVersions = queryKey[1] as FloorPlanVersionList[]

      // Fetch logs for all floor plan versions concurrently
      const allLogs = await Promise.all(
        floorPlanVersions.map((version) =>
          VisionAPI.fetchHeatmapV2FloorVersionLogs({
            floor_version_id: version.id as number,
            date: queryKey[2] as string,
            peak: queryKey[3] as boolean,
          })
        )
      )

      // Find the first version that has logs
      const firstVersionWithLogs = floorPlanVersions.find((_, index) => allLogs[index]?.length > 0)

      // Set the activeVersion state to the first version with logs
      if (firstVersionWithLogs) {
        setActiveVersion(firstVersionWithLogs)
      } else {
        setActiveVersion(undefined)
      }

      // Aggregate all logs into a single array
      return allLogs.flat()
    },
    {
      onSuccess: (data) => {
        if ((data && data.length < 1) || !data) {
          NotificationUtils.toast("No heatmap logs to display in selected date range", {
            severity: "info",
          })
        }
      },
      enabled: !!selectedDate && !!selectedFloor && floorPlanVersions && floorPlanVersions.length > 0,
    }
  )

  const mappedFloors =
    floors && floors?.length > 0
      ? floors.map((floor) => {
          return {
            label: floor.floor_name,
            value: floor.id as number,
          }
        })
      : []

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.datePicker}>
          <SingleDayPicker
            date={selectedDate}
            disableFuture
            onDateChange={(date) => {
              setSelectedDate(date)
              setActiveVersion(undefined)
            }}
          />
        </div>
        <Select
          id="floor-select"
          placeholder="Select Floor"
          value={selectedFloor as number}
          handleChange={(e) => {
            setSelectedFloor(e.target.value as number)
            setActiveVersion(undefined)
          }}
          loading={isFloorsLoading}
          optionsWithValues={mappedFloors}
          width={235}
          isNotchedLabel
          hideDescription
        />
      </div>
      <HeatmapTimeline
        data={heatmapData}
        isLoading={isFloorVersionsLoading || isHeatmapDataLoading}
        aggregation={{
          aggregationMode,
          setAggregationMode,
        }}
        selectedFloor={
          selectedFloor && activeVersion && floorPlanVersions && floorPlanVersions?.length > 0
            ? {
                floorPlanVersions,
                activeFloorPlan: activeVersion,
                setActiveFloorPlan: setActiveVersion,
              }
            : {}
        }
        emptySelectionPlaceholder={
          selectedFloor && floorPlanVersions && floorPlanVersions?.length < 1 ? "No floor versions found" : ""
        }
        selectedDay={selectedDate}
        key={`Heatmap_For_Floor_${selectedFloor}_aggregated_${aggregationMode}`}
      />
    </div>
  )
}
export default FloorsHeatmap
