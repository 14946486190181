import { FC } from "react"

import MotionIcon from "@mui/icons-material/DirectionsRunOutlined"
import ViolenceIcon from "@mui/icons-material/GppBadOutlined"
import FireIcon from "@mui/icons-material/LocalFireDepartment"
import StrayAnimalIcon from "@mui/icons-material/Pets"

import { Typography } from "@synapse-analytics/synapse-ui"

import BlacklistCarIcon from "../assets/eventsIcons/blacklistCar.svg?react"
import BlacklistPersonIcon from "../assets/eventsIcons/blacklistPerson.svg?react"
import IntrusionIcon from "../assets/eventsIcons/intrusion.svg?react"

import styles from "./EventTypeChip.module.scss"

// events array contain the styling information of each event type
type EventColor =
  | "base"
  | "important"
  | "negative"
  | "positive"
  | "warning"
  | "inverse"
  | "purple"
  | "orange"
  | "gray"
  | "pink"

const events = [
  {
    event: "Fire",
    color: "warning" as EventColor,
    background: "var(--yellow-background-2)",
    icon: <FireIcon width={20} height={20} />,
  },
  {
    event: "Violence",
    color: "negative" as EventColor,
    background: "var(--red-background-2)",
    icon: <ViolenceIcon width={20} height={20} />,
  },
  {
    event: "Motion",
    color: "positive" as EventColor,
    background: "var(--green-background-2)",
    icon: <MotionIcon width={24} height={20} />,
  },
  {
    event: "Intrusion",
    color: "pink" as EventColor,
    background: "var(--pink-background-2)",
    icon: <IntrusionIcon width={20} height={20} />,
  },
  {
    event: "Blacklisted Car",
    color: "base" as EventColor,
    background: "var(--gray-background-2)",
    icon: <BlacklistCarIcon width={23} height={23} />,
    colorVariant: 1,
  },
  {
    event: "Blacklisted Person",
    color: "base" as EventColor,
    background: "var(--gray-background-2)",
    icon: <BlacklistPersonIcon width={20} height={20} />,
    colorVariant: 1,
  },
  {
    event: "Stray Animal",
    color: "orange" as EventColor,
    background: "var(--orange-background-2)",
    icon: <StrayAnimalIcon width={20} height={20} fontSize="small" />,
  },
]

/**

 * @interface Props
 */
interface Props {
  /**
   * The type of event.
   *
   * Event Type :: Integer Representation
   *
   * FIRE             = 0
   * VIOLENCE         = 1
   * MOTION           = 2
   * INTRUSION        = 3
   * BLACKLIST_CAR    = 4
   * BLACKLIST_PERSON = 5
   * STRAY_ANIMAL    = 6
   *
   * @type {number}
   */
  type: number
  /**
   * Indicates if the event is a live alert.
   * @type {boolean}
   */
  liveAlert?: boolean
  /**
   * Indicates if the event is for analytics purposes.
   * @type {boolean}
   */
  isAnalytics?: boolean
  /**
   * The count of events.
   * @type {number}
   */
  eventCount?: number
}
const EventTypeChip: FC<Props> = ({ type, liveAlert, isAnalytics, eventCount }) => {
  return (
    <div
      className={`${styles.chipWrapper} ${liveAlert && styles.blink}`}
      style={{
        background: events[type]?.background,
        marginBottom: liveAlert ? "8px" : "0px",
        width: !isAnalytics ? "max-content" : "auto",
      }}
    >
      <Typography
        variant="a"
        color={events[type]?.color}
        variantColor={(events[type]?.colorVariant ?? 2) as 1 | 2}
        className={styles.eventName}
      >
        {events[type]?.icon}
        {events[type]?.event}
      </Typography>
      {isAnalytics && (
        <Typography variant="p" color={events[type]?.color} variantColor={(events[type]?.colorVariant ?? 2) as 1 | 2}>
          {eventCount}
        </Typography>
      )}
    </div>
  )
}
export default EventTypeChip
