import { FC, Fragment } from "react"

import { Divider } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"

import { DistributionData } from "../types/Custom/Interfaces"
import DistributionBar from "./DistributionBar"

import styles from "./DistributionBarsCard.module.scss"

/**
 * Interface for the DistributionBarsCard component props
 */
interface Props {
  /**
   * Array of distribution data to be displayed.
   */
  distributionData?: DistributionData[]
  /**
   * Indicates if the graph tooltip is visible.
   */
  isGraphTooltip?: boolean
  /**
   * The date to be displayed as a tooltip.
   */
  pointDate?: string
  /**
   * Indicates if the component is currently loading.
   */
  isLoading?: boolean
}

/**
 * DistributionBarsCard component
 *
 * This component displays a card with distribution bars and optional tooltip with a date.
 *
 * @param {Props} props - The component props.
 */
const DistributionBarsCard: FC<Props> = ({ distributionData, isGraphTooltip, pointDate, isLoading }: Props) => {
  return (
    <div className={`${styles.distributionCard} ${isGraphTooltip ? styles.graphToolTipWrapper : ""}`}>
      {isGraphTooltip && pointDate && (
        <Typography variant="label-bold" variantColor={2} gutterBottom align="center">
          {pointDate}
        </Typography>
      )}
      {distributionData?.map((data, index) => (
        <div key={index}>
          {data.sectionTitle && (
            <Fragment>
              <Divider className={styles.divider} />
              <Typography variant="p" variantColor={2} className={styles.cardTitle} align="center">
                {data.sectionTitle}
              </Typography>
            </Fragment>
          )}
          <DistributionBar {...data} isLoading={isLoading} />
        </div>
      ))}
    </div>
  )
}
export default DistributionBarsCard
