import { FC, RefObject, useEffect } from "react"

import { Typography, Avatar } from "@synapse-analytics/synapse-ui"
import DOMPurify from "dompurify"
import MarkdownIt from "markdown-it"

import { useTextStream } from "../../../hooks/useTextStream"
import auth from "../../../utils/auth"
import LoadingDots from "../../Loaders/LoadingDots"
import BotAvatar from "../assets/BotAvatar.svg?react"

import styles from "./Message.module.scss"

interface Props {
  /**
   * The sender of the message, either "user" or "bot".
   */
  sender: "user" | "bot"

  /**
   * The content of the message. Defaults to an empty string if not provided.
   */
  content?: string

  /**
   * Indicates if this is the latest message in the chat.
   */
  isLatest?: boolean

  /**
   * Indicates if the message is currently loading.
   */
  isLoading?: boolean

  /**
   * Indicates if there was an error with the message.
   */
  hasError?: boolean

  /**
   * Function to scroll to the bottom of the message container.
   */
  scrollToBottom?: () => void

  /**
   * Function to set the streaming state of the text.
   */
  setIsTextStreaming?: (isStreaming: boolean) => void

  /**
   * Indicates if the text stream should be stopped.
   */
  shouldStopStream?: boolean
  /**
   * A reference to the text area element for handling text input.
   */
  textAreaRef?: RefObject<HTMLTextAreaElement>
}

/**
 * Message Component
 *
 * This component is responsible for rendering a chat message sent by either a user or a bot.
 * It handles message content, displays loading states, manages streaming for bot responses,
 * and supports rich text formatting using Markdown and HTML.
 *
 * @param {Props} props - The properties for the Message component.
 * @returns {JSX.Element} The rendered message component.
 */
const Message: FC<Props> = ({
  sender,
  content = "",
  isLatest,
  isLoading,
  hasError,
  scrollToBottom,
  setIsTextStreaming,
  shouldStopStream,
  textAreaRef,
}) => {
  const isBot = sender === "bot"

  // This hook streams content for bot messages, mimicking a typing effect. For non-bot messages or if it's not the latest,
  // it simply displays the full content.
  const displayedContent = useTextStream(
    content,
    isBot && isLatest && !shouldStopStream,
    15,
    setIsTextStreaming,
    textAreaRef
  )

  // Initializes the Markdown parser with HTML and link support
  const markdownParser = new MarkdownIt({
    // Allows basic HTML tags within Markdown (e.g., <strong>, <em>)
    html: true,
    // Automatically turns URLs into clickable links
    linkify: true,
    // Enhances typography (e.g., replacing quotes with curly quotes)
    typographer: true,
    // Enables line breaks in the Markdown parser, allowing for newline characters to be treated as line breaks.
    breaks: true,
  })

  // Parse the streamed content into HTML from Markdown
  const parsedContent = markdownParser.render(displayedContent || "")

  // Sanitize the generated HTML to prevent XSS attacks by removing potentially harmful elements (e.g., <script>)
  const safeContent = DOMPurify.sanitize(parsedContent)

  /**
   * Scroll to the bottom of the message container when content changes or new messages arrive.
   * This ensures that the user always sees the latest message, even if it’s streamed.
   */
  useEffect(() => {
    scrollToBottom?.()
  }, [displayedContent, scrollToBottom])

  return (
    <div
      className={`${styles.messageWrapper} ${isBot ? styles.botMessageWrapper : styles.userMessageWrapper}`}
      style={{
        alignItems: isLoading ? "center" : "flex-start",
      }}
    >
      {isBot ? (
        <BotAvatar className={styles.botAvatar} />
      ) : (
        // Renders the user's avatar. Uses the auth utility to retrieve the user's full name.
        <Avatar
          name={auth?.getFullName().trim() || "ME"}
          isLoading={false}
          isNameInitials
          size="large"
          isTextVisible={false}
        />
      )}
      <div className={`${styles.message} ${!isBot ? styles.userMessage : ""}`}>
        <Typography variant="h3-regular" color={hasError ? "negative" : "base"} variantColor={hasError ? 2 : 1}>
          {isLoading ? (
            <LoadingDots size="small" />
          ) : hasError ? (
            "Something went wrong!"
          ) : (
            // Render the rich text message content. Uses the sanitized HTML from the parsed Markdown.
            <div dangerouslySetInnerHTML={{ __html: safeContent }} />
          )}
        </Typography>
      </div>
    </div>
  )
}

export default Message
