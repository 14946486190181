import { FC, Fragment, useState } from "react"
import { useNavigate } from "react-router-dom"

import EditIcon from "@mui/icons-material/EditOutlined"
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined"
import { Divider } from "@mui/material"

import { Button, Tag, Typography } from "@synapse-analytics/synapse-ui"

import { routes } from "../../../routes/routes"
import { definitions } from "../../../types/Generated/apiTypes"
import EditNode from "./EditNode"

import styles from "./NodeCard.module.scss"

type Node = definitions["NodeUpdateRetrieve"]

interface Props {
  node: Node
}
const NodeCard: FC<Props> = ({ node }) => {
  const navigate = useNavigate()

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  return (
    <Fragment>
      <div
        className={styles.wrapper}
        onClick={() =>
          navigate(`/${routes?.nodeDownAnalytics}/${node?.id}`, {
            state: {
              name: node?.name,
              id: node?.id,
            },
          })
        }
      >
        <div className={styles.header}>
          <div className={styles.infoSection}>
            <Typography variant="h2-bold" noWrap>
              {node.name}
            </Typography>
            <Button
              variant="ghost-secondary"
              startIcon={<EditIcon fontSize="small" />}
              onClick={(event) => {
                event?.stopPropagation()
                setIsDialogOpen(true)
              }}
            />
          </div>
          <div className={styles.healthAndVersion}>
            <Tag color={node?.connected ? "blue" : "red"} size="xsmall">
              {node?.connected ? "Connected" : "Not Connected"}
            </Tag>
            {node?.core_version && (
              <Typography variant="p" variantColor={2}>
                {node?.core_version}
              </Typography>
            )}
          </div>
        </div>

        <div className={styles.infoSection}>
          <div className={styles.info}>
            <Typography variant="h3-bold" variantColor={2}>
              Slug
            </Typography>
            <Typography variant="h3-regular">{node.slug}</Typography>
          </div>
          <div className={styles.info}>
            <Typography variant="h3-bold" variantColor={2}>
              Cameras count
            </Typography>
            <Typography variant="h3-regular">{node.cameras_count}</Typography>
          </div>
        </div>
        <div className={styles.info}>
          <Typography variant="h3-bold" variantColor={2}>
            Node internet speed
          </Typography>
          <div className={styles.infoSection}>
            <div className={styles.speedSection}>
              <div className={styles.speedIconContainer}>
                <FileDownloadOutlinedIcon className={styles.speedIcon} />
              </div>
              <Typography variant="p" variantColor={2}>
                Download:
              </Typography>
              <Typography variant="h3-regular" noWrap>
                {node?.download_mbps ?? "N/A"} Mbps
              </Typography>
            </div>
            <Divider orientation="vertical" flexItem className={styles.divider} />
            <div className={styles.speedSection}>
              <div className={styles.speedIconContainer}>
                <FileUploadOutlinedIcon className={styles.speedIcon} />
              </div>
              <Typography variant="p" variantColor={2}>
                Upload:
              </Typography>
              <Typography variant="h3-regular" noWrap>
                {node?.upload_mbps ?? "N/A"} Mbps
              </Typography>
            </div>
          </div>
        </div>
        {/* <div className={styles.info}>
        <Typography variant="h3-bold" variantColor={2} gutterBottom>
          Cameras services
        </Typography>
        <Grid container spacing={1.25}>
          {node.services?.map((service) => (
            <Grid item>
              <NodeService service={service} />
            </Grid>
          ))}
        </Grid>
      </div> */}
      </div>
      {isDialogOpen && <EditNode handleClose={() => setIsDialogOpen(false)} open={isDialogOpen} node={node} />}
    </Fragment>
  )
}
export default NodeCard
