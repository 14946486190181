import { FC, useEffect, useMemo, useRef, useState } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { Grid } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"
import { Moment } from "moment"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../../API/VisionAPI"
import DataOverTimeCard from "../../../../components/GraphCards/DataOverTimeCard"
import { useBranchesStore } from "../../../../store"
import { LineGraphData, TableColumn } from "../../../../types/Custom/Types"
import { definitions } from "../../../../types/Generated/apiTypes"
import { convertDailyDwellingToTableLogs, convertDwellingCountsToLineGraph } from "../../../../utils/dwellingUtils"
import { formatTimestampForTable } from "../../../../utils/genericHelpers"
import AvgCountWeek from "../../../EntranceGates/components/AvgCountWeek"
import NumericStats from "./NumericStats"

import styles from "./AreaDetails.module.scss"

type DwellingAreaDailyCounts = definitions["DwellingAreaDailyCounts"]
type DwellingAreaWeekDaysCounts = definitions["DwellingAreaWeekDaysCounts"]
type HourlyAVGCounts = definitions["DwellingAreaHourlyAVGCounts"]
type DwellingAreaAnalytics = definitions["DwellingAreaAnalytics"]
type EntityGroupsCounts = definitions["EntityGroupsCounts"]

type OverTimeTableData = {
  timestamp: Date | string
  "Dwelling counts"?: number
  "Dwelling time"?: number
}

interface Props {
  startDate: Moment | null
  endDate: Moment | null
  timeGrain: "hour" | "day"
  interval: Duration
  setLoadingState: (isLoading: boolean) => void
  staffFilterValue?: boolean | null
}
interface MatchParams {
  id?: string
}

const AreaDetails: FC<Props> = ({ startDate, endDate, timeGrain, interval, setLoadingState, staffFilterValue }) => {
  let params: MatchParams = useParams()
  const hourlyDataRef = useRef(null)

  const [dataType, setDataType] = useState<string>("Dwelling counts")

  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  // The data returned by the query, representing area daily counts
  const { data: dwellingDailyCounts, isLoading: dwellingDailyCountsLoading } = useQuery<DwellingAreaDailyCounts[]>(
    // The query key, which is an array containing the parameters used to fetch the data
    [
      "fetchDwellingDailyCounts",
      // Start date of the logs (converted to string)
      startDate?.format("YYYY-MM-DD"),
      // End date of the logs (converted to string)
      endDate?.format("YYYY-MM-DD"),
      // include staff stats if true , visitors if false , all if null
      staffFilterValue,
      // ID of the selected branch (number)
      parseInt(params!.id!),
    ],
    // Function to fetch the area counts based on the provided parameters
    ({ queryKey }) =>
      VisionAPI.fetchDwellingAreaDailyCounts({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        staff: queryKey[3] as boolean | null,
        id: queryKey[4] as number,
      }),
    {
      enabled: !!endDate,
    }
  )

  // The data returned by the query, representing the hourly average logs for a specific dwelling area
  const { data: areaHourlyAvgLogs, isLoading: avgLogsLoading } = useQuery<HourlyAVGCounts[]>(
    // The query key, which is an array containing the parameters used to fetch the data
    [
      // Identifier for the query (useful for debugging and cache management)
      "fetchAreaHourlyAvgLogs",
      // Start date of the logs (formatted as "YYYY-MM-DD")
      startDate?.format("YYYY-MM-DD"),
      // End date of the logs (formatted as "YYYY-MM-DD")
      endDate?.format("YYYY-MM-DD"),
      // include staff stats if true , visitors if false , all if null
      staffFilterValue,
      // ID of the specific dwelling area (parsed to integer)
      parseInt(params!.id!),
    ],
    // Function to fetch the hourly average counts for the specified dwelling area based on the provided parameters
    ({ queryKey }) =>
      VisionAPI.fetchDwellingAreaHourlyAvgCounts({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        staff: queryKey[3] as boolean | null,
        id: queryKey[4] as number,
      }),
    {
      enabled: !!startDate && !!endDate,
    }
  )

  // The data returned by the query, representing the week days logs for a specific dwelling area
  const { data: areaWeekDaysLogs, isLoading: areaWeekDaysLogsLoading } = useQuery<DwellingAreaWeekDaysCounts[]>(
    // The query key, which is an array containing the parameters used to fetch the data
    [
      // Identifier for the query (useful for debugging and cache management)
      "fetchAreaWeekDaysLogs",
      // Start date of the logs (formatted as "YYYY-MM-DD")
      startDate?.format("YYYY-MM-DD"),
      // End date of the logs (formatted as "YYYY-MM-DD")
      endDate?.format("YYYY-MM-DD"),
      // include staff stats if true , visitors if false , all if null
      staffFilterValue,
      // ID of the specific dwelling area (parsed to integer)
      parseInt(params!.id!),
    ],
    // Function to fetch the hourly average counts for the specified dwelling area based on the provided parameters
    ({ queryKey }) =>
      VisionAPI.fetchDwellingWeekDaysCounts({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        staff: queryKey[3] as boolean | null,
        id: queryKey[4] as number,
      }),
    {
      enabled: !!startDate && !!endDate,
    }
  )

  const { data: dwellingAnalytics, isLoading: isDwellingAnalyticsLoading } = useQuery<DwellingAreaAnalytics[]>(
    [
      "fetchDwellingAreasAnalytics",
      // Start date of the logs (formatted as "YYYY-MM-DD")
      startDate?.format("YYYY-MM-DD"),
      // End date of the logs (formatted as "YYYY-MM-DD")
      endDate?.format("YYYY-MM-DD"),
      // include staff stats if true , visitors if false , all if null
      staffFilterValue,
      // selected branch id
      selectedBranch,
      // ID of the specific dwelling area (parsed to integer)
      parseInt(params!.id!),
    ],
    ({ queryKey }) =>
      VisionAPI.fetchDwellingAreasAnalytics({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        staff: queryKey[3] as boolean | null,
        branch: queryKey[4] as number,
        entity: queryKey[5] as number,
      }),
    {
      enabled: !!startDate && !!endDate && !!selectedBranch,
    }
  )

  const { data: groupsCount, isLoading: isGroupsCountLoading } = useQuery<EntityGroupsCounts>(
    [
      "fetchDwellingAreaGroupsCounts",
      // ID of the specific dwelling area (parsed to integer)
      parseInt(params!.id!),
      // Start date of the logs (formatted as "YYYY-MM-DD")
      startDate?.format("YYYY-MM-DD"),
      // End date of the logs (formatted as "YYYY-MM-DD")
      endDate?.format("YYYY-MM-DD"),
    ],
    ({ queryKey }) =>
      VisionAPI.fetchEntityGroupsCounts(queryKey[1] as number, {
        from_date: queryKey[2] as string,
        to_date: queryKey[3] as string,
        state: "DWELL",
      }),
    {
      enabled: !!startDate && !!endDate && !!selectedBranch,
    }
  )

  useEffect(() => {
    if (
      [dwellingDailyCountsLoading, avgLogsLoading, areaWeekDaysLogsLoading, isDwellingAnalyticsLoading].some(
        (element) => element === true
      )
    ) {
      setLoadingState(true)
    } else {
      setLoadingState(false)
    }
  }, [setLoadingState, dwellingDailyCountsLoading, avgLogsLoading, areaWeekDaysLogsLoading, isDwellingAnalyticsLoading])

  // converting data to suitable format that nivo bar graph accepts
  // using the appropriate formatting function based on the sent data type
  const lineGraphData = useMemo(() => {
    if (!dwellingDailyCountsLoading && dwellingDailyCounts && endDate) {
      const lineGraphTableData: OverTimeTableData[] = convertDailyDwellingToTableLogs(
        dwellingDailyCounts,
        dataType as "Dwelling counts" | "Dwelling time"
      )

      const lineGraphData: LineGraphData[] = convertDwellingCountsToLineGraph(
        dwellingDailyCounts,
        dataType as "Dwelling counts" | "Dwelling time"
      )
      return { lineGraphTableData, lineGraphData }
    }
  }, [dataType, dwellingDailyCounts, dwellingDailyCountsLoading, endDate])

  const lineGraphTableColumns: TableColumn[] = [
    {
      title: "Date/Time",
      field: "timestamp",
      searchable: false,
      render: (rowData: OverTimeTableData) => (
        <div>{formatTimestampForTable(rowData.timestamp, timeGrain as string)}</div>
      ),
    },
    {
      title: `${dataType} ${dataType === "Dwelling time" ? " (minutes)" : ""}`,
      field: dataType,
      searchable: false,
      render: (rowData: OverTimeTableData) => (
        <div>{rowData[dataType as "Dwelling counts" | "Dwelling time"] || 0}</div>
      ),
    },
  ]

  return (
    <div className={styles.wrapper}>
      <Typography variant="a" variantColor={2}>
        Stats
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={2.8} xs={12}>
          {/* Numeric Stat cards  */}
          <NumericStats
            dwellingDailyCounts={dwellingDailyCounts}
            dwellingAreaAnalytics={dwellingAnalytics}
            isLoading={[dwellingDailyCountsLoading, isDwellingAnalyticsLoading, isGroupsCountLoading].some(
              (element) => element === true
            )}
            groupsCount={groupsCount}
          />
        </Grid>
        <Grid item md={9.2} xs={12}>
          {/* Daily Data component */}
          <DataOverTimeCard
            graphProps={{
              data: lineGraphData?.lineGraphData,
              interval: interval,
              shouldDisplayDistribution: true,
              hasCheckbox: false,
            }}
            tableProps={{
              data: lineGraphData?.lineGraphTableData,
              columns: lineGraphTableColumns,
            }}
            timeGrain={timeGrain}
            isLoading={dwellingDailyCountsLoading}
            reference={hourlyDataRef}
            setDataType={setDataType}
            dataType={dataType}
            dataOptions={["Dwelling counts", "Dwelling time"]}
            hasDataTypeSelect
          />
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <AvgCountWeek
            logsData={areaWeekDaysLogs!}
            loading={!!areaWeekDaysLogsLoading}
            interval={interval!}
            isDwellingArea
            key="week-day-average"
          />
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <AvgCountWeek
            logsData={areaHourlyAvgLogs!}
            loading={!!avgLogsLoading}
            interval={interval!}
            hourlyAvg
            isDwellingArea
            key="area-hourly-average"
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default AreaDetails
