import { FC, useEffect, Fragment } from "react"
import { useInfiniteQuery, useQueryClient, useQuery } from "react-query"

import { Skeleton } from "@mui/material"
import Grid from "@mui/material/Grid"
import useMediaQuery from "@mui/material/useMediaQuery"

import { Typography } from "@synapse-analytics/synapse-ui"
import { SwiperSlide } from "swiper/react"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../API/VisionAPI"
import SwiperContainer from "../../../components/SwiperContainer"
import { useBranchesStore } from "../../../store"
import { PaginatedCamerasList } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"
import { extractPageFromBackEndPaginationLink } from "../../../utils/genericHelpers"
import CameraCard from "../components/CameraCard"

import styles from "./OtherCameras.module.scss"

type CameraPaginated = definitions["PaginatedCamerasList"]
type CameraHealthStats = definitions["CamerasHealthStatistics"]

const OtherCameras: FC = () => {
  const xLargeScreen = useMediaQuery("(min-width:1900px)")
  const smallScreen = useMediaQuery("(min-width:1240px)")
  const largeScreen = useMediaQuery("(min-width:1525px)")
  const xSmallScreen = useMediaQuery("(max-width:680px)")
  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )
  const queryClient = useQueryClient()

  let limit = xLargeScreen ? 4 : largeScreen ? 3 : smallScreen ? 2 : xSmallScreen ? 1 : 1

  const {
    data: paginatedCameraData,
    fetchNextPage,
    isLoading: isLoadingCameras,
    isFetchingNextPage,
    isFetchingPreviousPage,
  } = useInfiniteQuery<PaginatedCamerasList>(
    ["fetchCamerasPaginated", selectedBranch, limit],
    ({ queryKey, pageParam = 1 }) =>
      VisionAPI.fetchCamerasPaginated({
        branch: queryKey[1] as number,
        limit: queryKey[2] as number,
        page: pageParam,
        ordering: "-created_at,-id",
      }),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage: PaginatedCamerasList) => {
        return lastPage?.next ? extractPageFromBackEndPaginationLink(lastPage.next) : undefined
      },
    }
  )

  const { data: healthStats, isLoading: isHealthStatsLoading } = useQuery<CameraHealthStats>(
    ["fetchCamerasHealthStats", selectedBranch],
    ({ queryKey }) =>
      VisionAPI.fetchCamerasHealthStats({
        branch: queryKey[1] as number,
      }),
    {
      refetchInterval: 60000,
      enabled: !!selectedBranch,
    }
  )
  const prefetchNext = (nextPage: number) => {
    queryClient.prefetchInfiniteQuery("fetchCamerasPaginated", () =>
      VisionAPI.fetchCamerasPaginated({
        branch: selectedBranch as number,
        limit: limit,
        page: nextPage,
        ordering: "-created_at,-id",
      })
    )
  }

  // prefetch a page ahead on mount , for sliding data component next page button to work.
  useEffect(() => {
    prefetchNext(2)
    //eslint-disable-next-line
  }, [])

  let loadingPlaceholders = new Array(limit).fill(null).map((r, i) => (
    <Grid item sm={12} xs={12} md={4} xl={3} key={i}>
      <Skeleton variant="rectangular" height={536} className={styles.loadingCards} />
    </Grid>
  ))

  const isLoading = isLoadingCameras || isFetchingNextPage || isFetchingPreviousPage || isHealthStatsLoading

  return (
    <Fragment>
      <Typography variant="h2-bold" variantColor={2} style={{ marginBottom: 12 }}>
        Other Cameras
      </Typography>
      {isLoadingCameras || isHealthStatsLoading ? (
        <Grid container spacing={2}>
          {loadingPlaceholders}
        </Grid>
      ) : (
        <SwiperContainer
          slidesPerView={limit}
          spaceBetweenSlides={8}
          slidesToScroll={limit}
          fetchNextPage={fetchNextPage}
          isLoading={isLoading}
          count={paginatedCameraData?.pages[0].count!}
        >
          {paginatedCameraData?.pages.map((page, i) =>
            page.results.map((camera: CameraPaginated, index: number) => (
              <SwiperSlide key={index}>
                <CameraCard hasMargin camera={camera} key={index} healthStats={healthStats} isCameraDetails />
              </SwiperSlide>
            ))
          )}
        </SwiperContainer>
      )}
    </Fragment>
  )
}
export default OtherCameras
