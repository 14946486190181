import { useContext, useRef } from "react"

import { Grid } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"

import Placeholder from "../../../../../components/Placeholder"
import { IEntitiesSetup } from "../../../../../types/Custom/Interfaces"
import { initializeCameraRegions } from "../../../../../utils/EntitiesSetupUtils"
import { EntitiesContext } from "../../EntitiesContext/EntitiesContext"
import CameraSidebar from "./components/CamerasSidebar"
import RegionsCreator from "./components/RegionsCreator"

import styles from "./SelectAndDraw.module.scss"

const SelectAndDraw = () => {
  const {
    formik,
    selectedCameras,
    setSelectedCameras,
    setSelectedDrawingCamera,
    selectedDrawingCamera,
    allRegions,
    setAllRegions,
    handleStateChange,
  } = useContext<IEntitiesSetup>(EntitiesContext)

  const sideBarRef = useRef<HTMLDivElement>(null)

  /**
   * Selects a camera for drawing on the interface.
   * Based on the type of area selected in the form, it adjusts the drawing state accordingly.
   *
   * @param {number} camera - The camera ID that is being selected for drawing.
   */
  const handleSelectCameraForDrawing = (camera: number) => {
    setSelectedDrawingCamera(camera)

    // Adjust the drawing state based on the selected type in the form.
    handleStateChange(
      formik.values.type === "Dwelling Area"
        ? 2
        : formik.values.type === "Cashier"
        ? 8
        : formik.values.type === "Zone"
        ? 9
        : 0
    )
  }

  /**
   * Handles the deletion of a camera from the selected cameras list and updates the regions.
   *
   * @param {number} cameraId - The ID of the camera to be removed.
   * @param {number[]} cameras - The updated array of selected camera IDs.
   */
  const handleDeleteCamera = (cameraId: number, cameras?: number[]) => {
    // 1. Remove regions for the removed camera
    const updatedRegions = { ...allRegions }
    delete updatedRegions[cameraId]
    setAllRegions(updatedRegions)

    // If cameras array is not provided, filter out the removed camera from selectedCameras
    const updatedCameras = cameras ?? selectedCameras.filter((id) => id !== cameraId)

    // 2. Update the selected cameras list without the removed camera
    setSelectedCameras(updatedCameras)

    // 3. Determine the camera to be selected for drawing:
    // If there's only one camera and it's being removed, set the drawing camera to null.
    if (updatedCameras.length === 0) {
      setSelectedDrawingCamera(null)
    } else if (cameraId === selectedDrawingCamera) {
      // Find the index of the removed camera and select the one just before it (or the first camera if it's the last one).
      const removedCameraIndex = selectedCameras.indexOf(cameraId)
      const newSelectedCamera = removedCameraIndex > 0 ? updatedCameras[removedCameraIndex - 1] : updatedCameras[0]
      handleSelectCameraForDrawing(newSelectedCamera)
    }
  }

  /**
   * Manages camera selection, updating the camera regions and selecting the appropriate camera for drawing.
   *
   * It handles the following:
   * - Adds new camera regions when cameras are added.
   * - Removes regions when cameras are deselected.
   * - Selects the appropriate camera for drawing based on the latest changes.
   * - Handles smooth scrolling for better UI interaction.
   *
   * @param {number[]} cameras - The array of selected camera IDs.
   */
  const handleSelectCameras = (cameras: number[]) => {
    // Check for newly added cameras
    const newlyAddedCameras = cameras.filter((cameraId) => !selectedCameras.includes(cameraId))

    // If there are newly added cameras, handle adding them
    if (newlyAddedCameras.length > 0) {
      // 1. Initialize regions for newly added cameras and add them to the current regions.
      newlyAddedCameras.forEach((cameraId) => {
        const newCameraRegions = initializeCameraRegions(cameraId)
        // Merge new regions with the existing regions.
        setAllRegions({
          ...allRegions,
          ...newCameraRegions,
        })
      })

      // 2. Update the selected cameras list with the new selection.
      setSelectedCameras(cameras)

      // 3. Select the last newly added camera for drawing and scroll to the bottom.
      handleSelectCameraForDrawing(newlyAddedCameras[newlyAddedCameras.length - 1])
    } else {
      // If no cameras were added, check for removed cameras
      const removedCameras = selectedCameras.filter((cameraId) => !cameras.includes(cameraId))

      if (removedCameras.length > 0) {
        // Use the handleDeleteCamera function to handle the removal
        handleDeleteCamera(removedCameras[0], cameras)
      }
    }
  }

  return (
    <div className={styles.wrapper}>
      <Typography variant="h3-bold" variantColor={2} gutterBottom>
        Draw regions for each selected camera
      </Typography>
      <div>
        <Grid container sx={{ height: "516px" }} display="flex" gap={1} flexWrap="nowrap">
          <CameraSidebar
            selectedCameras={selectedCameras}
            selectedDrawingCamera={selectedDrawingCamera}
            setSelectedDrawingCamera={handleSelectCameraForDrawing}
            sideBarRef={sideBarRef}
            allRegions={allRegions}
            handleDeleteCamera={handleDeleteCamera}
            type={formik.values.type}
            handleSelectCameras={handleSelectCameras}
          />
          <Grid xs={9.5}>
            {selectedDrawingCamera ? (
              <RegionsCreator />
            ) : (
              <Placeholder description="No cameras selected" selectionType="camera" svgWidth="130px" svgHeight="65px" />
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default SelectAndDraw
