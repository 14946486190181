import { FC } from "react"
import { useNavigate } from "react-router-dom"

import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { SvgIcon } from "@mui/material"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"

import { Tab, Tabs, Tag, Typography } from "@synapse-analytics/synapse-ui"

import { AccordionItem } from "../../../types/Custom/Interfaces"
import { getActiveScreen } from "../../../utils/DashboardUtils"

import styles from "./SidebarItem.module.scss"

/**
 * Props interface for SidebarItem component.
 */
interface Props {
  /** Optional icon component to display alongside the label. */
  Icon?: typeof SvgIcon
  /** The label for the sidebar item. */
  label: string
  /** The currently active screen identifier. */
  activeScreen: string
  /** Optional array of accordion items for expandable content. */
  accordionItems?: AccordionItem[]
  /** Optional route for navigation. */
  route?: string
  /** Optional subRoutes for navigation. */
  subRoutes?: string[]
}

/**
 * SidebarItem component renders a tab or an accordion item based on the presence of accordionItems.
 *
 * @param {Props} props - The properties for the SidebarItem component.
 * @returns {JSX.Element} The rendered SidebarItem component.
 */
const SidebarItem: FC<Props> = ({
  Icon,
  activeScreen,
  label,
  accordionItems,
  route,
  subRoutes = [],
}: Props): JSX.Element => {
  const navigate = useNavigate()

  if (!accordionItems)
    return (
      <Tabs orientation="vertical" activeTab={activeScreen}>
        <Tab
          id={`${label}_tab`}
          value={label}
          selected={
            subRoutes?.some((subRoute) => getActiveScreen(subRoute) === activeScreen) ||
            getActiveScreen(route as string) === activeScreen
          }
          label={label}
          onClick={() => navigate(`/${route}`)}
          icon={Icon}
          orientation="vertical"
          size="large"
        />
      </Tabs>
    )
  return (
    <Accordion className={styles.accordion} defaultExpanded={true}>
      <AccordionSummary
        className={styles.dropDownContent}
        expandIcon={
          <ExpandMoreIcon
            sx={{
              color: "var(--selected-icon-secondary)",
            }}
          />
        }
        classes={{ expanded: styles.expanded, root: styles.expanded, content: styles.content }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {Icon && (
          <Icon
            className={styles.icon}
            style={{
              color: accordionItems?.some(
                (item) =>
                  item.subRoutes?.some((subRoute) => getActiveScreen(subRoute) === activeScreen) ||
                  getActiveScreen(item.route as string) === activeScreen
              )
                ? "var(--selected-icon-primary)"
                : "var(--base-text-1)",
            }}
          />
        )}
        <Typography
          color={
            accordionItems?.some(
              (item) =>
                item.subRoutes?.some((subRoute) => getActiveScreen(subRoute) === activeScreen) ||
                getActiveScreen(item.route as string) === activeScreen
            )
              ? "important"
              : "base"
          }
          variantColor={2}
          variant="a"
        >
          {label}
        </Typography>
      </AccordionSummary>
      <div className={styles.itemsWrapper}>
        <Tabs orientation="vertical" value={activeScreen}>
          {accordionItems
            ?.filter((item) => !!item)
            .map((item, index) => (
              <Tab
                key={index}
                id={`${item.label}_tab`}
                value={item.label}
                selected={
                  item.subRoutes?.some((subRoute) => getActiveScreen(subRoute) === activeScreen) ||
                  getActiveScreen(item.route as string) === activeScreen
                }
                label={
                  <div className={styles.accordionItem}>
                    {item.label}
                    {item.tag && (
                      <Tag color={item.tag.color} size="xsmall">
                        {item.tag.label}
                      </Tag>
                    )}
                  </div>
                }
                onClick={() => navigate(`/${item.route}`)}
                orientation="vertical"
                size="large"
              />
            ))}
        </Tabs>
      </div>
    </Accordion>
  )
}

export default SidebarItem
