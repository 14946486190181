import React, { FC, Fragment } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useInfiniteQuery } from "react-query"

import CircularProgress from "@mui/material/CircularProgress"
import Grid from "@mui/material/Grid"
import useMediaQuery from "@mui/material/useMediaQuery"

import { Typography, Skeleton } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { Moment } from "moment"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../API/VisionAPI"
import NoLogsPlaceholder from "../../assets/NoTableLogs.svg?react"
import { useBranchesStore } from "../../store"
import { PaginatedSecurityEvents } from "../../types/Custom/Interfaces"
import { definitions } from "../../types/Generated/apiTypes"
import { extractPageFromBackEndPaginationLink } from "../../utils/genericHelpers"
import EventCard from "./components/EventCard"
import EventsSummary from "./components/EventsSummary"

import styles from "./HistoryEvents.module.scss"

type EventsPaginated = definitions["SecurityViolationList"]

interface Props {
  startDate: Moment | null
  endDate: Moment | null
  selectedCameras: number[]
  issueType: string
  interval: Duration
}
const HistoryEvents: FC<Props> = ({ startDate, endDate, selectedCameras, issueType, interval }) => {
  const xLargeScreen = useMediaQuery("(min-width:1900px)")
  const xSmallScreen = useMediaQuery("(max-width:1280px)")
  const eventsListLimit = xLargeScreen ? 18 : 12
  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  const {
    data: paginatedSecurityEvents,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery<PaginatedSecurityEvents, AxiosError>(
    [
      "fetchSecurityEventsLogs",
      eventsListLimit,
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      selectedCameras,
      issueType,
      selectedBranch,
    ],
    async ({ queryKey, pageParam = 1 }) => {
      const selectedCameras = queryKey[4] as number[]
      const cameras = selectedCameras.join(",")
      return VisionAPI.fetchSecurityEventsLogsPaginated({
        page: pageParam as number,
        limit: queryKey[1] as number,
        from_date: queryKey[2] as string,
        to_date: queryKey[3] as string,
        cameras,
        type: queryKey[5] as string,
        branch: queryKey[6] as number,
      })
    },
    {
      getNextPageParam: (lastPage: PaginatedSecurityEvents) => {
        return lastPage?.next ? extractPageFromBackEndPaginationLink(lastPage.next) : undefined
      },
      enabled: !!startDate && !!endDate && !!selectedBranch,
    }
  )

  const loadingPlaceholders = new Array(eventsListLimit).fill(null).map((_r, i) => (
    <Grid item md={xSmallScreen ? 6 : 4} sm={6} xs={12} xl={xLargeScreen ? 2.4 : 3} key={i}>
      <Skeleton variant="rectangular" height={318} width="auto" />
    </Grid>
  ))

  return (
    <div className={styles.wrapper}>
      <EventsSummary
        startDate={startDate}
        endDate={endDate}
        selectedCameras={selectedCameras}
        issueType={issueType}
        interval={interval}
      />
      <Typography variant="h3-bold" variantColor={2} gutterBottom className={styles.title}>
        Past Events
      </Typography>
      {/* fetched event logs goes here, within infinite scrolling component , grid view of event cards */}
      {/* with spacing of 2 , grid item occupation of md = 3 , sm = 2 , xs = 1 */}
      {isLoading ? (
        <Grid container spacing={2} style={{ display: "flex" }}>
          {isLoading && <Fragment>{loadingPlaceholders}</Fragment>}
        </Grid>
      ) : paginatedSecurityEvents && paginatedSecurityEvents?.pages?.length > 0 ? (
        <InfiniteScroll
          dataLength={paginatedSecurityEvents?.pages.reduce((acc, page) => acc + page.results.length, 0)}
          hasMore={hasNextPage ? true : false}
          next={fetchNextPage}
          loader={<CircularProgress className={styles.loadingMoreEvents} />}
          endMessage={
            paginatedSecurityEvents.pages[0].count > 0 ? (
              <Typography variant="h3-regular" align="center">
                You reached the bottom
              </Typography>
            ) : (
              <div className={styles.eventsPlaceholderWrapper}>
                <NoLogsPlaceholder className={styles.eventsPlaceholder} />
                <div>
                  <Typography variant="h2-regular">No Logs yet.</Typography>
                </div>
              </div>
            )
          }
        >
          <Grid container spacing={2.5} style={{ display: "flex" }}>
            {paginatedSecurityEvents?.pages.map((page, i) => (
              <React.Fragment key={i}>
                {page.results.map((eventLog: EventsPaginated, i: number) => (
                  <Grid key={i} item md={xSmallScreen ? 6 : 4} sm={6} xs={12} xl={xLargeScreen ? 2.4 : 3}>
                    <EventCard eventLog={eventLog} />
                  </Grid>
                ))}
              </React.Fragment>
            ))}
          </Grid>
        </InfiniteScroll>
      ) : (
        <div className={styles.eventsPlaceholderWrapper}>
          <NoLogsPlaceholder className={styles.eventsPlaceholder} />
          <div>
            <Typography variant="h2-regular">No Logs yet.</Typography>
          </div>
        </div>
      )}
    </div>
  )
}
export default HistoryEvents
