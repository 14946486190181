import { FC, useEffect, useState } from "react"

import PauseIcon from "@mui/icons-material/Pause"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import SkipNextIcon from "@mui/icons-material/SkipNext"
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious"

import { Chip, Tag, Typography } from "@synapse-analytics/synapse-ui"
import HeatmapJS from "keli-heatmap.js"

import { Mark } from "../types/Custom/Interfaces"
import { getAuthBaseUrl } from "../utils/auth"

import styles from "./HeatmapPlayer.module.scss"

/**
 * Props for the HeatmapPlayer component.
 */
interface HeatmapPlayerProps {
  /**
   * The maximum value to display in the legend.
   */
  maxValue: string
  /**
   * The URL of the heatmap image to display.
   */
  image?: string
  /**
   * Whether the heatmap is for floors.
   * If `true`, the component will adjust its layout and behavior for floor plan heatmaps.
   */
  isFloorsHeatmap?: boolean
  /**
   * Configuration for versioning (e.g., floor plan versions) For Old Heatmap.
   */
  versioning?: {
    /**
     * The currently active version of the heatmap.
     * This is used to highlight the selected version in the UI.
     */
    activeVersion?: number
    /**
     * An array of available versions for the heatmap.
     * Each version is represented by a number or `null` if no version is available.
     */
    availableVersions?: (number | null)[]
  }
  /**
   * Configuration for the slider control.
   */
  slider: {
    /**
     * The current value of the slider.
     * This determines the position of the slider thumb and the corresponding heatmap data.
     */
    value: number
    /**
     * Callback function triggered when the slider value changes.
     * @param value - The new value of the slider.
     */
    onChange: (value: number) => void
    /**
     * Callback function triggered when the "previous mark" button is clicked.
     * This moves the slider to the previous mark.
     */
    onPreviousMark: () => void
    /**
     * Callback function triggered when the "next mark" button is clicked.
     * This moves the slider to the next mark.
     */
    onNextMark: () => void
    /**
     * An array of marks to display on the slider.
     * Each mark represents a specific point in time or data range.
     */
    marks?: Mark[]
    /**
     * The index of the peak mark in the `marks` array.
     * This is used to highlight the peak value in the heatmap.
     * used in old heatmap
     */
    peakMarkIndex?: number | null
    /**
     * Whether the heatmap is currently playing (auto-sliding).
     * If `true`, the heatmap will automatically advance through the marks.
     */
    isPlaying?: boolean
    /**
     * Callback function triggered when the play/pause button is clicked.
     * This toggles the playback state.
     */
    onPlayPause: () => void
  }
  /**
   * Configuration for playback controls (play/pause) and navigation between marks.
   */
  /**
   * The time grain for the heatmap (e.g., "hourly" or "daily").
   * This determines how the marks and data are displayed.
   */
  timeGrain?: "hourly" | "daily"
  /**
   * Heatmap.JS instance instantiated in the timeline component.
   */
  heatmapInstance?: any
  setHeatmapInstance?: (instance: any) => void
}

const APIURL = getAuthBaseUrl()

/**
 * Initializes a heatmap instance and attaches it to the specified DOM element.
 * Returns the heatmap instance that got initialized
 */
const initializeHeatmap = (): any => {
  // Remove any existing canvas elements with the class "heatmap-canvas" to ensure no redundant canvases are still in the DOM
  // This is necessary when initializing new instance for another version of floor plan
  document.querySelectorAll(".heatmap-canvas").forEach((canvas) => canvas.remove())

  // Find the heatmap container element by ID
  const heatmapElement = document.getElementById("heatmap")

  // If the element exists, create and set the heatmap instance
  if (heatmapElement) {
    const heatmapInstance = HeatmapJS.create({
      container: heatmapElement,
      radius: 25,
      valueField: "val",
      defaultValueField: "val",
      backgroundColor: "rgba(55, 100, 222, 0.4)",
    })
    return heatmapInstance
  }
}

/**
 * A reusable component for rendering a heatmap player with controls.
 */
const HeatmapPlayer: FC<HeatmapPlayerProps> = ({
  image,
  isFloorsHeatmap,
  versioning,
  slider,
  timeGrain = "hourly",
  setHeatmapInstance,
  heatmapInstance,
  maxValue,
}) => {
  const [imageSize, setImageSize] = useState<{ width: number; height: number }>()
  const [tooltip, setTooltip] = useState({ display: "none", x: 0, y: 0, value: "" })

  /**
   * Loads the floor plan image and updates the image size state.
   * This effect runs whenever the `image` or `isFloorsHeatmap` props change.
   */
  useEffect(() => {
    if (image) {
      if (isFloorsHeatmap) {
        const img = new Image()
        img.src = `${APIURL}${image}`
        img.onload = () => {
          setImageSize({
            height: img.height,
            width: img.width,
          })
        }
      } else {
        // default size for cameras' frames
        setImageSize({ width: 736, height: 416 })
      }
    }
  }, [image, isFloorsHeatmap])

  /**
   * Update heatmap instance when image size changes and has been set.
   */
  useEffect(() => {
    if (imageSize && setHeatmapInstance) {
      setHeatmapInstance?.(initializeHeatmap())
    }
  }, [imageSize, setHeatmapInstance])

  /**
   * Handles mouse move events to update the tooltip.
   */
  const handleMouseMove = (ev: React.MouseEvent<HTMLDivElement>) => {
    const heatmapElement = document.getElementById("heatmap")
    if (heatmapInstance && heatmapElement) {
      const rect = heatmapElement.getBoundingClientRect()
      const x = ev.clientX - rect.left
      const y = ev.clientY - rect.top
      const value = heatmapInstance.getValueAt({ x, y })

      if (value) {
        setTooltip({
          display: "block",
          x: ev.clientX - rect.left,
          y: ev.clientY - rect.top,
          value: value.toString(),
        })
      } else {
        setTooltip({ display: "none", x: 0, y: 0, value: "" })
      }
    }
  }

  /**
   * Handles mouse out events to hide the tooltip.
   */
  const handleMouseOut = () => setTooltip({ display: "none", x: 0, y: 0, value: "" })

  return (
    <div className={styles.wrapper}>
      <div className={styles.legendsWrapper}>
        <div style={{ display: "column" }}>
          <Typography variant="span-bold" gutterBottom variantColor={2}>
            Legend Map
          </Typography>
          <div className={styles.valuesAndSymbols}>
            <div className={styles.valuesLegendWrapper}>
              <div className={styles.valuesLegend}></div>
              <div className={styles.valuesAnnotation}>
                <Typography variant="span-bold" className={styles.minMaxValues}>
                  0
                </Typography>
                <Typography variant="span-bold" className={styles.minMaxValues}>
                  {maxValue}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {imageSize && (
        <div
          id="heatmap"
          style={{
            height: `${imageSize?.height}px`,
            width: `${imageSize?.width}px`,
            position: "relative",
          }}
          onMouseMove={handleMouseMove}
          onMouseOut={handleMouseOut}
        >
          <img
            className={styles.floorPlan}
            width={imageSize?.width}
            height={imageSize?.height}
            src={isFloorsHeatmap ? `${APIURL}${image}` : image}
            alt="heatmapImage"
          />
          <div
            className={styles.customTooltip}
            style={{
              display: tooltip.display,
              left: tooltip.x - 5,
              top: tooltip.y - 40,
            }}
          >
            {tooltip.value}
          </div>
        </div>
      )}
      <div className={styles.sliderWrapper}>
        {/* Floor version number */}
        {isFloorsHeatmap && (
          <div className={styles.versionWrapper}>
            {versioning &&
              versioning.availableVersions?.map(
                (versionNo, index) =>
                  versionNo !== null && (
                    <Chip
                      size="small"
                      clickable={false}
                      isSelected={versioning.activeVersion === versionNo}
                      className={styles.versionTitle}
                      key={`floor_version_${index}`}
                    >
                      Version {versionNo}
                    </Chip>
                  )
              )}
          </div>
        )}

        <div className={styles.playerWrapper}>
          <div className={styles.marksWrapper}>
            {slider?.marks?.map((mark, index) => (
              <div className={styles.markWrapper} key={index} onClick={() => slider.onChange(mark.value)}>
                {((slider.peakMarkIndex !== null && index === slider.peakMarkIndex) || mark?.isPeak) && (
                  <Tag color="red" variant="outlined" size="xsmall" className={styles.peakTag}>
                    Peak {timeGrain === "hourly" ? "hour" : "day"}
                  </Tag>
                )}
                <div
                  className={styles.mark}
                  style={{
                    background: index === slider.peakMarkIndex || mark.isPeak ? "var(--red-background-1)" : "",
                    backgroundPosition: index <= slider.value ? "left" : "",
                    border: index === slider.value ? "2px solid var(--selected-border-primary-2)" : undefined,
                  }}
                ></div>
                <Typography variant="label-bold" className={styles.markLabel}>
                  {mark.label}
                </Typography>
              </div>
            ))}
          </div>

          <div className={styles.playerControls}>
            <SkipPreviousIcon className={styles.playerControl} onClick={slider.onPreviousMark} />
            {slider.isPlaying ? (
              <PauseIcon className={styles.playIconSlider} onClick={slider.onPlayPause} />
            ) : (
              <PlayArrowIcon className={styles.playIconSlider} onClick={slider.onPlayPause} />
            )}
            <SkipNextIcon className={styles.playerControl} onClick={slider.onNextMark} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeatmapPlayer
