import { ChangeEvent, FC, Fragment, useState } from "react"

import StackedBarChartIcon from "@mui/icons-material/StackedBarChart"
import TableViewIcon from "@mui/icons-material/TableView"
import { Box, Divider } from "@mui/material"

import { Chip, Select, Typography } from "@synapse-analytics/synapse-ui"

import { ColoredLineGraphData, TableColumn } from "../../types/Custom/Types"
import { getDefaultCheckboxes, getInitialActiveStatsTab } from "../../utils/genericHelpers"
import ButtonSwitch from "../Buttons/ButtonSwitch"
import CheckboxesLineGraph from "../graphs/CheckboxesLineGraph"
import CountTable from "../tables/GenericTable"

import styles from "./CheckboxesLineGraphCard.module.scss"

interface GraphProps {
  /**
   * the graph data.
   */
  data: ColoredLineGraphData[]
}

interface TableProps {
  columns: TableColumn[]
  data: any
}

interface Props {
  graphProps: GraphProps
  tableProps?: TableProps
  title: string
  isLoading?: Boolean
  timeGrain?: "day" | "hour"
  isLargeInterval?: boolean
  contentHeight?: number
  ref?: React.MutableRefObject<any>
  /**
   * Group of props related to data types
   */
  dataTypeProps?: {
    /**
     * Optional array of data type options for the select
     */
    dataTypes?: string[]
    /**
     * Optional function to set the data type
     */
    setDataType?: (type: string) => void
    /**
     * Optional current selected data type
     */
    selectedDataType?: string
  }

  /**
   * Group of props related to sub filters
   */
  subFilterSets?: {
    /**
     * Optional array of sub filters options mapped to chips
     */
    subFilters?: string[]
    /**
     * Optional selected sub filter
     */
    selectedSubFilter?: string
    /**
     * Optional function to set the selected sub filter
     */
    setSelectedSubFilter?: (subFilter: string) => void
  }[]
}

const CheckboxesLineGraphCard: FC<Props> = ({
  title,
  graphProps,
  tableProps,
  isLoading,
  timeGrain = "hour",
  isLargeInterval,
  contentHeight = 350,
  dataTypeProps,
  subFilterSets,
  ref,
}) => {
  const defaultCheckboxes = getDefaultCheckboxes(graphProps?.data)
  const [activeView, setActiveView] = useState(getInitialActiveStatsTab({ graphProps, tableProps }))

  const handleDataTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dataTypeProps?.setDataType?.(event.target.value)
  }

  return (
    <div className={styles.wrapper} ref={ref}>
      <Fragment>
        <div className={styles.header}>
          <div className={styles.titleAndSelect}>
            <Typography variant="h2-bold" variantColor={2}>
              {title}
            </Typography>

            {dataTypeProps && (
              <Select
                options={dataTypeProps?.dataTypes}
                value={dataTypeProps?.selectedDataType}
                handleChange={handleDataTypeChange}
              />
            )}
          </div>

          {graphProps && tableProps && (
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "block",
                },
              }}
            >
              <ButtonSwitch
                activePage={activeView}
                pages={["Graph", "Table"]}
                handleSelectButton={setActiveView}
                disabled={!!isLoading}
                pagesIcons={[<StackedBarChartIcon />, <TableViewIcon />]}
              />
            </Box>
          )}
        </div>
        {subFilterSets && (
          <div className={styles.subFiltersSetsWrapper}>
            {subFilterSets.map((subFilterSet, index) => (
              <div className={styles.subFilters}>
                {subFilterSet?.subFilters?.map((subFilter) => (
                  <Chip
                    clickable
                    onClick={() => subFilterSet?.setSelectedSubFilter?.(subFilter)}
                    isSelected={subFilterSet?.selectedSubFilter === subFilter}
                    size="small"
                    className={styles.filter}
                  >
                    {subFilter}
                  </Chip>
                ))}
                {subFilterSets.length > 1 && index !== subFilterSets.length - 1 && (
                  <Divider orientation="vertical" flexItem className={styles.divider} />
                )}
              </div>
            ))}
          </div>
        )}
        {activeView === 0 && graphProps ? (
          <CheckboxesLineGraph
            data={graphProps?.data}
            isLoading={isLoading}
            graphHeight={contentHeight}
            defaultCheckboxes={defaultCheckboxes}
            timeGrain={timeGrain}
            isLargeInterval={isLargeInterval}
          />
        ) : (
          activeView === 1 &&
          tableProps && (
            <CountTable
              data={tableProps?.data}
              isLoading={!!isLoading}
              columns={tableProps.columns}
              title={title}
              height={contentHeight + 32}
              verticalMargin={1}
            />
          )
        )}
      </Fragment>
    </div>
  )
}
export default CheckboxesLineGraphCard
