import moment from "moment"

export function updateSliderMarks(marks, allData, dateDiff) {
  if (allData && allData.length > 0) {
    // Daily
    if (dateDiff === 1) {
      for (let k = 0; k < marks?.length; k++) {
        let tmpDate = new Date(allData[k]?.time_stamp)
        marks[k].label = moment(tmpDate).format("h A")
      }
      // Monthly
    } else {
      for (let k = 0; k < marks?.length; k++) {
        let tmpDate = new Date(allData[k]?.time_stamp)
        marks[k].label = !isNaN(tmpDate) && `${moment(tmpDate).format("M")}-${moment(tmpDate).format("D")}`
      }
    }
  }
}
// The function returns a color value that represents the value passed in comparison with the maxValue given
export function assignColor(value, maxValue, colors) {
  const index = Math.floor((value / maxValue) * (colors.length - 1))
  return colors[index]
}
export function getCurrentDay() {
  const now = new Date()
  const dayStart = new Date()
  const dayEnd = new Date()

  dayStart.setHours(0, 0, 0, 0)
  dayEnd.setDate(now.getDate() + 1)
  dayEnd.setHours(3, 59, 59, 999)
  return [dayStart, dayEnd]
}

export const calculateLegendValues = (minimum, maximum) => {
  const colorRange = maximum - minimum
  const colorStep = colorRange / 6
  const startValues = []

  for (let i = 0; i < 6; i++) {
    startValues.push(minimum + i * colorStep)
  }
  return startValues
}
