import { FC } from "react"

import { Divider } from "@mui/material"

import { Chip } from "@synapse-analytics/synapse-ui"

import { StaffFilterOptions } from "../types/Custom/Types"

import styles from "./AnalyticsTargetsFilter.module.scss"

const analyticsTargets = ["visitors", "staff", "all"]

interface AnalyticsTargetsFilterProps {
  setValue: (target: StaffFilterOptions) => void
  value?: StaffFilterOptions
  hasSecondDivider?: boolean
}
const AnalyticsTargetsFilter: FC<AnalyticsTargetsFilterProps> = ({ setValue, value, hasSecondDivider }) => {
  return (
    <div className={styles.wrapper}>
      <Divider orientation="vertical" className={styles.divider} />
      {analyticsTargets?.map((target, index) => (
        <Chip
          clickable
          key={index}
          onClick={() => setValue(target as StaffFilterOptions)}
          isSelected={target === value}
          className={styles.target}
        >
          {target} analytics
        </Chip>
      ))}
      {hasSecondDivider && <Divider orientation="vertical" className={styles.divider} />}
    </div>
  )
}
export default AnalyticsTargetsFilter
