import { useState } from "react"
import { useQuery } from "react-query"

import { DateRangePicker, Select, NotificationUtils, InputChangeEvent, Chip } from "@synapse-analytics/synapse-ui"
import { NumberParam, StringParam, useQueryParam, withDefault } from "use-query-params"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../API/VisionAPI"
import { useDateQuery } from "../../../hooks/useDateQuery"
import { useBranchesStore } from "../../../store"
import HeatmapLogs from "./HeatmapLogs"

import styles from "./FloorsHeatmap.module.scss"

const FloorsHeatmap = () => {
  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )
  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()

  const [selectedFloor, setSelectedFloor] = useQueryParam("floor", NumberParam)
  const [timeGrain, setTimeGrain] = useQueryParam("grain", withDefault(StringParam, "hourly"))

  const [floorLoading, setFloorLoading] = useState(false)

  const handleFloorChange = (e: InputChangeEvent) => {
    const value = e.target.value
    setTimeout(() => {
      refetchVersions()
    }, 300)
    setSelectedFloor(value as number)
  }

  // Fetch floor plans
  const { data: floors, isFetching: isFloorsLoading } = useQuery(
    ["fetchFloors", selectedBranch],
    ({ queryKey }) => VisionAPI.fetchFloorPlans({ branch: queryKey[1] as number }),
    {
      enabled: !!selectedBranch,
    }
  )

  const getFloorLoadingState = (loading: boolean) => {
    setFloorLoading(loading)
  }

  // Fetch floor plan versions
  const {
    data: floorPlanVersions,
    isFetching: loadingFloorVersions,
    refetch: refetchVersions,
  } = useQuery(
    ["fetchFloorPlanVersions", selectedFloor, startDate?.toISOString(), endDate?.toISOString()],
    async ({ queryKey }) =>
      VisionAPI.fetchFloorPlanVersions({
        floor_id: queryKey[1] as number,
        from_dt: queryKey[2] as string,
        to_dt: queryKey[3] as string,
      }),
    {
      onSuccess: (data) => {
        if ((data && data.length < 1) || !data) {
          NotificationUtils.toast("No Floor versions to display in selected date range", {
            severity: "warning",
          })
        }
      },
      enabled: !isFloorsLoading && !!selectedFloor && !!endDate,
    }
  )

  const mappedFloors =
    floors && floors?.length > 0
      ? floors.map((floor) => {
          return {
            label: floor.floor_name,
            value: floor.id as number,
          }
        })
      : []

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Select
          id="floor-select"
          placeholder="Select Floor"
          value={selectedFloor ?? ""}
          handleChange={handleFloorChange}
          disabled={!endDate}
          loading={isFloorsLoading}
          optionsWithValues={mappedFloors}
          width={235}
          label={!!selectedFloor ? "Selected Floor" : ""}
          isNotchedLabel
        />
        <div className={styles.datePicker}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            disabled={floorLoading}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            disableFuture
          />
        </div>
        <Chip isSelected={timeGrain === "hourly"} clickable onClick={() => setTimeGrain("hourly")}>
          Hourly average
        </Chip>
        <Chip isSelected={timeGrain === "daily"} clickable onClick={() => setTimeGrain("daily")}>
          Daily average
        </Chip>
      </div>

      <HeatmapLogs
        getFloorLoadingState={getFloorLoadingState}
        floorPlanVersions={floorPlanVersions}
        loadingFloorVersions={loadingFloorVersions}
        startDate={startDate?.format("YYYY-MM-DD")}
        endDate={endDate?.format("YYYY-MM-DD")}
        floorId={selectedFloor}
        timeGrain={timeGrain as "hourly" | "daily"}
      />
    </div>
  )
}
export default FloorsHeatmap
