import React, { useContext, SyntheticEvent, useEffect, useState, Fragment } from "react"
import Dropzone from "react-dropzone"

import { Card, CircularProgress, Snackbar } from "@mui/material"
import MuiAlert, { AlertProps } from "@mui/material/Alert"

import { Typography } from "@synapse-analytics/synapse-ui"
import { SingleDayPicker } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import TimePicker from "../../../../components/TimePicker"
import { IFootageData } from "../../../../types/Custom/Interfaces"
import { FootageContext } from "../../footageContext/FootageContext"

import styles from "./SelectFootage.module.scss"

// alert message
const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const SelectFootage = () => {
  const {
    formik,
    handleDropFootage,
    alert,
    alertMessage,
    closeAlert,
    footagePreview,
    footagePreviewLoading,
    services,
    initFFmpeg,
  } = useContext<IFootageData>(FootageContext)
  useEffect(() => {
    initFFmpeg()
    //eslint-disable-next-line
  }, [])
  const [isHovering, setIsHovering] = useState(false)
  const handleTimeChange = (date: Date | null) => {
    if (!isNaN(formik!.values?.startTime.getTime()) && formik?.values?.startTime) {
      formik?.setFieldValue("startTime", date)
    } else {
      formik?.setFieldValue("startTime", date)
    }
  }
  const handleDateChange = (date: moment.Moment | null) => {
    if (!isNaN(formik!.values?.startDate.getTime()) && formik?.values?.startDate) {
      formik?.setFieldValue("startDate", moment(date).toDate())
    } else {
      formik?.setFieldValue("startDate", moment(date).toDate())
    }
  }
  const handleMouseOver = () => {
    setIsHovering(true)
  }
  const handleMouseOut = () => {
    setIsHovering(false)
  }

  const handleCloseSnackbar = (_event: SyntheticEvent | Event, reason?: string) => {
    if (reason !== "clickaway") {
      closeAlert()
    }
  }

  return (
    <Fragment>
      <Card className={styles.dateTimeCard}>
        <Typography variant="a" variantColor={2} className={styles.dateTimeTitle}>
          Start date & time
        </Typography>
        <div className={styles.datePicker}>
          <SingleDayPicker
            date={formik?.values?.startDate ? moment(formik?.values?.startDate) : moment()}
            disableFuture
            onDateChange={handleDateChange}
          />
          <TimePicker time={formik?.values?.startTime} handleTimeChange={handleTimeChange} />
        </div>
      </Card>
      <Card className={styles.uploadFootageCard}>
        <Dropzone
          onDrop={handleDropFootage}
          accept={{
            "video/*": [],
          }}
          maxSize={5000000000}
          disabled={footagePreviewLoading}
        >
          {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => {
            const additionalClass = isDragAccept ? styles.accept : isDragReject ? styles.reject : ""
            return (
              <div
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                {...getRootProps({
                  className: `${styles.footageDropZone} ${additionalClass}`,
                })}
                style={{ cursor: footagePreviewLoading ? "default" : "pointer" }}
              >
                <input {...getInputProps()} />
                {footagePreview && services ? (
                  <div
                    className={styles.previewContainer}
                    style={{ backgroundColor: isHovering ? "var(--neutral-background-hover)" : "" }}
                  >
                    <img src={footagePreview} alt="footagePreview" className={styles.footagePreview} />
                    {isHovering && footagePreview && services && (
                      <Typography
                        variant="p"
                        variantColor={2}
                        className={`${styles.hoverText}`}
                        onMouseOver={handleMouseOver}
                      >
                        Re-Drag {"&"} drop footage or Re-Select
                      </Typography>
                    )}
                  </div>
                ) : (
                  <Fragment>
                    {footagePreviewLoading && services === null ? (
                      <CircularProgress size={40} style={{ margin: 24 }} />
                    ) : (
                      <Fragment>
                        <Typography variant="p" variantColor={2} className={styles.footagePlaceholder}>
                          Drag {"&"} drop footage or Select
                        </Typography>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </div>
            )
          }}
        </Dropzone>
        <Typography variant="span-regular" variantColor={2}>
          {footagePreview && services
            ? "Processing Done"
            : footagePreviewLoading && services === null
            ? "Processing.."
            : "Insert your desired footage"}
        </Typography>
      </Card>
      {/* if image URL is not valid */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={alert}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <div>
          <Alert onClose={handleCloseSnackbar} severity="error">
            {alertMessage}
          </Alert>
        </div>
      </Snackbar>
    </Fragment>
  )
}

export default SelectFootage
