import React, { FC, Fragment, useRef, useState } from "react"
import { useQuery } from "react-query"

import CloseIcon from "@mui/icons-material/Close"
import { IconButton } from "@mui/material"

import { InputText, Menu, Select, MenuItem, InputChangeEvent, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"

import { VisionAPI } from "../../../API/VisionAPI"
import ClearFilter from "../../../assets/clearFilter.png"
import ArrowAdornment from "../../../components/ArrowAdornment"
import { definitions } from "../../../types/Generated/apiTypes"
import { carsColors, carsBrands, bodyTypes, carsStates } from "../../../utils/carsUtils"
import CarsBrands from "./CarBrandIcon"

import styles from "./CarsFilters.module.scss"

type ListsOfInterest = definitions["ListOfInterest"]

interface Props {
  isFetching: boolean
  endDate: any | null
  isAnalytics?: boolean
  selectedColor?: { color: string; value: string }
  carStateFilter?: string
  selectedListOfInterest: string | ""
  selectedCarBrand: string
  selectedCarType: string
  setSelectedColor?: (color: { color: string; value: string }) => void
  setCarStateFilter?: (state: string) => void
  setSelectedListOfInterest: (list: string | "") => void
  setSelectedCarBrand: (brand: string) => void
  setSelectedCarType: (type: string) => void
  handleResetReport?: () => void
  handleResetPage?: () => void
}
const CarsFilters: FC<Props> = ({
  isFetching,
  endDate,
  isAnalytics,
  selectedColor = { color: "All Colors", value: "" },
  selectedCarBrand = "",
  selectedCarType = "",
  selectedListOfInterest = "",
  carStateFilter = "",
  setSelectedCarBrand,
  setSelectedCarType,
  setSelectedListOfInterest,
  setCarStateFilter,
  setSelectedColor,
  handleResetReport,
  handleResetPage,
}) => {
  const [colorFilterAnchor, setColorFilterAnchor] = useState<null | HTMLElement>(null)
  const [brandFilterAnchor, setBrandFilterAnchor] = useState<null | HTMLElement>(null)
  const [brandAutoComplete, setBrandAutoComplete] = useState<string>(selectedCarBrand)
  const colorInputRef = useRef<HTMLInputElement>(null)
  const brandInputRef = useRef<HTMLInputElement>(null)
  const isColorMenuOpen = Boolean(colorFilterAnchor)
  const isBrandMenuOpen = Boolean(brandFilterAnchor)

  // fetch car lists of interest
  const { data: listsOfInterest, isLoading: listsOfInterestLoading } = useQuery<ListsOfInterest[], AxiosError>(
    "fetchCarsListOfInterest",
    () => VisionAPI.fetchCarListsOfInterest()
  )

  const handleOpenBrandMenu = (): void => {
    if (!isBrandMenuOpen && brandInputRef?.current) {
      setBrandFilterAnchor(brandInputRef.current)
      brandInputRef?.current.focus()
    }
  }

  const handleCloseColorMenu = (): void => {
    setColorFilterAnchor(null)
  }
  const handleCloseBrandMenu = (): void => {
    setBrandFilterAnchor(null)
  }

  const handleColorAdornmentClick = (): void => {
    if (colorInputRef?.current) {
      if (isColorMenuOpen) {
        handleCloseColorMenu()
      } else {
        setColorFilterAnchor(colorInputRef.current)
        colorInputRef?.current.focus()
      }
    }
  }

  const handleBrandColorAdornmentClick = (): void => {
    if (brandInputRef?.current) {
      if (isBrandMenuOpen) {
        handleCloseBrandMenu()
      } else {
        setBrandFilterAnchor(brandInputRef.current)
        brandInputRef?.current.focus()
      }
    }
  }

  const handleColorChange = (color: { color: string; value: string }) => {
    setSelectedColor?.(color)
    handleResetReport?.()
    handleResetPage?.()
    handleCloseColorMenu()
  }

  const handleListOfInterestChange = (event: InputChangeEvent) => {
    setSelectedListOfInterest(event.target.value as string)
    handleResetReport?.()
    handleResetPage?.()
  }

  const handleCarBrandChange = (brand: string) => {
    setSelectedCarBrand(brand)
    setBrandAutoComplete(brand)
    handleResetReport?.()
    handleResetPage?.()
    handleCloseBrandMenu()
  }

  const handleCarTypeChange = (event: InputChangeEvent) => {
    setSelectedCarType(event.target.value as string)
    handleResetReport?.()
    handleResetPage?.()
  }

  const handleCarStateFilterChange = (event: InputChangeEvent) => {
    setCarStateFilter?.(event.target.value as string)
    handleResetReport?.()
    handleResetPage?.()
  }

  const handleChangeBrandSearch = (e: InputChangeEvent) => {
    handleOpenBrandMenu()
    setBrandAutoComplete(e.target.value as string)
  }

  const handleResetFilters = () => {
    setSelectedListOfInterest("")
    setSelectedCarBrand("")
    setBrandAutoComplete("")
    setSelectedCarType("")
    setSelectedColor?.({ color: "All Colors", value: "" })
    setCarStateFilter?.("")
    handleResetReport?.()
    handleResetPage?.()
  }

  return (
    <div className={styles.wrapper}>
      <Fragment>
        {!isAnalytics && (
          <div className={styles.formControl}>
            <InputText
              value={selectedColor.color}
              key={selectedColor.value}
              variant="filled"
              isSelect
              isNotchedLabel
              label="Car Color"
              placeholder="All Colors"
              disabled={isFetching || !endDate}
              endAdornment={
                <ArrowAdornment handleAdornmentClick={handleColorAdornmentClick} isOpen={isColorMenuOpen} />
              }
              handleInputClick={handleColorAdornmentClick}
              anchoringRef={colorInputRef}
              adornmentSize="small"
              hideDescription
            />
            <Menu open={isColorMenuOpen} onClose={handleCloseColorMenu} anchorEl={colorFilterAnchor} menuMaxContent>
              {carsColors.map((carColor) => (
                <MenuItem
                  value={carColor.value}
                  className={styles.colorsMenuItem}
                  isSelected={carColor.value === selectedColor.value}
                  onClick={() => handleColorChange(carColor)}
                >
                  <div
                    title={carColor.color}
                    className={`${styles.carColor} ${carColor.value === "" && styles.allColors}`}
                    style={{
                      background: carColor.color.includes("-")
                        ? `linear-gradient(90deg, ${carColor.color.split("-")[0]} 7.59%,${
                            carColor.color.split("-")[1]
                          } 99.26%)`
                        : carColor.color,
                      border: carColor.color === "White" ? "1px solid var(--neutral-text-enabled)" : "",
                    }}
                  ></div>
                  <p>{carColor.color}</p>
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}
      </Fragment>
      <div className={styles.formControl}>
        <Select
          isNotchedLabel
          label="List Of Interest"
          value={selectedListOfInterest}
          handleChange={handleListOfInterestChange}
          id="car-list"
          loading={listsOfInterestLoading}
          disabled={isFetching || !endDate}
          optionsWithValues={
            listsOfInterest && [
              { label: "All Lists", value: "" },
              ...listsOfInterest
                .filter((listsOfInterest) => listsOfInterest.type === "cars")
                .map((listOfInterest) => {
                  return {
                    label: listOfInterest.name,
                    value: `${listOfInterest.id}`,
                  }
                }),
            ]
          }
          menuProps={{ menuMaxContent: true }}
          width={140}
        />
      </div>

      <div className={styles.formControl}>
        <Select
          isNotchedLabel
          label="Body Type"
          value={selectedCarType}
          handleChange={handleCarTypeChange}
          id="car-type"
          disabled={isFetching || !endDate}
          optionsWithValues={bodyTypes}
          menuProps={{
            menuMaxContent: true,
          }}
          width={140}
        />
      </div>
      {!isAnalytics && (
        <div className={styles.formControl}>
          <Select
            isNotchedLabel
            label="Car State"
            value={carStateFilter}
            handleChange={handleCarStateFilterChange}
            id="car-state"
            disabled={isFetching || !endDate}
            optionsWithValues={carsStates}
            menuProps={{
              menuMaxContent: true,
            }}
            width={140}
          />
        </div>
      )}
      <div className={styles.formControl}>
        <InputText
          value={brandAutoComplete}
          key={selectedCarBrand}
          handleChange={handleChangeBrandSearch}
          variant="filled"
          placeholder="Search a brand"
          isNotchedLabel
          label="Car Brand"
          disabled={isFetching || !endDate}
          endAdornment={
            <ArrowAdornment handleAdornmentClick={handleBrandColorAdornmentClick} isOpen={isBrandMenuOpen} />
          }
          handleInputClick={handleBrandColorAdornmentClick}
          anchoringRef={brandInputRef}
          adornmentSize="small"
          hideDescription
          shouldHideInputText={!!selectedCarBrand}
        >
          {selectedCarBrand && (
            <div className={styles.brandTextContainer}>
              <div className={styles.logoAndBrand}>
                <CarsBrands carBrand={selectedCarBrand} />
                <Typography variant="a" textTransform="capitalize">
                  {brandAutoComplete}
                </Typography>
              </div>
              <CloseIcon onClick={() => handleCarBrandChange("")} fontSize="inherit" className={styles.clearInput} />
            </div>
          )}
        </InputText>
        <Menu
          open={isBrandMenuOpen}
          onClose={handleCloseBrandMenu}
          anchorEl={brandFilterAnchor}
          menuMaxContent
          key="Car-Brands"
        >
          <MenuItem
            className={styles.colorsMenuItem}
            value={""}
            isSelected={selectedCarBrand === ""}
            onClick={() => handleCarBrandChange("")}
          >
            All Brands
          </MenuItem>
          {carsBrands
            .filter((brand) =>
              selectedCarBrand === brandAutoComplete
                ? true
                : brand?.toLowerCase().includes(brandAutoComplete?.toLowerCase())
            )
            .map((carBrand) => (
              <MenuItem
                className={styles.colorsMenuItem}
                value={carBrand}
                onClick={() => handleCarBrandChange(carBrand)}
                isSelected={carBrand === selectedCarBrand}
              >
                <CarsBrands carBrand={carBrand} />
                <Typography variant="a" textTransform="capitalize">
                  {carBrand}
                </Typography>
              </MenuItem>
            ))}
        </Menu>
      </div>

      {(!!selectedColor.value ||
        !!selectedListOfInterest ||
        !!selectedCarBrand ||
        !!selectedCarType ||
        carStateFilter) && (
        <IconButton className={styles.filterIcon} onClick={handleResetFilters} size="large">
          <img src={ClearFilter} alt="ClearFilter" />
        </IconButton>
      )}
    </div>
  )
}

export default CarsFilters
