import { FC, useState, useEffect, Fragment } from "react"

import GetAppIcon from "@mui/icons-material/GetApp"
import { Card } from "@mui/material"

import { Button, Typography } from "@synapse-analytics/synapse-ui"
import { CsvBuilder } from "filefy"

import CategoriesLineGraph from "../../../components/graphs/CheckboxesLineGraph"
import GraphEmptyState from "../../../components/graphs/GraphEmptyState"
import { definitions } from "../../../types/Generated/apiTypes"
import { convertToLineGraphData } from "../../../utils/shopUtils"

import styles from "./CategoriesOverTime.module.scss"

type ShopsCategoryLogs = definitions["ShopsCategoryLogs"]

interface Props {
  logsData: ShopsCategoryLogs[]
  loading: Boolean
  interval: Duration
  categoriesOverTimeRef: any
}
type graphPoint = {
  x: Date
  y: number
}
type GraphData = {
  id: string
  color: string
  data: graphPoint[]
}
const CategoriesOverTime: FC<Props> = ({
  logsData,
  loading,
  interval = { days: 7, hours: 23, minutes: 59, months: 0, seconds: 0, years: 0 },
  categoriesOverTimeRef,
}) => {
  const [lineGraphData, setLineGraphData] = useState<GraphData[]>([
    {
      id: "",
      color: "",
      data: [{ x: new Date(), y: 0 }],
    },
  ])
  const [defaultCheckboxes, setDefaultCheckboxes] = useState<{ [key: string]: Boolean }>({ "": false })
  const [showExport, setShowExport] = useState(false)

  useEffect(() => {
    if (loading === false && logsData) {
      setLineGraphData(convertToLineGraphData(logsData))
      if (logsData.length > 0 && interval && (interval!.days! > 1 || interval!.months! >= 1)) {
        setShowExport(true)
      } else {
        setShowExport(false)
      }
    } else {
      setShowExport(false)
    }
  }, [logsData, loading, interval])
  useEffect(() => {
    let statesObj: { [key: string]: Boolean } = {}
    for (let log of lineGraphData) {
      statesObj[log.id] = false
    }
    setDefaultCheckboxes(statesObj)
  }, [lineGraphData])
  const handleExportCSV = () => {
    const builder = new CsvBuilder(`Categories Over Time.csv`)
    let csvFormattedData: string[][] = [[]]
    csvFormattedData.pop()
    const daysArray: string[] = []
    if (!loading && lineGraphData)
      lineGraphData.map((element, graphDataIndex) => {
        const csvRow: string[] = []
        element.data.map((log, logIndex) => {
          graphDataIndex === 0 && daysArray.push(log.x.toLocaleDateString())
          return csvRow.push(log.y.toString())
        })
        return csvFormattedData.push([element.id, ...csvRow])
      })
    const categoriesArray: string[] = []
    lineGraphData.map((element) => categoriesArray.push(element.id))
    builder
      .setColumns(["Category \\ Day ", ...daysArray])
      .addRows(csvFormattedData)
      .exportFile()
  }
  return (
    <Card className={styles.wrapper} ref={categoriesOverTimeRef}>
      <Fragment>
        <div className={styles.header}>
          <Typography variant="h2-bold" variantColor={2} noWrap>
            Categories over Time
          </Typography>
          {showExport && (
            <Button onClick={() => handleExportCSV()} startIcon={<GetAppIcon />} variant="secondary" size="small" />
          )}
        </div>
        {interval && interval!.days! <= 1 && interval!.months! < 1 ? (
          <GraphEmptyState isRangeWarning={true} isCategories={true} />
        ) : (
          <CategoriesLineGraph
            data={lineGraphData}
            isLoading={loading}
            graphHeight={240}
            defaultCheckboxes={defaultCheckboxes}
            isLargeInterval={interval?.months! > 1}
          />
        )}
      </Fragment>
    </Card>
  )
}
export default CategoriesOverTime
