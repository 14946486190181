import React, { FC, Fragment } from "react"

import StorefrontIcon from "@mui/icons-material/Storefront"
import { Avatar, Box } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"

import FirstPlaceMedal from "../assets/leaderboard/firstPlace.svg?react"
import Crown from "../assets/leaderboard/firstPlaceCrown.svg?react"
import LeftWing from "../assets/leaderboard/leftWing.svg?react"
import RightWing from "../assets/leaderboard/rightWing.svg?react"
import SecondPlaceMedal from "../assets/leaderboard/secondPlace.svg?react"
import ThirdPlaceMedal from "../assets/leaderboard/thirdPlace.svg?react"
import { getNameInitials } from "../utils/genericHelpers"

import styles from "./Podium.module.scss"

type Place = {
  name?: string
  value?: number
  subcategory?: string | number
  unit?: string
}

type PodiumData = {
  first: Place
  second: Place
  third: Place
}

interface Props {
  data: PodiumData
}

interface RenderPlaceProps {
  place: Place
  PlaceMedal: React.FC<React.SVGProps<SVGSVGElement>>
  className: string
  placeLabel: string
  nameColor?: "warning" | "orange"
  isFirst?: boolean
}

const renderPlace: FC<RenderPlaceProps> = ({ place, PlaceMedal, className, placeLabel, nameColor, isFirst }) => (
  <div className={styles.podium} style={{ opacity: place.name ? 1 : 0.4 }}>
    <Box
      sx={{
        display: {
          xs: "none",
          sm: "block",
        },
      }}
    >
      <div style={{ position: "relative" }}>
        {isFirst && (
          <Fragment>
            <LeftWing className={styles.leftWing} />
            <Crown className={styles.crown} />
            <RightWing className={styles.rightWing} />
          </Fragment>
        )}
        <Avatar className={`${styles.avatar} ${className}`}>
          <Typography variant="h1-regular" className={styles.avatarTitle} color={nameColor ?? "gray"} variantColor={2}>
            {place.name ? getNameInitials(place.name) : <StorefrontIcon className={styles.avatarIcon} />}
          </Typography>
        </Avatar>
        <PlaceMedal className={styles.avatarIndex} />
      </div>
    </Box>
    <Typography
      variant="h3-regular"
      title={place.name || placeLabel}
      className={styles.placeText}
      noWrap
      align="center"
    >
      {place.name ?? "No Logs Yet"}
    </Typography>
    <Typography variant="h1-bold" variantColor={2} className={styles.placeText} noWrap align="center">
      {place?.value && place?.value > 0 ? `${place?.value?.toLocaleString()} ${place.unit || ""}` : "No Data"}
    </Typography>
    {place.subcategory && (
      <Typography variant="p" noWrap align="center">
        {place.subcategory}
      </Typography>
    )}
  </div>
)

const Podium: FC<Props> = ({ data }) => {
  return (
    <div className={styles.wrapper}>
      {/* Third Place */}
      {renderPlace({
        place: data.third,
        PlaceMedal: ThirdPlaceMedal,
        className: styles.thirdPlace,
        placeLabel: "Third Place",
        nameColor: "orange",
      })}
      {/* First Place */}
      {renderPlace({
        place: data.first,
        PlaceMedal: FirstPlaceMedal,
        className: styles.firstPlace,
        placeLabel: "First Place",
        nameColor: "warning",
        isFirst: true,
      })}
      {/* Second Place */}
      {renderPlace({
        place: data.second,
        PlaceMedal: SecondPlaceMedal,
        className: styles.secondPlace,
        placeLabel: "Second Place",
      })}
    </div>
  )
}

export default Podium
