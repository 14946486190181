import { useState } from "react"

import CloseIcon from "@mui/icons-material/Close"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"

import { Button } from "@synapse-analytics/synapse-ui"
import { Typography } from "@synapse-analytics/synapse-ui"

import ChatHead from "../assets/Robot.svg?react"

import styles from "./LandingWindow.module.scss"

/**
 * Props for the LandingWindow component.
 */
interface LandingWindowProps {
  /** Function to handle the start of a new chat session. */
  handleStartNewChat: () => void
  /** Function to handle minimizing the chatbot window. */
  handleMinimizeChatbot: () => void
  /** Boolean indicating whether the landing window is open. */
  isOpen: boolean
}

/**
 * LandingWindow component displays the initial interface for the chatbot.
 * It includes options to start a new chat or view a disclaimer.
 *
 * @param {LandingWindowProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
const LandingWindow = ({ handleStartNewChat, handleMinimizeChatbot, isOpen }: LandingWindowProps): JSX.Element => {
  const [activeContent, setActiveContent] = useState<"disclaimer" | "landing">("landing")

  return (
    <div
      className={styles.landingScreen}
      style={{
        display: isOpen ? "flex" : "none",
        background: activeContent === "disclaimer" ? "var(--primary-background-default)" : "var(--base-background-3)",
      }}
    >
      <div className={styles.header}>
        {activeContent === "disclaimer" && (
          <div className={styles.title}>
            <Typography variant="h2-bold" align="center">
              Disclaimer
            </Typography>
          </div>
        )}
        <CloseIcon
          onClick={handleMinimizeChatbot}
          className={`${styles.close} ${activeContent === "disclaimer" ? styles.disclaimerClose : ""}`}
        />
      </div>
      {activeContent === "landing" ? (
        <div className={styles.landingContent}>
          <ChatHead className={styles.chatHead} />
          <Typography variant="h1-bold" align="center">
            Hello!
          </Typography>
          <Typography variant="h2-bold" align="center" gutterBottom>
            I'm Azka Bot
          </Typography>
          <Typography variant="h3-regular" variantColor={2} align="center" gutterBottom>
            Your guide to explore our app and access database information.
          </Typography>
          <div className={styles.warningContainer}>
            <WarningAmberIcon className={styles.warningIcon} fontSize="small" />
            <Typography variant="label-regular" color="warning" variantColor={2}>
              Please note: Once you close the app, this chat will not be saved and will be discarded.
            </Typography>
          </div>
          <div className={styles.buttonContainer}>
            <Button onClick={handleStartNewChat} variant="primary">
              Let's Chat
            </Button>
            <Button onClick={() => setActiveContent("disclaimer")} variant="ghost">
              Disclaimer
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.landingContent}>
          <div className={styles.disclaimer}>
            <Typography variant="h3-regular">
              Welcome to Azka Vision Chatbot! I'm here to assist you with any questions or information you need
              regarding our services, analytics solutions, or anything related to data-driven insights. As a chatbot,
              I'm designed to provide quick and helpful responses based on the information available to me. Please keep
              in mind that while I strive to offer accurate and relevant information, my responses are generated based
              on pre-existing data and patterns. For complex or specific inquiries, it's always recommended to consult
              our human experts for a more personalized and comprehensive response.
              <br />
              <br />
              If you have any concerns, suggestions, or encounter any issues while interacting with me, please feel free
              to let us know. We're constantly working to improve your experience and provide you with the best support
              possible.
            </Typography>
          </div>
          <div className={styles.buttonContainer}>
            <Button onClick={handleStartNewChat} variant="secondary">
              Let's Chat
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default LandingWindow
