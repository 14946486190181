import { FC, Fragment } from "react"

import { Box } from "@mui/material"
import Divider from "@mui/material/Divider"

import { BarDatum, BarTooltipProps } from "@nivo/bar"
import { Typography } from "@synapse-analytics/synapse-ui"

import { MissingInfo } from "../../../types/Custom/Types"
import { graphColors } from "../../../utils/graphUtils"

import styles from "./GraphTooltip.module.scss"

interface Point {
  id: string
  serieColor: string
  serieId: string
  data: {
    yFormatted: string
  }
}
interface Props {
  missingInfo?: MissingInfo[] | null | string
  timeStamp?: string
  slicePoints?: Point[]
  barGraphSlice?: BarTooltipProps<BarDatum>
  weekAvg?: boolean
  isTableTooltip?: boolean
  entity?: string
  originalCountIn?: number
  originalCountOut?: number
  missingCountIn?: number
  missingCountOut?: number
  key?: string
}

function shouldRenderLegend(point: Point, missingInfo?: MissingInfo[] | null | string) {
  // Check if the missingInfo array length is less than 2
  if (missingInfo && Array.isArray(missingInfo) && missingInfo.length < 2) {
    return (
      (missingInfo &&
        +point.data["yFormatted"] !== missingInfo[0]?.countOut &&
        +point.data["yFormatted"] !== missingInfo[0]?.countIn) ||
      !missingInfo ||
      (point.serieId !== "Count In - missing data" && point.serieId !== "Count Out - missing data")
    )
  }

  // If the length is 2 or more, return true by default
  return true
}

const GraphTooltip: FC<Props> = ({
  missingInfo,
  timeStamp,
  slicePoints,
  barGraphSlice,
  weekAvg,
  isTableTooltip,
  entity,
  originalCountIn,
  originalCountOut,
  missingCountIn,
  missingCountOut,
  key,
}) => {
  return (
    <div className={`${styles.wrapper} ${!isTableTooltip ? styles.container : ""}`} key={key}>
      {timeStamp && (
        <Typography variant="p" gutterBottom align="center" style={{ width: "100%" }}>
          {timeStamp}
        </Typography>
      )}
      {entity && (
        <Typography variant="p" gutterBottom align="center" style={{ width: "100%" }}>
          {entity}
        </Typography>
      )}
      {!!missingInfo && barGraphSlice?.id !== "Count In" && (
        <Fragment>
          <Typography variant="a">Missing data filled by the admin</Typography>

          {Array.isArray(missingInfo) ? (
            missingInfo.map(
              (info, index) =>
                info && (
                  <div className={styles.infoWrapper}>
                    <Box display="flex">
                      <Typography variant="p" variantColor={2}>
                        File name
                      </Typography>
                      <Typography variant="p" variantColor={2} style={{ marginLeft: "8px" }}>
                        {info.fileName}
                      </Typography>
                    </Box>
                    <Box display="flex" maxWidth="280px" mb={0.875}>
                      <Typography variant="p" variantColor={2}>
                        Entities
                      </Typography>
                      <Typography variant="p" variantColor={2} style={{ marginLeft: "8px" }}>
                        {info.entities}
                      </Typography>
                    </Box>
                    <Box display="flex" mb={0.875}>
                      <span
                        className={styles.colorSquare}
                        style={{
                          background: graphColors["Count In"],
                        }}
                      />
                      <Typography variant="label-regular" style={{ marginRight: "21px" }}>
                        File total count in
                      </Typography>
                      <Typography variant="label-bold">{info.countIn}</Typography>
                    </Box>
                    <Box display="flex">
                      <span
                        className={styles.colorSquare}
                        style={{
                          background: graphColors["Count Out"],
                        }}
                      />
                      <Typography variant="label-regular" style={{ marginRight: "21px" }}>
                        File total count out
                      </Typography>
                      <Typography variant="label-bold">{info.countOut}</Typography>
                    </Box>
                    {((isTableTooltip && missingInfo.length - 1 !== index) || !isTableTooltip) && (
                      <Divider
                        variant="middle"
                        sx={{
                          margin: 1,
                        }}
                      />
                    )}
                  </div>
                )
            )
          ) : (
            <Fragment>
              <Box display="flex" maxWidth="280px" mb={0.875}>
                <Typography variant="p" variantColor={2} noWrap>
                  Files Included
                </Typography>
                <Typography variant="p" variantColor={2} style={{ marginLeft: "8px" }}>
                  {missingInfo}
                </Typography>
              </Box>
              {missingCountIn !== undefined && (
                <Box display="flex" mb={0.875}>
                  <span
                    className={styles.colorSquare}
                    style={{
                      background: graphColors["Count In"],
                    }}
                  />
                  <Typography variant="label-regular" style={{ marginRight: "21px" }}>
                    Count In
                  </Typography>
                  <Typography variant="label-bold">
                    {`${missingCountIn} (missing) ${!!originalCountIn ? ` + ${originalCountIn} (original)` : ""}`}
                  </Typography>
                </Box>
              )}
              {missingCountOut !== undefined && (
                <Box display="flex" mb={0.875}>
                  <span
                    className={styles.colorSquare}
                    style={{
                      background: graphColors["Count Out"],
                    }}
                  />
                  <Typography variant="label-regular" style={{ marginRight: "21px" }}>
                    Count Out
                  </Typography>
                  <Typography variant="label-bold">
                    {`${missingCountOut} (missing) ${!!originalCountOut ? `+ ${originalCountOut} (original)` : ""}`}{" "}
                  </Typography>
                </Box>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
      {!!missingInfo && slicePoints && (
        <Typography variant="a" gutterBottom align="center" style={{ width: "100%" }}>
          Missing + Counter Data
        </Typography>
      )}
      {!!barGraphSlice && (
        <Box display="flex" mb={0.875} key={barGraphSlice.id}>
          <span
            className={styles.colorSquare}
            style={{
              background: barGraphSlice.color,
            }}
          />
          <Typography variant="label-regular" style={{ marginRight: "21px" }}>
            {barGraphSlice.id}
          </Typography>
          <Typography variant="label-bold">{barGraphSlice.value}</Typography>
        </Box>
      )}
      {slicePoints?.map(
        (point) =>
          shouldRenderLegend(point, missingInfo) && (
            <Box display="flex" mb={0.875} key={point.id}>
              <span
                className={styles.colorSquare}
                style={{
                  background: point.serieColor,
                }}
              />
              <Typography variant="label-regular" style={{ marginRight: "21px" }}>
                {`${point.serieId} ${weekAvg ? " - Count In : " : ""}`}
              </Typography>
              <Typography variant="label-bold">
                {point.data["yFormatted"]} {point?.serieId?.includes("time") && " Minute(s)"}
              </Typography>
            </Box>
          )
      )}
    </div>
  )
}
export default GraphTooltip
