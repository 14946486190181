import React, { useMemo } from "react"

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { definitions } from "../../../types/Generated/apiTypes"

import styles from "./CarPathsTable.module.scss"

type CarPaths = definitions["CarPaths"]

interface CarPathsTableProps {
  carPaths?: CarPaths[]
}

const CarPathsTable: React.FC<CarPathsTableProps> = ({ carPaths }) => {
  /**
   * Deep clones the carPaths and reverses the order of the paths if it exists.
   * This is done to display paths from oldest to newest.
   *
   * @returns The reversed paths array if carPaths exists, otherwise undefined.
   */
  const paths: CarPaths[] | undefined = useMemo(() => {
    if (!carPaths) return undefined
    const clonedCarPaths = JSON.parse(JSON.stringify(carPaths))
    return clonedCarPaths.map((carPath: CarPaths) => ({
      ...carPath,
      paths: carPath.paths.reverse(),
    }))
  }, [carPaths])

  return (
    <TableContainer className={styles.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              classes={{
                root: styles.cellRoot,
                head: styles.head,
                body: styles.body,
              }}
            >
              Date
            </TableCell>
            <TableCell
              classes={{
                root: styles.cellRoot,
                head: styles.head,
                body: styles.body,
              }}
            >
              From
            </TableCell>
            <TableCell
              classes={{
                root: styles.cellRoot,
                head: styles.head,
                body: styles.body,
              }}
            >
              To
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paths?.map((carPath) => (
            <TableRow className={styles.rowRoot}>
              <TableCell
                classes={{
                  root: styles.cellRoot,
                  head: styles.head,
                  body: styles.body,
                }}
                component="th"
                scope="row"
              >
                {moment(carPath.day).format("DD MMM YYYY")}
              </TableCell>
              <TableCell
                classes={{
                  root: `${styles.cellRoot} ${styles.fromCell}`,
                  head: styles.head,
                  body: styles.body,
                }}
                align="left"
              >
                {carPath.paths.map((path) => (
                  <div className={styles.timeCell} key={path.timestamp}>
                    <span className={styles.cameraName} title={path.camera}>
                      {path.camera}
                    </span>
                    <Typography variant="label-regular" variantColor={2}>
                      {moment(path.timestamp).format("h:mm A")}
                    </Typography>
                  </div>
                ))}
              </TableCell>
              <TableCell
                classes={{
                  root: `${styles.cellRoot} ${styles.toCell}`,
                  head: styles.head,
                  body: styles.body,
                }}
                align="left"
              >
                {carPath.paths.map((_path, index) => (
                  <div className={styles.timeCell} key={`${carPath.day}-${index}`}>
                    <span
                      className={styles.cameraName}
                      title={index < carPath.paths.length - 1 ? carPath.paths[index + 1].camera : ""}
                    >
                      {index < carPath.paths.length - 1 ? carPath.paths[index + 1].camera : ""}{" "}
                    </span>
                    <Typography variant="label-regular" variantColor={2}>
                      {index < carPath.paths.length - 1
                        ? moment(carPath.paths[index + 1].timestamp).format("h:mm A")
                        : "-"}
                    </Typography>
                  </div>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CarPathsTable
