import { Divider } from "@mui/material"

import { Avatar, Tag, Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { definitions } from "../../../types/Generated/apiTypes"
import FootagePlaceholder from "../assets/footagePlaceholder.svg?react"

import styles from "./FootageCard.module.scss"

type Footages = definitions["CameraFootageRetrieve"]
const FootageCard = ({ footage }: { footage: Footages }) => {
  return (
    <div key={footage?.id} className={styles.footage}>
      <div className={styles.header}>
        <div className={styles.title}>
          <Typography variant="a">Footage {footage?.id}</Typography>
          <Tag variant="filled" color={footage?.state?.includes("In Progress") ? "yellow" : "green"} size="xsmall">
            {footage?.state?.includes("In Progress") ? "Processing" : "Completed"}
          </Tag>
        </div>
        <Avatar
          size="small"
          isLoading={false}
          isNameInitials
          name={footage?.uploaded_by?.full_name || footage?.uploaded_by?.username}
        />
      </div>
      <div className={styles.imageWrapper}>
        {footage?.thumbnail === null ? (
          <div className={styles.noImage}>
            <FootagePlaceholder />
            <Typography variant="label-regular" variantColor={2}>
              No thumbnail
            </Typography>
          </div>
        ) : (
          <img src={footage?.thumbnail} alt="footageImage" className={styles.footageImage} />
        )}
      </div>

      <div className={styles.dateRangeWrapper}>
        <div className={styles.dateRange}>
          <Typography variant="label-bold">Start date</Typography>
          <Typography variant="label-regular" variantColor={2}>
            {moment(footage?.start_dt).format("l")}
          </Typography>
          <Typography variant="label-regular" variantColor={2}>
            {moment(footage?.start_dt).format("h:mm a")}
          </Typography>
        </div>

        <Divider
          variant="middle"
          flexItem
          orientation="vertical"
          sx={{
            margin: "9px 8px",
          }}
        />
        <div className={styles.dateRange}>
          <Typography variant="label-bold">End date</Typography>
          <Typography variant="label-regular" variantColor={2}>
            {moment(footage?.end_dt).format("l")}
          </Typography>
          <Typography variant="label-regular" variantColor={2}>
            {moment(footage?.end_dt).format("h:mm a")}
          </Typography>
        </div>
      </div>

      <div className={styles.dateRange}>
        <Typography variant="label-bold">Processing ended:</Typography>
        <Typography variant="label-regular" variantColor={2}>
          {moment(footage?.created_at).format("l")}
        </Typography>
      </div>
    </div>
  )
}
export default FootageCard
