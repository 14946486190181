import React, { FC, useState, Fragment } from "react"
import { useMutation, useQueryClient } from "react-query"

import { Switch } from "@synapse-analytics/synapse-ui"

import { VisionAPI } from "../../../API/VisionAPI"
import WarningDialog from "../../../components/WarningDialog"
import { definitions } from "../../../types/Generated/apiTypes"

type PaginatedCamera = definitions["PaginatedCamerasList"]
type Camera = definitions["CameraRetrieveUpdate"]

interface Props {
  camera?: PaginatedCamera | Camera
}

const CameraStatus: FC<Props> = ({ camera }) => {
  const queryClient = useQueryClient()
  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false)

  const handleOpenStatusDialog = (): void => setIsStatusDialogOpen(true)

  const handleCloseStatusDialog = (): void => setIsStatusDialogOpen(false)

  const { mutate: toggleCameraStatus, isLoading: isTogglingCamera } = useMutation(
    (values: { id?: number; status: boolean }) =>
      VisionAPI.updateCameraStatus({ id: values.id, status: values.status }),
    {
      onSettled: () => {
        queryClient?.invalidateQueries("fetchSingleCamera")
        queryClient?.invalidateQueries("fetchCamerasPaginated")
        handleCloseStatusDialog()
      },
    }
  )

  const handleToggleCamera = () => toggleCameraStatus({ id: camera?.id, status: !camera?.active })

  return (
    <Fragment>
      {/* Camera Status Switch Confirmation [ON/OFF] Dialog */}
      <WarningDialog
        isOpen={isStatusDialogOpen}
        onConfirm={handleToggleCamera}
        onCancel={handleCloseStatusDialog}
        isLoading={isTogglingCamera}
        dialogTitle={`Switch ${camera?.name} ${camera?.active ? "OFF" : "ON"} `}
        content={camera?.active ? `Are you sure to turn off the camera?` : `Are you sure to turn on the camera?`}
      />
      <div
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        <Switch checked={camera?.active} onChange={handleOpenStatusDialog} />
      </div>
    </Fragment>
  )
}

export default CameraStatus
