import { FC, KeyboardEvent, useMemo, useState } from "react"
import { useQuery } from "react-query"

import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import CarStatsIcon from "@mui/icons-material/AutoGraph"
import CloseIcon from "@mui/icons-material/Close"
import CarPathIcon from "@mui/icons-material/Route"
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart"
import TableViewIcon from "@mui/icons-material/TableView"
import { Grid, Paper, Dialog } from "@mui/material"

import { Select, Skeleton, Tab, Tabs, Typography, Button } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import moment from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import ButtonSwitch from "../../../components/Buttons/ButtonSwitch"
import GraphEmptyState from "../../../components/graphs/GraphEmptyState"
import PaginatedBarGraph from "../../../components/graphs/PaginatedBarGraph"
import CountTable from "../../../components/tables/GenericTable"
import { TableColumn } from "../../../types/Custom/Types"
import { definitions } from "../../../types/Generated/apiTypes"
import { convertToHumanReadableDuration, processCarVisitData } from "../../../utils/carsUtils"
import CarPath from "./CarPath"
import CarPathsTable from "./CarPathsTable"

import styles from "./CarsDetails.module.scss"

type CarVisit = definitions["CarVisit"]
type CarPaths = definitions["CarPaths"]
type CarLogsResults = definitions["CarCounterLogRetrieve"]

interface Props {
  car?: CarLogsResults
  open: boolean
  logsCount?: number
  selectedIndex: number
  handleClose: () => void
  handleChangeSelectedIndex: (index: number) => void
  handleFetchDetailsPage: (direction?: "forward" | "backward") => void
  hasMore?: boolean
  hasPrevious?: boolean
  isLoadingPage?: boolean
}

const tabs = [
  {
    icon: <CarStatsIcon />,
    label: "Car Stats",
  },
  {
    icon: <CarPathIcon />,
    label: "Car Path",
  },
]

const CarsDetails: FC<Props> = ({
  car,
  open,
  logsCount,
  selectedIndex,
  hasMore,
  hasPrevious,
  isLoadingPage,
  handleChangeSelectedIndex,
  handleFetchDetailsPage,
  handleClose,
}) => {
  const [activeView, setActiveView] = useState(0)
  const [activeTab, setActiveTab] = useState(0)
  const [currentGraphPage, setCurrentGraphPage] = useState(1)
  const [selectedDays, setSelectedDays] = useState<string[]>([])
  const [pathData, setPathData] = useState<CarPaths[]>([])

  const { data: visitData, isFetching: isCarDetailsLoading } = useQuery<CarVisit, AxiosError>(
    ["fetchCarLicensePlate", car?.license_plate_data],
    ({ queryKey }) => VisionAPI.fetchCarLicensePlate({ license_plate: queryKey[1] as string }),
    {
      onSuccess: (data) => {
        setSelectedDays([moment(data.paths[0].day).format("DD MMM YYYY")])
        setPathData([data.paths[0]])
      },
      keepPreviousData: true,
      enabled: !!car?.license_plate_data,
    }
  )

  const isLoading = isCarDetailsLoading || isLoadingPage

  const handleNavigateForward = () => {
    if (selectedIndex + 1 === logsCount) {
      handleFetchDetailsPage("forward")
    } else {
      handleChangeSelectedIndex(selectedIndex + 1)
    }
  }

  const handleNavigateBackward = () => {
    if (selectedIndex === 0) {
      handleFetchDetailsPage("backward")
    } else {
      handleChangeSelectedIndex(selectedIndex - 1)
    }
  }

  const handleKeyboardNavigation = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "ArrowRight" && (selectedIndex + 1 !== logsCount || hasMore)) {
      handleNavigateForward()
    } else if (e.key === "ArrowLeft" && (hasPrevious || selectedIndex !== 0)) {
      handleNavigateBackward()
    }
  }

  const handleDaysChange = (selectedDays: string[]) => {
    if (selectedDays?.length < 1) return
    setSelectedDays(selectedDays)
    setPathData(visitData?.paths?.filter((day) => selectedDays.includes(moment(day.day).format("DD MMM YYYY"))) || [])
  }

  const statsGraphData = useMemo(() => processCarVisitData(visitData?.visits_duration), [visitData])

  const statsTableCols: TableColumn[] = [
    {
      title: "Date",
      field: "date",
      searchable: true,
      render: (rowData) => moment(rowData.date).format("DD MMM YYYY"),
    },
    {
      title: "Entrance Entity",
      field: "entity_name",
      searchable: false,
    },
    {
      title: "Time Spent",
      field: "duration",
      searchable: false,
      render: (rowData) => (rowData.duration ? convertToHumanReadableDuration(rowData.duration) : "No data"),
    },
  ]

  const availableDays = visitData?.paths?.map((day) => moment(day.day).format("DD MMM YYYY")) || []

  return (
    <Dialog
      maxWidth="lg"
      PaperProps={{
        sx: {
          minWidth: "1480px",
        },
      }}
      open={open}
      onClose={handleClose}
      fullWidth
      onKeyDown={handleKeyboardNavigation}
    >
      <Paper elevation={0} className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>
            <Typography variant="h2-bold">All Car History</Typography>
            <Typography variant="h2-bold" variantColor={2} textTransform="capitalize">
              {car?.brand} {car?.car_model || ""} ({car?.license_plate_data!})
            </Typography>
            <Typography variant="h2-regular" variantColor={2}>
              | <b>Last visit</b> {moment(car?.timestamp).format("DD MMM YYYY  - hh:mm A")}
            </Typography>
          </div>
          <CloseIcon className={styles.close} onClick={handleClose} />
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {isLoading ? (
              <Skeleton variant="rectangular" width="100%" height="425px" />
            ) : (
              <img className={styles.image} src={car?.car_image!} alt="carImage" />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={styles.cardWrapper}>
              <div className={styles.header}>
                <Tabs value={activeTab}>
                  {tabs.map((tab, index) => (
                    <Tab
                      key={index}
                      icon={() => tab.icon}
                      label={tab.label}
                      onClick={() => setActiveTab(index)}
                      value={index}
                      selected={activeTab === index}
                      id={`${index}_tab`}
                    />
                  ))}
                </Tabs>
                <ButtonSwitch
                  activePage={activeView}
                  pages={["Graph", "Table"]}
                  handleSelectButton={setActiveView}
                  disabled={isCarDetailsLoading}
                  pagesIcons={[<StackedBarChartIcon />, <TableViewIcon />]}
                />
              </div>
              {availableDays.length > 0 && (
                <div style={{ visibility: activeTab === 1 ? "visible" : "hidden" }}>
                  <Select
                    options={availableDays}
                    value={`Date Filter (${selectedDays.length})`}
                    hideDescription
                    loading={isLoading}
                    placeholder="Select a day"
                    fullWidth
                    multipleModeProps={{
                      isActive: true,
                      hideChips: true,
                      selectedValues: selectedDays,
                      setSelectedValues: handleDaysChange,
                    }}
                  />
                </div>
              )}

              {/* Car Stats */}
              {activeTab === 0 &&
                (activeView === 0 ? (
                  <PaginatedBarGraph
                    isDurationGraph
                    customBarTitle="- Duration"
                    barGraphData={statsGraphData}
                    isLoading={isLoading}
                    indexBy="date"
                    keys={["duration"]}
                    maxBars={4}
                    height={330}
                    currentPage={currentGraphPage}
                    setCurrentPage={setCurrentGraphPage}
                  />
                ) : (
                  <CountTable
                    data={statsGraphData}
                    isLoading={!!isLoading || !!isLoadingPage}
                    columns={statsTableCols}
                    title={"Stats - Time spent"}
                    height={328}
                  />
                ))}

              {/* Car Path */}
              {activeTab === 1 &&
                (activeView === 0 ? (
                  <div className={styles.pathGraphWrapper}>
                    {pathData.length === 0 ? (
                      <GraphEmptyState />
                    ) : (
                      pathData.map((path) => <CarPath key={path.day} pathData={path} isLoading={isLoading} />)
                    )}
                  </div>
                ) : (
                  <CarPathsTable carPaths={pathData} />
                ))}
            </div>
          </Grid>
        </Grid>
        <div className={styles.paginationWrapper}>
          <Button
            variant="secondary"
            onClick={handleNavigateBackward}
            disabled={!hasPrevious && selectedIndex === 0}
            startIcon={<ArrowBackIcon fontSize="small" />}
          >
            Previous
          </Button>
          <Typography variant="p" variantColor={2}>
            You can use keyboard arrows ( ⬅️ / ➡️ ) to navigate
          </Typography>
          <Button
            variant="secondary"
            onClick={handleNavigateForward}
            disabled={selectedIndex + 1 === logsCount && !hasMore}
            endIcon={<ArrowForwardIcon fontSize="small" />}
          >
            Next
          </Button>
        </div>
      </Paper>
    </Dialog>
  )
}

export default CarsDetails
