import React, { useState, FC, Fragment } from "react"
import { useMutation, useQueryClient } from "react-query"

import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined"
import { IconButton } from "@mui/material"

import { NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import { definitions } from "../../../types/Generated/apiTypes"
import CampaignPopup from "../components/CampaignPopup"

import styles from "./CampaignNotes.module.scss"

type CampaignNotesList = definitions["CampaignNotesList"]

interface Props {
  note: CampaignNotesList
  chosenCampaign: boolean
}

const CampaignNotes: FC<Props> = ({ note, chosenCampaign }) => {
  const queryClient = useQueryClient()
  const [openDeleteNote, setOpenDeleteNote] = useState(false)

  const { mutate: deleteNote, isLoading: loadingDeleteNote } = useMutation(
    () => VisionAPI.deleteNoteCampaign(note?.id),
    {
      onSuccess: () => {
        setOpenDeleteNote(false)
        if (chosenCampaign) {
          queryClient.invalidateQueries("fetchChosenCampaign")
        } else {
          queryClient.invalidateQueries("fetchSingleCampaign")
        }
        queryClient.invalidateQueries("fetchWeatherSingleCampaign")
        NotificationUtils.toast("Note deleted successfully", {
          severity: "success",
        })
      },
    }
  )

  const handleOpenDelete = () => {
    setOpenDeleteNote(true)
  }
  const handleCloseDelete = () => {
    setOpenDeleteNote(false)
  }

  return (
    <Fragment>
      {/* notes */}
      <div className={styles.note}>
        <div className={styles.noteInfo}>
          <Typography variant="a" variantColor={2}>
            {note?.user?.full_name!}
          </Typography>
          <Typography variant="label-regular" variantColor={2}>
            {moment(note?.created_at!).format("DD/MM/YYYY")}
          </Typography>
        </div>

        <Typography variant="label-regular" variantColor={2} className={styles.noteBody}>
          {note?.body!}
        </Typography>

        {/* delete note */}
        {note?.can_delete && (
          <IconButton className={styles.deleteNote} onClick={handleOpenDelete} size="large">
            <DeleteIcon />
          </IconButton>
        )}
      </div>

      {/* Delete Notes */}
      <CampaignPopup
        type="deleteNote"
        name=""
        loading={loadingDeleteNote}
        open={openDeleteNote}
        handleAction={deleteNote}
        handleClose={handleCloseDelete}
      />
    </Fragment>
  )
}

export default CampaignNotes
