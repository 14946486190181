import React, { useState, useEffect, useMemo } from "react"
import { PathLine } from "react-svg-pathline"

import { v4 } from "uuid"

import { assignColor } from "../../../utils/mcpUtils"

import styles from "./MCPPath.module.scss"

const defaultColors = ["#0021CF", "#D905D0", "#32E6E6", "#56EE49", "#FAC02B", "#CF3E00"]

const MCPPath = ({ lineData, maxValue, width, height }) => {
  const [color, setColor] = useState("#0021CF")
  const [uniqueId, setUniqueId] = useState()
  // directed arrow start position
  const arrowStart = useMemo(() => {
    return { x: lineData?.at(-4)?.x, y: lineData?.at(-4)?.y }
  }, [lineData])
  // directed arrow end position
  const arrowEnd = useMemo(() => {
    return { x: lineData?.at(-2)?.x, y: lineData?.at(-2)?.y }
  }, [lineData])
  // arrow line rotation angle calculation
  const lineAngle = useMemo(() => {
    return Math.atan2(arrowEnd.y - arrowStart.y, arrowEnd.x - arrowStart.x)
  }, [arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y])
  const middle = useMemo(() => {
    const middleX = Math.floor((lineData?.at(0)?.x + lineData?.at(-1)?.x) / 2)
    const middleY = Math.floor((lineData?.at(0)?.y + lineData?.at(-1)?.y) / 2)

    return { x: middleX, y: middleY }
  }, [lineData])
  // arrow head rotation angle calculation
  const angle = useMemo(() => {
    return lineAngle + Math.PI
  }, [lineAngle])
  // setting a unique id for each pathline
  // used to unique each color pattern
  useEffect(() => {
    const id = v4()
    setUniqueId(id)
  }, [])
  // assigning color for the whole pathline depending on its value compared to max value
  useEffect(() => {
    // passing values to assignColor function
    const value = lineData[0].val
    const assignedColor = assignColor(value, maxValue, defaultColors)
    setColor(assignedColor)
  }, [lineData, maxValue])

  return (
    <div
      style={{
        position: "absolute",
      }}
    >
      <svg svg width={width} height={height}>
        <g>
          <circle cx={lineData?.at(0)?.x} cy={lineData?.at(0)?.y} r="8.5" fill={color} />
        </g>

        <PathLine points={lineData} stroke={color} strokeWidth="3" fill="none" r={0.01} />
        <g key={uniqueId}>
          <defs>
            <marker
              id={uniqueId}
              markerWidth="100"
              markerHeight="100"
              refX="0"
              refY="3"
              orient="auto"
              fill={color}
              viewBox={`0 0 100 100`}
            >
              <path d="M0,0 L0,6 L9,3 z" transform={`rotate(${angle}, 4.5, 3)`} />
            </marker>
          </defs>
          <path
            d={`M${arrowStart.x},${arrowStart.y} L${arrowEnd.x},${arrowEnd.y}`}
            stroke={color}
            strokeWidth="3"
            markerStart="transparent"
            markerEnd={`url(#${uniqueId})`}
          />
        </g>
        <defs>
          <filter id={`outlineColor_of_${uniqueId}`}>
            <feMorphology in="SourceAlpha" result="Dilated" operator="dilate" radius="2" />
            <feFlood flood-color={`${color}`} result="OutlineColor" />
            <feComposite in="OutlineColor" in2="Dilated" operator="in" result="Outline" />
            <feMerge>
              <feMergeNode in="Outline" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <text
          x={middle.x}
          filter={`url(#outlineColor_of_${uniqueId})`}
          d="M1,1 h4"
          y={middle.y}
          text-anchor="middle"
          className={styles.lineValue}
          stroke={"var(--base-inverse-text-1)"}
          fill="none"
        >
          {lineData[0]?.val}
        </text>
      </svg>
    </div>
  )
}
export default MCPPath
