import { FC, useEffect, useState, Fragment } from "react"
import { useQuery } from "react-query"

import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { CircularProgress } from "@mui/material"

import { Chip, Typography } from "@synapse-analytics/synapse-ui"
import clsx from "clsx"

import { VisionAPI } from "../../../../API/VisionAPI"
import { definitions } from "../../../../types/Generated/apiTypes"
import cameraAttentionPlaceholder from "../../assets/cameraAttention.svg"
import cameraDownPlaceholder from "../../assets/cameraDown.svg"
import cameraHealthyPlaceholder from "../../assets/cameraHealthy.svg"
import check from "../../assets/check.png"
import DeleteCameraAlert from "./DeleteCameraAlert"

import styles from "./CameraPreview.module.scss"

type Camera = definitions["CameraRetrieveUpdate"] & {
  colorCode?: string
}
type CameraHealth = definitions["CameraHealth"]
interface Props {
  camera: number
  addValidCamera?: (validCamera: Camera) => void
  removeValidCamera?: (cameraToRemove: number) => void
  addInvalidCamera?: (validCamera: Camera) => void
  removeInvalidCamera?: (cameraToRemove: number) => void
  unSelectCamera?: (cameraToRemove: number) => void
  isDrawingSelection: boolean
  selectedCamera?: number
  checkMark: boolean
  margin: boolean
  colorCode?: string
  isAdded?: boolean
  isInvalid?: boolean
  isLocated?: boolean
}
const CameraPreview: FC<Props> = ({
  camera,
  addValidCamera,
  removeValidCamera,
  addInvalidCamera,
  removeInvalidCamera,
  isDrawingSelection,
  selectedCamera,
  isAdded,
  checkMark,
  unSelectCamera,
  margin,
  colorCode,
  isInvalid,
  isLocated,
}) => {
  const [invalidAnimation, setInvalidAnimation] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)

  const { data: cameraData, isLoading: cameraDataLoading } = useQuery<Camera>(
    ["fetchSingleCamera", camera],
    ({ queryKey }) => VisionAPI.fetchSingleCamera(queryKey[1] as number),
    {
      enabled: !!camera,
    }
  )

  const { data: camerasHealth, isLoading: camerasHealthLoading } = useQuery<CameraHealth>(
    ["fetchCameraHealth", camera],
    ({ queryKey }) => VisionAPI.fetchCameraHealth(queryKey[1] as number),
    {
      enabled: !!camera,
    }
  )

  useEffect(() => {
    if (!cameraDataLoading && cameraData && !isDrawingSelection) {
      if (cameraData.sample_frame) {
        addValidCamera?.({
          ...cameraData,
          colorCode: `${Math.round(Math.random() * 200)},${Math.round(Math.random() * 200)},${Math.round(
            Math.random() * 200
          )}`,
        })
      } else {
        addInvalidCamera?.({
          ...cameraData,
          colorCode: `${Math.round(Math.random() * 200)},${Math.round(Math.random() * 200)},${Math.round(
            Math.random() * 200
          )}`,
        })
      }
    }

    //eslint-disable-next-line
  }, [cameraData, cameraDataLoading])

  const handleInvalidCameraClick = () => {
    setInvalidAnimation(true)
    setTimeout(() => {
      setInvalidAnimation(false)
    }, 300)
  }

  const handleDeleteCamera = () => {
    unSelectCamera?.(camera)
    if (!cameraDataLoading && cameraData && !isDrawingSelection) {
      if (cameraData.sample_frame) {
        removeValidCamera?.(camera)
      } else {
        removeInvalidCamera?.(camera)
      }
    }
  }

  const handleOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false)
  }

  const handleClickOnUnCheck = () => {
    if (isLocated) {
      handleOpenDeleteDialog()
    } else {
      handleDeleteCamera()
    }
  }
  return (
    <Fragment>
      <div
        className={clsx(styles.cameraCard, invalidAnimation && styles.shakeAnimation)}
        style={{
          height: isDrawingSelection ? "150px" : "200px",
          marginBottom: isDrawingSelection ? "" : "16px",
          cursor: isDrawingSelection ? "pointer" : "auto",
          border: isDrawingSelection
            ? camera === selectedCamera
              ? `5px solid rgb(${colorCode})`
              : "5px solid var(--base-background-3)"
            : "",
          boxSizing: "border-box",
          borderRadius: "4px",
        }}
        onClick={() => {
          if ((!cameraDataLoading && cameraData && !cameraData.sample_frame && isDrawingSelection) || isInvalid) {
            handleInvalidCameraClick()
          }
        }}
      >
        {cameraDataLoading ? (
          <CircularProgress size={40}></CircularProgress>
        ) : cameraData?.sample_frame ? (
          <div className={styles.previewCard}>
            <div
              className={styles.cameraName}
              style={{
                background: colorCode ? `rgb(${colorCode})` : "var(--base-background-3)",
              }}
            >
              <Typography variant={isDrawingSelection ? "label-bold" : "h3-bold"} title={cameraData.name}>
                {cameraData.name.length >= 14 ? cameraData.name.slice(0, 12) + ".." : cameraData.name}
              </Typography>
              {!camerasHealthLoading && camerasHealth && camerasHealth.health !== null && (
                <img
                  src={
                    camerasHealth.health === 1
                      ? cameraHealthyPlaceholder
                      : camerasHealth.health < 1 && camerasHealth.health > 0
                      ? cameraAttentionPlaceholder
                      : camerasHealth.health === 0
                      ? cameraDownPlaceholder
                      : ""
                  }
                  alt="cameras_health_indicator"
                />
              )}
            </div>
            {!isDrawingSelection && !checkMark && (
              <DeleteOutlineIcon
                className={styles.validCamera}
                style={{
                  top: isDrawingSelection ? "4%" : "6%",
                  cursor: isDrawingSelection ? "auto" : "pointer",
                }}
                onClick={handleClickOnUnCheck}
              />
            )}
            <img
              src={cameraData.sample_frame}
              alt="camera_sample_frame"
              height="100%"
              width="100%"
              style={{
                borderRadius: !isDrawingSelection ? 4 : "",
                top: 0,
                left: 0,
                minHeight: 140,
              }}
            />
            {isDrawingSelection && !checkMark && (
              <img src={check} alt="success" style={{ position: "absolute", top: "27%", left: "33%", height: 70 }} />
            )}
            {isAdded && (
              <div className={styles.addedCameras}>
                <Typography variant="h3-bold" color="positive" variantColor={2} className={styles.addedStatus}>
                  <CheckCircleIcon />
                  Done
                </Typography>
              </div>
            )}

            <Chip size="small" className={styles.activeStatus} isSelected={cameraData.active}>
              {cameraData.active ? "Active" : "Inactive"}
            </Chip>
          </div>
        ) : (
          <Fragment>
            {cameraData && (
              <Typography
                variant={isDrawingSelection ? "label-bold" : "h3-bold"}
                className={styles.cameraName}
                title={cameraData?.name}
              >
                {cameraData?.name.length >= 14 ? cameraData?.name.slice(0, 12) + ".." : cameraData?.name}
                {/* camera down indicator */}
                {!camerasHealthLoading && camerasHealth && camerasHealth.health !== null && (
                  <img
                    src={
                      camerasHealth.health === 1
                        ? cameraHealthyPlaceholder
                        : camerasHealth.health < 1 && camerasHealth.health > 0
                        ? cameraAttentionPlaceholder
                        : camerasHealth.health === 0
                        ? cameraDownPlaceholder
                        : ""
                    }
                    alt="cameras_health_indicator"
                    style={{ marginLeft: 10 }}
                  />
                )}
              </Typography>
            )}
            {!checkMark && !isDrawingSelection && (
              <DeleteOutlineIcon
                className={styles.validCamera}
                style={{
                  top: isDrawingSelection ? "4%" : "6%",
                  cursor: isDrawingSelection ? "auto" : "pointer",
                }}
                onClick={handleClickOnUnCheck}
              />
            )}
            <Typography variant="label-bold" variantColor={2} className={styles.noFrame}>
              No Frame Captured
            </Typography>
            {cameraData && (
              <Chip size="small" className={styles.activeStatus} isSelected={cameraData.active}>
                {cameraData.active ? "Active" : "Inactive"}
              </Chip>
            )}
          </Fragment>
        )}
      </div>
      {isDeleteDialogOpen && (
        <DeleteCameraAlert
          open={isDeleteDialogOpen}
          cameraName={cameraData?.name}
          handleDeleteCamera={handleDeleteCamera}
          onClose={handleCloseDeleteDialog}
        />
      )}
    </Fragment>
  )
}
export default CameraPreview
