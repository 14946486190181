import { FC } from "react"

import BlurLinearOutlinedIcon from "@mui/icons-material/BlurLinearOutlined"
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled"
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun"
import DoNotStepIcon from "@mui/icons-material/DoNotStep"
import GppBadOutlinedIcon from "@mui/icons-material/GppBadOutlined"
import OccupancyIcon from "@mui/icons-material/GroupsOutlined"
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment"
import NoTransferIcon from "@mui/icons-material/NoTransfer"
import PeopleIcon from "@mui/icons-material/People"
import PersonOffIcon from "@mui/icons-material/PersonOff"
import StrayAnimalIcon from "@mui/icons-material/Pets"
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt"

import { Tag } from "@synapse-analytics/synapse-ui"

import MCPIcon from "../assets/servicesIcons/mcp.svg?react"
import { ServiceEventType } from "../types/Custom/Types"

import styles from "./ServiceEventTag.module.scss"

interface Props {
  serviceEventType: ServiceEventType
  hasMargin?: boolean
  size?: "small" | "default"
}

type Color = "blue" | "red" | "yellow" | "green" | "purple" | "orange" | "gray" | "pink"

type Service = {
  icon: JSX.Element
  label: string
  color: Color
}

type Services = {
  [key: string]: Service
}

const ServiceCard: FC<Props> = ({ serviceEventType, hasMargin, size }) => {
  const services: Services = {
    mcp: {
      icon: <MCPIcon />,
      label: "MCP",
      color: "blue",
    },
    violence: {
      icon: <GppBadOutlinedIcon className={styles.icon} />,
      label: "Violence",
      color: "red",
    },
    heatmap: {
      icon: <BlurLinearOutlinedIcon className={styles.icon} />,
      label: "Heatmap",
      color: "red",
    },
    counter: {
      icon: <PeopleIcon className={styles.icon} />,
      label: "Counter",
      color: "purple",
    },
    occupancy: {
      icon: <OccupancyIcon className={styles.icon} />,
      label: "Occupancy",
      color: "pink",
    },
    car: {
      icon: <DirectionsCarFilledIcon className={styles.icon} />,
      label: "Car",
      color: "purple",
    },
    face: {
      icon: <SentimentSatisfiedAltIcon className={styles.icon} />,
      label: "Faces",
      color: "orange",
    },
    fire: {
      icon: <LocalFireDepartmentIcon className={styles.icon} />,
      label: "Fire",
      color: "yellow",
    },
    motion: {
      icon: <DirectionsRunIcon className={styles.icon} />,
      label: "Motion",
      color: "green",
    },
    intrusion: {
      icon: <DoNotStepIcon className={styles.icon} />,
      label: "Intrusion",
      color: "pink",
    },
    blackListCar: {
      icon: <NoTransferIcon className={styles.icon} />,
      label: "Blacklist Car",
      color: "gray",
    },
    blackListPerson: {
      icon: <PersonOffIcon className={styles.icon} />,
      label: "Blacklist Person",
      color: "gray",
    },
    stray_animals: {
      icon: <StrayAnimalIcon className={styles.icon} />,
      label: "Stray Animals",
      color: "orange",
    },
  }
  return (
    <Tag
      color={services[serviceEventType]?.color ?? "gray"}
      style={{
        marginRight: hasMargin ? "8px" : "",
      }}
      size={size || "default"}
    >
      {services[serviceEventType]?.label ?? ""}
      <div className={styles.iconWrapper}>{services[serviceEventType]?.icon}</div>
    </Tag>
  )
}
export default ServiceCard
