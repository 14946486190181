import { useState, FC, useEffect, useMemo } from "react"
import { useQuery } from "react-query"

import { Grid } from "@mui/material"

import { Chip, Typography } from "@synapse-analytics/synapse-ui"
import { Skeleton } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { Moment } from "moment"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../API/VisionAPI"
import DataOverTimeCard from "../../../components/GraphCards/DataOverTimeCard"
import PaginatedBarGraphCard from "../../../components/GraphCards/PaginatedBarGraphCard"
import { useBranchesStore } from "../../../store"
import { TableColumn } from "../../../types/Custom/Types"
import { definitions } from "../../../types/Generated/apiTypes"
import { calculateSumPerTime, convertDataToHourlyLineGraph } from "../../../utils/counterUtils"
import { formatTimestampForTable } from "../../../utils/genericHelpers"

import styles from "./SingleCorridor.module.scss"

type EntitiesLogs = definitions["EntitiesLogs"]
type CorridorCameraCount = definitions["CorridorCameraCount"]
type Corridor = definitions["Corridor"]

interface Props {
  startDate: Moment | null
  endDate: Moment | null
  interval: Duration
  refs: any[]
  timeGrain: "hour" | "day"
  staffFilterValue?: boolean | null
}

const loadingChips = new Array(6).fill(null).map((_, i) => (
  <Grid item md={2} key={i}>
    <Skeleton variant="rectangular" height={26} width="auto" />
  </Grid>
))

const SingleCorridor: FC<Props> = ({ startDate, endDate, timeGrain, staffFilterValue, interval, refs }) => {
  const [selectedCorridor, setSelectedCorridor] = useState<Corridor>()
  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  const { data: corridorEntities, isLoading: corridorEntitiesLoading } = useQuery<Corridor[], AxiosError>(
    ["fetchCorridorEntities", selectedBranch],
    ({ queryKey }) => VisionAPI.fetchCorridors({ branch: queryKey[1] as number }),
    {
      enabled: !!selectedBranch,
    }
  )
  const { data: singleCorridorLogs, isLoading: singleCorridorLogsLoading } = useQuery<EntitiesLogs>(
    [
      "fetchCorridorsLogs",
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      timeGrain,
      selectedCorridor?.id,
      selectedBranch,
      staffFilterValue,
    ],

    ({ queryKey }) =>
      VisionAPI.fetchCorridorsCounterStats({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        date_slice: queryKey[3] as string,
        entity_ids: queryKey[4] as number,
        branch: queryKey[5] as number,
        staff: queryKey[6] as boolean | null,
      }),
    {
      enabled: [
        !!endDate,
        corridorEntities && corridorEntities?.length > 0,
        !!selectedCorridor?.id,
        !!selectedBranch,
      ].every((check) => check === true),
    }
  )

  const { data: cameraBreakdownLogs, isLoading: cameraBreakdownLogsLoading } = useQuery<CorridorCameraCount[]>(
    [
      "fetchCorridorCameraBreakdownLogs",
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      staffFilterValue,
      selectedCorridor?.id,
      selectedBranch,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchCorridorsCameraBreakdownStats(queryKey[4] as number, {
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        staff: queryKey[3] as boolean | null,
        branch: queryKey[5] as number,
      }),
    {
      enabled: [
        !!endDate,
        !!selectedBranch,
        corridorEntities && corridorEntities?.length > 0,
        !!selectedCorridor?.id,
      ].every((check) => check === true),
    }
  )

  // handling clicking on one of the corridor filters
  const handleSelectCategory = (corridor: Corridor) => {
    //checking whether clicking on another category option or just toggling (unselecting)
    if (corridor.name !== selectedCorridor?.name) {
      setSelectedCorridor(corridor)
    }
  }

  useEffect(() => {
    if (!corridorEntitiesLoading && corridorEntities && corridorEntities[0]) {
      setSelectedCorridor(Object.values(corridorEntities)[0])
    }
  }, [corridorEntities, corridorEntitiesLoading])

  // converting data to suitable format that nivo bar graph accepts
  // using the appropriate formatting function based on the sent data type
  const processLineGraphData = useMemo(() => {
    if (!singleCorridorLogsLoading && singleCorridorLogs?.entities_logs && endDate) {
      const lineGraphTableData = calculateSumPerTime(singleCorridorLogs?.entities_logs)
      const lineGraphData = convertDataToHourlyLineGraph(lineGraphTableData)
      return { lineGraphTableData, lineGraphData }
    }
  }, [endDate, singleCorridorLogs, singleCorridorLogsLoading])

  const barGraphTableColumns: TableColumn[] = [
    {
      title: "Camera",
      field: "camera",
      searchable: false,
    },
    {
      title: "Male Count",
      field: "male_count",
      searchable: false,
    },
    {
      title: "Female Count",
      field: "female_count",
      searchable: false,
    },
    {
      title: "Adult Count",
      field: "adult_count",
      searchable: false,
    },
    {
      title: "Child Count",
      field: "child_count",
      searchable: false,
    },
  ]
  const lineGraphTableColumns: TableColumn[] = [
    {
      title: "Date/Time",
      field: "timestamp",
      searchable: false,
      render: (rowData) => formatTimestampForTable(rowData.timestamp, timeGrain as string),
    },
    {
      title: "Count In",
      field: "Count In",
      searchable: false,
    },
    {
      title: "Count Out",
      field: "Count Out",
      searchable: false,
    },
  ]

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Typography variant="h3-bold" gutterBottom variantColor={2} tooltip="Analytics of selected corridor">
          Single Corridor Details
        </Typography>
      </div>
      <Grid container spacing={1}>
        {corridorEntitiesLoading ? (
          loadingChips
        ) : corridorEntities && corridorEntities.length > 0 ? (
          corridorEntities?.map((corridor: any, index: number) => (
            <Grid item key={index}>
              <Chip
                clickable
                onClick={() => handleSelectCategory(corridor)}
                isSelected={selectedCorridor?.name === corridor.name}
                disabled={singleCorridorLogsLoading}
              >
                {corridor.name}
              </Chip>
            </Grid>
          ))
        ) : (
          <Grid item>
            <Typography variant="label-bold" variantColor={2}>
              No corridors found
            </Typography>
          </Grid>
        )}
      </Grid>
      <DataOverTimeCard
        graphProps={{
          data: processLineGraphData?.lineGraphData,
          interval: interval,
          hasCheckbox: false,
        }}
        tableProps={{
          columns: lineGraphTableColumns,
          data: processLineGraphData?.lineGraphTableData,
        }}
        timeGrain={timeGrain}
        isLoading={[singleCorridorLogsLoading].some((element) => element === true)}
        reference={refs[0]}
        shouldIncludeMissingData={false}
        dataType={`Corridor ${selectedCorridor?.name} Over Time`}
      />
      <PaginatedBarGraphCard
        data={cameraBreakdownLogs}
        isLoading={cameraBreakdownLogsLoading}
        startDate={startDate?.format("YYYY-MM-DD")}
        endDate={endDate?.format("YYYY-MM-DD")}
        reference={refs[1]}
        graphProps={{
          indexBy: "camera",
          shouldDisplayDistribution: true,
        }}
        tableProps={{
          columns: barGraphTableColumns,
        }}
        title={`Corridor ${selectedCorridor?.name} Broken Down By Camera`}
      />
    </div>
  )
}

export default SingleCorridor
