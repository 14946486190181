import { FC, useEffect } from "react"
import { useQuery } from "react-query"

import { Grid } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"
import { Moment } from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import PaginatedBarGraph from "../../../components/GraphCards/PaginatedBarGraphCard"
import { ExportingRefs } from "../../../types/Custom/Interfaces"
import { TableColumn } from "../../../types/Custom/Types"
import { definitions } from "../../../types/Generated/apiTypes"
import CategoriesOverTime from "./CategoriesOverTime"
import TenantsSubcategories from "./TenantsSubcategories"

import styles from "./ShopStatistics.module.scss"

type CategoryCount = definitions["CategoryCount"]
type ShopsCategoryLogs = definitions["ShopsCategoryLogs"]

interface Props {
  startDate: Moment | null
  endDate?: Moment | null
  interval: Duration
  timeGrain: "hour" | "day"
  setLoadingState: (isLoading: boolean) => void
  refs: ExportingRefs
  staffFilterValue?: boolean | null
}
const ShopStatistics: FC<Props> = ({
  startDate,
  endDate,
  interval,
  setLoadingState,
  timeGrain,
  refs,
  staffFilterValue,
}) => {
  const { categoriesOverTimeRef, categoriesPerformanceRef, subcategoriesPerformanceRef, hourlyAvgRef, weekDayAvgRef } =
    refs

  const { data: dailyCategoriesCountData, isLoading: dailyCategoryCountLoading } = useQuery<ShopsCategoryLogs[]>(
    ["fetchDailyCategoriesCount", startDate?.format("YYYY-MM-DD"), endDate?.format("YYYY-MM-DD"), staffFilterValue],
    ({ queryKey }) =>
      VisionAPI.fetchDailyCategoriesCount({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        staff: queryKey[3] as boolean | null,
      }),
    {
      enabled: !!endDate,
    }
  )
  const { data: categoriesCountData, isLoading: categoriesCountLoading } = useQuery<CategoryCount[]>(
    ["fetchCategoriesCount", startDate?.format("YYYY-MM-DD"), endDate?.format("YYYY-MM-DD"), staffFilterValue],
    ({ queryKey }) =>
      VisionAPI.fetchCategoriesCount({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        staff: queryKey[3] as boolean | null,
      }),
    {
      enabled: !!endDate,
    }
  )

  useEffect(() => {
    if (categoriesCountLoading || dailyCategoryCountLoading) {
      setLoadingState(true)
    } else {
      setLoadingState(false)
    }
  }, [categoriesCountLoading, setLoadingState, dailyCategoryCountLoading])

  const tableColumns: TableColumn[] = [
    {
      title: "Category",
      field: "category",
      searchable: true,
    },
    {
      title: "Male Count",
      field: "male_count",
      searchable: false,
    },
    {
      title: "Female Count",
      field: "female_count",
      searchable: false,
    },
    {
      title: "Adult Count",
      field: "adult_count",
      searchable: false,
    },
    {
      title: "Child Count",
      field: "child_count",
      searchable: false,
    },
    {
      title: "Total Count",
      field: "total_count",
      searchable: false,
    },
  ]

  return (
    <div className={styles.wrapper}>
      <Typography variant="a" variantColor={2} className={styles.title}>
        Stats
      </Typography>
      {/* Categories Performance */}
      <Grid container spacing={2}>
        <Grid item md={12}>
          <PaginatedBarGraph
            data={categoriesCountData}
            isLoading={categoriesCountLoading}
            startDate={startDate?.format("YYYY-MM-DD")}
            endDate={endDate?.format("YYYY-MM-DD")}
            reference={categoriesPerformanceRef}
            graphProps={{
              indexBy: "category",
              shouldDisplayDistribution: true,
            }}
            tableProps={{
              columns: tableColumns,
            }}
            title="Categories performance"
          />
        </Grid>
        <Grid item md={12} style={{ width: "100%" }}>
          <CategoriesOverTime
            logsData={dailyCategoriesCountData!}
            loading={dailyCategoryCountLoading}
            interval={interval}
            categoriesOverTimeRef={categoriesOverTimeRef}
          />
        </Grid>
      </Grid>

      {/* Temporal parts of the analytics */}

      {/* Tenants Sub Categories Section */}
      <TenantsSubcategories
        categoriesLogsData={categoriesCountData}
        dailyCategoriesCountData={dailyCategoriesCountData}
        loading={categoriesCountLoading || dailyCategoryCountLoading}
        startDate={startDate}
        endDate={endDate}
        timeGrain={timeGrain}
        subcategoriesPerformanceRef={subcategoriesPerformanceRef}
        weekDayAvgRef={weekDayAvgRef}
        hourlyAvgRef={hourlyAvgRef}
        interval={interval}
        staffFilterValue={staffFilterValue}
      />
    </div>
  )
}
export default ShopStatistics
