import { useQuery } from "react-query"

import { NotificationUtils, SingleDayPicker } from "@synapse-analytics/synapse-ui"
import { NumberParam, useQueryParam, withDefault } from "use-query-params"

import { VisionAPI } from "../../API/VisionAPI"
import CameraSearch from "../../components/CameraSearch"
import { useDateQuery } from "../../hooks/useDateQuery"
import { definitions } from "../../types/Generated/apiTypes"
import HeatmapTimeline from "./components/HeatmapTimeline"

import styles from "./Heatmap.module.scss"

type Camera = definitions["CameraRetrieveUpdate"]
export type HeatMapHourly = definitions["HeatMapHourly"]

/**
 * CamerasHeatmap component displays a heatmap for selected camera and date.
 * It allows users to select a camera and date to view corresponding heatmap data.
 * The component fetches camera details and heatmap logs using the VisionAPI.
 * It also provides a date picker and a camera search component for user interaction.
 */
const CamerasHeatmap = () => {
  const [selectedDate, setSelectedDate] = useDateQuery({ isDayPicker: true })

  const [selectedCamera, setSelectedCamera] = useQueryParam("camera", NumberParam)
  const [aggregationMode, setAggregationMode] = useQueryParam("aggregationMode", withDefault(NumberParam, 0))

  const { data: cameraData, isLoading: isCameraDataLoading } = useQuery<Camera>(
    ["fetchSingleCamera", selectedCamera],
    ({ queryKey }) => VisionAPI.fetchSingleCamera(queryKey[1] as number),
    {
      enabled: !!selectedCamera,
    }
  )

  const { data: heatmapData, isLoading: isHeatmapDataLoading } = useQuery<HeatMapHourly[]>(
    ["fetchCameraHeatmapV2Logs", selectedCamera, selectedDate?.format("YYYY-MM-DD"), aggregationMode === 1],
    ({ queryKey }) =>
      VisionAPI.fetchCameraHeatmapV2Logs({
        camera_id: queryKey[1] as number,
        date: queryKey[2] as string,
        peak: queryKey[3] as boolean,
      }),
    {
      onSuccess: (data) => {
        if ((data && data.length < 1) || !data) {
          NotificationUtils.toast("No heatmap logs to display in selected date range", {
            severity: "info",
          })
        }
      },
      enabled: !!selectedDate && !!selectedCamera,
    }
  )

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.datePicker}>
          <SingleDayPicker date={selectedDate} disableFuture onDateChange={(date) => setSelectedDate(date)} />
        </div>
        <CameraSearch
          setSelectedCamera={setSelectedCamera}
          services="heatmap"
          selectedCamera={selectedCamera as number}
        />
      </div>
      <HeatmapTimeline
        data={heatmapData}
        isLoading={isCameraDataLoading || isHeatmapDataLoading}
        aggregation={{ aggregationMode, setAggregationMode }}
        selectedCamera={
          selectedCamera && cameraData
            ? {
                image: cameraData?.sample_frame,
                name: cameraData?.name,
              }
            : undefined
        }
        emptySelectionPlaceholder={
          selectedCamera && (!cameraData || (cameraData && !cameraData?.sample_frame)) ? "No camera frame found." : ""
        }
        key={`Heatmap_For_Camera_${selectedCamera}_aggregated_${aggregationMode}`}
      />
    </div>
  )
}
export default CamerasHeatmap
