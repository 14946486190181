import React, { useState, useContext, useEffect, Fragment } from "react"
import { useQuery } from "react-query"
import { useParams, useLocation } from "react-router-dom"

import { Grid, CircularProgress, Paper } from "@mui/material"
import Pagination from "@mui/material/Pagination"

import { Typography, Skeleton } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"

import { VisionAPI } from "../../../API/VisionAPI"
import Placeholder from "../../../assets/placeholder.svg"
import Search from "../../../components/Search"
import { definitions } from "../../../types/Generated/apiTypes"
import { CampaignContext } from "../CampaignContext/CampaignContext"
import CampaignCard from "../components/CampaignCard"
import CampaignList from "../components/CampaignList"
import CampaignDetails from "./CampaignDetails"

import styles from "./CampaignDetaiLsWrapper.module.scss"

type CampaignDetail = definitions["CampaignDetail"]

type campaignId = {
  id: string
}

let pageSize: number = 14

const SKELETON_HEIGHT = 200

interface Location {
  title: string
  loadedCampaign: boolean
}

const CampaignDetaiLsWrapper = () => {
  const { id } = useParams<campaignId>()
  const location: { state: Location } = useLocation()
  const { title, loadedCampaign } = location?.state

  const {
    compare,
    handleCampaignsPagination,
    loadingPastCampaigns,
    pastCampaigns,
    searchValue,
    selectChosenCampaign,
    chosenCampaignId,
    singleCampaignId,
    selectSingleCampaign,
    handleSearch,
  } = useContext(CampaignContext)
  const [pageOffset, setPageOffset] = useState(0)
  const [isLoaded, setIsLoaded] = useState(loadedCampaign)

  useEffect(() => {
    if (compare) setPageOffset(0)
  }, [compare])

  const { fetchCampaignListStatus } = useContext(CampaignContext)

  // fetch original Campaign data
  const {
    data: singleCampaign,
    isLoading,
    refetch,
  } = useQuery<CampaignDetail, AxiosError>(
    ["fetchSingleCampaign", selectSingleCampaign ? singleCampaignId : id],
    ({ queryKey }) => VisionAPI.fetchSingleCampaign({ id: queryKey[1] as string | number }),
    {
      enabled: (!selectSingleCampaign && id) || (selectSingleCampaign && singleCampaignId) ? true : false,
    }
  )

  // fetch chosen Campaign data
  const { data: chosenCampaign, isLoading: isLoadingChosenCampaign } = useQuery<CampaignDetail, AxiosError>(
    ["fetchChosenCampaign", chosenCampaignId],
    ({ queryKey }) => VisionAPI.fetchSingleCampaign({ id: queryKey[1] as string | number }),
    {
      enabled: selectChosenCampaign && chosenCampaignId > 0 ? true : false,
    }
  )

  useEffect(() => {
    if (!isLoading) setIsLoaded(true)
  }, [isLoading])

  useEffect(() => {
    refetch()
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    return () => {}
    //eslint-disable-next-line
  }, [])

  let loadingPlaceholders = new Array(14).fill(null).map((r, i) => (
    <Grid item xs={12} md={6} key={i}>
      <Skeleton variant="rectangular" height={SKELETON_HEIGHT} width="auto" />
    </Grid>
  ))

  return (
    <Fragment>
      <Typography
        variant="h2-regular"
        tooltip="Campaign details information with statistics"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        Campaign Details
      </Typography>

      <Grid container spacing={2} className={styles.wrapper}>
        {/* campaign details screen */}
        <Grid item xs={12} md={compare ? 6 : 12}>
          <div
            className={styles.details}
            style={{
              border: compare ? "3px solid var(--primary-background-default)" : "none",
              backgroundColor: compare ? "var(--base-background-3)" : "transparent",
              borderRadius: compare ? "8px" : 0,
              padding: compare ? 0 : "8px",
            }}
          >
            {isLoading ? (
              <Paper
                elevation={0}
                className={styles.campaignLoadingWrapper}
                style={{
                  height:
                    (isLoading && !compare) ||
                    (!isLoading && !isLoadingChosenCampaign && compare && selectSingleCampaign)
                      ? "auto"
                      : isLoading && isLoadingChosenCampaign
                      ? "200vh"
                      : "100%",
                }}
              >
                <CircularProgress
                  className={styles.campaignLoading}
                  sx={{
                    top: !compare ? "35vh" : "50vh",
                    left: !compare ? "45%" : "50%",
                  }}
                />
              </Paper>
            ) : (
              <CampaignDetails
                chosenCampaign={false}
                singleCampaign={singleCampaign!}
                title={selectSingleCampaign ? "Past Campaigns" : title}
              />
            )}
          </div>
        </Grid>

        {/* compare screen */}
        {compare && (
          <Grid item xs={12} md={6}>
            {selectChosenCampaign ? (
              isLoadingChosenCampaign ? (
                <Paper elevation={0} className={styles.campaignLoadingWrapper}>
                  <CircularProgress className={styles.campaignLoading} />
                </Paper>
              ) : (
                <CampaignDetails chosenCampaign singleCampaign={chosenCampaign!} title="Past Campaigns" />
              )
            ) : (
              <Paper className={styles.compareWrapper} elevation={0}>
                {/* search campaign to compare */}
                <Grid container spacing={2} className={styles.searchWrapper}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h3-bold">Pick an event</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Search
                      type="compareCampaigns"
                      loading={loadingPastCampaigns}
                      placeholder="E.g.EVENT"
                      handleSearch={handleSearch}
                      searchValue={searchValue}
                    />
                  </Grid>
                </Grid>

                {/* campaign list */}
                {loadingPastCampaigns ? (
                  <Grid container spacing={2}>
                    {loadingPlaceholders}
                  </Grid>
                ) : pastCampaigns && pastCampaigns?.results?.length > 0 ? (
                  <Grid container spacing={2}>
                    {pastCampaigns?.results?.map((campaign) => (
                      <Grid item xs={12} md={6} key={campaign?.id}>
                        <CampaignCard campaign={campaign} title={title} compare={true} />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <div className={styles.noCampaignsWrapper}>
                    <img src={Placeholder} alt="No campaigns" className={styles.noCampaignsImage} />
                    <Typography variant="p" align="center">
                      No campaigns found
                    </Typography>
                  </div>
                )}

                {/* Campaign list Pagination */}
                {pastCampaigns && pastCampaigns?.results?.length > 0 && (
                  <Pagination
                    count={Math.ceil(pastCampaigns?.count! / pageSize)}
                    disabled={loadingPastCampaigns}
                    classes={{ root: styles.rootPagination }}
                    page={pageOffset + 1}
                    siblingCount={2}
                    color="standard"
                    shape="rounded"
                    onChange={(e, offset) => {
                      setPageOffset(offset - 1)
                      handleCampaignsPagination(offset - 1)
                    }}
                  />
                )}
              </Paper>
            )}
          </Grid>
        )}
      </Grid>

      {/* Other campaigns [Upcoming] */}
      {isLoaded && (
        <CampaignList
          campaignId={id}
          title="Upcoming Campaigns"
          fetchKey="fetchCampaignListUpcoming"
          fetchRequest={fetchCampaignListStatus}
          status="upcoming"
        />
      )}
    </Fragment>
  )
}

export default CampaignDetaiLsWrapper
