import { FC, useMemo, Ref } from "react"

import { GroupsOutlined, PersonOutlineOutlined, TrendingDown, TrendingUp } from "@mui/icons-material"
import { Divider, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"

import { Skeleton, Typography } from "@synapse-analytics/synapse-ui"

import AvgIcon from "../assets/AvgIcon.svg?react"
import { DistributionData } from "../types/Custom/Interfaces"
import { definitions } from "../types/Generated/apiTypes"
import DistributionBarsCard from "./DistributionBarsCard"
import LoadingDots from "./Loaders/LoadingDots"

import styles from "./StatsCard.module.scss"

/**
 * EntityGroupsCounts is a type definition for entity groups counts data.
 * It is used to display counts of groups, average group size, and singles.
 */
type EntityGroupsCounts = definitions["EntityGroupsCounts"]

/**
 * Props interface for StatsCard component.
 */
interface Props {
  /**
   * The title of the stats card.
   */
  title: string
  /**
   * The current numeric statistic.
   */
  numericStat: number
  /**
   * The historical numeric statistic for comparison.
   */
  historicalStat?: number
  /**
   * The distribution data for the distribution bars card.
   */
  distributionData?: DistributionData[]
  /**
   * The height of the card. Defaults to "80%".
   */
  height?: string
  /**
   * Whether to apply margin bottom to the card. Defaults to false.
   */
  marginBottom?: boolean
  /**
   * Whether the numerical statistic is currently loading.
   */
  isNumericalStatLoading?: boolean
  /**
   * Whether the distribution bars are currently loading.
   */
  areBarsLoading?: boolean
  /**
   * A reference to the card element.
   */
  reference: Ref<HTMLDivElement>
  /**
   * Whether this is the second card in a series. Defaults to false.
   */
  isSecondCard?: boolean
  /**
   * The unit of measurement for the numeric statistic. Defaults to an empty string.
   */
  unit?: string
  /**
   * The entity groups counts data.
   */
  entityGroupsCounts?: EntityGroupsCounts
}

/**
 * StatsCard component displays a card with various statistics, including a title, numeric statistic,
 * historical comparison, distribution data, and entity groups counts.
 *
 * @param {Props} props - The props for the StatsCard component.
 * @returns {JSX.Element} The JSX element for the StatsCard component.
 */
const StatsCard: FC<Props> = ({
  title,
  numericStat,
  historicalStat,
  height = "80%",
  marginBottom,
  isNumericalStatLoading,
  reference,
  isSecondCard,
  entityGroupsCounts,
  unit,
  distributionData,
  areBarsLoading,
}: Props): JSX.Element => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"))

  /**
   * Calculates the difference in percentage between the current and historical numeric statistics.
   *
   * @returns {number} The difference in percentage.
   */
  const differenceInPercentage = useMemo(() => {
    if (historicalStat && numericStat) {
      return ((numericStat - historicalStat) / historicalStat) * 100
    }
    return 0
  }, [numericStat, historicalStat])

  /**
   * Renders the difference in percentage between the current and historical numeric statistics.
   *
   * @returns {JSX.Element | null} The JSX element for the difference or null if no difference.
   */
  const renderDifference = (): JSX.Element | null => {
    if (differenceInPercentage === 0 || !isFinite(differenceInPercentage)) return null
    const isPositive = differenceInPercentage > 0
    return (
      <div className={styles.changeWrapper}>
        <Typography variant="p" align="center" variantColor={2} color={isPositive ? "positive" : "negative"}>
          {Math.abs(differenceInPercentage).toFixed(0)}%
          {isPositive ? <TrendingUp fontSize="small" /> : <TrendingDown fontSize="small" />}
        </Typography>
      </div>
    )
  }

  return (
    <div
      ref={reference}
      className={styles.statsCard}
      style={{
        height: height,
        marginBottom: marginBottom ? 12 : 0,
        width: smallScreen ? "100%" : "auto",
      }}
    >
      <div className={styles.cardHeader}>
        <Typography variant="p" align="left" variantColor={2} className={styles.cardTitle}>
          {title}
        </Typography>
        <div className={styles.countsWrapper}>
          {isNumericalStatLoading ? (
            <LoadingDots minHeight={36} />
          ) : (
            <div className={styles.currentCounts}>
              <Typography variant="h2-bold" align="center" noWrap variantColor={2} className={styles.countNumber}>
                {numericStat > 0 && numericStat !== Infinity
                  ? `${numericStat.toLocaleString()} ${unit || ""}`
                  : "No Data"}
              </Typography>
              {renderDifference()}
            </div>
          )}
          {historicalStat !== undefined && numericStat !== 0 && historicalStat !== 0 && (
            <Typography variant="p" align="center" variantColor={2}>
              Last Week {historicalStat.toLocaleString()}
            </Typography>
          )}
        </div>
        {!isSecondCard &&
          (isNumericalStatLoading ? (
            <Skeleton variant="rectangular" width="100%" height={68} />
          ) : (
            <div className={styles.groupCountsWrapper}>
              <Typography variant="span-regular" variantColor={2} className={styles.group} noWrap>
                <GroupsOutlined fontSize="small" />
                <b>{entityGroupsCounts?.groups_count || 0}</b> Groups{" "}
                {entityGroupsCounts?.counts_in_groups ? <b>({entityGroupsCounts?.counts_in_groups} people)</b> : ""}
              </Typography>
              <Typography variant="span-regular" variantColor={2} className={styles.group} noWrap>
                <AvgIcon fontSize="small" /> Avg group : <b>{entityGroupsCounts?.avg_groups_size || 0}</b>
              </Typography>
              <Typography variant="span-regular" variantColor={2} className={styles.group} noWrap>
                <PersonOutlineOutlined fontSize="small" />
                <b>{entityGroupsCounts?.counts_without_group || 0}</b> Single people
              </Typography>
            </div>
          ))}
      </div>
      {!isSecondCard && <Divider className={styles.divider} />}
      {!isSecondCard && <DistributionBarsCard distributionData={distributionData} isLoading={areBarsLoading} />}
    </div>
  )
}

export default StatsCard
