import { useState, FC, ChangeEvent } from "react"
import { useQueryClient } from "react-query"

import PlayArrowIcon from "@mui/icons-material/PlayArrow"

import { RadioButton, RadioButtonGroup, Select, Skeleton, Typography } from "@synapse-analytics/synapse-ui"

import CameraPlaceholder from "../../../assets/noFrame.svg?react"
import { definitions } from "../../../types/Generated/apiTypes"
import CamerasLive from "../CamerasLive"
import RefreshFrame from "../components/RefreshFrame"

import styles from "./LiveStream.module.scss"

type Camera = definitions["CameraRetrieveUpdate"]

interface Props {
  camera?: Camera
  isLoading?: boolean
}
const LiveStream: FC<Props> = ({ camera, isLoading }) => {
  const [playStream, setPlayStream] = useState(false)
  const [cameraMode, setCameraMode] = useState<"full" | "light" | "debug">("full")
  const [debugService, setDebugService] = useState<"counter" | "car">("counter")

  const hasStream = !isLoading && camera?.url && camera.url.trim().length > 0

  const queryClient = useQueryClient()

  // when camera mode is changed
  const handleCameraMode = (event: ChangeEvent<HTMLInputElement>) => {
    setCameraMode(event.target.value as "full" | "light" | "debug")
    setTimeout(() => {
      queryClient?.invalidateQueries("fetchCamerasStream")
    }, 300)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Typography variant="h3-bold" variantColor={2}>
          Live Stream
        </Typography>
        <RefreshFrame cameraId={camera?.id} hasNoStream={!hasStream} isLoading={isLoading} />
      </div>
      {camera?.sample_frame && (
        <div className={styles.cameraModes}>
          {/* Camera Modes */}
          <RadioButtonGroup
            name="camera-modes"
            alignment="horizontal"
            spacing={3}
            className={styles.radioGroup}
            hideDescription
          >
            <RadioButton
              label="Full"
              value="full"
              id="full"
              readOnly
              checked={cameraMode === "full"}
              onChange={handleCameraMode}
            />
            <RadioButton
              label="Light"
              value="light"
              id="light"
              readOnly
              checked={cameraMode === "light"}
              onChange={handleCameraMode}
            />
            <RadioButton
              label="Debug"
              value="debug"
              id="debug"
              readOnly
              checked={cameraMode === "debug"}
              onChange={handleCameraMode}
            />
          </RadioButtonGroup>
          <Select
            id="debugService"
            value={debugService}
            handleChange={(e) => setDebugService(e.target.value as "counter" | "car")}
            options={["counter", "car"]}
            label="Debug Service"
            isNotchedLabel
            width={130}
          />
        </div>
      )}
      {isLoading ? (
        <Skeleton height={260} width="100%" variant="rectangular" />
      ) : hasStream ? (
        playStream && camera && camera.id ? (
          <CamerasLive
            selectedCameras={[camera?.id]}
            isCameraDetails
            debugService={debugService}
            cameraMode={cameraMode}
            key={camera?.id}
          />
        ) : (
          <div className={styles.placeholderWrapper}>
            {camera?.sample_frame ? (
              <img
                src={`${camera?.sample_frame}?t=${camera?.updated_at}`}
                alt="camera_frame"
                className={styles.sampleFrame}
                key={`frame_at_${camera?.updated_at}`}
              />
            ) : (
              <Typography variant="label-bold" variantColor={2} className={styles.noFrame}>
                No Frame Captured
              </Typography>
            )}
            <PlayArrowIcon className={styles.playStream} onClick={() => setPlayStream(true)} />
          </div>
        )
      ) : (
        <div className={styles.placeholderWrapper}>
          <CameraPlaceholder />
          <Typography variant="h2-bold" variantColor={2}>
            ADD URL FOR CAMERA PREVIEW
          </Typography>
        </div>
      )}
    </div>
  )
}
export default LiveStream
