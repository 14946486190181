import React, { FC } from "react"

import SearchIcon from "@mui/icons-material/Search"

import { InputText } from "@synapse-analytics/synapse-ui"

import styles from "./Search.module.scss"

interface Props {
  handleSearch: (value: string) => void
  searchValue: string
  placeholder: string
  loading?: boolean
  variant?: "filled" | "outlined"
  size?: number
  type?: string
}

const Search: FC<Props> = ({ handleSearch, searchValue, placeholder, variant = "filled", loading, type, size }) => {
  return (
    <InputText
      loading={loading}
      hideDescription
      width={size ? size : type === "compareCampaigns" ? 285 : type === "carLogs" ? 298 : 340}
      value={searchValue}
      handleChange={(e) => {
        handleSearch(e.target.value as string)
      }}
      placeholder={placeholder}
      startAdornment={<SearchIcon className={styles.icon} fontSize="small" />}
      adornmentSize="small"
      variant={variant}
    />
  )
}

export default Search
