import { FC, useEffect, useState, Fragment } from "react"
import { useQuery } from "react-query"

import { Moment } from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import Placeholder from "../../../components/Placeholder"
import { definitions } from "../../../types/Generated/apiTypes"
import HeatmapLogs from "./HeatmapLogs"

type Camera = definitions["CameraRetrieveUpdate"]

interface Props {
  startDate: Moment | null
  endDate: Moment | null
  selectedCamera: number
  timeGrain: "daily" | "hourly"
  loadingSetter: (loadingState: boolean) => void
  disableSetter: (disableState: boolean) => void
}
const HeatmapContainer: FC<Props> = ({
  startDate,
  endDate,
  selectedCamera,
  timeGrain,
  loadingSetter,
  disableSetter,
}) => {
  const [open, setOpen] = useState(false)
  const [interval, setInterval] = useState<string>("")

  useEffect(() => {
    if (startDate && endDate) {
      const intervalString = `from_dt=${startDate.toISOString()}&to_dt=${endDate.toISOString}`
      setInterval(intervalString)
    }
  }, [startDate, endDate])

  const { data: cameraData, isLoading: cameraDataLoading } = useQuery<Camera>(
    ["fetchSingleCamera", selectedCamera],
    ({ queryKey }) => VisionAPI.fetchSingleCamera(queryKey[1] as number),
    {
      enabled: !!selectedCamera,
    }
  )
  useEffect(() => {
    if (cameraDataLoading === true) {
      loadingSetter(true)
    } else {
      loadingSetter(false)
    }
    //eslint-disable-next-line
  }, [cameraDataLoading])
  useEffect(() => {
    if (!cameraDataLoading)
      if (!cameraData?.sample_frame) {
        setOpen(true)
        disableSetter(true)
      } else {
        disableSetter(false)
      }
    //eslint-disable-next-line
  }, [cameraData, cameraDataLoading])
  return (
    <Fragment>
      {cameraData && cameraData?.sample_frame && !open && cameraData.id !== undefined ? (
        <HeatmapLogs
          startDate={startDate}
          endDate={endDate}
          cameraSnapshot={cameraData.sample_frame}
          cameraId={cameraData.id}
          loadingSetter={loadingSetter}
          timeGrain={timeGrain}
          key={interval}
        />
      ) : (
        <Placeholder
          isScreenPlaceholder
          alertMessage="Camera Sample Frame Not Found"
          openSnackbar={open}
          selectionType="camera"
          isLoading={cameraDataLoading}
        />
      )}
    </Fragment>
  )
}
export default HeatmapContainer
