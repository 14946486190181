import { FC, useContext, ChangeEvent } from "react"

import { Checkbox, Typography } from "@synapse-analytics/synapse-ui"

import { EntitiesTypes, IMissingData, SelectedEntity } from "../../../../../types/Custom/Interfaces"
import { MissingDataContext } from "../../MissingDataContext/MissingDataContext"

import styles from "./EntityCheckbox.module.scss"

interface Props {
  entity: SelectedEntity
  onlySelected?: boolean
  selectedType: EntitiesTypes
}
const EntityCheckbox: FC<Props> = ({ entity, selectedType, onlySelected }) => {
  const { handleEntityCheck, selectedEntities } = useContext<IMissingData>(MissingDataContext)
  return (
    <div
      className={styles.wrapper}
      style={{
        background: !!selectedEntities[entity?.id!]
          ? "var(--selected-background-primary)"
          : "var(--tertiary-background-default)",
        borderColor: !!selectedEntities[entity?.id!]
          ? "var(--selected-border-primary-1)"
          : "var(--tertiary-background-default)",
      }}
    >
      <Checkbox
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleEntityCheck(e, entity?.id!, entity?.name!, selectedType)}
        label={entity?.name!}
        name={`${entity?.id!}_selection`}
        checked={!!selectedEntities[entity?.id!]}
        indeterminate={onlySelected}
      />
      {onlySelected && (
        <Typography variant="label-bold" variantColor={2} className={styles.section}>
          {entity?.type} section
        </Typography>
      )}
    </div>
  )
}
export default EntityCheckbox
