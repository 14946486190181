import React, { FC, useState, useEffect } from "react"

import { Grid, Paper } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"

import CarRecord from "../../../assets/carsRecord.svg"
import TotalCars from "../../../assets/totalCars.svg"
import { definitions } from "../../../types/Generated/apiTypes"

import styles from "./CarsListsCard.module.scss"

type CarsCount = definitions["NewReturning"]
type ListsOfInterest = definitions["ListOfInterest"]

interface Props {
  carsCounts?: CarsCount
  carsCountsLoading?: boolean
  carsLists?: boolean
  list?: ListsOfInterest
  listsOfInterestLoading?: boolean
}

const CarsListsCard: FC<Props> = ({ carsCounts, carsCountsLoading, carsLists, list, listsOfInterestLoading }) => {
  const [newCarsCount, setNewCarsCount] = useState<number>(0)
  const [returningCarsCount, setReturningCarsCount] = useState<number>(0)
  const [unknownCarsCount, setUnknownCarsCount] = useState<number>(0)
  const [carList, setCarList] = useState<ListsOfInterest>()
  // setting new and returning car counts
  useEffect(() => {
    if (carsCounts && !carsCountsLoading) {
      setNewCarsCount(carsCounts.n_new)
      setReturningCarsCount(carsCounts.n_returning)
      setUnknownCarsCount(carsCounts.n_unknown)
    }
  }, [carsCountsLoading, carsCounts])

  useEffect(() => {
    if (carsLists && !listsOfInterestLoading && list) {
      setCarList(list)
    }
  }, [list, listsOfInterestLoading, carsLists])
  return (
    <Grid item xs={12} md={2}>
      <Paper className={styles.wrapper} elevation={0}>
        <div className={styles.list}>
          <div className={styles.listContent}>
            <img src={carsLists ? CarRecord : TotalCars} alt="recordIcon" />

            <div className={styles.stats}>
              <Typography variant="p" variantColor={2}>
                {carsLists ? `${carList?.name} Hits` : "Total Records"}
              </Typography>
              <Typography variant="h1-regular" gutterBottom>
                {newCarsCount + returningCarsCount + unknownCarsCount}
              </Typography>
            </div>
          </div>
        </div>
      </Paper>
    </Grid>
  )
}

export default CarsListsCard
