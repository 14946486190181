import { FC } from "react"

import { Grid } from "@mui/material"

import { Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { format } from "date-fns"

import RegionsCanvas from "../../../components/RegionsCanvas"
import { definitions } from "../../../types/Generated/apiTypes"

import styles from "./ResolvedCard.module.scss"

type CVCLog = definitions["CameraViewChangeLog"]

interface Props {
  cvc: CVCLog
}
const ResolvedCard: FC<Props> = ({ cvc }) => {
  return (
    <Grid item xs={12} md={6} lg={3}>
      <div className={styles.wrapper}>
        <Typography variant="h3-bold" noWrap style={{ width: "100%" }}>
          “{cvc.camera_name}”
        </Typography>
        <RegionsCanvas imageSrc={cvc.comparison_image} height={160} width={340} />
        <Typography variant="label-regular" variantColor={2} noWrap className={styles.resolved}>
          Resolved at
          {cvc.resolved_at ? (
            <Tooltip title={format(new Date(cvc.resolved_at), "dd/MM/yyyy, p")} placement="right">
              <b>{format(new Date(cvc.resolved_at), "MMM d-h:mm a")} </b>
            </Tooltip>
          ) : (
            <b>N/A</b>
          )}
          by <b>{cvc.resolved_by}</b>
        </Typography>
      </div>
    </Grid>
  )
}
export default ResolvedCard
