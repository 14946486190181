import React, { FC, useEffect, useRef, memo, useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import CloseIcon from "@mui/icons-material/Close"
import { Dialog, DialogActions, DialogTitle, DialogContent } from "@mui/material"

import { Typography, Button, InputText, Select, InputChangeEvent } from "@synapse-analytics/synapse-ui"
import { FormikProps } from "formik"

import { VisionAPI } from "../../../API/VisionAPI"
import { definitions } from "../../../types/Generated/apiTypes"

import styles from "./CarsAddPopup.module.scss"

type ListsOfInterest = definitions["ListOfInterest"]

interface Props {
  open: boolean
  type: string
  licensePlate?: string
  listsOfInterest?: ListsOfInterest[]
  handleClose: () => void
  formikLists?: FormikProps<{ newListName: string }>
  formikLicensePlate?: FormikProps<{ licensePlate: string }>
}

const CarsAddPopup: FC<Props> = ({
  open,
  handleClose,
  formikLists,
  formikLicensePlate,
  type,
  licensePlate,
  listsOfInterest,
}) => {
  const [selectedList, setSelectedList] = useState<number>()
  const inputRef = useRef<HTMLDivElement>(null)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (listsOfInterest && listsOfInterest?.length > 0) {
      setSelectedList(listsOfInterest[0].id)
    }
  }, [listsOfInterest])

  const handleChange = (event: InputChangeEvent) => {
    setSelectedList(event.target.value as number)
  }
  useEffect(() => {
    inputRef.current!?.focus()
  }, [formikLists])

  const { mutate: addCarToList } = useMutation(
    () =>
      VisionAPI.addCarLicensePlateToList({
        license_plate: licensePlate as string,
        list_of_interest: selectedList as number,
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries("fetchCarLogsList")
        queryClient.invalidateQueries("fetchSecurityLogs")
        handleClose()
      },
    }
  )

  return (
    <Dialog
      PaperProps={{
        style: { backgroundColor: "var(--brand-background)", padding: 30, overflow: "visible" },
      }}
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={"xs"}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="alert-dialog-title" classes={{ root: styles.title }}>
        <div className={styles.header}>
          <Typography variant="h2-bold">
            {type === "list" ? "Add car list" : type === "addCarToList" ? "Add Car to List" : "Add License plate"}
          </Typography>
          <CloseIcon onClick={handleClose} className={styles.iconContainer} />
        </div>
      </DialogTitle>
      <DialogContent
        classes={{ root: styles.content }}
        sx={{
          overflow: "visible",
        }}
      >
        {type === "list" ? (
          <InputText
            ref={inputRef}
            error={formikLists!?.touched.newListName && Boolean(formikLists!?.errors.newListName)}
            label="List Name"
            id="newListName"
            placeholder="Enter list name"
            value={formikLists!?.values?.newListName}
            handleChange={formikLists!?.handleChange}
            fullWidth
          />
        ) : type === "licensePlate" ? (
          <InputText
            error={formikLicensePlate!?.touched.licensePlate && Boolean(formikLicensePlate!?.errors.licensePlate)}
            label="License Plate"
            id="licensePlate"
            placeholder="Enter license plate name"
            value={formikLicensePlate!?.values?.licensePlate.trim()}
            handleChange={formikLicensePlate!?.handleChange}
            fullWidth
          />
        ) : (
          listsOfInterest &&
          listsOfInterest?.length > 0 && (
            <Select
              label="List Name"
              value={selectedList}
              handleChange={handleChange}
              fullWidth
              optionsWithValues={listsOfInterest?.map((list) => {
                return { label: list?.name, value: list?.id! }
              })}
            />
          )
        )}
      </DialogContent>
      <DialogActions>
        {type === "addCarToList" ? (
          <Button variant="primary" onClick={() => addCarToList()} className={styles.submit}>
            Save
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={type === "list" ? formikLists!?.submitForm : formikLicensePlate!?.submitForm}
            className={styles.submit}
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default memo(CarsAddPopup)
