import { useEffect, useState, Fragment } from "react"
import { useQuery } from "react-query"

import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ExportIcon from "@mui/icons-material/GetApp"
import SettingsIcon from "@mui/icons-material/Settings"
import { Grid, CircularProgress } from "@mui/material"
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker"

import { Button, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import moment from "moment"
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params"

import { VisionAPI } from "../../../API/VisionAPI"
import Search from "../../../components/Search"
import { useDateQuery } from "../../../hooks/useDateQuery"
import { PaginatedCarsLogs } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"
import { ExportReport, carsColors } from "../../../utils/carsUtils"
import { exportFetchedDataToCSV } from "../../../utils/genericHelpers"
import { CarColorParamConfig } from "../../../utils/queryParamCustomConfigs"
import CarsFilters from "../components/CarsFilters"
import CarsLists from "./CarsLists"
import CarsLogsTable from "./CarsLogsTable"

import styles from "./CarsHistory.module.scss"

type ListsOfInterest = definitions["ListOfInterest"]

const CarsHistory = () => {
  const [query, setQuery] = useQueryParams({
    selectedListOfInterest: withDefault(StringParam, ""),
    selectedCarBrand: withDefault(StringParam, ""),
    selectedCarType: withDefault(StringParam, ""),
    pageNumber: withDefault(NumberParam, 1),
    searchValue: withDefault(StringParam, ""),
    carStateFilter: withDefault(StringParam, ""),
    selectedColor: withDefault(CarColorParamConfig, carsColors[0]),
    pageSize: withDefault(NumberParam, 10),
  })

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>("")
  const [carsListsLoading, setCarsListsLoading] = useState(true)
  const [generateReport, setGenerateReport] = useState(false)

  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()

  // fetch car lists of interest
  const { data: listsOfInterest, isLoading: listsOfInterestLoading } = useQuery<ListsOfInterest[], AxiosError>(
    "fetchCarsListOfInterest",
    () => VisionAPI.fetchCarListsOfInterest()
  )

  // fetch car list paginated data
  const { data, isLoading, isFetching } = useQuery<PaginatedCarsLogs, AxiosError>(
    [
      "fetchCarLogsListPaginated",
      startDate?.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      endDate?.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      debouncedSearchValue,
      query.selectedColor?.value,
      query.carStateFilter,
      query.selectedListOfInterest,
      query.selectedCarBrand,
      query.selectedCarType,
      query.pageNumber,
      query.pageSize,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchCarLogsList({
        start_dt: queryKey[1] as string,
        end_dt: queryKey[2] as string,
        search: queryKey[3] as string,
        car_color: queryKey[4] as string,
        state: queryKey[5] as string,
        list_of_interest: queryKey[6] as number,
        brand: queryKey[7] as string,
        car_type: queryKey[8] as string,
        page: queryKey[9] as number,
        limit: queryKey[10] as number,
      }),
    {
      enabled: !!endDate,
      keepPreviousData: true,
    }
  )
  const { data: carsReport, isLoading: carsReportLoading } = useQuery(
    [
      "fetchGatesReport",
      startDate?.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      endDate?.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      debouncedSearchValue,
      query.selectedColor?.value,
      query.carStateFilter,
      query.selectedListOfInterest,
      query.selectedCarBrand,
      query.selectedCarType,
    ],
    ({ queryKey }) =>
      ExportReport(
        queryKey[1] as string,
        queryKey[2] as string,
        queryKey[3] as string,
        queryKey[4] as string,
        queryKey[5] as string,
        queryKey[6] as string,
        queryKey[7] as string,
        queryKey[8] as string
      ),
    {
      enabled: !!startDate && !!endDate && generateReport,
    }
  )
  const handleGenerateReport = () => {
    setGenerateReport(true)
  }

  const handleResetReport = () => {
    setGenerateReport(false)
  }
  const handlePageNumber = (pageNumber: number) => {
    setQuery({ pageNumber })
  }

  const handleResetPage = () => handlePageNumber(1)

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(moment(date))
    handleResetPage()
    handleResetReport()
  }

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(moment(date))
    handleResetPage()
    handleSearch("")
    handleResetReport()
  }

  const handleSearch = (value: string) => {
    setQuery({ searchValue: value })
  }

  const handleCarsListsLoading = (isLoading: boolean) => {
    setCarsListsLoading(isLoading)
  }

  // debouncing search value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(query.searchValue)
    }, 500)
    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler)
    }
  }, [query.searchValue])

  return (
    <Fragment>
      <Typography
        variant="h2-regular"
        tooltip="Cars license plate recognition with logo detection"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        Cars Logs
      </Typography>

      <div className={styles.header}>
        {/* filter */}
        <div className={styles.searchAndPicker}>
          <Search
            placeholder="Search using license plate"
            handleSearch={handleSearch}
            searchValue={query.searchValue}
            type="carLogs"
          />

          {/* date range */}
          <div className={styles.datePickers}>
            <form className={styles.datePicker} noValidate>
              <MobileDateTimePicker
                className={styles.datePicker}
                format="PPp"
                onAccept={handleStartDateChange}
                value={startDate?.toDate()}
                slotProps={{
                  textField: {
                    variant: "standard",
                    InputProps: {
                      disableUnderline: true,
                      endAdornment: <ChevronRightIcon />,
                      classes: {
                        root: styles.muiPicker,
                      },
                      sx: {
                        "&.MuiOutlinedInput-notchedOutline": {
                          border: "0px",
                        },
                      },
                    },
                  },
                }}
                disableFuture
                disabled={isFetching || carsListsLoading}
              />
            </form>
            <form className={styles.datePicker} noValidate>
              <MobileDateTimePicker
                className={styles.datePicker}
                format="PPp"
                onAccept={handleEndDateChange}
                value={endDate?.toDate()}
                slotProps={{
                  textField: {
                    variant: "standard",
                    InputProps: {
                      disableUnderline: true,
                      classes: {
                        root: styles.muiPicker,
                      },
                      sx: {
                        width: "165px !important",
                      },
                    },
                  },
                }}
                disableFuture
                minDate={startDate ? startDate.toDate() : undefined}
                disabled={isFetching || carsListsLoading}
              />
            </form>
          </div>
        </div>
        {/* Cars Filters and export button */}
        <div className={styles.filtersAndExport}>
          <CarsFilters
            isFetching={isFetching}
            endDate={endDate}
            selectedColor={query.selectedColor}
            selectedCarBrand={query.selectedCarBrand}
            selectedCarType={query.selectedCarType}
            selectedListOfInterest={query.selectedListOfInterest}
            setSelectedCarBrand={(brand: string) => setQuery({ selectedCarBrand: brand })}
            setSelectedCarType={(type: string) => setQuery({ selectedCarType: type })}
            setSelectedListOfInterest={(list: string) => setQuery({ selectedListOfInterest: list })}
            carStateFilter={query.carStateFilter}
            setSelectedColor={(value) => setQuery({ selectedColor: value })}
            setCarStateFilter={(state: string) => setQuery({ carStateFilter: state })}
            handleResetReport={handleResetReport}
            handleResetPage={handleResetPage}
          />

          <div style={{ display: "flex" }}>
            {!carsReportLoading && !carsReport ? (
              <Button
                onClick={handleGenerateReport}
                variant="secondary"
                style={{
                  alignItems: "center",
                }}
                startIcon={<SettingsIcon fontSize="small" className={styles.icon} />}
                disabled={!startDate || !endDate}
              >
                Generate Report
              </Button>
            ) : (
              <Button
                disabled={!carsReport || carsReportLoading}
                variant="secondary"
                onClick={() => {
                  if (carsReport && !carsReportLoading) {
                    exportFetchedDataToCSV(
                      carsReport,
                      `Cars-report-from-${startDate?.format("YYYY-MM-DD")}-to-${endDate?.format("YYYY-MM-DD")}`
                    )
                  }
                }}
              >
                {carsReportLoading ? (
                  <CircularProgress size={20} className={styles.loadingReport} />
                ) : (
                  <ExportIcon fontSize="small" style={{ marginRight: 4 }} />
                )}
                Export
              </Button>
            )}
          </div>
        </div>
      </div>

      {/* listCards */}
      <div>
        <CarsLists
          loadingSetter={handleCarsListsLoading}
          startDate={startDate}
          endDate={endDate}
          selectedListOfInterest={query.selectedListOfInterest}
          selectedCarBrand={query.selectedCarBrand}
          selectedCarType={query.selectedCarType}
          listsOfInterest={listsOfInterest!}
          listsOfInterestLoading={listsOfInterestLoading}
        />
      </div>

      {/* table of car logs lists */}
      <Grid container>
        <Grid item xs={12} md={12} className={styles.wrapper}>
          <CarsLogsTable
            data={data}
            handlePageNumber={handlePageNumber}
            isLoading={isLoading}
            pageSize={query.pageSize}
            handleChangePageSize={(size) => setQuery({ pageSize: size })}
            pageNumber={query.pageNumber}
            key={`${query.selectedColor}_${query.searchValue}`}
          />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default CarsHistory
