import React, { useState, useEffect, Fragment } from "react"

import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import { Box, useMediaQuery } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import { useTheme } from "@mui/material/styles"

import { ResponsiveLine } from "@nivo/line"

import GraphEmptyState from "./GraphEmptyState"
import GraphLoadingState from "./GraphLoadingState"
import { gridTheme } from "./gridTheme"

import styles from "./CheckboxesLineGraph.module.scss"

function CheckboxesLineGraph({ data, graphHeight, isLoading, defaultCheckboxes, isLargeInterval, timeGrain = "day" }) {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"))

  const [checkBoxStates, setCheckBoxStates] = React.useState(defaultCheckboxes)
  const [slicedData, setSlicedData] = useState(data)

  // This useEffect synchronizes the `slicedData` state with the `checkBoxStates` and `data` state.
  // It checks the status of all checkboxes to determine if they are either all checked or all unchecked.
  // If all checkboxes are either checked or unchecked, it sets `slicedData` to the original `data`.
  // If only some checkboxes are checked, it filters the `data` to include only the items corresponding to the checked checkboxes and updates `slicedData` accordingly.
  useEffect(() => {
    const areTrue = Object.values(checkBoxStates).every((value) => value === true)
    const areFalse = Object.values(checkBoxStates).every((value) => value === false)

    if (areTrue || areFalse) {
      setSlicedData(data)
    } else {
      let newDataArr = []
      for (let log of data) {
        if (checkBoxStates[log.id] === true) {
          newDataArr.push(log)
        }
      }
      setSlicedData(newDataArr)
    }
  }, [checkBoxStates, data])

  const handleChange = (event) => {
    setCheckBoxStates({ ...checkBoxStates, [event.target.name]: event.target.checked })
  }

  const filteredCounts = data?.filter((element) => element.data.length > 0)

  return (
    <div
      style={{
        minHeight: graphHeight,
      }}
    >
      {isLoading ? (
        <GraphLoadingState />
      ) : data && data?.length > 0 && filteredCounts?.length > 0 ? (
        <Fragment>
          <Box
            style={{
              height: graphHeight,
            }}
          >
            <ResponsiveLine
              data={slicedData}
              theme={gridTheme}
              margin={{ top: 60, right: 33, bottom: 70, left: 55 }}
              xScale={{ type: "time", format: "native", precision: timeGrain, useUTC: false }}
              xFormat={timeGrain === "hour" ? "time:%d %b %I %p" : "time:%a %d %b"}
              enablePoints={false}
              curve="cardinal"
              yScale={{ type: "linear", min: "auto", max: "auto", stacked: false, reverse: false }}
              sliceTooltip={({ slice }) => {
                return (
                  <div
                    style={{
                      background: "white",
                      color: "inherit",
                      fontSize: "14px",
                      borderRadius: "2px",
                      boxShadow: "rgba(128, 24, 24, 0.25) 0px 1px 2px",
                      padding: "5px 9px",
                    }}
                  >
                    <div>
                      <span>
                        {timeGrain === "hour"
                          ? slice.points[0].data.xFormatted.slice(7)
                          : slice.points[0].data.xFormatted}
                      </span>
                      <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <tbody>
                          {slice.points.map((point) => (
                            <tr key={point.id}>
                              <td style={{ padding: "3px 5px" }}>
                                <span
                                  key="chip"
                                  style={{
                                    display: "block",
                                    width: "12px",
                                    height: "12px",
                                    background: point.serieColor,
                                  }}
                                />
                              </td>
                              <td style={{ padding: "3px 5px" }}>{point.serieId} : </td>
                              <td style={{ padding: "3px 5px" }}>
                                <strong key="value">{point.data["yFormatted"]}</strong>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={
                timeGrain === "hour"
                  ? {
                      tickValues: "every 2 hour",
                      format: "%I %p",
                      orient: "bottom",
                      tickSize: 6,
                      tickPadding: 9,
                      legendPosition: "middle",
                      fill: "var(--secondary-text-light)",
                    }
                  : {
                      tickValues: smallScreen || isLargeInterval ? "every 2 days" : "every 1 day",
                      format: "%a %d %b",
                      orient: "bottom",
                      legend: "",
                      tickSize: 6,
                      tickRotation: -45,
                      tickPadding: 9,
                      legendPosition: "middle",
                    }
              }
              axisLeft={{
                orient: "left",
                tickValues: 6,
                tickSize: 0,
                tickPadding: 8,
                tickRotation: 0,
                legend: "",
                legendOffset: -50,
                legendPosition: "middle",
              }}
              colors={(line) => line.color}
              pointSize={5}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              enablePointLabel={false}
              pointLabel="y"
              pointLabelYOffset={-12}
              useMesh={true}
              enableSlices="x"
            />
          </Box>
          <div
            className={styles.checkBoxesGroup}
            style={{
              marginTop: smallScreen ? 10 : 0,
              position: smallScreen ? "relative" : "static",
              zIndex: smallScreen ? 100 : "auto",
            }}
          >
            <Grid container spacing={1} className={styles.checkboxesWrapper}>
              {data &&
                data.length > 0 &&
                data?.map(
                  (element, index) =>
                    element.id &&
                    element.data.length > 0 && (
                      <Grid item key={index}>
                        <FormControlLabel
                          title={element.id}
                          classes={{ root: styles.formLabelRoot }}
                          style={{ color: element.color }}
                          control={
                            <Checkbox
                              checked={checkBoxStates[`${element.id}`]}
                              onChange={handleChange}
                              name={element.id}
                              icon={<CheckBoxOutlineBlankIcon style={{ color: element.color }} />}
                              checkedIcon={<CheckBoxIcon style={{ color: element.color }} />}
                            />
                          }
                          label={`${
                            element?.id !== null && element?.id?.length >= 12
                              ? element?.id?.slice(0, 9) + ".."
                              : element.id
                          }`}
                        />
                      </Grid>
                    )
                )}
            </Grid>
          </div>
        </Fragment>
      ) : (
        <GraphEmptyState noEnoughData={filteredCounts.length < 1} />
      )}
    </div>
  )
}

export default CheckboxesLineGraph
