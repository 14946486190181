import { FC, useState, Fragment } from "react"
import { useMutation, useQueryClient } from "react-query"

import AccessTimeIcon from "@mui/icons-material/AccessTime"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import EditIcon from "@mui/icons-material/EditOutlined"
import { Box } from "@mui/material"

import { Typography, Button, NotificationUtils, Avatar } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import WarningDialog from "../../../components/WarningDialog"
import { definitions } from "../../../types/Generated/apiTypes"

import styles from "./MissingDataCard.module.scss"

type MissingData = definitions["MissingDataInfo"]
type MissingDataEntity = definitions["missing_data_entity"]

const MAX_ENTITY_NAME_LENGTH = 11

/**
 * Returns the name of the entity after processing it, to make entities' names fit in one line in missing data card .
 * @param {MissingDataEntity} entity - The entity object.
 * @param {number} index - The index of the entity in the array.
 * @param {MissingData} missingData - The missing data object.
 * @returns {string} The name of the entity.
 */
const getEntityName = (entity: MissingDataEntity, index: number, missingData: MissingData): string => {
  const isLongEntityName = entity.name.length > MAX_ENTITY_NAME_LENGTH
  const isLastEntity = index + 1 === missingData?.entities?.length
  const isThirdOrLaterEntity = missingData!.entities!.length > 3 && (index > 1 || missingData!.entities!.length > 4)
  let entityName = ""
  if (isLongEntityName && isThirdOrLaterEntity) {
    entityName = `${entity.name.slice(0, MAX_ENTITY_NAME_LENGTH)}..`
  } else {
    entityName = entity.name
  }

  return `${entityName}${isLastEntity ? "" : ","}`
}

interface Props {
  missingData: MissingData
  handleOpenEdit: (missingData: MissingData) => void
}
const MissingDataCard: FC<Props> = ({ missingData, handleOpenEdit }) => {
  const [openDelete, setOpenDelete] = useState(false)

  const queryClient = useQueryClient()

  const { mutate: deleteMissingData, isLoading: loadingDeleteMissingData } = useMutation(
    () => VisionAPI.deleteMissingData(missingData?.id as number),
    {
      onSuccess: async () => {
        setOpenDelete(false)
        await queryClient?.invalidateQueries("fetchMissingData")
        NotificationUtils.toast(`${missingData.title} has been deleted successfully`, {
          severity: "success",
        })
      },
    }
  )

  return (
    <Fragment>
      <WarningDialog
        isOpen={openDelete}
        isLoading={loadingDeleteMissingData}
        actionTitle="Delete"
        content="Be aware by deleting this file this action can't be undone."
        onConfirm={deleteMissingData}
        onCancel={() => setOpenDelete(false)}
        dialogTitle={`Delete “${missingData?.title}” missing data file?`}
      />
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <Typography variant="h2-bold">{missingData.title}</Typography>
          <div className={styles.actions}>
            <Button
              variant="ghost"
              onClick={() => {
                setOpenDelete(true)
              }}
            >
              <DeleteIcon fontSize="small" />
            </Button>
            <Button variant="ghost" onClick={() => handleOpenEdit(missingData)}>
              <EditIcon fontSize="small" />
            </Button>
          </div>
        </div>
        <Box display="flex">
          <Avatar
            dateTime={moment(missingData.created_at)}
            isNameInitials
            name={missingData.user}
            isLoading={false}
            size="regular"
          />
        </Box>
        <Box my={1.5} display="flex" flexDirection="column">
          <Typography variant="label-bold">Date Range</Typography>
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={0.5}>
            <Box display="flex" flexDirection="column" px={1}>
              <Box display="flex">
                <Typography variant="p" variantColor={2}>
                  <CalendarTodayIcon fontSize="small" sx={{ marginRight: 1 }} />
                </Typography>
                <Typography variant="p" variantColor={2}>
                  {moment(missingData.min_timestamp).format("DD/MM/YYYY")}
                </Typography>
              </Box>
              <Box display="flex">
                <Typography variant="p" variantColor={2}>
                  <AccessTimeIcon fontSize="small" sx={{ marginRight: 1 }} />
                </Typography>
                <Typography variant="p" variantColor={2}>
                  {moment(missingData.min_timestamp).format("hh A")}
                </Typography>
              </Box>
            </Box>
            <ChevronRightIcon sx={{ fontSize: "24px" }} />
            <Box display="flex" flexDirection="column" px={1}>
              <Box display="flex">
                <Typography variant="p" variantColor={2}>
                  <CalendarTodayIcon fontSize="small" sx={{ marginRight: 1 }} />
                </Typography>
                <Typography variant="p" variantColor={2}>
                  {moment(missingData.max_timestamp).format("DD/MM/YYYY")}
                </Typography>
              </Box>
              <Box display="flex">
                <Typography variant="p" variantColor={2}>
                  <AccessTimeIcon fontSize="small" sx={{ marginRight: 1 }} />
                </Typography>
                <Typography variant="p" variantColor={2}>
                  {moment(missingData.max_timestamp).format("hh A")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box mb={0.5}>
          <Typography variant="label-bold" className={styles.sectionTitle}>
            Entities ({missingData?.entities?.length})
          </Typography>
        </Box>
        {missingData?.entities && missingData?.entities.length > 0 ? (
          <Box display="flex">
            {missingData?.entities?.slice(0, 4).map((entity, index) => (
              <Typography
                key={`${index}`}
                variant="label-regular"
                display="inline"
                noWrap
                title={entity.name}
                style={{ maxWidth: 100 }}
              >
                {getEntityName(entity, index, missingData)} &nbsp;
              </Typography>
            ))}
            {missingData?.entities?.length > 4 && (
              <Typography variant="label-regular" className={styles.tagsCaption} display="inline" noWrap>
                + {missingData?.entities.length - 4} more
              </Typography>
            )}
          </Box>
        ) : (
          <Typography variant="label-regular" variantColor={2}>
            No Entities Associated
          </Typography>
        )}
      </div>
    </Fragment>
  )
}
export default MissingDataCard
