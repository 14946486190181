import React, { FC, useState, Fragment } from "react"
import { useMutation, useQueryClient } from "react-query"

import HighlightOffIcon from "@mui/icons-material/Close"
import { CircularProgress, Dialog, Slide, SlideProps, DialogContent } from "@mui/material"

import { Typography, Button, InputText, NotificationUtils } from "@synapse-analytics/synapse-ui"
import { useFormik, FormikProps } from "formik"
import * as Yup from "yup"

import { VisionAPI } from "../../../API/VisionAPI"
import WarningDialog from "../../../components/WarningDialog"
import { definitions } from "../../../types/Generated/apiTypes"

import styles from "./EditNode.module.scss"

type Node = definitions["NodeUpdateRetrieve"]

const validationFormik = () => {
  return Yup.object({
    name: Yup.string().required("Node name is required"),
  })
}

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
  handleClose: () => void
  open: boolean
  node?: Node
}

const EditNode: FC<Props> = ({ handleClose, open, node }) => {
  const [isCancelMessageOpen, setIsCancelMessageOpen] = useState(false)
  const queryClient = useQueryClient()

  const { mutate: updateNode, isLoading: updateNodeIsLoading } = useMutation(
    (node: Node) => VisionAPI.updateNode(node),
    {
      onSuccess: async (data) => {
        // refetch nodes
        await queryClient.invalidateQueries("fetchNodesList")
        NotificationUtils.toast(`Node ${data.name} has been updated successfully`, {
          severity: "success",
        })
        // close dialog
        handleConfirmClose()
      },
    }
  )

  const formik: FormikProps<Node> = useFormik<Node>({
    initialValues: {
      id: node?.id,
      name: node?.name as string,
      slug: node?.slug,
    },
    validationSchema: validationFormik,
    onSubmit: (values) => updateNode(values),
  })

  // closing confirmation message pop up
  const handleTriggerCloseConfirmation = () => setIsCancelMessageOpen(true)

  const handleCancelCloseConfirmation = () => setIsCancelMessageOpen(false)

  // Empty All States of all the chosen inputs
  const handleConfirmClose = () => {
    formik.resetForm()
    setIsCancelMessageOpen(false)
    handleClose()
    handleCancelCloseConfirmation()
  }

  const handleCloseClick = () => {
    // close dialog without warning and confirmation if fields hadn't been touched yet.
    if (formik.dirty) handleTriggerCloseConfirmation()
    else handleConfirmClose()
  }

  return (
    <Fragment>
      <WarningDialog
        confirmationText="cancel node editing process?"
        isOpen={isCancelMessageOpen}
        onCancel={handleCancelCloseConfirmation}
        onConfirm={handleConfirmClose}
      />

      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth
        TransitionComponent={Transition}
        onClose={handleCloseClick}
        disableEscapeKeyDown
        aria-labelledby="edit-node-dialog"
        aria-describedby="this dialog allows user to edit node name"
      >
        <DialogContent className={styles.wrapper}>
          <div className={styles.header}>
            <Typography variant="h2-bold">Edit node name</Typography>
            <HighlightOffIcon onClick={handleCloseClick} className={styles.iconContainer} />
          </div>

          {/* Name Input */}
          <div className={styles.inputWrapper}>
            <InputText
              id="name"
              label="Machine Name"
              placeholder="E.g. Machine-1"
              fullWidth
              value={formik?.values.name}
              required
              handleBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name) && formik.errors.name}
              description="Add a name from 8 to 40 characters"
              handleChange={formik.handleChange}
            />
          </div>
          <div className={styles.actionButton}>
            <Button onClick={() => formik.handleSubmit()} variant="primary" disabled={!formik.isValid}>
              <Fragment>
                <span style={{ marginRight: updateNodeIsLoading ? 10 : 0 }}>Update</span>
                {updateNodeIsLoading && <CircularProgress size={20} />}
              </Fragment>
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default EditNode
