import { Fragment } from "react"
import { useQuery } from "react-query"

import { Grid } from "@mui/material"

import { Skeleton, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { StringParam, useQueryParam, withDefault } from "use-query-params"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../API/VisionAPI"
import NoLogsPlaceholder from "../../assets/NoHistoryLogs.svg"
import Search from "../../components/Search"
import { useBranchesStore } from "../../store"
import { definitions } from "../../types/Generated/apiTypes"
import NodeCard from "./components/NodeCard"

import styles from "./NodesList.module.scss"

type Node = definitions["NodeUpdateRetrieve"]

const loadingPlaceholders = new Array(12).fill(null).map((r, i) => (
  <Grid item xs={12} sm={6} md={4} xl={3} key={i}>
    <Skeleton variant="rectangular" height={250} width="auto" />
  </Grid>
))

const NodesList = () => {
  const [searchValue, setSearchValue] = useQueryParam("search", withDefault(StringParam, ""))

  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  // TODO:: bring service and healthy/unhealthy filters back
  // const [selectedServices, setSelectedServices] = useState<ServiceFilterOptions>({
  //   none: false,
  //   mcp: false,
  //   heatmap: false,
  //   car: false,
  //   counter: false,
  //   face: false,
  //   violence: false,
  //   motion: false,
  //   fire: false,
  // })
  // const [servicesMode, setServicesMode] = useState<"and" | "or">("or")
  // const [healthyFilter, setHealthyFilter] = useState<boolean | null>()
  // const handleChangeServices = (event: ChangeEvent<HTMLInputElement>) => {
  //   setSelectedServices((prevValue) => {
  //     return { ...prevValue, [event.target.id]: event.target.checked }
  //   })
  // }
  // const handleServicesMode = (event: ChangeEvent<HTMLInputElement>) => {
  //   setServicesMode(event.target.value as "or" | "and")
  // }

  const handleSearch = (value: string) => setSearchValue(value)

  const { data: nodes, isLoading: isNodesLoading } = useQuery<Node[], AxiosError>(
    ["fetchNodesList", selectedBranch, searchValue],
    ({ queryKey }) => VisionAPI.fetchNodesList({ branch: queryKey[1] as number, search: queryKey[2] as string }),
    {
      enabled: !!selectedBranch,
    }
  )

  return (
    <Fragment>
      <Typography
        variant="h2-regular"
        tooltip="Information regarding your nodes"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        Nodes
      </Typography>

      {/* search cameras */}
      <div className={styles.header}>
        <div className={styles.headerItems}>
          <div className={styles.searchNodes}>
            <Search
              placeholder="Search by node name or slug number"
              handleSearch={handleSearch}
              searchValue={searchValue}
            />
          </div>
          {/* TODO:: bring service and healthy/unhealthy filters back */}
          {/* <ServicesFilter
            selectedServices={selectedServices}
            handleChangeServices={handleChangeServices}
            handleServicesMode={handleServicesMode}
            servicesMode={servicesMode}
          />
          <Chip
            removable={healthyFilter === true ? true : false}
            clickable
            onClick={() => setHealthyFilter((prevValue) => (prevValue === true ? null : true))}
            onRemove={() => null}
            isSelected={healthyFilter === true ? true : false}
            className={styles.healthFilter}
          >
            Healthy
          </Chip>
          <Chip
            removable={healthyFilter === false ? true : false}
            clickable
            onClick={() => setHealthyFilter((prevValue) => (prevValue === false ? null : false))}
            onRemove={() => null}
            isSelected={healthyFilter === false ? true : false}
          >
            Unhealthy
          </Chip> */}
        </div>
      </div>
      <Grid container spacing={2}>
        {isNodesLoading ? (
          loadingPlaceholders
        ) : nodes && nodes?.length > 0 ? (
          nodes?.map((node: Node, index: number) => (
            <Grid item xs={12} sm={6} md={4} xl={3} key={index}>
              <NodeCard node={node} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} className={styles.nodesPlaceholderWrapper}>
            <img src={NoLogsPlaceholder} className={styles.nodesPlaceholder} alt="No categories found" />
            <div>
              <Typography variant="h2-bold" align="center" className={styles.noNodesText}>
                No Nodes Yet.
              </Typography>
            </div>
          </Grid>
        )}
      </Grid>
    </Fragment>
  )
}
export default NodesList
