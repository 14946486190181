import React from "react"

import TimelineConnector from "@mui/lab/TimelineConnector"
import TimelineDot from "@mui/lab/TimelineDot"
import TimelineItem from "@mui/lab/TimelineItem"
import TimelineSeparator from "@mui/lab/TimelineSeparator"
import { Avatar } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"

import { definitions } from "../../../types/Generated/apiTypes"

import styles from "./CampaignTimeline.module.scss"

type CampaignNotesList = definitions["CampaignNotesList"]

const CampaignTimeline = ({ note }: { note: CampaignNotesList }) => {
  return (
    <TimelineItem>
      <TimelineSeparator classes={{ root: styles.root }}>
        <TimelineDot>
          <Avatar className={styles.avatar}>
            <Typography variant="label-bold" textTransform="capitalize">
              {note?.user?.full_name?.split(" ")[0].toUpperCase()[0]}
              {note?.user?.full_name?.split(" ")[1].toUpperCase()[0]}
            </Typography>
          </Avatar>
        </TimelineDot>
        <TimelineConnector classes={{ root: styles.connector }} />
      </TimelineSeparator>
    </TimelineItem>
  )
}

export default CampaignTimeline
