import { useState, FC, Fragment } from "react"
import { useQuery, useMutation } from "react-query"

import AddIcon from "@mui/icons-material/AddCircle"
import DeleteIcon from "@mui/icons-material/DeleteOutline"
import { Grid } from "@mui/material"

import { Typography, Button, NotificationUtils, Tabs, Tab } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { useFormik } from "formik"
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params"
import * as Yup from "yup"

import { VisionAPI } from "../../../API/VisionAPI"
import Search from "../../../components/Search"
import WarningDialog from "../../../components/WarningDialog"
import { definitions } from "../../../types/Generated/apiTypes"
import CarsAddPopup from "../components/CarsAddPopup"
import CarsLicenseTable from "./CarsLicenseTable"

import styles from "./CarsListsScreen.module.scss"

type ListsOfInterest = definitions["ListOfInterest"]

interface ListsOfLicensePlates {
  license_plate: string
}

const CarsListsScreen: FC = () => {
  const [open, setOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [openLicense, setOpenLicense] = useState(false)
  const [query, setQuery] = useQueryParams({
    searchValue: withDefault(StringParam, ""),
    listId: NumberParam,
    listName: StringParam,
  })
  const [deletedList, setDeletedList] = useState(false)

  const handleSearch = (value: string) => {
    setQuery({ searchValue: value })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseDelete = () => {
    setOpenDelete(false)
  }

  const handleOpenLicense = () => {
    setOpenLicense(true)
  }

  const handleCloseLicense = () => {
    setOpenLicense(false)
  }

  const { mutate: newList } = useMutation(
    (values: { newListName: string }) => VisionAPI.addCarListOfInterest({ name: values.newListName }),
    {
      onSuccess: (data) => {
        NotificationUtils.toast(`${formikLists.values?.newListName} has been added successfully`, {
          severity: "success",
        })
        setQuery({ listId: data?.id as number, listName: data?.name })
        setOpen(false)
        refetch()
        refetchListOfInterest()
      },
    }
  )

  const validationFormikLists = () => {
    let validationSchema
    validationSchema = Yup.object({
      newListName: Yup.string().required("List name can't be empty"),
    })

    return validationSchema
  }

  const formikLists = useFormik({
    initialValues: {
      newListName: "",
    },
    validationSchema: validationFormikLists,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      newList(values)
      resetForm()
    },
  })

  const { mutate: newLicense } = useMutation(
    (values: { licensePlate: string }) =>
      VisionAPI.addCarLicensePlateToList({
        license_plate: values.licensePlate,
        list_of_interest: query.listId as number,
      }),
    {
      onSuccess: () => {
        NotificationUtils.toast(`${formikLicensePlate.values?.licensePlate} has been added successfully`, {
          severity: "success",
        })
        refetch()
        setOpenLicense(false)
      },
    }
  )

  const validationFormikLicense = () => {
    let validationSchema
    validationSchema = Yup.object({
      licensePlate: Yup.string().required("License plate name can't be empty"),
    })

    return validationSchema
  }

  const formikLicensePlate = useFormik({
    initialValues: {
      licensePlate: "",
    },
    validationSchema: validationFormikLicense,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      newLicense(values)
      resetForm()
    },
  })

  const handleChangeList = (listId: number, listName: string) => {
    setQuery({ listId: listId, listName: listName })
    setDeletedList(false)
    setTimeout(() => {
      refetch()
    }, 300)
  }

  // fetch car lists of interest
  const { data: listsOfInterest, refetch: refetchListOfInterest } = useQuery<ListsOfInterest[], AxiosError>(
    "fetchCarsListOfInterest",
    () => VisionAPI.fetchCarListsOfInterest(),
    {
      onSuccess: (data) => {
        if (data && data.length > 0 && !data?.some((list) => list.id === query.listId)) {
          setQuery({ listId: data[0].id as number, listName: data[0].name })
        }
      },
    }
  )

  // fetch car lists of license plates
  const {
    data: listsOfLicensePlates,
    isFetching,
    refetch,
  } = useQuery<ListsOfLicensePlates[], AxiosError>(
    ["fetchCarLicensePlatesFromLists", deletedList ? listsOfInterest && listsOfInterest[0]?.id : query.listId],
    ({ queryKey }) => VisionAPI.fetchCarLicensePlatesFromLists({ list_of_interest: queryKey[1] as number }),
    {
      enabled: !!query.listId || !!(listsOfInterest && listsOfInterest[0]?.id),
    }
  )

  const { mutate: deleteList } = useMutation(() => VisionAPI.deleteCarListsOfInterest(query.listId as number), {
    onSuccess: () => {
      NotificationUtils.toast(`${query.listName} has been deleted successfully `, {
        severity: "success",
      })
      setOpenDelete(false)
      setDeletedList(true)
      setQuery({
        listId: listsOfInterest && listsOfInterest[0].id,
        listName: listsOfInterest && listsOfInterest[0].name,
      })
      refetch()
      refetchListOfInterest()
    },
  })

  const { mutate: deleteLicense } = useMutation(
    (licensePlate: string) =>
      VisionAPI.deleteCarLicensePlateFromList({
        license_plate: licensePlate,
        list_of_interest: query.listId as number,
      }),
    {
      onSuccess: () => {
        NotificationUtils.toast("license plate been deleted successfully", {
          severity: "success",
        })
        refetch()
      },
    }
  )

  return (
    <Fragment>
      <Typography
        variant="h2-regular"
        tooltip="Cars License plate recognition with Logo detection"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        Cars watch list
      </Typography>

      {/* search and add new lists and license plates */}
      <div className={styles.header}>
        <Search placeholder="Search using license plate" handleSearch={handleSearch} searchValue={query.searchValue} />

        <div className={styles.actions}>
          <Button variant="secondary" onClick={() => setOpen(true)} startIcon={<AddIcon fontSize="small" />}>
            Add List
          </Button>

          <Button variant="primary" onClick={handleOpenLicense} startIcon={<AddIcon fontSize="small" />}>
            Add car
          </Button>
        </div>
      </div>

      {/* all car lists */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <div className={styles.listsWrapper}>
            <Typography variant="h3-bold">Cars Lists</Typography>
            {listsOfInterest && listsOfInterest.length > 0 ? (
              <Tabs activeTab={query.listId as number} orientation="vertical">
                {listsOfInterest.map((list) => (
                  <Tab
                    orientation="vertical"
                    id={`${list.name}_tab`}
                    value={list.id}
                    key={list.id}
                    size="default"
                    label={
                      <div className={styles.listItem}>
                        <Typography
                          variant="label-regular"
                          color={query.listId === list.id ? "important" : "base"}
                          variantColor={2}
                        >
                          {list.name}
                        </Typography>
                        <DeleteIcon sx={{ fontSize: 16 }} onClick={() => setOpenDelete(true)} />
                      </div>
                    }
                    selected={query.listId === list.id}
                    onClick={() => handleChangeList(list.id!, list.name)}
                  />
                ))}
              </Tabs>
            ) : (
              <div className={styles.noData}>
                <Typography variant="h2-bold">No car lists yet!</Typography>
              </div>
            )}
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={9}>
          <CarsLicenseTable
            data={listsOfInterest && listsOfInterest.length > 0 ? listsOfLicensePlates! : []}
            loading={isFetching}
            searchValue={query.searchValue}
            deleteLicense={deleteLicense}
          />
        </Grid>
      </Grid>

      {/* add car list */}
      <CarsAddPopup type="list" formikLists={formikLists} open={open} handleClose={handleClose} />
      <CarsAddPopup
        type="licensePlate"
        formikLicensePlate={formikLicensePlate}
        open={openLicense}
        handleClose={handleCloseLicense}
      />

      <WarningDialog
        isOpen={openDelete}
        onCancel={handleCloseDelete}
        onConfirm={() => {
          deleteList()
          setOpenDelete(false)
        }}
        dialogTitle={`Delete List “${query.listName}”?`}
        actionTitle="Delete"
        content="Be aware when deleting this action can not be undone."
        isDangerousAction
      />
    </Fragment>
  )
}

export default CarsListsScreen
