import { useRef, FC, useMemo } from "react"

import adultsIcon from "../../../../assets/adultsIcon.svg"
import childrenIcon from "../../../../assets/childrenIcon.svg"
import femaleIcon from "../../../../assets/femaleIcon.svg"
import maleIcon from "../../../../assets/maleIcon.svg"
import StatsCard from "../../../../components/StatsCard"
import { definitions } from "../../../../types/Generated/apiTypes"
import { calculateDwellingAreaStatistics } from "../../../../utils/dwellingUtils"

import styles from "./NumericStats.module.scss"

/**
 * Type definitions for DwellingAreaDailyCounts, DwellingAreaAnalytics, and EntityGroupsCounts
 * These types are used to define the structure of the data passed to the NumericStats component.
 */
type DwellingAreaDailyCounts = definitions["DwellingAreaDailyCounts"]
type DwellingAreaAnalytics = definitions["DwellingAreaAnalytics"]
type EntityGroupsCounts = definitions["EntityGroupsCounts"]

/**
 * Props interface for NumericStats component.
 * This interface defines the properties that can be passed to the NumericStats component.
 */
interface Props {
  /**
   * Array of daily counts for dwelling areas.
   */
  dwellingDailyCounts?: DwellingAreaDailyCounts[]
  /**
   * Array of analytics for dwelling areas.
   */
  dwellingAreaAnalytics?: DwellingAreaAnalytics[]
  /**
   * Counts of entity groups.
   */
  groupsCount?: EntityGroupsCounts
  /**
   * Indicates if the component is currently loading data.
   */
  isLoading?: boolean
}

/**
 * NumericStats component displays various numeric statistics and distributions related to dwelling areas.
 * It calculates and displays the total dwelling count, average dwelling time, and distributions of gender and age.
 *
 * @param {Props} props - The props for the NumericStats component.
 * @returns {JSX.Element} The JSX element for the NumericStats component.
 */
const NumericStats: FC<Props> = ({
  dwellingDailyCounts,
  dwellingAreaAnalytics,
  groupsCount,
  isLoading,
}: Props): JSX.Element => {
  const visitorsRef = useRef<HTMLDivElement>(null)
  const dwellTimeRef = useRef(null)

  /**
   * Calculates and memoizes the dwelling area statistics based on the provided data.
   * If the data is not available or the component is loading, it returns default values.
   */
  const { totalDwellingCount, averageDwellingTime, maleCount, femaleCount, adultCount, childCount } = useMemo(() => {
    if (dwellingDailyCounts && dwellingAreaAnalytics && !isLoading) {
      return calculateDwellingAreaStatistics(dwellingDailyCounts, dwellingAreaAnalytics)
    }
    return {
      totalDwellingCount: 0,
      averageDwellingTime: 0,
      maleCount: 0,
      femaleCount: 0,
      adultCount: 0,
      childCount: 0,
    }
  }, [isLoading, dwellingDailyCounts, dwellingAreaAnalytics])

  /**
   * Calculates and memoizes the percentage of females based on the male and female counts.
   * If either count is undefined, it returns 50 as a default value.
   */
  const femalePercentage = useMemo(() => {
    return maleCount && femaleCount ? (femaleCount / (maleCount + femaleCount)) * 100 : 50
  }, [maleCount, femaleCount])

  /**
   * Calculates and memoizes the percentage of males based on the male and female counts.
   * If either count is undefined, it returns 100 as a default value.
   */
  const malePercentage = useMemo(() => {
    return maleCount && femaleCount ? (maleCount / (maleCount + femaleCount)) * 100 : 100
  }, [maleCount, femaleCount])

  /**
   * Calculates and memoizes the percentage of children based on the child and adult counts.
   * If either count is undefined, it returns 50 as a default value.
   */
  const childrenPercentage = useMemo(() => {
    return childCount && adultCount ? (childCount / (childCount + adultCount)) * 100 : 50
  }, [childCount, adultCount])

  /**
   * Calculates and memoizes the percentage of adults based on the child and adult counts.
   * If either count is undefined, it returns 100 as a default value.
   */
  const adultPercentage = useMemo(() => {
    return childCount && adultCount ? (adultCount / (childCount + adultCount)) * 100 : 100
  }, [childCount, adultCount])

  return (
    <div className={styles.statsCardsWrapper}>
      {/* Dwelling Count Stats Card */}
      <StatsCard
        title="Total Count"
        numericStat={totalDwellingCount}
        distributionData={[
          {
            leftBar: {
              percentage: femalePercentage,
              label: "Female",
              value: femaleCount,
              color: "var(--pink-background-2)",
              tooltipTitle: `Female : ${femaleCount?.toLocaleString()}`,
              icon: femaleIcon,
            },
            rightBar: {
              percentage: malePercentage,
              label: "Male",
              value: maleCount,
              color: "var(--blue-background-2)",
              tooltipTitle: `Male : ${maleCount?.toLocaleString() || "No data"}`,
              icon: maleIcon,
            },
            title: "Gender Distribution",
          },
          {
            leftBar: {
              percentage: childrenPercentage,
              label: "Children",
              value: childCount,
              color: "var(--green-background-2)",
              tooltipTitle: `Children : ${childCount?.toLocaleString()}`,
              icon: childrenIcon,
            },
            rightBar: {
              percentage: adultPercentage,
              label: "Adults",
              value: adultCount,
              color: "var(--purple-background-2)",
              tooltipTitle: `Adults : ${adultCount?.toLocaleString()}`,
              icon: adultsIcon,
            },
            title: "Age Distribution",
          },
        ]}
        isNumericalStatLoading={isLoading}
        areBarsLoading={isLoading}
        reference={visitorsRef}
        marginBottom
        entityGroupsCounts={groupsCount}
      />
      {/* Occupancy In Card */}
      <StatsCard
        title="Average dwelling time"
        numericStat={averageDwellingTime}
        isNumericalStatLoading={isLoading}
        reference={dwellTimeRef}
        isSecondCard
        height="20%"
        unit="Minutes"
      />
    </div>
  )
}
export default NumericStats
