import { FC, Fragment, useState } from "react"
import { UseMutateFunction } from "react-query"

import DeleteIcon from "@mui/icons-material/DeleteOutline"

import { Action } from "@material-table/core"
import { AxiosResponse } from "axios"

import WarningDialog from "../../../components/WarningDialog"
import LicenseTable from "../../../components/tables/GenericTable"
import { TableColumn } from "../../../types/Custom/Types"
import { definitions } from "../../../types/Generated/apiTypes"

type ListsOfLicensePlates = definitions["CarListMembershipLicensePlate"]

interface Props {
  data: ListsOfLicensePlates[]
  loading: boolean
  searchValue: string
  deleteLicense: UseMutateFunction<AxiosResponse<any> | undefined, unknown, any, unknown>
}

const CarsLicenseTable: FC<Props> = ({ data, loading, deleteLicense, searchValue }) => {
  const [open, setOpen] = useState(false)
  const [licensePlate, setLicensePlate] = useState("")

  const handleClose = () => {
    setOpen(false)
    setLicensePlate("")
  }

  const handleOpen = (licensePlate: string) => {
    setOpen(true)
    setLicensePlate(licensePlate)
  }

  // filter license plates
  const filterLicense =
    data &&
    data!?.filter((license) => {
      return license?.license_plate?.toLowerCase().includes(searchValue.toLowerCase())
    })

  const tableColumns: TableColumn[] = [
    {
      title: "License Plate",
      field: "license_plate",
      searchable: false,
    },
  ]

  const tableActions: Action<ListsOfLicensePlates>[] = [
    {
      icon: () => <DeleteIcon />,
      tooltip: "Delete User",
      onClick: (_e: any, data: ListsOfLicensePlates) => handleOpen(data?.license_plate),
    },
  ]

  const isEmptyTable = (data && data?.length < 1) || !data

  return (
    <Fragment>
      <LicenseTable
        columns={tableColumns}
        data={filterLicense}
        actions={tableActions}
        isLoading={loading}
        isEmpty={isEmptyTable}
        hasExport={false}
        hasPagination
        height="100%"
        title="License Plates"
      />

      <WarningDialog
        isOpen={open}
        onCancel={handleClose}
        onConfirm={() => {
          deleteLicense(licensePlate)
          setOpen(false)
        }}
        dialogTitle={`Delete Car “${licensePlate}”?`}
        actionTitle="Delete"
        content="Be aware when deleting this action can not be undone."
        isDangerousAction
      />
    </Fragment>
  )
}

export default CarsLicenseTable
