import React, { useContext, useEffect, useState } from "react"

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import { Step, StepLabel, Stepper } from "@mui/material"

import { Button } from "@synapse-analytics/synapse-ui"

import { IEntitiesSetup } from "../../../../../../types/Custom/Interfaces"
import { Point } from "../../../../../../types/Custom/Types"
import { EntitiesContext } from "../../../EntitiesContext/EntitiesContext"
import AreaSelection from "./AreaSelection"
import RegionsArtboard from "./RegionsArtboard"

import styles from "./RegionsCreator.module.scss"

const RegionsCreator = () => {
  const {
    availableRegions,
    selectedDrawingCamera,
    selectedRegion,
    setSelectedRegion,
    handleStateChange,
    selectedState,
    allRegions,
    setAllRegions,
    formik,
  } = useContext<IEntitiesSetup>(EntitiesContext)

  const [points, setPoints] = useState<Point[]>([])

  /**
   * Checks if the selected drawing camera has a region in the specified step (state)
   * with at least 3 points in the `points` array.
   *
   * @function checkForCompletion
   * @param {number} step - The current step (state) to check for completion.
   * @returns {boolean} - Returns `true` if the step is completed, `false` otherwise.
   */
  const checkForCompletion = (step: number): boolean => {
    if (!selectedDrawingCamera || !allRegions[selectedDrawingCamera]) return false

    return allRegions[selectedDrawingCamera][step]?.isFulfilled
  }

  /**
   * Adds a new region to the currently selected camera's active state (step).
   *
   * The new region will be associated with the selected camera and the current
   * active step (state) in the region selection process. If the camera's regions
   * haven't been initialized, this function will initialize the regions for all steps.
   *
   * @function handleAddNewArea
   * @returns {void} - Returns nothing. Updates the state of `allRegions`.
   */
  const handleAddNewArea = () => {
    if (!selectedDrawingCamera) return // Ensure a camera is selected

    // Clone the current allRegions state to avoid mutating directly
    const updatedAllRegions = { ...allRegions }

    // Access the regions for the current active state (step) of the selected camera
    const currentStateRegions = updatedAllRegions[selectedDrawingCamera][selectedState]

    // Create a new region object with empty points and other default properties
    const newRegion = {
      camera: selectedDrawingCamera, // The selected camera ID
      state: selectedState, // The current active step (state)
      points: [], // Initial empty points for the new region
      dwell_thresh: new Date().setMinutes(0, 5), // Set dwell_thresh to null (can be customized as needed)
    }

    // Append the new region to the existing regions for the current active step
    currentStateRegions.regions.push(newRegion)

    // Update the allRegions state with the newly added region
    setAllRegions(updatedAllRegions)
    // set the selected region to be the newly created region
    setSelectedRegion(currentStateRegions.regions.length - 1)
  }

  // whenever points change (newly drawn points or a point is deleted)
  // update values in regions array
  useEffect(() => {
    // Exit early if there are no selected cameras or regions to update
    if (
      !selectedDrawingCamera ||
      !allRegions[selectedDrawingCamera] ||
      !allRegions[selectedDrawingCamera][selectedState]
    )
      return

    // Clone allRegions to avoid direct mutation
    const allCamerasRegions = { ...allRegions }

    // Get the regions object for the selected camera and active step
    const regionsObj = allCamerasRegions[selectedDrawingCamera][selectedState].regions

    if (!regionsObj) return // Exit if no regions exist for the camera and step

    // Get the current points for the selected region
    const currentPoints = regionsObj[selectedRegion]?.points || []

    // Check if points actually changed before updating
    const pointsHaveChanged = JSON.stringify(currentPoints) !== JSON.stringify(points)

    if (!pointsHaveChanged) return // If points haven't changed, exit early

    // Update the points array for the selected region
    const newRegionObj = { ...regionsObj[selectedRegion], points: points }
    regionsObj[selectedRegion] = newRegionObj

    // Update the isFulfilled flag based on the number of points
    const isFulfilled = regionsObj.some((region) => region.points.length >= 3)
    allCamerasRegions[selectedDrawingCamera][selectedState].isFulfilled = isFulfilled

    // Only update the state if changes are necessary
    setAllRegions(allCamerasRegions)
  }, [selectedState, allRegions, points, selectedDrawingCamera, selectedRegion, setAllRegions])

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Stepper nonLinear activeStep={selectedState}>
          {availableRegions.map((step: string, state) => (
            <Step
              key={state}
              completed={checkForCompletion(state)}
              style={{ display: step !== "" ? "" : "none" }}
              className={styles.step}
            >
              <StepLabel
                onClick={() => handleStateChange(state as 0 | 1 | 2 | 8 | 9)}
                icon={
                  formik.values.type === "Dwelling Area" ||
                  formik.values.type === "Cashier" ||
                  formik.values.type === "Zone"
                    ? 1
                    : state + 1
                }
                className={styles.stepButton}
                classes={{
                  active: styles.activeStep,
                }}
              >
                {step}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Button
          variant="primary"
          size="small"
          startIcon={<AddCircleOutlineIcon fontSize="small" />}
          className={styles.addButton}
          onClick={() => handleAddNewArea()}
        >
          Add area
        </Button>
      </div>
      <AreaSelection setPoints={setPoints} points={points} />
      {/* Region Artboard */}
      <RegionsArtboard points={points} setPoints={setPoints} />
    </div>
  )
}
export default RegionsCreator
