import { FC, useContext, useEffect } from "react"
import { useInfiniteQuery } from "react-query"

import NoteAddIcon from "@mui/icons-material/InsertDriveFile"
import { Paper, Grid, useMediaQuery } from "@mui/material"

import { Typography, Skeleton } from "@synapse-analytics/synapse-ui"
import { SwiperSlide } from "swiper/react"

import Placeholder from "../../../assets/placeholder.svg"
import SwiperContainer from "../../../components/SwiperContainer"
import { PaginatedCampaignsList } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"
import { extractPageFromBackEndPaginationLink } from "../../../utils/genericHelpers"
import { CampaignContext } from "../CampaignContext/CampaignContext"
import CampaignCard from "./CampaignCard"

import styles from "./CampaignList.module.scss"

type CampaignListObj = definitions["CampaignList"]

const breakpoints = {
  1480: {
    slidesPerView: 4,
    slidesToScroll: 4,
  },
  1240: {
    slidesPerView: 3,
    slidesToScroll: 3,
  },
  500: {
    slidesPerView: 1,
    slidesToScroll: 1,
  },
}

interface Props {
  handleOpen?: () => void
  title: string
  status?: "upcoming" | "inprogress" | "past"
  campaignId?: string
  fetchKey?: string
  fetchRequest: (pageParam: number, status: "upcoming" | "inprogress" | "past") => Promise<PaginatedCampaignsList>
  getLoadingState?: (loading: boolean) => void
}

const CampaignList: FC<Props> = ({
  fetchKey,
  fetchRequest,
  handleOpen,
  title,
  getLoadingState,
  campaignId,
  status,
}) => {
  const { formik, compare, debouncedSearchValue } = useContext(CampaignContext)
  const xLargeScreen = useMediaQuery("(min-width:1900px)")
  const smallScreen = useMediaQuery("(max-width:1240px)")
  const largeScreen = useMediaQuery("(max-width:1525px)")
  const xSmallScreen = useMediaQuery("(max-width:680px)")
  let limit = 4

  if (xLargeScreen) {
    limit = 4
  } else if (largeScreen) {
    limit = 4
  } else if (smallScreen) {
    limit = 3
  } else if (xSmallScreen) {
    limit = 1
  }

  const { data, fetchNextPage, isLoading, isFetching, isFetchingNextPage, isFetchingPreviousPage } =
    useInfiniteQuery<PaginatedCampaignsList>(
      [fetchKey!, !compare ? debouncedSearchValue : "", status],
      ({ pageParam = 1, queryKey }) => fetchRequest(pageParam, queryKey[2] as "upcoming" | "inprogress" | "past"),
      {
        keepPreviousData: true,
        enabled: !!formik?.values?.endDateFilter,
        getNextPageParam: (lastPage: PaginatedCampaignsList) => {
          return lastPage?.next ? extractPageFromBackEndPaginationLink(lastPage.next) : undefined
        },
      }
    )

  useEffect(() => {
    if (!campaignId) {
      if (isFetching) {
        getLoadingState!(true)
      } else {
        getLoadingState!(false)
      }
    }
  }, [getLoadingState, isFetching, campaignId])

  const loadingState = isFetchingNextPage || isFetchingPreviousPage ? true : false

  const loadingPlaceholders = new Array(limit).fill(null).map((r, i) => (
    <Grid item xs={12} md={3} sm={6} key={i}>
      <Skeleton variant="rectangular" height={280} width="100%" />
    </Grid>
  ))

  return (
    <div
      style={{
        padding: campaignId ? "16px 16px" : "16px 0px",
      }}
    >
      <Typography
        variant="h3-bold"
        variantColor={2}
        style={{
          marginBottom: isLoading ? "16px" : "24px",
        }}
      >
        {title}
      </Typography>

      {isLoading || (isFetching && !loadingState) ? (
        <div className={styles.loadingWrapper}>
          <Grid container spacing={1}>
            {loadingPlaceholders}
          </Grid>
        </div>
      ) : data?.pages![0]?.results!?.length > 0 ? (
        <SwiperContainer
          slidesPerView={limit}
          slidesToScroll={limit}
          spaceBetweenSlides={8}
          fetchNextPage={fetchNextPage}
          isLoading={isFetching}
          breakpoints={breakpoints}
        >
          {title === "In progress Campaigns" && (
            <SwiperSlide>
              <Paper
                className={styles.addCampaign}
                onClick={() => {
                  handleOpen!()
                  formik!.resetForm()
                }}
              >
                <div>
                  <NoteAddIcon className={styles.addCampaignIcon} />
                  <Typography variant="a" variantColor={2} className={styles.addCampaignDesc} align="center">
                    + Add new Campaign
                  </Typography>
                </div>
              </Paper>
            </SwiperSlide>
          )}

          {data?.pages!?.length > 0 &&
            data?.pages!.map((page) =>
              page?.results?.map((campaign: CampaignListObj) => (
                <SwiperSlide key={campaign?.id}>
                  <CampaignCard campaign={campaign} title={title} compare={false} />
                </SwiperSlide>
              ))
            )}
        </SwiperContainer>
      ) : (
        // NO Campaigns
        <Grid container spacing={2}>
          {title === "In progress Campaigns" && (
            <Grid item md={3} xs={12}>
              <div
                className={styles.addCampaign}
                onClick={() => {
                  handleOpen!()
                  formik!.resetForm()
                }}
              >
                <div>
                  <NoteAddIcon className={styles.addCampaignIcon} />
                  <Typography variant="a" variantColor={2} className={styles.addCampaignDesc} align="center">
                    + Add new Campaign
                  </Typography>
                </div>
              </div>
            </Grid>
          )}

          <Grid item md={title === "In progress Campaigns" ? 9 : 12} xs={12}>
            <div className={styles.noCampaignsWrapper}>
              <img src={Placeholder} alt="No campaigns" className={styles.noCampaignsImage} />
              <Typography variant="label-regular" align="center">
                Nothing to show here
              </Typography>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default CampaignList
