import { FC, Fragment, useState } from "react"
import { useQuery } from "react-query"

import Add from "@mui/icons-material/AddCircleRounded"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import { Grid } from "@mui/material"

import { Button, Tag, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { format } from "date-fns"
import moment from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import NoFrame from "../../../assets/noFrame.svg?react"
import IncidentTag from "../../../components/ServiceEventTag"
import ViewFrameDialog from "../../../components/ViewFrameDialog"
import { ServiceEventType } from "../../../types/Custom/Types"
import { definitions } from "../../../types/Generated/apiTypes"
import { ServiceEventTypeMapper } from "../../../utils/genericHelpers"
import CarBrandIcon from "../../Cars/components/CarBrandIcon"
import CarsAddPopup from "../../Cars/components/CarsAddPopup"

import styles from "./EventCard.module.scss"

type ListsOfInterest = definitions["ListOfInterest"]
type EventsPaginated = definitions["SecurityViolationList"]
type CarEventPaginated = definitions["CarCounterLogRetrieve"]

interface Props {
  eventLog: EventsPaginated & CarEventPaginated
  isCarEvent?: boolean
}
const CounterEventCard: FC<Props> = ({ eventLog, isCarEvent }) => {
  const imageSource = isCarEvent ? eventLog?.car_image : eventLog?.image
  const altText = isCarEvent ? "car_image" : eventLog?.camera_name
  const maxHeight = isCarEvent ? "150px" : "95px"

  const [isAddToListDialogOpen, setIsAddToListDialogOpen] = useState(false)
  const [isHoveringOnFrame, setIsHoveringOnFrame] = useState(false)
  const [isAddExpanded, setIsAddExpanded] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleCloseLicense = () => {
    setIsAddToListDialogOpen(false)
  }

  // fetch car lists of interest
  const { data: listsOfInterest } = useQuery<ListsOfInterest[], AxiosError>("fetchCarsListOfInterest", () =>
    VisionAPI.fetchCarListsOfInterest()
  )

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
    setIsHoveringOnFrame(false)
  }

  return (
    <div className={styles.eventCard}>
      {/* Camera Frame */}
      <Grid
        item
        xs={6}
        className={styles.mediaWrapper}
        style={{
          boxShadow: !isCarEvent ? "none" : undefined,
        }}
      >
        {isCarEvent && (
          <div
            className={styles.addToList}
            onClick={() => setIsAddToListDialogOpen(true)}
            onMouseOver={() => setIsAddExpanded(true)}
            onMouseOut={() => setIsAddExpanded(false)}
          >
            <Add
              style={{
                marginRight: isAddExpanded ? "4px" : "0px",
              }}
              className={styles.addIcon}
            />
            {isAddExpanded && (
              <Typography variant="a" noWrap style={{ marginRight: 4 }}>
                Add car to list
              </Typography>
            )}
          </div>
        )}
        {!imageSource ? (
          <div
            className={styles.media}
            style={{
              maxHeight: !isCarEvent ? "95px" : "150px",
            }}
          >
            <NoFrame className={styles.noFrame} />
            <Typography variant="span-bold" variantColor={2} className={styles.noFrame} align="center">
              No Frame
            </Typography>
          </div>
        ) : (
          <div
            onMouseEnter={() => setIsHoveringOnFrame(true)}
            onMouseLeave={() => setIsHoveringOnFrame(false)}
            style={{ maxHeight }}
            className={styles.media}
          >
            {isHoveringOnFrame && (
              <div className={styles.backdrop}>
                <Button variant="secondary" onClick={() => setIsDialogOpen(true)}>
                  View
                </Button>
              </div>
            )}
            <img className={styles.media} style={{ maxHeight }} src={imageSource} alt={altText} />
            <ViewFrameDialog
              frame={imageSource}
              handleCloseDialog={handleCloseDialog}
              open={isDialogOpen}
              name={`${eventLog?.camera_name || `${eventLog?.license_plate_data}`}`}
              caption={moment(eventLog?.timestamp).fromNow()}
              infoItem={
                eventLog?.camera_name ? (
                  <div>
                    <IncidentTag
                      serviceEventType={ServiceEventTypeMapper[eventLog?.type] as ServiceEventType}
                      size={"small"}
                    />
                  </div>
                ) : undefined
              }
            />
          </div>
        )}
        {isCarEvent && (
          <div className={styles.licenseData}>
            <Typography
              variant="a"
              title={eventLog?.license_plate_data}
              noWrap
              style={{ width: "100%" }}
              align="center"
            >
              {eventLog?.license_plate_data}
            </Typography>
          </div>
        )}
      </Grid>
      {/* Event Details */}
      <Grid item xs={6} className={styles.eventInfo}>
        <div className={styles.header}>
          <Typography variant="label-bold" variantColor={2}>
            {isCarEvent ? "Gate" : "Event"}
          </Typography>
          {eventLog?.timestamp && (
            <div className={styles.timestamp}>
              <Tooltip title={format(new Date(eventLog?.timestamp), "dd/MM/yyyy, p")} placement="right">
                <Typography variant="label-regular" variantColor={2} noWrap>
                  {moment(eventLog?.timestamp).fromNow()}
                </Typography>
              </Tooltip>
            </div>
          )}
        </div>
        {isCarEvent ? (
          <Typography variant="a" className={styles.halfGutter} noWrap style={{ width: "100%" }}>
            {eventLog?.entity}
          </Typography>
        ) : (
          <div className={styles.eventChip}>
            <IncidentTag serviceEventType={ServiceEventTypeMapper[eventLog?.type] as ServiceEventType} size={"small"} />
          </div>
        )}
        <Typography className={styles.halfGutter} variant="label-bold" variantColor={2}>
          {isCarEvent ? "Car brand" : "Camera"}
        </Typography>
        {isCarEvent ? (
          <Typography
            variant="a"
            className={`${styles.carBrand} ${styles.halfGutter}`}
            textTransform="capitalize"
            noWrap
            style={{ width: "100%" }}
          >
            {eventLog?.brand && <CarBrandIcon carBrand={eventLog?.brand} />}
            {eventLog?.brand && eventLog?.brand}
          </Typography>
        ) : (
          <Typography variant="a" noWrap style={{ width: "100%" }}>
            {eventLog?.camera_name}
          </Typography>
        )}
        {isCarEvent && (
          <Fragment>
            <Typography className={styles.halfGutter} variant="label-bold" variantColor={2}>
              Car Model
            </Typography>
            <Typography variant="a" gutterBottom noWrap style={{ width: "100%" }}>
              {eventLog?.car_model || "No data"}
            </Typography>
            <Typography className={styles.halfGutter} variant="label-bold" variantColor={2}>
              Match lists
            </Typography>
            {eventLog?.loi_memberships ? (
              <div className={styles.listsOfInterest}>
                {eventLog?.loi_memberships.split(",").map((loi) => (
                  <Tag variant="filled" color="blue" size="xsmall" style={{ width: "min-content" }}>
                    {loi}
                  </Tag>
                ))}
              </div>
            ) : (
              <Typography variant="label-regular" variantColor={2} className={styles.noLists}>
                <FormatListBulletedIcon sx={{ marginRight: "4px" }} fontSize="small" />
                No List
              </Typography>
            )}
          </Fragment>
        )}
      </Grid>
      {isCarEvent && (
        <CarsAddPopup
          type="addCarToList"
          open={isAddToListDialogOpen}
          handleClose={handleCloseLicense}
          licensePlate={eventLog?.license_plate_data}
          listsOfInterest={listsOfInterest}
        />
      )}
    </div>
  )
}
export default CounterEventCard
