import { useMediaQuery } from "@mui/material"

import { Select, Snackbar, Typography } from "@synapse-analytics/synapse-ui"
import { StringParam, useQueryParam, withDefault } from "use-query-params"

import CamerasHeatmap from "./CamerasHeatmap/CamerasHeatmap"
import FloorsHeatmap from "./FloorsHeatmap/FloorsHeatmap"

import styles from "./Heatmap.module.scss"

const Heatmap = () => {
  const [heatmapMode, setHeatmapMode] = useQueryParam("heatmapMode", withDefault(StringParam, "Floors Heatmap"))
  const smallScreen = useMediaQuery("(max-width:1500px)")

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleAndSelect}>
          <Typography
            variant="h2-regular"
            tooltip={`Where most visitors tend to go for selected date range`}
            tooltipPlacement="right"
            tooltipIconSize={22}
            gutterBottom
            variantColor={2}
          >
            {heatmapMode}
          </Typography>
          <Select
            id="heatmap-mode"
            value={heatmapMode}
            options={["Floors Heatmap", "Cameras Heatmap"]}
            handleChange={(e) => setHeatmapMode(e.target.value as "Cameras Heatmap" | "Floors Heatmap")}
            menuProps={{
              menuMaxContent: true,
            }}
            width={190}
          />
        </div>
        <Snackbar title="Deprecation Notice" severity="warning" className={styles.deprecationWarning}>
          The old version will be removed soon, {smallScreen && <br />}
          along with all data.
          <br />
          {!smallScreen && "Please back up important information."}
        </Snackbar>
      </div>
      {heatmapMode === "Floors Heatmap" ? <FloorsHeatmap /> : <CamerasHeatmap />}
    </div>
  )
}

export default Heatmap
