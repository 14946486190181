import { useEffect, useMemo, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"

import AddCircleIcon from "@mui/icons-material/AddCircle"
import CarParkingsIcon from "@mui/icons-material/CommuteOutlined"
import CashierIcon from "@mui/icons-material/CreditCard"
import DeleteOutline from "@mui/icons-material/DeleteOutline"
import EditIcon from "@mui/icons-material/Edit"
import GetAppIcon from "@mui/icons-material/GetApp"
import OccupancyIcon from "@mui/icons-material/GroupsOutlined"
import EntranceGatesIcon from "@mui/icons-material/MeetingRoom"
import TenantsIcon from "@mui/icons-material/ShoppingCartOutlined"
import DwellingAreasIcon from "@mui/icons-material/Timer"
import CorridorsIcon from "@mui/icons-material/WebStories"
import { useMediaQuery, useTheme } from "@mui/material"

import { Action } from "@material-table/core"
import { Button, NotificationUtils, Tab, Tabs, Typography, Tooltip } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { CsvBuilder } from "filefy"
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../API/VisionAPI"
import CameraSearch from "../../components/CameraSearch"
import Search from "../../components/Search"
import WarningDialog from "../../components/WarningDialog"
import EntitiesTable from "../../components/tables/GenericTable"
import { useBranchesStore } from "../../store"
import { AvailableEntities, EntityRowData } from "../../types/Custom/Interfaces"
import { TableColumn } from "../../types/Custom/Types"
import { definitions } from "../../types/Generated/apiTypes"
import { mapCameraAndRegionsToEntities } from "../../utils/entityUtils"
import EntitiesContextProvider from "./EntitiesSetup/EntitiesContext/EntitiesContext"
import EntitiesSetup from "./EntitiesSetup/EntitiesSetup"

import styles from "./EntitiesAndRegions.module.scss"

type Gate = definitions["Gate"]

type Shop = definitions["Shop"]

type Corridor = definitions["Corridor"]

type DwellingArea = definitions["DwellingArea"]

type Parking = definitions["Parking"]

type Cashier = definitions["Cashier"]

type Zone = definitions["Zone"]

type CamerasList = definitions["CameraList"]

type Floor = definitions["FloorPlanList"]

type Region = definitions["counterentitiy_region_serializer"]

const sectionHeight = "auto"
type TabInfo = {
  label: string
  value: string
  icon: React.ReactElement
}

type RegionPoint = {
  x: number
  y: number
}
type RegionData = {
  camera: number
  id: number
  points: RegionPoint[]
  state: 0 | 1 | 2
  dwell_thresh?: Date | number
}
interface Entity extends Object {
  id: number
  name: string
  cameras: number[]
  regions: RegionData[]
  camera_name: string[]
  inRegion: boolean
  outRegion: boolean
  type: AvailableEntities
}
type TableData = Entity & {
  tableData: { id: number }
}

const tabsInfo: Array<TabInfo> = [
  { label: "Entrance Gates", value: "Entrance Gate", icon: <EntranceGatesIcon className={styles.tabIcon} /> },
  { label: "Tenants", value: "Tenant", icon: <TenantsIcon className={styles.tabIcon} /> },
  { label: "Corridors", value: "Corridor", icon: <CorridorsIcon className={styles.tabIcon} /> },
  { label: "Dwelling Areas", value: "Dwelling Area", icon: <DwellingAreasIcon className={styles.tabIcon} /> },
  { label: "Car Parkings", value: "Car Parking", icon: <CarParkingsIcon className={styles.tabIcon} /> },
  { label: "Cashiers", value: "Cashier", icon: <CashierIcon className={styles.tabIcon} /> },
  { label: "Occupancy Zones", value: "Zone", icon: <OccupancyIcon className={styles.tabIcon} /> },
]

// order entities by their update date
const ordering = "-updated_dt"

function EntitiesAndRegions() {
  const queryClient = useQueryClient()
  const theme = useTheme()
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const [query, setQuery] = useQueryParams({
    searchVal: withDefault(StringParam, ""),
    selectedCamera: NumberParam,
    activeTab: withDefault(StringParam, "Entrance Gate"),
  })

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [selectedEntity, setSelectedEntity] = useState<EntityRowData>()
  const [isEdit, setIsEdit] = useState(false)
  const [tableData, setTableData] = useState<TableData[]>([])
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [selectedEntityToDelete, setSelectedEntityToDelete] = useState<EntityRowData>()

  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  const handleSearch = (value: string) => {
    setQuery({ searchVal: value })
  }

  const filteredTableData = useMemo(() => {
    return tableData.filter((element) => element.name.toLowerCase().includes(query.searchVal.toLowerCase()))
  }, [tableData, query.searchVal])

  const handleTabChange = (tab: AvailableEntities): void => {
    setQuery({ activeTab: tab })
  }

  // fetch regions
  const { data: regionsData, isLoading: regionsLoading } = useQuery<Region[], AxiosError>("fetchRegions", () =>
    VisionAPI.fetchRegions()
  )
  const { data: floors, isLoading: isFloorsLoading } = useQuery<Floor[], AxiosError>(
    ["fetchFloors", selectedBranch],
    ({ queryKey }) => VisionAPI.fetchFloorPlans({ branch: queryKey[1] as number }),
    {
      enabled: !!selectedBranch && query.activeTab === "Zone",
    }
  )

  // fetch cameras list
  const { data: cameras, isLoading: camerasListLoading } = useQuery<CamerasList[], AxiosError>(
    ["fetchCamerasShortList", selectedBranch, query.activeTab],
    ({ queryKey }) =>
      VisionAPI.fetchCamerasShortList({
        branch: queryKey[1] as number,
        services: queryKey[1] === "Car Parking" ? "car" : "",
      }),
    {
      enabled: !!selectedBranch,
    }
  )

  const { data: entranceEntities, isLoading: entranceEntitiesLoading } = useQuery<Gate[], AxiosError>(
    ["fetchEntranceEntities", selectedBranch, query.selectedCamera, ordering],
    ({ queryKey }) =>
      VisionAPI.fetchEntrances({
        branch: queryKey[1] as number,
        camera: queryKey[2] as number,
        ordering: queryKey[3] as string,
      }),
    {
      enabled: query.activeTab === "Entrance Gate" && !!selectedBranch,
    }
  )

  const { data: shopEntities, isLoading: shopEntitiesLoading } = useQuery<Shop[], AxiosError>(
    ["fetchShopsEntities", selectedBranch, query.selectedCamera, ordering],
    ({ queryKey }) =>
      VisionAPI.fetchShops({
        branch: queryKey[1] as number,
        camera: queryKey[2] as number,
        ordering: queryKey[3] as string,
      }),
    {
      enabled: query.activeTab === "Tenant" && !!selectedBranch,
    }
  )

  const { data: corridorEntities, isLoading: corridorEntitiesLoading } = useQuery<Corridor[], AxiosError>(
    ["fetchCorridorEntities", selectedBranch, query.selectedCamera, ordering],
    ({ queryKey }) =>
      VisionAPI.fetchCorridors({
        branch: queryKey[1] as number,
        camera: queryKey[2] as number,
        ordering: queryKey[3] as string,
      }),
    {
      enabled: query.activeTab === "Corridor" && !!selectedBranch,
    }
  )

  const { data: dwellingAreas, isLoading: dwellingAreasLoading } = useQuery<DwellingArea[], AxiosError>(
    ["fetchDwellingAreasEntities", selectedBranch, query.selectedCamera, ordering],
    ({ queryKey }) =>
      VisionAPI.fetchDwellingAreas({
        branch: queryKey[1] as number,
        camera: queryKey[2] as number,
        ordering: queryKey[3] as string,
      }),
    {
      enabled: query.activeTab === "Dwelling Area" && !!selectedBranch,
    }
  )

  const { data: carParking, isLoading: carParkingLoading } = useQuery<Parking[], AxiosError>(
    ["fetchCarParkingEntities", query.selectedCamera, ordering],
    ({ queryKey }) => VisionAPI.fetchCarParking({ camera: queryKey[1] as number, ordering: queryKey[2] as string }),
    {
      enabled: query.activeTab === "Car Parking",
    }
  )

  const { data: carParkingRegions, isLoading: carParkingRegionsLoading } = useQuery<Region[], AxiosError>(
    ["fetchCarParkingRegions", query.selectedCamera],
    ({ queryKey }) => VisionAPI.fetchCarParkingRegions({ camera: queryKey[1] as number }),
    {
      enabled: query.activeTab === "Car Parking",
    }
  )

  const { data: cashierEntities, isLoading: cashierEntitiesLoading } = useQuery<Cashier[], AxiosError>(
    ["fetchCashierEntities", selectedBranch, query.selectedCamera, ordering],
    ({ queryKey }) =>
      VisionAPI.fetchCashiers({
        branch: queryKey[1] as number,
        camera: queryKey[2] as number,
        ordering: queryKey[3] as string,
      }),
    {
      enabled: query.activeTab === "Cashier" && !!selectedBranch,
    }
  )

  const { data: zonesEntities, isLoading: zonesEntitiesLoading } = useQuery<Zone[], AxiosError>(
    ["fetchZoneEntities", selectedBranch, query.selectedCamera, ordering],
    ({ queryKey }) =>
      VisionAPI.fetchZonesEntities({
        branch: queryKey[1] as number,
        camera: queryKey[2] as number,
        ordering: queryKey[3] as string,
      }),
    {
      enabled: query.activeTab === "Zone" && !!selectedBranch,
    }
  )

  const { mutate: deleteCarParkingEntity, isLoading: isDeletingParkingEntity } = useMutation(
    (entity: number) => VisionAPI.deleteCarParking(entity as number),
    {
      onSuccess: async () => {
        NotificationUtils.toast("Car parking entity deleted successfully", {
          severity: "success",
        })
        await queryClient.invalidateQueries("fetchCarParkingEntities")
        handleCloseDeleteDialog()
      },
    }
  )

  const { mutate: deleteCorridor, isLoading: isDeletingCorridorEntity } = useMutation(
    (entity: number) => VisionAPI.deleteCorridor(entity),
    {
      onSuccess: async () => {
        NotificationUtils.toast("Corridor entity deleted successfully", {
          severity: "success",
        })
        await queryClient.invalidateQueries("fetchCorridorEntities")
        handleCloseDeleteDialog()
      },
    }
  )

  const { mutate: deleteDwellingArea, isLoading: isDeletingDwellingAreaEntity } = useMutation(
    (entity: number) => VisionAPI.deleteDwellingArea(entity),
    {
      onSuccess: async () => {
        NotificationUtils.toast("Dwelling area entity deleted successfully", {
          severity: "success",
        })
        await queryClient.invalidateQueries("fetchDwellingAreasEntities")
        handleCloseDeleteDialog()
      },
    }
  )

  const { mutate: deleteGate, isLoading: isDeletingGateEntity } = useMutation(
    (entity: number) => VisionAPI.deleteGate(entity),
    {
      onSuccess: async () => {
        NotificationUtils.toast("Entrance gate entity deleted successfully", {
          severity: "success",
        })
        await queryClient.invalidateQueries("fetchEntranceEntities")
        handleCloseDeleteDialog()
      },
    }
  )

  const { mutate: deleteShop, isLoading: isDeletingShopEntity } = useMutation(
    (entity: number) => VisionAPI.deleteShop(entity),
    {
      onSuccess: async () => {
        NotificationUtils.toast("Tenant entity deleted successfully", {
          severity: "success",
        })
        await queryClient.invalidateQueries("fetchShopsEntities")
        handleCloseDeleteDialog()
      },
    }
  )

  const { mutate: deleteCashier, isLoading: isDeletingCashierEntity } = useMutation(
    (entity: number) => VisionAPI.deleteCashier(entity),
    {
      onSuccess: async () => {
        NotificationUtils.toast("Cashier entity deleted successfully", {
          severity: "success",
        })
        await queryClient.invalidateQueries("fetchCashierEntities")
        handleCloseDeleteDialog()
      },
    }
  )

  const { mutate: deleteZoneEntity, isLoading: isDeletingZoneEntity } = useMutation(
    (entity: number) => VisionAPI.deleteZoneEntity(entity),
    {
      onSuccess: async () => {
        NotificationUtils.toast("Zone entity deleted successfully", {
          severity: "success",
        })
        await queryClient.invalidateQueries("fetchZoneEntities")
        handleCloseDeleteDialog()
      },
    }
  )

  // Use useMemo to format Regions
  const regions = useMemo(() => {
    let regionsObj: {
      [key: string]: Region
    } = {}
    regionsData?.forEach((region) => (regionsObj[region?.id!] = { ...region }))
    return regionsObj
  }, [regionsData])

  // Use useMemo to format cameras object
  const camerasObj = useMemo(() => {
    let formattedCamerasObj: { [key: string]: CamerasList } = {}
    cameras?.forEach((camera: CamerasList) => {
      formattedCamerasObj[camera.id!] = { ...camera }
    })
    return formattedCamerasObj
  }, [cameras])

  // global table loading state , if any of them is true => isLoading = true
  const isLoading = [
    camerasListLoading,
    carParkingLoading,
    carParkingRegionsLoading,
    regionsLoading,
    corridorEntitiesLoading,
    entranceEntitiesLoading,
    shopEntitiesLoading,
    dwellingAreasLoading,
    cashierEntitiesLoading,
    zonesEntitiesLoading,
    isFloorsLoading,
  ].some((element) => element === true)

  const isDeletingEntity = [
    isDeletingParkingEntity,
    isDeletingCorridorEntity,
    isDeletingDwellingAreaEntity,
    isDeletingGateEntity,
    isDeletingShopEntity,
    isDeletingCashierEntity,
    isDeletingZoneEntity,
  ].some((element) => element === true)

  // setting table data for each tab
  useEffect(() => {
    switch (query.activeTab) {
      case "Entrance Gate":
        setTableData(mapCameraAndRegionsToEntities(entranceEntities, camerasObj, regions, "Entrance Gate"))
        break
      case "Tenant":
        setTableData(mapCameraAndRegionsToEntities(shopEntities, camerasObj, regions, "Tenant"))
        break
      case "Corridor":
        setTableData(mapCameraAndRegionsToEntities(corridorEntities, camerasObj, regions, "Corridor"))
        break
      case "Dwelling Area":
        setTableData(mapCameraAndRegionsToEntities(dwellingAreas, camerasObj, regions, "Dwelling Area"))
        break
      case "Car Parking":
        setTableData(mapCameraAndRegionsToEntities(carParking, camerasObj, carParkingRegions, "Car Parking"))
        break
      case "Cashier":
        setTableData(mapCameraAndRegionsToEntities(cashierEntities, camerasObj, regions, "Cashier"))
        break
      case "Zone":
        setTableData(mapCameraAndRegionsToEntities(zonesEntities, camerasObj, regions, "Zone"))
        break
      default:
        setTableData([])
    }
  }, [
    camerasObj,
    carParking,
    carParkingRegions,
    cashierEntities,
    corridorEntities,
    dwellingAreas,
    entranceEntities,
    query.activeTab,
    regions,
    shopEntities,
    zonesEntities,
  ])

  const handleExportCSV = () => {
    const builder = new CsvBuilder(`${query?.activeTab} Entities.csv`)
    let csvFormattedData: string[][] = [[]]
    csvFormattedData.pop()
    if (filteredTableData || tableData) {
      const dataToExport = filteredTableData
      for (const entry of dataToExport) {
        csvFormattedData.push([entry.name, entry.camera_name.join("/")])
      }
    }
    builder.setColumns(["Entity", "Cameras"]).addRows(csvFormattedData).exportFile()
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
    // reset selected entity
    setSelectedEntity(undefined)
    setIsEdit(false)
  }

  const handleOpenEdit = (rowData: EntityRowData) => {
    setSelectedEntity(rowData)
    setIsEdit(true)
    setIsDialogOpen(true)
  }

  const handleEditEntityOpen = (rowData: EntityRowData) => {
    handleOpenEdit(rowData)
  }

  const handleOpenDeleteDialog = (rowData: EntityRowData) => {
    setOpenDeleteDialog(true)
    setSelectedEntityToDelete(rowData)
  }

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }

  const handleDeleteEntity = () => {
    if (selectedEntityToDelete) {
      const { type, id } = selectedEntityToDelete
      if (!id) return
      switch (type) {
        case "Entrance Gate":
          deleteGate(id)
          break
        case "Tenant":
          deleteShop(id)
          break
        case "Corridor":
          deleteCorridor(id)
          break
        case "Car Parking":
          deleteCarParkingEntity(id)
          break
        case "Cashier":
          deleteCashier(id)
          break
        case "Zone":
          deleteZoneEntity(id)
          break
        default:
          deleteDwellingArea(id)
          break
      }
    }
  }

  const entitiesTableColumns: TableColumn[] = [
    {
      title: "Entity Name",
      field: "name",
      searchable: false,
    },
    ...(query?.activeTab === "Zone"
      ? [
          {
            title: "Floor",
            field: "floor",
            render: (rowData: EntityRowData) => floors?.find((floor) => floor.id === rowData?.floor)?.floor_name || "",
          },
        ]
      : []),
    { title: "Camera", field: "camera_name", searchable: false },
  ]

  const entitiesTableActions: Action<EntityRowData>[] = [
    {
      icon: () => <EditIcon />,
      tooltip: "Edit Entity",
      onClick: (event: any, data: any) => handleEditEntityOpen(data),
      position: "row",
    },
    {
      icon: () => <DeleteOutline />,
      tooltip: "Delete Selected Entities",
      onClick: (event: any, data: any) => handleOpenDeleteDialog(data),
    },
  ]

  return (
    <div className={styles.wrapper}>
      <div>
        <Typography
          variant="h2-regular"
          tooltip="Get All Entities Data"
          tooltipPlacement="right"
          tooltipIconSize={22}
          gutterBottom
          variantColor={2}
        >
          Entities & Regions
        </Typography>
      </div>
      <div className={styles.header}>
        <Search
          handleSearch={handleSearch}
          searchValue={query.searchVal}
          placeholder="Search by entity name"
          size={235}
        />
        <CameraSearch
          setSelectedCamera={(camera) => setQuery({ selectedCamera: camera })}
          selectedCamera={query.selectedCamera as number}
        />
      </div>
      <div className={styles.verticalMargin} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={styles.tabsAndButtons}>
          <Tabs value={query.activeTab} className={styles.tabs}>
            {/*Grouping Tabs */}
            {tabsInfo.map((tab) => (
              <Tab
                icon={() => tab.icon}
                label={tab.label}
                onClick={() => handleTabChange(tab.value as AvailableEntities)}
                value={tab.value}
                selected={query.activeTab === tab.value}
                id={`${tab.value}_tab`}
              />
            ))}
          </Tabs>
          <div className={styles.tableButtons}>
            <Button
              variant="secondary"
              className={styles.exportButton}
              onClick={() => handleExportCSV()}
              startIcon={<GetAppIcon fontSize="small" />}
            >
              Export
            </Button>
            <Tooltip title={mobileScreen ? "You can only add entities from desktop." : ""} placement="top">
              <Button
                onClick={() => setIsDialogOpen(true)}
                variant="primary"
                disabled={mobileScreen}
                startIcon={<AddCircleIcon fontSize="small" />}
              >
                Add Entity
              </Button>
            </Tooltip>

            {isDialogOpen && (
              <EntitiesContextProvider
                activeTab={query.activeTab as AvailableEntities}
                setIsDialogOpen={setIsDialogOpen}
                handleClose={handleCloseDialog}
                isDialogOpen={isDialogOpen}
                editData={selectedEntity}
                isEdit={isEdit}
                key={selectedEntity?.id}
              >
                <EntitiesSetup />
              </EntitiesContextProvider>
            )}
          </div>
        </div>

        <EntitiesTable
          title="Entities Table"
          data={filteredTableData}
          height={sectionHeight}
          hasExport={false}
          isLoading={isLoading}
          columns={entitiesTableColumns}
          actions={entitiesTableActions}
          hasPagination
          pageSize={15}
          isEmpty={filteredTableData.length === 0 || !filteredTableData}
        />
      </div>
      <WarningDialog
        isOpen={openDeleteDialog}
        isLoading={isDeletingEntity}
        actionTitle="Delete"
        content="Be aware by deleting these cameras, this action can't be undone."
        onConfirm={handleDeleteEntity}
        onCancel={handleCloseDeleteDialog}
        dialogTitle={`You're about to delete ${selectedEntityToDelete?.name} entity`}
        shouldLockWhileLoading
      />
    </div>
  )
}

export default EntitiesAndRegions
