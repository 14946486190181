import { useState, useEffect, Fragment, useRef } from "react"

import GetAppIcon from "@mui/icons-material/GetApp"

import { Button, DateRangePicker, Typography } from "@synapse-analytics/synapse-ui"
import intervalToDuration from "date-fns/intervalToDuration"
import { useQueryParam, withDefault } from "use-query-params"

import AnalyticsTargetsFilter from "../../components/AnalyticsTargetsFilter"
import { useDateQuery } from "../../hooks/useDateQuery"
import { exportGraphs } from "../../utils/genericHelpers"
import { StaffParam } from "../../utils/queryParamCustomConfigs"
import CorridorStatistics from "./components/CorridorStatistics"

import styles from "./Corridors.module.scss"

const Corridors = () => {
  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()
  const [analyticsTarget, setAnalyticsTarget] = useQueryParam("target", withDefault(StaffParam, "visitors"))
  const staffFilterValue = analyticsTarget === "visitors" ? false : analyticsTarget === "staff" ? true : null

  const [timeGrain, setTimeGrain] = useState<"hour" | "day" | null>("day")
  const [interval, setInterval] = useState<Duration>()
  const cardsRefs = {
    corridorsPerformanceRef: useRef(),
    corridorOverTimeRef: useRef(),
    corridorBreakdown: useRef(),
  }

  useEffect(() => {
    if (endDate !== null && startDate !== null) {
      const interval = intervalToDuration({
        start: startDate!.toDate(),
        end: endDate!.toDate(),
      })
      setInterval(interval)
      if (interval.days! > 1 || interval.months! >= 1) {
        setTimeGrain("day")
      } else {
        setTimeGrain("hour")
      }
    }
  }, [startDate, endDate, timeGrain])

  return (
    <Fragment>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Typography
            variant="h2-regular"
            tooltip="All you need to know about corridors analytics ."
            tooltipPlacement="right"
            tooltipIconSize={22}
            variantColor={2}
          >
            Corridors
          </Typography>
          <Button
            startIcon={<GetAppIcon fontSize="small" />}
            variant="secondary"
            onClick={() => exportGraphs(cardsRefs, startDate, endDate, "Corridor")}
            disabled={!startDate || !endDate}
          >
            Export
          </Button>
        </div>
        <div className={styles.filters}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            disableFuture
          />
          <AnalyticsTargetsFilter value={analyticsTarget} setValue={(target) => setAnalyticsTarget(target)} />
        </div>
        <CorridorStatistics
          startDate={startDate}
          endDate={endDate}
          interval={interval!}
          timeGrain={timeGrain!}
          refs={cardsRefs}
          staffFilterValue={staffFilterValue}
        />
      </div>
    </Fragment>
  )
}
export default Corridors
