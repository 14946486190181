import React, { FC } from "react"

import { Paper } from "@mui/material"

import MaterialTable, { MTableToolbar } from "@material-table/core"
import { Typography } from "@synapse-analytics/synapse-ui"

import EmptyPlaceholder from "../../assets/leaderboard/emptyState.svg?react"
import { TableColumn } from "../../types/Custom/Types"

import styles from "./LeaderBoardTable.module.scss"

interface Props {
  title: string
  data: any[]
  columns: TableColumn[]
  isLoading: boolean
  uniqueRowId?: number
  isEmpty?: boolean
}
const EmptyState = () => {
  return (
    <div className={styles.leaderBoardPlaceholderWrapper}>
      <EmptyPlaceholder className={styles.leaderBoardPlaceholder} />
      <div>
        <Typography variant="h2-regular" variantColor={2} align="center">
          No logs yet
        </Typography>
      </div>
      <Typography variantColor={2} variant="label-regular" align="center">
        When counts come in, the leaderboard
        <br />
        will be automatically generated then.
      </Typography>
    </div>
  )
}
const LeaderBoard: FC<Props> = ({ title, data, columns, isLoading, uniqueRowId, isEmpty }) => {
  if (isEmpty) {
    return <EmptyState />
  }

  return (
    <MaterialTable
      isLoading={isLoading}
      title={title}
      columns={columns!}
      actions={[]}
      data={data}
      options={{
        padding: "default",
        maxBodyHeight: "auto",
        defaultExpanded: () => false,
        paging: false,
        pageSize: 10,
        exportAllData: false,
        draggable: false,
        search: false,
        sorting: false,
        maxColumnSort: 0,
        selection: false,
        actionsColumnIndex: -1,
        showTitle: false,
        toolbar: false,
        headerStyle: {
          backgroundColor: "var(--tertiary-background-hover)",
          color: "var(--base-text-1)",
          fontSize: 12,
          fontWeight: 700,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        rowStyle: (_rowData, index) => {
          if (index % 2) {
            return {
              padding: "8px 19px !important",
              backgroundColor: "var(--base-background-1)",
              borderRadius: "4px 4px 4px 4px",
              color: "var(--base-text-1)",
              fontSize: "14px !important",
              fontWeight: 400,
            }
          } else {
            return {
              padding: "8px 19px !important",
              backgroundColor: "var(--base-background-3)",
              borderRadius: "4px 4px 4px 4px",
              color: "var(--base-text-1)",
              fontSize: "14px !important",
              fontWeight: 400,
            }
          }
        },
      }}
      localization={{
        header: {
          actions: "",
        },
        body: {
          emptyDataSourceMessage: <EmptyState />,
        },
      }}
      components={{
        Toolbar: (props) => (
          <div
            style={{
              height: "0px",
            }}
          >
            <MTableToolbar {...props} />
          </div>
        ),
        Container: (props) => (
          <Paper
            {...props}
            elevation={0}
            className={styles.table}
            style={{
              border: !uniqueRowId ? 0 : "",
            }}
          />
        ),
      }}
    />
  )
}

export default LeaderBoard
