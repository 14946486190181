import React, { FC, ReactElement } from "react"

import { Button } from "@mui/material"

import styles from "./ButtonSwitch.module.scss"

interface Props {
  activePage: number
  pages: string[]
  liveHistory?: boolean
  handleSelectButton?: (index: number) => void
  disabled?: boolean
  pagesIcons: ReactElement[]
}

const ButtonSwitch: FC<Props> = ({ activePage, pages, liveHistory, handleSelectButton, disabled, pagesIcons }) => {
  return (
    <div className={styles.toggleButtonsWrapper}>
      {pages &&
        pages?.map((page, i) => (
          <Button
            key={i}
            disabled={disabled}
            className={`${styles.toggleButton} ${activePage !== i ? styles.inActiveButton : styles.activeButton}`}
            onClick={() => pages.length > 1 && handleSelectButton!(i)}
          >
            {i === 0 && activePage === 0 && liveHistory && (
              <div className={styles.ringContainer}>
                <div className={styles.ringing}></div>
                <div className={styles.circle}></div>
              </div>
            )}
            {(i === 0 && activePage === 1 && liveHistory) ||
              (i === 0 &&
                !liveHistory &&
                React.cloneElement(pagesIcons[0], {
                  className: styles.icon,
                }))}
            {i > 0 &&
              React.cloneElement(pagesIcons[i], {
                className: styles.icon,
              })}
            {page}
          </Button>
        ))}
    </div>
  )
}

export default ButtonSwitch
