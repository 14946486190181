import { useEffect, FC, useRef, useState } from "react"

import { Button } from "@synapse-analytics/synapse-ui"

import NoCamera from "../assets/NoCamera.svg"
import { areasColors } from "../utils/EntitiesSetupUtils"

import styles from "./RegionsCanvas.module.scss"

interface Props {
  imageSrc?: string
  regions?: { state: 0 | 1 | 2 | 8; points: { x: number; y: number }[] }[]
  handleClick?: () => void
  width?: number
  height: number
}
const RegionsCanvas: FC<Props> = ({ width, height, imageSrc, regions, handleClick }) => {
  const [isHovering, setIsHovering] = useState(false)

  const ref = useRef<HTMLCanvasElement>(null)

  const handleMouseEnter = () => setIsHovering(true)
  const handleMouseLeave = () => setIsHovering(false)

  // useEffect to draw the provided image onto the canvas and render regions with specified points and states
  useEffect(() => {
    // Create a new Image object
    const image = new Image()
    const noCamera = new Image()
    noCamera.src = NoCamera

    // Set the source of the image to the provided imageSrc
    image.src = imageSrc || ""

    // When the image is loaded, perform the following actions
    image.onload = () => {
      // Get the canvas element from the ref
      const canvas = ref.current

      // Get the 2D rendering context of the canvas
      const ctx = canvas?.getContext("2d")

      // If the rendering context exists
      if (ctx) {
        // Draw the loaded image onto the canvas
        ctx.drawImage(image, 0, 0, canvas!.width, canvas!.height)

        // Set the stroke style for drawing regions
        ctx.strokeStyle = "var(--base-text-1)"

        // Set the line width for drawing regions
        ctx.lineWidth = 0.6

        if (regions) {
          // Iterate over each region
          regions.forEach((region) => {
            // Determine the fill style based on the region state
            const fillStyle = areasColors[region.state as 0 | 1 | 2 | 8]

            // Set the fill style
            ctx.fillStyle = fillStyle

            // Begin drawing a new path
            ctx.beginPath()

            // Move to the first point of the region
            ctx.moveTo(region.points[0].x, region.points[0].y)

            // Iterate over each point of the region
            for (let i = 0; i < region.points.length; i++) {
              // Draw lines to connect the points
              ctx.lineTo(region.points[i].x, region.points[i].y)
            }

            // Close the path
            ctx.closePath()

            // Stroke the path
            ctx.stroke()

            // Fill the path
            ctx.fill()
          })
        }
      }
    }

    // If the image fails to load, display "No frame available" text
    image.onerror = () => {
      const canvas = ref.current
      const ctx = canvas?.getContext("2d")

      if (ctx) {
        // Clear the canvas
        ctx.clearRect(0, 0, canvas!.width, canvas!.height)

        // Set text properties
        ctx.fillStyle = "var(--base-text-2)"
        ctx.font = "14px Inter"
        ctx.textAlign = "center"
        ctx.textBaseline = "middle"

        // Draw the NoCamera image in the center of the canvas
        ctx.drawImage(noCamera, canvas!.width / 2 - 35, canvas!.height / 2 - 40, noCamera.width, noCamera.height)

        // Draw the text in the center of the canvas
        ctx.fillText("No frame available", canvas!.width / 2, canvas!.height / 2 + 20)
      }
    }
  }, [imageSrc, regions, ref])

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={styles.wrapper}>
      {isHovering && !!handleClick && (
        <div className={styles.backdrop}>
          <Button variant="secondary" onClick={handleClick}>
            Preview
          </Button>
        </div>
      )}
      <canvas ref={ref} className={styles.drawingBoard} width={width || "100%"} height={height} />
    </div>
  )
}
export default RegionsCanvas
