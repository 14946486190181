import { FC, memo } from "react"

import GetAppIcon from "@mui/icons-material/GetApp"
import { Paper } from "@mui/material"

import MaterialTable, { Action, MTableToolbar } from "@material-table/core"
import { Button, Typography } from "@synapse-analytics/synapse-ui"

import EmptyPlaceholder from "../../assets/noListData.svg?react"
import { TableColumn } from "../../types/Custom/Types"
import { exportTableDataToCSV } from "../../utils/genericHelpers"
import CustomPagination from "./components/CustomPagination"

import styles from "./GenericTable.module.scss"

const MemoizedCustomPagination = memo(CustomPagination)

/**
 * Interface for the properties of the GenericTable component.
 */
interface Props {
  /**
   * The title of the table.
   */
  title?: string
  /**
   * The data to be displayed in the table.
   */
  data?: any[]
  /**
   * The columns of the table.
   */
  columns: TableColumn[]
  /**
   * The actions available for each row.
   */
  actions?: Action<any>[]
  /**
   * Indicates if the data is currently loading.
   */
  isLoading: boolean
  /**
   * Indicates if the table is empty.
   */
  isEmpty?: boolean
  /**
   * The height of the table.
   */
  height?: number | string
  /**
   * The vertical margin of the table.
   */
  verticalMargin?: number
  /**
   * Indicates if the export functionality is available.
   */
  hasExport?: boolean
  /**
   * The page size if pagination is enabled.
   */
  pageSize?: number
  /**
   * Indicates if the pagination functionality is available.
   */
  hasPagination?: boolean
  /**
   * The unique row id.
   */
  uniqueRowId?: number
}

/**
 * The GenericTable component displays a Material-UI table with customizable columns, actions, and export functionality.
 *
 * @param {Props} props - The properties of the GenericTable component.
 * @returns The GenericTable component.
 */
const GenericTable: FC<Props> = ({
  columns,
  title,
  data = [],
  isLoading,
  height,
  verticalMargin,
  actions = [],
  hasExport = true,
  hasPagination,
  pageSize,
  uniqueRowId,
}: Props) => {
  const EmptyState = () => {
    return (
      <div
        className={styles.emptyStateWrapper}
        style={{
          height: (typeof height === "number" ? height : 300) - 60,
        }}
      >
        <EmptyPlaceholder className={styles.emptyState} />
        <div>
          <Typography variant="h2-regular" variantColor={2} align="center" textTransform="capitalize">
            No data yet
          </Typography>
        </div>
      </div>
    )
  }
  return (
    <MaterialTable
      isLoading={isLoading}
      title={title}
      columns={[
        ...columns,
        // this column is to reserve space for the export button to have a column itself
        hasExport
          ? {
              align: "right",
              title: "",
              field: "",
              searchable: false,
              width: 10,
            }
          : {},
      ]}
      actions={actions}
      data={data}
      options={{
        padding: "default",
        maxBodyHeight: "auto",
        defaultExpanded: () => false,
        paging: hasPagination,
        pageSize: pageSize ?? 10,
        exportAllData: true,
        draggable: false,
        search: false,
        sorting: false,
        maxColumnSort: 0,
        selection: false,
        actionsColumnIndex: -1,
        showTitle: false,
        toolbar: hasExport,
        headerStyle: {
          backgroundColor: "var(--base-background-3)",
          color: "var(--base-text-1)",
          fontSize: 14,
          fontWeight: 700,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          borderBottom: "1px solid var(--base-border-1)",
          padding: "8px 12px",
          textTransform: "capitalize",
        },
        rowStyle: (rowData, index) => {
          if (rowData.id && rowData.id === uniqueRowId && data) {
            return {
              backgroundColor: "var(--blue-background-2)",
              color: "var(--base-text-1)",
              fontSize: 16,
              fontWeight: 700,
              whiteSpace: "pre",
            }
          }
          if (index % 2) {
            return {
              padding: "8px 12px !important",
              backgroundColor: "var(--base-background-2)",
              borderRadius: "0px",
              color: "var(--base-text-1)",
              fontSize: "14px !important",
              fontWeight: 400,
              whiteSpace: "pre",
            }
          } else {
            return {
              padding: "8px 12px !important",
              backgroundColor: "var(--base-background-3)",
              borderRadius: "0px",
              color: "var(--base-text-1)",
              fontSize: "14px !important",
              fontWeight: 400,
              whiteSpace: "pre",
            }
          }
        },
      }}
      localization={{
        header: {
          actions: "Action",
        },
        body: {
          emptyDataSourceMessage: <EmptyState />,
        },
      }}
      components={{
        Toolbar: (props) => (
          <div className={styles.toolbar}>
            <MTableToolbar {...props} />
            {hasExport && (
              <Button
                variant="secondary"
                startIcon={<GetAppIcon fontSize="small" />}
                onClick={() => exportTableDataToCSV(columns, data, title)}
              />
            )}
          </div>
        ),
        Pagination: (props) => (
          <MemoizedCustomPagination
            variant="simple"
            count={props.count ?? data?.length ?? 0}
            page={props.page ?? 0}
            onPageChange={props.onPageChange}
            rowsPerPage={props.rowsPerPage}
            setRowsPerPage={props.onRowsPerPageChange}
            hideRowCount
            enableDebounce={false}
          />
        ),
        Container: (props) => (
          <Paper
            {...props}
            elevation={0}
            sx={{
              height: height ? height : 585,
              marginTop: verticalMargin ?? undefined,
            }}
            className={styles.table}
          />
        ),
      }}
    />
  )
}
export default GenericTable
