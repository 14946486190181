import React, { useState, useEffect, FC } from "react"
import { useQuery } from "react-query"

import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import { CircularProgress } from "@mui/material"

import { Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { format } from "date-fns"
import moment from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import { ArcProgress } from "../../../components/ArcProgress"
import { definitions } from "../../../types/Generated/apiTypes"
import HealthError from "../assets/healthError.svg?react"

import styles from "./CameraHealth.module.scss"

type CameraHealthIndication = definitions["CameraHealth"]

const getStatusIcon = (isSuccessful?: boolean) => {
  if (isSuccessful)
    return (
      <CheckIcon
        fontSize="small"
        sx={{
          color: "var(--green-background-1)",
        }}
      />
    )
  return (
    <CloseIcon
      fontSize="small"
      sx={{
        color: "var(--red-background-1)",
      }}
    />
  )
}

interface Props {
  cameraId: number
}

const CameraHealth: FC<Props> = ({ cameraId }) => {
  const [healthyPercentage, setHealthyPercentage] = useState<number>(0)

  const { data: cameraHealth, isLoading: cameraHealthLoading } = useQuery<CameraHealthIndication>(
    ["fetchCameraHealth", cameraId],
    ({ queryKey }) => VisionAPI.fetchCameraHealth(queryKey[1] as number),
    {
      // refetch every 1 minute
      refetchInterval: 60000,
    }
  )

  // set camera health percentage on camera health change
  useEffect(() => {
    if (!cameraHealthLoading && cameraHealth) {
      setHealthyPercentage(cameraHealth.health * 100)
    }
  }, [cameraHealth, cameraHealthLoading])

  return (
    <div className={styles.wrapper}>
      {cameraHealthLoading ? (
        <CircularProgress size={25} className={styles.loading} />
      ) : cameraHealth ? (
        <div className={styles.wrapper}>
          <ArcProgress value={healthyPercentage} size="170px" label="Camera Health" />
          <div className={styles.testWrapper}>
            <div className={styles.test}>
              {getStatusIcon(cameraHealth?.ping_check)}
              <Typography variant="label-regular" variantColor={2}>
                Ping test
              </Typography>
            </div>
            <div className={styles.test}>
              {getStatusIcon(cameraHealth?.stream_check)}
              <Typography variant="label-regular" variantColor={2}>
                Stream test
              </Typography>
            </div>
          </div>
          {cameraHealth?.updated_at && (
            <Tooltip title={format(new Date(cameraHealth?.updated_at), "dd/MM/yyyy, p")} placement="right">
              <Typography variant="span-regular" variantColor={2} noWrap>
                Last update <span style={{ fontWeight: 600 }}>{moment(cameraHealth?.updated_at).fromNow()}</span>
              </Typography>
            </Tooltip>
          )}
        </div>
      ) : (
        <div className={styles.wrapper}>
          <HealthError />
          <Typography
            variant="label-regular"
            style={{
              margin: "12px 0px 4px 0px",
            }}
            variantColor={2}
            color="negative"
            gutterBottom
          >
            Error Loading
          </Typography>
          <Typography variant="label-regular" variantColor={2} color="negative">
            Camera Health
          </Typography>
        </div>
      )}
    </div>
  )
}

export default CameraHealth
