import moment from "moment"

export function calculateDailyLogs(heatmapLogs) {
  const result = []
  if (heatmapLogs && heatmapLogs.length > 0) {
    result.length = 31
    let previousDay = -1
    heatmapLogs.forEach((elem) => {
      if (new Date(elem.timestamp).getDate() - 1 === previousDay) {
        let tmp = result[previousDay].logs.concat(elem.logs)
        result[previousDay].logs = tmp
      } else {
        const obj = { logs: elem.logs, timestamp: elem.timestamp, version_no: elem.version_no }
        const emptyObj = { logs: [], timestamp: "", version_no: -1 }
        previousDay = new Date(elem.timestamp).getDate() - 1
        if (!result[previousDay]) {
          result[previousDay] = obj
        } else {
          result[previousDay] = emptyObj
        }
      }
    })
    for (let i = 0; i < result.length; i++) {
      const obj = { logs: [], timestamp: "", version_no: -1 }
      if (!result[i]) {
        result[i] = obj
      }
    }
    if (result.length < 31) {
      for (let i = result.length - 1; i < 31; i++) {
        const obj = { logs: [], timestamp: "", version_no: -1 }
        result[i] = obj
      }
    }
  }
  return result
}

export function calculateHourlyLogs(heatmapLogs) {
  // Initialize an array with 24 objects, one for each hour of the day
  const result = Array.from({ length: 24 }, () => ({
    logs: [], // Array to hold logs for the hour
    timestamp: "", // Placeholder for a representative timestamp
    version_no: -1, // Placeholder for version number
  }))

  // Check if heatmapLogs is not empty
  if (heatmapLogs && heatmapLogs.length > 0) {
    // Iterate through each log entry
    heatmapLogs.forEach((elem) => {
      // Extract the hour from the timestamp
      const hour = new Date(elem.timestamp).getHours()

      // Iterate through each log in the current log entry
      elem.logs.forEach((log) => {
        // Check if there is an existing log with the same x, y, and floor_version_no
        const existingLog = result[hour].logs.find(
          (existing) =>
            existing.x === log.x && existing.y === log.y && existing.floor_version_no === log.floor_version_no
        )

        if (existingLog) {
          // If an existing log is found, aggregate the values
          existingLog.value += log.value
        } else {
          // If no existing log is found, add the new log
          result[hour].logs.push({ ...log })
        }
      })

      // Set a representative timestamp and version number for the hour if not already set
      if (!result[hour].timestamp) {
        result[hour].timestamp = elem.timestamp
        result[hour].version_no = elem.version_no
      }
    })
  }

  // Return the result array containing aggregated logs per hour
  return result
}

export function updateSliderMarks(marks, allData, timeGrain) {
  if (allData && allData.length > 0) {
    // hourly
    if (timeGrain === "hourly") {
      for (let k = 0; k < marks.length; k++) {
        let tmpDate = new Date(allData[k]?.timestamp)
        marks[k].label = moment(tmpDate).format("h A")
      }
    }
    // Other time grains
    else {
      for (let k = 0; k < marks.length; k++) {
        let tmpDate = new Date(allData[k]?.timestamp)
        marks[k].label = !isNaN(tmpDate) && `${moment(tmpDate).format("D MMM")}`
      }
    }
  }
}

export const DynamicHeatmapMarks = (range) => {
  const marks = []
  for (let i = 0; i < range; i++) {
    marks.push({ value: i, label: "" })
  }
  return marks
}

export const dayMarks = [
  {
    value: 0,
    label: "",
  },
  {
    value: 1,
    label: "",
  },
  {
    value: 2,
    label: "",
  },
  {
    value: 3,
    label: "",
  },
  {
    value: 4,
    label: "",
  },
  {
    value: 5,
    label: "",
  },
  {
    value: 6,
    label: "",
  },
  {
    value: 7,
    label: "",
  },
  {
    value: 8,
    label: "",
  },
  {
    value: 9,
    label: "",
  },
  {
    value: 10,
    label: "",
  },
  {
    value: 11,
    label: "",
  },
  {
    value: 12,
    label: "",
  },
  {
    value: 13,
    label: "",
  },
  {
    value: 14,
    label: "",
  },
  {
    value: 15,
    label: "",
  },
  {
    value: 16,
    label: "",
  },
  {
    value: 17,
    label: "",
  },
  {
    value: 18,
    label: "",
  },
  {
    value: 19,
    label: "",
  },
  {
    value: 20,
    label: "",
  },
  {
    value: 21,
    label: "",
  },
  {
    value: 22,
    label: "",
  },
  {
    value: 23,
    label: "",
  },
]

export function calculateWeeklyDataPerTime(heatmapLogs) {
  const result = []
  if (heatmapLogs && heatmapLogs.length > 0) {
    result.length = 7
    let previousDay = -1
    heatmapLogs.forEach((elem) => {
      if (new Date(elem.timestamp).getDay() === previousDay) {
        let tmp = result[previousDay].logs.concat(elem.logs)
        result[previousDay].logs = tmp
      } else {
        const obj = { logs: elem.logs, timestamp: elem.timestamp }
        previousDay = new Date(elem.timestamp).getDay()
        if (!result[previousDay]) {
          result[previousDay] = obj
        }
      }
    })
    for (let i = 0; i < result.length; i++) {
      const obj = { logs: [], timestamp: "", version_no: -1 }
      if (!result[i]) {
        result[i] = obj
      }
    }
  }
  return result
}
