import React, { useContext, useState, Fragment } from "react"

import { Typography } from "@synapse-analytics/synapse-ui"
import { DateRangePicker } from "@synapse-analytics/synapse-ui"
import moment, { Moment } from "moment"

import Search from "../../components/Search"
import { useDateQuery } from "../../hooks/useDateQuery"
import { CampaignContext } from "./CampaignContext/CampaignContext"
import CampaignNewEdit from "./CampaignCreate/CampaignNewEdit"
import CampaignList from "./components/CampaignList"

import styles from "./CampaignPlanner.module.scss"

const CampaignPlanner = () => {
  const { formik, fetchSearchedCampaigns, fetchCampaignListStatus, handleSearch, debouncedSearchValue, searchValue } =
    useContext(CampaignContext)

  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleStartDateChange = (startDate: Moment | null) => {
    formik?.setFieldValue("startDateFilter", startDate)
    setStartDate(startDate)
  }
  const handleEndDateChange = (endDate: Moment | null) => {
    formik?.setFieldValue("endDateFilter", endDate)
    setEndDate(endDate)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const getLoadingState = (loading: boolean) => {
    setLoading(loading)
  }

  const yearBeforeLast = (date: moment.Moment): boolean => {
    return moment().subtract(365, "days") < date && date < moment()
  }
  const yearAfterNext = (date: moment.Moment): boolean => {
    return moment().add(365, "days") > date && date > moment()
  }

  return (
    <Fragment>
      <Typography
        variant="h2-regular"
        tooltip="Create Campaign and compare between campaigns"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        Campaign List
      </Typography>
      <div className={styles.searchFilter}>
        <Search loading={loading} placeholder="E.g.EVENT" handleSearch={handleSearch} searchValue={searchValue} />

        <div className={styles.dateRange}>
          <DateRangePicker
            startDate={startDate}
            disabled={loading}
            endDate={endDate}
            onStartDateChange={handleStartDateChange}
            onEndDateChange={handleEndDateChange}
            isOutsideRange={(day) => yearAfterNext(day) || yearBeforeLast(day)}
            disableFuture={false}
          />
        </div>
      </div>

      {/* Campaign list */}
      {debouncedSearchValue === "" ? (
        <div>
          {/* In progress campaigns */}
          <CampaignList
            getLoadingState={getLoadingState}
            handleOpen={handleOpen}
            title="In progress Campaigns"
            fetchKey="fetchCampaignListProgress"
            fetchRequest={fetchCampaignListStatus}
            status="inprogress"
          />

          {/* upcoming campaigns*/}
          <CampaignList
            getLoadingState={getLoadingState}
            title="Upcoming Campaigns"
            fetchKey="fetchCampaignListUpcoming"
            fetchRequest={fetchCampaignListStatus}
            status="upcoming"
          />

          {/* Past campaigns */}
          <CampaignList
            getLoadingState={getLoadingState}
            title="Past Campaigns"
            fetchKey="fetchCampaignListPast"
            fetchRequest={fetchCampaignListStatus}
            status="past"
          />
        </div>
      ) : (
        <div className={styles.listWrapper}>
          {/* Searched campaigns */}
          <CampaignList
            getLoadingState={getLoadingState}
            title={`Search for "${debouncedSearchValue}"`}
            fetchKey="fetchSearchedCampaigns"
            fetchRequest={fetchSearchedCampaigns}
          />
        </div>
      )}

      {/* create/edit campaign */}
      <CampaignNewEdit handleClose={handleClose} open={open} />
    </Fragment>
  )
}

export default CampaignPlanner
