import { FC, useMemo, useState } from "react"
import { useQuery } from "react-query"

import { Grid } from "@mui/material"

import { Chip, DateRangePicker, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../API/VisionAPI"
import CheckboxesLineGraphCard from "../../components/GraphCards/CheckboxesLineGraphCard"
import PaginatedBarGraphCard from "../../components/GraphCards/PaginatedBarGraphCard"
import PieChartCard from "../../components/GraphCards/PieChartCard"
import { useDateQuery } from "../../hooks/useDateQuery"
import { useBranchesStore } from "../../store"
import { TableColumn } from "../../types/Custom/Types"
import { definitions } from "../../types/Generated/apiTypes"
import { convertDataToColoredLineGraphAndTable } from "../../utils/genericHelpers"

import styles from "./GenericOccupancy.module.scss"

type ZonesOccupancyAnalytics = definitions["ZonesOccupancyAnalytics"]
type SeatsOccupancyAnalytics = definitions["SeatsOccupancyAnalytics"]

const availableFilters = [
  { label: "maximum", tooltip: "The largest value in a set" },
  { label: "minimum", tooltip: "The smallest value in a set" },
  { label: "mean", tooltip: "The average of a set of numbers" },
  { label: "median", tooltip: "The middle value in a sorted list" },
]

type OccupancyType = "Staff" | "Seat"

/**
 * Props for the `GenericOccupancy` component.
 */
interface GenericOccupancyProps {
  /**
   * The type of occupancy to display. Determines the data and labels used.
   * - `"Staff"`: Displays staff occupancy analytics (seated, standing).
   * - `"Seat"`: Displays seat occupancy analytics (occupied, unoccupied).
   */
  occupancyType: OccupancyType
}

const distribution = {
  Staff: ["seated", "standing"],
  Seat: ["occupied", "unoccupied"],
}

/**
 * A reusable component for displaying occupancy analytics.
 * It supports both staff and seat occupancy, with dynamic data fetching and rendering.
 *
 * @param {GenericOccupancyProps} props - The component props.
 * @param {OccupancyType} props.occupancyType - The type of occupancy to display.
 * @returns {JSX.Element} The rendered component.
 */
const GenericOccupancy: FC<GenericOccupancyProps> = ({ occupancyType }: GenericOccupancyProps): JSX.Element => {
  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()

  const [selectedStat, setSelectedStat] = useState<"maximum" | "minimum" | "mean" | "median">("maximum")
  const [barSelection, setBarSelection] = useState<{
    type: string
    floor?: string
  }>({
    type: "Floors",
  })

  const [lineSelection, setLineSelection] = useState<{
    type: string
    floor?: string
    state: string
  }>({
    type: "Floors",
    // DEFAULT
    state: distribution[occupancyType][0],
  })

  const timeGrain = useMemo(() => {
    if (!startDate) return "day"
    const effectiveEndDate = endDate || moment()
    return moment(effectiveEndDate).diff(moment(startDate), "hours") >= 24 ? "day" : "hour"
  }, [startDate, endDate])

  const { data: occupancyAnalytics, isLoading: isOccupancyAnalyticsLoading } = useQuery<
    ZonesOccupancyAnalytics | SeatsOccupancyAnalytics
  >(
    [
      `fetch${occupancyType}OccupancyAnalytics`,
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      selectedStat,
      selectedBranch,
    ],
    ({ queryKey }) => {
      const params = {
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        stat: queryKey[3] as "maximum" | "minimum" | "mean" | "median",
        branch_id: queryKey[4] as number,
      }

      return occupancyType === "Staff"
        ? VisionAPI.fetchZonesOccupancyAnalytics(params)
        : VisionAPI.fetchSeatsOccupancyAnalytics(params)
    },
    {
      onSuccess: () => {
        setBarSelection({ type: "Floors" })
        setLineSelection({ type: "Floors", state: distribution[occupancyType][0] })
      },
      enabled: !!endDate && !!selectedBranch,
    }
  )

  const barGraphTableColumns: TableColumn[] = [
    {
      title: barSelection.type === "Floors" ? "Floor" : "Zone",
      field: barSelection.type === "Floors" ? "floor" : "zone",
      searchable: true,
    },
    ...distribution[occupancyType].map((type) => ({
      title: `${type} count`,
      field: type,
      searchable: false,
    })),
  ]

  const handleSelectBarType = (type: "Floors" | "Zones") => {
    if (type === "Floors") {
      setBarSelection({ type: "Floors" })
    } else {
      setBarSelection({
        type: "Zones",
        floor: (occupancyAnalytics as ZonesOccupancyAnalytics | SeatsOccupancyAnalytics)?.floors?.[0]?.floor,
      })
    }
  }

  const handleSelectLineType = (type: "Floors" | "Zones") => {
    if (type === "Floors") {
      setLineSelection({ type: "Floors", state: distribution[occupancyType][0] })
    } else {
      setLineSelection({
        type: "Zones",
        floor: (occupancyAnalytics as ZonesOccupancyAnalytics | SeatsOccupancyAnalytics)?.floors?.[0]?.floor,
        state: distribution[occupancyType][0],
      })
    }
  }

  const barGraphData = useMemo(() => {
    return barSelection.type === "Floors"
      ? (occupancyAnalytics as ZonesOccupancyAnalytics | SeatsOccupancyAnalytics)?.floors
      : (occupancyAnalytics as ZonesOccupancyAnalytics | SeatsOccupancyAnalytics)?.floors?.find(
          (floor) => floor.floor === barSelection.floor
        )?.zones
  }, [barSelection.floor, barSelection.type, occupancyAnalytics])

  const { graphData: lineGraphData, tableData: hourlyAvgTableCounts } = useMemo(
    () =>
      convertDataToColoredLineGraphAndTable({
        data:
          lineSelection.type === "Floors"
            ? (occupancyAnalytics as ZonesOccupancyAnalytics | SeatsOccupancyAnalytics)?.floors
            : (occupancyAnalytics as ZonesOccupancyAnalytics | SeatsOccupancyAnalytics)?.floors?.find(
                (floor) => floor.floor === lineSelection.floor
              )?.zones,
        indexByKey: lineSelection.type === "Floors" ? "floor" : "zone",
        logsKey: "logs",
        logTimeStampKey: "time_group",
        logCountKey: lineSelection.state,
        timestampFormatting: timeGrain === "day" ? "D MMM YYYY" : "h A",
        timeGrain: timeGrain,
      }),
    [lineSelection.type, lineSelection.state, lineSelection.floor, occupancyAnalytics, timeGrain]
  )

  const lineGraphTableColumns: TableColumn[] = [
    {
      title: timeGrain === "day" ? "Day" : "Hour",
      field: "time_group",
      searchable: true,
    },
    ...(lineSelection.type === "Zones"
      ? (occupancyAnalytics as ZonesOccupancyAnalytics | SeatsOccupancyAnalytics)?.floors
          ?.find((floor) => floor.floor === lineSelection.floor)
          ?.zones.map((zone) => ({
            title: zone.zone,
            field: zone.zone,
            searchable: false,
          })) ?? []
      : (occupancyAnalytics as ZonesOccupancyAnalytics | SeatsOccupancyAnalytics)?.floors?.map((floor) => ({
          title: floor.floor,
          field: floor.floor,
          searchable: false,
        })) ?? []),
  ]

  const pieChartData = useMemo(() => {
    if (occupancyType === "Staff") {
      const staffData = occupancyAnalytics as ZonesOccupancyAnalytics
      return [
        {
          id: "Seated",
          label: "Seated",
          value: +(staffData?.seated || 0),
          color: "var(--blue-background-2)",
        },
        {
          id: "Standing",
          label: "Standing",
          value: +(staffData?.standing || 0),
          color: "var(--gray-background-2)",
        },
      ]
    } else {
      const seatData = occupancyAnalytics as SeatsOccupancyAnalytics
      return [
        {
          id: "Occupied",
          label: "Occupied",
          value: +(seatData?.occupied || 0),
          color: "var(--blue-background-2)",
        },
        {
          id: "Unoccupied",
          label: "Unoccupied",
          value: +(seatData?.unoccupied || 0),
          color: "var(--gray-background-2)",
        },
      ]
    }
  }, [occupancyAnalytics, occupancyType])

  return (
    <div className={styles.container}>
      <Typography
        variant="h2-regular"
        tooltip={`Comprehensive analytics dashboard showcasing ${occupancyType} occupancy analytics.`}
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
        textTransform="capitalize"
      >
        {occupancyType} Occupancy
      </Typography>
      <div className={styles.header}>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          disabled={isOccupancyAnalyticsLoading}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          disableFuture
        />
        <div className={styles.filters}>
          {availableFilters.map((filter, index) => (
            <Tooltip title={filter.tooltip} variant="secondary" key={index}>
              <Chip
                clickable
                onClick={() => setSelectedStat(filter.label as "maximum" | "minimum" | "mean" | "median")}
                isSelected={selectedStat === filter.label}
                size="default"
                className={styles.filter}
                tabIndex={index}
              >
                {filter.label}
              </Chip>
            </Tooltip>
          ))}
        </div>
      </div>
      <Typography variant="h3-bold" variantColor={2}>
        Stats
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <PieChartCard
            title={`Total ${occupancyType} occupancy`}
            data={pieChartData}
            isLoading={isOccupancyAnalyticsLoading}
            pieChartValue={+(occupancyAnalytics?.total || 0)}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <PaginatedBarGraphCard
            data={barGraphData}
            isLoading={isOccupancyAnalyticsLoading}
            startDate={startDate?.format("YYYY-MM-DD")}
            endDate={endDate?.format("YYYY-MM-DD")}
            contentHeight={400}
            graphProps={{
              indexBy: barSelection.type === "Floors" ? "floor" : "zone",
              isPaginated: true,
              keys: [...distribution[occupancyType]],
              colors: {
                [distribution[occupancyType][0]]: "var(--blue-background-2)",
                [distribution[occupancyType][1]]: "var(--gray-background-2)",
              },
            }}
            tableProps={{
              columns: barGraphTableColumns,
            }}
            title={`${occupancyType} counts`}
            dataTypeProps={{
              dataTypes: ["Floors", "Zones"],
              selectedDataType: barSelection.type,
              setDataType: handleSelectBarType,
            }}
            subFilterProps={
              barSelection.type === "Zones"
                ? {
                    selectedSubFilter: barSelection.floor,
                    subFilters: (occupancyAnalytics as ZonesOccupancyAnalytics | SeatsOccupancyAnalytics)?.floors?.map(
                      (floor) => floor.floor
                    ),
                    setSelectedSubFilter: (floor: string) => setBarSelection((prev) => ({ ...prev, floor })),
                  }
                : {}
            }
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxesLineGraphCard
            graphProps={{ data: lineGraphData }}
            tableProps={{
              columns: lineGraphTableColumns,
              data: hourlyAvgTableCounts,
            }}
            title={`${occupancyType} occupancy over time`}
            isLoading={isOccupancyAnalyticsLoading}
            timeGrain={timeGrain}
            dataTypeProps={{
              dataTypes: ["Floors", "Zones"],
              selectedDataType: lineSelection.type,
              setDataType: handleSelectLineType,
            }}
            subFilterSets={[
              {
                subFilters: [...distribution[occupancyType], "total"],
                selectedSubFilter: lineSelection.state,
                setSelectedSubFilter: (state: string) => setLineSelection((prev) => ({ ...prev, state })),
              },
              ...(lineSelection.type === "Zones"
                ? [
                    {
                      selectedSubFilter: lineSelection.floor,
                      subFilters: (
                        occupancyAnalytics as ZonesOccupancyAnalytics | SeatsOccupancyAnalytics
                      )?.floors?.map((floor) => floor.floor),
                      setSelectedSubFilter: (floor: string) => setLineSelection((prev) => ({ ...prev, floor })),
                    },
                  ]
                : []),
            ]}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default GenericOccupancy
