import { FC, Fragment, useMemo, useState } from "react"

import ArrowIcon from "@mui/icons-material/TrendingFlat"

import { Button, Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import ViewFrameDialog from "../../../components/ViewFrameDialog"
import GraphEmptyState from "../../../components/graphs/GraphEmptyState"
import GraphLoadingState from "../../../components/graphs/GraphLoadingState"
import { definitions } from "../../../types/Generated/apiTypes"
import CarImagePlaceholder from "../components/assets/CarImagePlaceholder.svg?react"

import styles from "./CarPath.module.scss"

type CarPaths = definitions["CarPaths"]

/**
 * Props for the CarPathsProps component.
 * @property pathData - An array of CarPath objects.
 * @property isLoading - A boolean indicating if the component is currently loading.
 */
interface CarPathsProps {
  /**
   * @property pathData - An array of CarPath objects.
   */
  pathData?: CarPaths
  /**
   * @property isLoading - A boolean indicating if the component is currently loading.
   */
  isLoading?: boolean
}

const MAX_PATHS_PER_ROW = 4

/**
 * The CarPathsProps component displays a graphical representation of car paths.
 * It can display a loading state, an empty state, or a list of car paths with images.
 *
 * @param props - The props for the component.
 * @returns The JSX element for the component.
 */
const CarPath: FC<CarPathsProps> = ({ pathData, isLoading }) => {
  const isEmpty = !pathData || pathData?.paths?.length === 0
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [hoveredFrame, setHoveredFrame] = useState<number | null>(null)

  /**
   * Deep clones the pathData and reverses the order of the paths if it exists.
   * This is done to display paths from oldest to newest.
   *
   * @returns The reversed paths array if pathData exists, otherwise undefined.
   */
  const paths: CarPaths["paths"] = useMemo(() => {
    if (!pathData) return undefined
    const clonedPathData = JSON.parse(JSON.stringify(pathData))
    return clonedPathData.paths.reverse()
  }, [pathData])

  return (
    <Fragment>
      {isLoading ? (
        <GraphLoadingState />
      ) : isEmpty ? (
        <GraphEmptyState />
      ) : (
        <div className={styles.wrapper}>
          <Typography variant="label-bold" variantColor={2}>
            {moment(pathData?.day).format("DD MMM YYYY")}
          </Typography>
          <div className={styles.pathsWrapper}>
            {paths?.map((path, index) => (
              <div key={path.timestamp} className={styles.pathAndArrow}>
                {/* This conditionally renders an ArrowIcon between car paths, except for the first path in every row (each row has MAX_PATHS_PER_ROW paths). */}
                {index > 0 && index % MAX_PATHS_PER_ROW !== 0 && <ArrowIcon className={styles.icon} />}
                <div className={styles.carPath}>
                  <div className={styles.carPathTitle}>
                    <Typography variant="span-bold" variantColor={2} className={styles.carPathIndex}>
                      {index + 1}
                    </Typography>
                    <div>
                      <Typography variant="span-bold" className={styles.cameraName} noWrap title={path.camera}>
                        {path.camera}
                      </Typography>
                      <Typography variant="span-regular" variantColor={2}>
                        {moment(path.timestamp).format("h:mm A")}
                      </Typography>
                    </div>
                  </div>
                  <div className={styles.carImageWrapper}>
                    {path.photo ? (
                      <div
                        onMouseEnter={() => setHoveredFrame(index)}
                        onMouseLeave={() => setHoveredFrame(null)}
                        className={styles.media}
                      >
                        {hoveredFrame === index && (
                          <div className={styles.backdrop}>
                            <Button variant="secondary" onClick={() => setIsDialogOpen(true)} size="small">
                              View
                            </Button>
                          </div>
                        )}
                        <img src={path.photo} className={styles.carImage} alt="car" />
                        <ViewFrameDialog
                          frame={path.photo}
                          handleCloseDialog={() => {
                            setHoveredFrame(null)
                            setIsDialogOpen(false)
                          }}
                          open={isDialogOpen && hoveredFrame === index}
                          name={`${path.camera} - ${moment(path.timestamp).format("DD MMM YYYY, h:mm A")}`}
                        />
                      </div>
                    ) : (
                      <div className={styles.noCarImage}>
                        <CarImagePlaceholder />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default CarPath
