import { useEffect, useRef, useState } from "react"
import useWebSocket from "react-use-websocket"

import { NotificationUtils } from "@synapse-analytics/synapse-ui"
import { v4 as uuidv4 } from "uuid"

import { storeApi, useBranchesStore } from "../store"
import { WsMessage } from "../types/Custom/Interfaces"

/**
 * Configuration for the WebSocket hook.
 */
interface WebSocketTaskConfig {
  taskName: string
  params: Record<string, unknown>
  notificationLabel?: string
  timeoutInterval?: number
  handleOnSuccess?: () => void
}

/**
 * Hook to manage WebSocket tasks.
 *
 * @param config Configuration object containing task details.
 * @returns WebSocket state and functions for managing the connection.
 */
export const useWebSocketTask = <T,>({
  taskName,
  params,
  notificationLabel = "",
  timeoutInterval = 60000,
  handleOnSuccess,
}: WebSocketTaskConfig) => {
  const [selectedBranch] = useBranchesStore((state) => [state.selectedBranch])

  const [apiWsProtocol, apiHostname, apiPort] = [
    storeApi.getState().apiWsProtocol,
    storeApi.getState().apiHostname,
    storeApi.getState().apiPort,
  ]

  const [shouldConnect, setShouldConnect] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [parsedMessage, setParsedMessage] = useState<WsMessage<T> | null>(null)

  const connectionTimer = useRef<NodeJS.Timeout | null>(null)

  const wsUrl = `${apiWsProtocol}//${apiHostname}:${apiPort}/ws/node/trigger-task/${selectedBranch}/`

  const { sendJsonMessage, readyState } = useWebSocket(
    wsUrl,
    {
      onError: () => {
        NotificationUtils.toast("WebSocket connection error", { severity: "warning" })
        setIsLoading(false)
        setShouldConnect(false)
      },
      onMessage: (event) => {
        try {
          const message = JSON.parse(event.data) as WsMessage<T>
          setParsedMessage(message)
          setIsLoading(false)

          if (message.success) {
            NotificationUtils.toast(`${notificationLabel} task completed successfully`, { severity: "success" })
            handleOnSuccess?.()
          } else if (message.error) {
            NotificationUtils.toast(`Error: ${message.error}`, { severity: "error" })
          }
        } catch (error) {
          console.error("Failed to parse WebSocket message:", error)
        }

        setShouldConnect(false)
        if (connectionTimer.current) {
          clearTimeout(connectionTimer.current)
        }
      },
      onClose: () => {
        if (connectionTimer.current) {
          clearTimeout(connectionTimer.current)
        }
        setIsLoading(false)
      },
    },
    shouldConnect
  )

  /**
   * Sends a WebSocket task request.
   */
  const triggerTask = () => {
    setParsedMessage(null)
    setShouldConnect(true)
    setIsLoading(true)

    sendJsonMessage({
      task_uuid: uuidv4(),
      task: taskName,
      params,
    })

    connectionTimer.current = setTimeout(() => {
      setShouldConnect(false)
      setIsLoading(false)
      NotificationUtils.toast("Connection timed out, please try again!", { severity: "warning" })
    }, timeoutInterval)
  }

  /**
   * Cleanup effect: Ensures the WebSocket connection is closed when the component unmounts.
   * This does more than just reset the `shouldConnect` state;
   * it also triggers a proper close handshake with the server.
   */
  useEffect(() => {
    return () => setShouldConnect(false)
  }, [])

  return {
    isLoading,
    parsedMessage,
    triggerTask,
    readyState,
  }
}
