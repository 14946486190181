import { FC, useState, useEffect, ChangeEvent, useContext, Fragment } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"

import CompareIcon from "@mui/icons-material/BarChart"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import CloseIcon from "@mui/icons-material/Close"
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined"
import EditIcon from "@mui/icons-material/EditOutlined"
import { Paper, Grid } from "@mui/material"

import { Typography, Select, InputChangeEvent, NotificationUtils, Tag } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import ActionButton from "../../../components/Buttons/ActionButton"
import { routes } from "../../../routes/routes"
import { definitions } from "../../../types/Generated/apiTypes"
import { CampaignContext } from "../CampaignContext/CampaignContext"
import CampaignNewEdit from "../CampaignCreate/CampaignNewEdit"
import CampaignGraph from "../components/CampaignGraph"
import CampaignPopup from "../components/CampaignPopup"

import styles from "./CampaignGeneral.module.scss"

type CampaignDetail = definitions["CampaignDetail"]

interface Props {
  campaign: CampaignDetail
  chosenCampaign: boolean
}

const CampaignGeneral: FC<Props> = ({ campaign, chosenCampaign }) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [campaignList, setCampaignList] = useState<any[]>([])
  const [day, setDay] = useState("")
  const [selectedCampaign, setSelectedCampaign] = useState<number | undefined>(campaign?.id)
  const queryClient = useQueryClient()
  const {
    compare,
    handleCompare,
    pastCampaigns,
    handleChosenCampaign,
    selectSingleCampaign,
    selectChosenCampaign,
    loadingPastCampaigns,
  } = useContext(CampaignContext)

  useEffect(() => {
    if (pastCampaigns && pastCampaigns?.results?.length > 0) {
      const past = pastCampaigns?.results
      const combined = [
        ...past,
        {
          id: campaign?.id,
          name: campaign?.name,
        },
      ]
      if (chosenCampaign) {
        setCampaignList(pastCampaigns?.results)
      } else {
        setCampaignList(combined)
      }
    }
  }, [chosenCampaign, pastCampaigns, campaign])

  const listOfCampaigns = selectSingleCampaign ? pastCampaigns && pastCampaigns?.results : campaignList

  useEffect(() => {
    if (campaign) setDay(campaign!?.goal_achieved_per_day![0]?.day)
  }, [campaign])

  const handleChangeDays = (event: ChangeEvent) => {
    const targetValue = event.target as HTMLInputElement
    const value = targetValue.value as string
    setDay(value)
  }

  const convertDataToGraph = (campaign: CampaignDetail) => {
    if (campaign) {
      const goalPerDay = campaign?.goal_achieved_per_day?.filter((res) => res?.day === day && res?.count > 0)
      const result = goalPerDay?.map(({ day: id, hourly_count: data, ...rest }) => {
        const formatData = data?.map(({ hour: x, count: y }) => {
          const time = moment(String(x), "h").format("h A")
          return { x: time, y }
        })
        return { id, data: formatData, ...rest }
      })
      return result!.filter((data) => data.count > 0)
    }
  }

  useEffect(() => {
    if (campaign) {
      convertDataToGraph(campaign)
    }
    //eslint-disable-next-line
  }, [campaign])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleOpenDelete = () => {
    setOpenDelete(true)
  }

  const handleCloseDelete = () => {
    setOpenDelete(false)
  }

  const duration = moment(campaign?.end_date).diff(moment(campaign?.start_date), "days")

  const { mutate: deleteCampaign } = useMutation(() => VisionAPI.deleteCampaign(campaign?.id), {
    onSuccess: () => {
      handleCloseDelete()
      navigate(`/${routes?.campaign}`, { replace: true })
      NotificationUtils.toast("Campaign deleted successfully", {
        severity: "success",
      })
    },
  })

  const handleChosenCampaignRefetch = (id: number) => {
    handleChosenCampaign(id!, chosenCampaign ? "chosen" : "single")
    if (id) {
      setTimeout(async () => {
        if (chosenCampaign) {
          await queryClient.invalidateQueries("fetchChosenCampaign")
          await queryClient.invalidateQueries("fetchWeatherSingleCampaign")
        } else {
          await queryClient.invalidateQueries("fetchSingleCampaign")
          await queryClient.invalidateQueries("fetchWeatherSingleCampaign")
        }
      }, 300)
    }
  }

  const handleChangeCampaigns = (event: InputChangeEvent) => {
    setSelectedCampaign(event.target.value as number)
    handleChosenCampaignRefetch(event.target.value as number)
  }

  return (
    <Fragment>
      <Paper elevation={0} className={styles.wrapper}>
        <Grid container spacing={2} className={styles.header}>
          <Grid item xs={12} md={3}>
            <Typography variant="a" variantColor={2}>
              General Details
            </Typography>
          </Grid>

          <Grid item xs={12} md={9} className={styles.actionButtons}>
            {!compare && (
              <Fragment>
                <ActionButton handleClick={handleOpen} icon={<EditIcon fontSize="small" />}>
                  Edit
                </ActionButton>
                <ActionButton handleClick={handleOpenDelete} deletable={true} icon={<DeleteIcon fontSize="small" />}>
                  Delete
                </ActionButton>
              </Fragment>
            )}

            {!chosenCampaign && (
              <ActionButton
                handleClick={handleCompare}
                comparable={compare}
                icon={compare ? <CloseIcon fontSize="small" /> : <CompareIcon fontSize="small" />}
              >
                {compare ? "Exit" : "Compare"}
              </ActionButton>
            )}
          </Grid>
        </Grid>

        {selectChosenCampaign ? (
          <div
            style={{
              margin: chosenCampaign ? "24px 0px" : "16px 0px 24px",
            }}
          >
            <Select
              id="Campaign Select"
              value={selectedCampaign}
              handleChange={handleChangeCampaigns}
              loading={loadingPastCampaigns}
              placeholder="Select A Campaign"
              description="Pick an event"
              optionsWithValues={
                listOfCampaigns &&
                listOfCampaigns?.map((past) => {
                  return { label: past?.name, value: past?.id }
                })
              }
              autosize
            />
          </div>
        ) : (
          <Typography variant="h3-bold" className={styles.name}>
            {campaign?.name}
          </Typography>
        )}

        <Typography variant="label-regular" variantColor={2}>
          DURATION: {duration} DAYS
        </Typography>

        {/* Duration */}
        <div className={styles.duration}>
          <Typography variant="p">{campaign?.start_date!}</Typography>
          <ChevronRightIcon className={styles.rangeIcon} />
          <Typography variant="p">{campaign?.end_date!}</Typography>
        </div>

        {/* Description */}
        {campaign?.description! && (
          <Fragment>
            <Typography variant="label-regular" variantColor={2} gutterBottom>
              DESCRIPTION
            </Typography>
            <Typography variant="p" variantColor={2} className={styles.description}>
              {campaign?.description!}
            </Typography>
          </Fragment>
        )}

        {/* Tags */}
        <Typography variant="label-regular" variantColor={2} gutterBottom>
          TAGS
        </Typography>
        {campaign?.tags?.length > 0 ? (
          campaign?.tags?.map((tag, i) => (
            <Tag key={`${tag}-${i}`} size="xsmall" variant="filled" color="gray" className={styles.tags}>
              {tag}
            </Tag>
          ))
        ) : (
          <Typography variant="span-regular" variantColor={2} gutterBottom>
            No tags to display
          </Typography>
        )}

        {/* Count Line Graph */}
        <CampaignGraph
          convertDataToGraph={convertDataToGraph}
          campaign={campaign}
          handleChangeDays={handleChangeDays}
          day={day}
        />
      </Paper>

      {/* ADD/EDIT Campaign */}
      <CampaignNewEdit edit={open ? true : false} campaign={campaign} open={open} handleClose={handleClose} />

      {/* Delete Campaign */}
      <CampaignPopup
        type="Campaign"
        name={campaign?.name!}
        open={openDelete}
        handleAction={deleteCampaign}
        handleClose={handleCloseDelete}
      />
    </Fragment>
  )
}

export default CampaignGeneral
