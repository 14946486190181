import { FC } from "react"

import AddIcon from "@mui/icons-material/Add"
import SaveIcon from "@mui/icons-material/Save"

import { Button } from "@synapse-analytics/synapse-ui"

import styles from "./ActionButton.module.scss"

interface Props {
  title: string
  edit?: boolean
  disabled?: boolean
  onClick?: () => void
  isLoading?: any
}

const ActionButton: FC<Props> = ({ title, edit = false, onClick, isLoading, disabled }) => {
  return (
    <Button
      disabled={disabled || isLoading}
      variant="primary"
      size="regular"
      endIcon={edit ? <SaveIcon fontSize="small" /> : <AddIcon fontSize="small" />}
      className={styles.button}
      onClick={onClick}
      isLoading={isLoading}
    >
      <div style={{ display: "flex", alignItems: "items" }}>{title}</div>
    </Button>
  )
}

export default ActionButton
