import React, { useState, Fragment } from "react"

import { Chip, DateRangePicker } from "@synapse-analytics/synapse-ui"
import { NumberParam, StringParam, useQueryParam, withDefault } from "use-query-params"

import CameraSearch from "../../../components/CameraSearch"
import Placeholder from "../../../components/Placeholder"
import { useDateQuery } from "../../../hooks/useDateQuery"
import HeatmapContainer from "./HeatmapContainer"

import styles from "./CamerasHeatmap.module.scss"

const CamerasHeatmap = () => {
  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()
  const [selectedCamera, setSelectedCamera] = useQueryParam("camera", NumberParam)
  const [timeGrain, setTimeGrain] = useQueryParam("grain", withDefault(StringParam, "hourly"))

  const [isLoading, setIsLoading] = useState(false)
  const [disableDateRange, setDisableDateRange] = useState(false)

  const handleLoading = (loadingState: boolean) => {
    setIsLoading(loadingState)
  }

  const handleDisableDateRange = (disableState: boolean) => {
    setDisableDateRange(disableState)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <CameraSearch
          setSelectedCamera={setSelectedCamera}
          services="heatmap"
          shouldSearchActive
          selectedCamera={selectedCamera as number}
        />

        <div className={styles.datePicker}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            disabled={isLoading || disableDateRange}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            disableFuture
          />
        </div>
        <Chip isSelected={timeGrain === "hourly"} clickable onClick={() => setTimeGrain("hourly")}>
          Hourly average
        </Chip>
        <Chip isSelected={timeGrain === "daily"} clickable onClick={() => setTimeGrain("daily")}>
          Daily average
        </Chip>
      </div>

      {/* if no camera is selected yet  , show placeholder */}
      <Fragment>
        {selectedCamera ? (
          <HeatmapContainer
            startDate={startDate}
            endDate={endDate}
            selectedCamera={selectedCamera}
            loadingSetter={handleLoading}
            disableSetter={handleDisableDateRange}
            timeGrain={timeGrain as "hourly" | "daily"}
            key={`Heatmap_Of_${selectedCamera}_${timeGrain}`}
          />
        ) : (
          <Placeholder selectionType="camera" isScreenPlaceholder />
        )}
      </Fragment>
    </div>
  )
}
export default CamerasHeatmap
