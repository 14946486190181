import React, { FC } from "react"

// icons
import CameraIcon from "@mui/icons-material/CameraAltOutlined"
import CategoryIcon from "@mui/icons-material/Category"
import CarsIcon from "@mui/icons-material/Commute"
import MCPIcon from "@mui/icons-material/CompareArrows"
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun"
import NodesIcon from "@mui/icons-material/DnsOutlined"
import MarketingIcon from "@mui/icons-material/Equalizer"
import SecurityEventsIcon from "@mui/icons-material/GridOn"
import APIIntegrationIcon from "@mui/icons-material/IntegrationInstructionsOutlined"
import PeopleFlowIcon from "@mui/icons-material/LensBlur"
import FloorIcon from "@mui/icons-material/Map"
import PlaceIcon from "@mui/icons-material/Place"
import CameraLive from "@mui/icons-material/VideocamOutlined"
import RolesIcon from "@mui/icons-material/VpnKeyOutlined"
import { Paper } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"

import { routes } from "../../../routes/routes"
import { AccordionItem } from "../../../types/Custom/Interfaces"
import Auth from "../../../utils/auth"
import SidebarItem from "./SidebarItem"

import styles from "./UserData.module.scss"

/**
 * Props interface for UserData component.
 */
interface Props {
  /** The persona type of the user. */
  persona: string
  /** The currently active screen identifier. */
  activeScreen: string
}

/**
 * UserData component renders the sidebar items based on the user's persona and permissions.
 *
 * @param {Props} props - The properties for the UserData component.
 * @returns {JSX.Element} The rendered UserData component.
 */
const UserData: FC<Props> = ({ activeScreen, persona }: Props): JSX.Element => {
  /**
   * Checks if the user has any generic admin screens.
   */
  const hasAdminGenericItems = [
    Auth.getCategoriesPermissions(),
    Auth.getUsersPermission(),
    Auth.getCarsAdminPermission(),
  ].some((condition) => !!condition)

  const analyticsItems = [
    Auth.getPeopleCountPermission() && {
      label: "People Count",
      icon: DirectionsRunIcon,
      items: [
        {
          label: "Gates",
          route: routes?.gates,
          subRoutes: [routes?.liveGates, routes?.historyGates],
        },
        {
          label: "Categories",
          route: routes?.tenants,
        },
        {
          label: "Tenants",
          route: routes?.tenantsList,
          subRoutes: [routes?.tenantDetails],
        },
        {
          label: "Dwelling Areas",
          route: routes?.dwellingAreas,
          subRoutes: [routes?.dwellingAreaDetails],
        },
        {
          label: "Checkout Counters",
          route: routes?.checkoutCounter,
        },
        {
          label: "Corridors",
          route: routes?.corridors,
        },
        Auth.getMissingDataPermission() && {
          label: "Missing Data",
          route: routes?.missingData,
        },
      ].filter(Boolean),
    },
    (Auth.getHeatmapPermission() || Auth.getZonesPermission()) && {
      label: "People flow",
      subRoutes: [routes?.heatmap, routes?.oldHeatmap, routes?.staffOccupancy, routes?.seatOccupancy],
      icon: PeopleFlowIcon,
      items: [
        Auth.getZonesPermission() && {
          label: "Staff Occupancy",
          route: routes?.staffOccupancy,
        },
        Auth.getZonesPermission() && {
          label: "Seat Occupancy",
          route: routes?.seatOccupancy,
        },
        Auth.getHeatmapPermission() && {
          label: "Heatmap V2",
          route: routes?.heatmap,
        },
        Auth.getHeatmapPermission() && {
          label: "Heatmap",
          route: routes?.oldHeatmap,
          tag: { label: "deprecated", color: "yellow" },
        },
      ].filter(Boolean),
    },
    Auth.getMcpPermissions() && {
      label: "Most Common Paths",
      subRoutes: [routes?.floorsMCP, routes?.camerasMCP],
      icon: MCPIcon,
      items: [
        {
          label: "Floors MCP",
          route: routes?.floorsMCP,
        },
        {
          label: "Cameras MCP",
          route: routes?.camerasMCP,
        },
      ],
    },
    Auth.getCarsAnalyticsPermission() && {
      label: "Cars",
      icon: CarsIcon,
      items: [
        {
          label: "Cars Analytics",
          route: routes?.carsAnalytics,
        },
      ],
    },
  ].filter(Boolean) as AccordionItem[]

  const adminItems = [
    Auth.getCamerasPermission() && {
      label: "Cameras",
      icon: CameraIcon,
      items: [
        {
          label: "Cameras List",
          route: routes?.cameraList,
          subRoutes: [routes?.cameraDetails],
        },
        {
          label: "Cameras View Changes",
          route: routes?.cameraViewChanges,
        },
      ],
    },
    Auth.getFloorPermission() && {
      label: "Floors Setup",
      route: `${routes?.floors}`,
      icon: FloorIcon,
    },
    Auth.getRegionsPermission() && {
      label: "Entities & Regions",
      route: `${routes?.entitiesAndRegions}`,
      icon: PlaceIcon,
    },
    Auth.getNodesPermission() && {
      label: "Nodes",
      route: `${routes?.nodesList}`,
      subRoutes: [routes?.nodeDownAnalytics],
      icon: NodesIcon,
    },
    hasAdminGenericItems && "Generic Items",
    Auth.getCategoriesPermissions() && {
      label: "Categories Management",
      route: `${routes?.categoriesList}`,
      icon: CategoryIcon,
    },
    Auth.getUsersPermission() && {
      label: "Users & Permissions",
      route: `${routes?.users}`,
      subRoutes: [routes?.createUser, routes?.userDetails],
      icon: RolesIcon,
    },
    Auth.getCarsAdminPermission() && {
      label: "Cars Lists",
      route: `${routes?.carsList}`,
      icon: CarsIcon,
    },
    Auth.getAPIIntegrationPermission() && {
      label: "API Integration",
      route: `${routes?.apiIntegration}`,
      icon: APIIntegrationIcon,
    },
  ].filter(Boolean) as (AccordionItem | string)[]

  const marketingItems = [
    Auth.getCampaignPermission() && {
      label: "Campaign List",
      route: `${routes?.campaign}`,
      icon: MarketingIcon,
    },
  ].filter(Boolean) as AccordionItem[]

  const securityItems = [
    Auth.getSecurityCameraStreamPermissions() && {
      label: "Live Streams",
      route: `${routes?.liveStreams}`,
      icon: CameraLive,
    },
    Auth.getSecurityEventsPermissions() && {
      label: "Incidents",
      route: `${routes?.securityIncidents}`,
      icon: SecurityEventsIcon,
    },
    Auth.getSecurityCarsPermissions() && {
      label: "Car Logs",
      route: `${routes?.carsLogs}`,
      icon: CarsIcon,
    },
  ].filter(Boolean) as AccordionItem[]

  /**
   * Renders the sidebar items based on the provided items array.
   *
   * @param {(AccordionItem | string)[]} items - The array of items to render.
   * @returns {JSX.Element[]} The rendered sidebar items.
   */
  const renderSidebarItems = (items: (AccordionItem | string)[]): JSX.Element[] =>
    items.map((item) =>
      typeof item === "string" ? (
        <Typography key={item} variant="span-bold" variantColor={2}>
          {item}
        </Typography>
      ) : (
        <SidebarItem
          key={item.label}
          activeScreen={activeScreen}
          label={item.label}
          Icon={item.icon}
          accordionItems={item.items}
          route={item.route}
          subRoutes={item.subRoutes}
        />
      )
    )

  return (
    <Paper className={styles.userData}>
      <Typography
        variant="span-bold"
        textTransform="capitalize"
        variantColor={2}
        gutterBottom
        className={styles.descTitle}
      >
        {persona} details
      </Typography>

      {/* Analytics Persona Items */}
      <div className={styles.itemsWrapper}>
        {persona === "analytics" && renderSidebarItems(analyticsItems)}

        {/* Admin Persona Items */}
        {persona === "admin" && renderSidebarItems(adminItems)}

        {/* Marketing Persona Items */}
        {persona === "marketing" && renderSidebarItems(marketingItems)}

        {/* Security Persona Items */}
        {persona === "security" && renderSidebarItems(securityItems)}
      </div>
    </Paper>
  )
}

export default UserData
