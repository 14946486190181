/* eslint-disable jsx-a11y/accessible-emoji */
import React, { FC, useContext } from "react"
import { useNavigate } from "react-router-dom"

import CalendarIcon from "@mui/icons-material/CalendarTodayOutlined"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Card, Slider, Box } from "@mui/material"

import { Typography, Chip } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { routes } from "../../../routes/routes"
import { definitions } from "../../../types/Generated/apiTypes"
import { CampaignContext } from "../CampaignContext/CampaignContext"

import styles from "./CampaignCard.module.scss"

type CampaignList = definitions["CampaignList"]

interface Props {
  campaign: CampaignList
  title: string
  compare: boolean
}

const CampaignCard: FC<Props> = ({ campaign, title, compare }) => {
  const { handleChosenCampaign } = useContext(CampaignContext)
  const navigate = useNavigate()
  const duration = moment(campaign?.end_date).diff(moment(campaign?.start_date), "days")
  const daysPassed = moment(new Date()).diff(moment(campaign?.start_date), "days")
  const daysLeft = moment(campaign?.end_date).diff(moment(new Date()), "days")
  const daysUntilUpcoming = moment(campaign?.start_date).diff(moment(), "days") + 1
  const campaignGoal = Math.round((campaign?.achieved_goal! / campaign?.goal!) * 100)
  const achievedGoals = campaignGoal === Infinity ? "0%" : `${campaignGoal}%`

  return (
    <Card
      className={styles.wrapper}
      sx={{
        height: "auto",
        minHeight: compare ? "0" : "350px",
        boxShadow: compare ? "0px 0px 4px 0px rgb(0 0 0 / 16%)" : "none",
      }}
      onClick={() => {
        if (compare) {
          handleChosenCampaign!(campaign?.id!, "chosen")
        } else {
          navigate(`/${routes?.campaign}/${campaign?.id!}`, {
            state: { title: title, loadedCampaign: false },
          })
        }
      }}
    >
      {/* campaign name */}
      <Typography variant="h3-bold" gutterBottom className={styles.name}>
        {campaign?.name!}
      </Typography>

      <Box
        className={styles.info}
        sx={{
          padding: {
            xl: campaign?.tags && campaign?.tags?.length > 0 ? "16px" : "16px 16px 44px",
            xs: campaign?.tags && campaign?.tags?.length > 0 ? "16px 4px" : "16px 16px 44px",
          },
        }}
      >
        <div className={styles.dateWrapper}>
          <CalendarIcon className={styles.icon} />

          {/*  campaign dates */}
          <div>
            {/* dates */}
            <div style={{ display: "flex" }}>
              <Typography variant="a">{moment(campaign?.start_date).format("DD/MM/YYYY")}</Typography>
              <ChevronRightIcon />
              <Typography variant="a">{moment(campaign?.end_date!).format("DD/MM/YYYY")}</Typography>
            </div>

            {/* Goal */}
            <div className={styles.duration}>
              <Typography variant="a" variantColor={2}>
                Duration: {duration} Days
              </Typography>
            </div>
          </div>
        </div>

        {/* tags */}
        <div className={styles.tags}>
          {campaign &&
            campaign?.tags!?.length > 0 &&
            campaign?.tags?.slice(0, 2).map((tag) => (
              <Chip size="small" key={tag} className={styles.campaignTags} isSelected>
                {tag}
              </Chip>
            ))}
          {campaign?.tags?.length > 2 && (
            <Typography variant="p" variantColor={2}>
              + {campaign?.tags?.length - 2} more
            </Typography>
          )}
        </div>
      </Box>

      {/* Campaign goal */}
      {!compare &&
        (title === "Upcoming Campaigns" ? (
          <div className={styles.upcomingEvents}>
            <Typography variant="label-bold" variantColor={2} gutterBottom>
              {daysUntilUpcoming} days until event 🎉
            </Typography>
            <Typography variant="h2-bold" variantColor={2}>
              {campaign?.goal!}
            </Typography>
            <Typography variant="label-regular" variantColor={2}>
              Event Goal
            </Typography>
          </div>
        ) : (
          <div
            className={styles.goal}
            style={{
              marginTop: "47.2px",
            }}
          >
            <Typography variant="label-bold" className={campaignGoal > 100 ? styles.exceed : ""}>
              Goal{campaignGoal > 100 && " exceed 🎉"}
            </Typography>
            <Slider
              sx={{
                "& .MuiSlider-valueLabel": {
                  right: campaign?.achieved_goal! > campaign?.goal! ? "30px" : "0px",
                  top: campaign?.achieved_goal! > campaign?.goal! ? "-10px" : "0px",
                  width: campaign?.achieved_goal! > campaign?.goal! ? "auto" : "32px",
                  height: campaign?.achieved_goal! > campaign?.goal! ? "auto" : "32px",
                },
              }}
              classes={{
                root: styles.root,
                track: styles.track,
                disabled: styles.disabled,
                thumb: styles.thumb,
                rail: styles.rail,
                valueLabel: styles.valueLabel,
              }}
              value={campaign?.achieved_goal}
              max={campaign?.goal}
              disabled
              valueLabelDisplay="on"
              aria-label="custom slider"
              valueLabelFormat={achievedGoals}
            />

            <div className={styles.goalProgress}>
              <Typography variant="label-regular" variantColor={2}>
                {daysPassed} Days Passed
              </Typography>
              <Typography variant="label-regular" variantColor={2}>
                {Math.sign(daysLeft) === -1 ? "Completed" : `${daysLeft} Days Left`}
              </Typography>
            </div>
          </div>
        ))}
    </Card>
  )
}

export default CampaignCard
