import { useState, useEffect, Fragment } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useInfiniteQuery } from "react-query"

import AddCircleIcon from "@mui/icons-material/AddCircle"
import { useMediaQuery, Grid, CircularProgress } from "@mui/material"

import { Button, DateRangePicker, Skeleton, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../API/VisionAPI"
import Search from "../../components/Search"
import { useDateQuery } from "../../hooks/useDateQuery"
import { useDebounceSearch } from "../../hooks/useDebouncedSearch"
import { useBranchesStore } from "../../store"
import { PaginatedMissingData } from "../../types/Custom/Interfaces"
import { definitions } from "../../types/Generated/apiTypes"
import { extractPageFromBackEndPaginationLink } from "../../utils/genericHelpers"
import NoLogsPlaceholder from "./assets/NoLogs.svg?react"
import MissingDataCard from "./components/MissingDataCard"
import MissingDataContextProvider from "./components/MissingDataContext/MissingDataContext"
import MissingDataSetup from "./components/MissingDataSetup/MissingDataSetup"

import styles from "./MissingDataList.module.scss"

type MissingData = definitions["MissingDataInfo"]

const MissingDataList = () => {
  const [searchValue, setSearchValue] = useState("")
  const debouncedSearchValue = useDebounceSearch(searchValue, 500)
  const [limit, setLimit] = useState(16)
  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()
  const [openSetup, setOpenSetup] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedInfo, setSelectedInfo] = useState<MissingData | null>()
  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  const xLargeScreen = useMediaQuery("(min-width:1900px)")
  const smallScreen = useMediaQuery("(max-width:1240px)")
  const largeScreen = useMediaQuery("(max-width:1525px)")
  const xSmallScreen = useMediaQuery("(max-width:680px)")

  useEffect(() => {
    if (xLargeScreen) {
      setLimit(16)
    } else if (xSmallScreen) {
      setLimit(4)
    } else if (smallScreen) {
      setLimit(8)
    } else if (largeScreen) {
      setLimit(12)
    } else {
      setLimit(16)
    }
  }, [xLargeScreen, smallScreen, largeScreen, xSmallScreen])

  const {
    data: paginatedMissingData,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery<PaginatedMissingData, AxiosError>(
    [
      "fetchMissingData",
      debouncedSearchValue,
      limit,
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      selectedBranch,
    ],
    ({ queryKey, pageParam = 1 }) =>
      VisionAPI.fetchMissingData({
        search: queryKey[1] as string,
        limit: queryKey[2] as number,
        from_date: queryKey[3] as string,
        to_date: queryKey[4] as string,
        branch: queryKey[5] as number,
        page: pageParam,
      }),
    {
      getNextPageParam: (lastPage: PaginatedMissingData) => {
        return lastPage?.next ? extractPageFromBackEndPaginationLink(lastPage.next) : undefined
      },
      enabled: !!startDate && !!endDate && !!selectedBranch,
    }
  )

  const handleSearch = (value: string) => {
    setSearchValue(value)
  }

  // close dialog and reset states
  const handleCloseSetup = () => {
    setOpenSetup(false)
    if (isEdit) {
      setIsEdit(false)
      setSelectedInfo(null)
    }
  }

  const handleOpenEdit = (missingData: MissingData) => {
    setIsEdit(true)
    setSelectedInfo(missingData)
    setOpenSetup(true)
  }

  const handleOpenSetup = () => {
    setOpenSetup(true)
  }

  // loading skeletons
  let loadingPlaceholders = new Array(limit).fill(null).map((r, i) => (
    <Grid item md={xSmallScreen ? 6 : 4} sm={6} xs={12} xl={xLargeScreen ? 3 : 4} key={i}>
      <Skeleton variant="rectangular" height={234} width="auto" />
    </Grid>
  ))

  return (
    <Fragment>
      <Typography
        variant="h2-regular"
        tooltip="Add and edit missing data to your entities"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        Missing data
      </Typography>
      <div className={styles.headerUtils}>
        <div className={styles.searchAndDate}>
          <Search
            handleSearch={handleSearch}
            searchValue={searchValue}
            placeholder="E.g. Missing Data Name"
            loading={isLoading}
          />
          <div className={styles.datePicker}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              disabled={isLoading}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
            />
          </div>
          {/* Entities Filter */}
        </div>
        <Button
          variant="primary"
          disabled={isLoading}
          startIcon={<AddCircleIcon fontSize="small" />}
          onClick={handleOpenSetup}
        >
          Add New Missing Data
        </Button>
      </div>
      {paginatedMissingData && paginatedMissingData?.pages?.length > 0 ? (
        <InfiniteScroll
          dataLength={paginatedMissingData?.pages.reduce((acc, page) => acc + page.results.length, 0)}
          hasMore={hasNextPage ? true : false}
          next={fetchNextPage}
          loader={<CircularProgress className={styles.loadingMore} />}
          endMessage={
            paginatedMissingData.pages[0].count < 1 ? (
              <div className={styles.noLogsWrapper}>
                <NoLogsPlaceholder />
                <div>
                  <Typography variant="h2-bold">No Missing Data Entries Found.</Typography>
                </div>
              </div>
            ) : (
              paginatedMissingData.pages[0].count > 1 && (
                <p
                  style={{
                    textAlign: "center",
                  }}
                >
                  You reached the bottom
                </p>
              )
            )
          }
          scrollThreshold={0.9}
        >
          <Grid container spacing={2} style={{ display: "flex" }}>
            {/* Missing Data list */}
            {paginatedMissingData?.pages.map((page, i) => (
              <Fragment key={i}>
                {page.results.map((missingDataEntity: MissingData, i: number) => (
                  <Grid key={i} item md={xSmallScreen ? 6 : 4} sm={6} xs={12} xl={xLargeScreen ? 3 : 4}>
                    <MissingDataCard missingData={missingDataEntity} handleOpenEdit={handleOpenEdit} />
                  </Grid>
                ))}
              </Fragment>
            ))}
          </Grid>
        </InfiniteScroll>
      ) : (
        <Grid container spacing={2} style={{ display: "flex" }}>
          {isLoading && loadingPlaceholders}
        </Grid>
      )}
      <MissingDataContextProvider
        isEdit={isEdit}
        editInfo={selectedInfo}
        key={isEdit ? `Editing_${selectedInfo?.title}` : "Setup missing data"}
      >
        <MissingDataSetup handleClose={handleCloseSetup} open={openSetup} />
      </MissingDataContextProvider>
    </Fragment>
  )
}
export default MissingDataList
