import { useContext, useEffect } from "react"
import { useQuery } from "react-query"

import { Card, Grid } from "@mui/material"

import { InputText, Select } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../../../API/VisionAPI"
import { useBranchesStore } from "../../../../../store"
import { IEntitiesSetup } from "../../../../../types/Custom/Interfaces"
import { definitions } from "../../../../../types/Generated/apiTypes"
import { getEntityPlaceholder } from "../../../../../utils/EntitiesSetupUtils"
import { EntitiesContext } from "../../EntitiesContext/EntitiesContext"

import styles from "./EntityForm.module.scss"

type Category = definitions["Category"]
type Floor = definitions["FloorPlanList"]

const EntityForm = () => {
  const { formik, isEdit, subcategories, setSubcategories } = useContext<IEntitiesSetup>(EntitiesContext)

  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  const { data: categories, isLoading: isLoadingCategories } = useQuery<Category[], AxiosError>(
    "fetchCategories",
    VisionAPI.fetchCategories,
    {
      enabled: formik.values.type === "Tenant" || formik.values.type === "Dwelling Area",
    }
  )

  const { data: floors, isLoading: isFloorsLoading } = useQuery<Floor[], AxiosError>(
    ["fetchFloors", selectedBranch],
    ({ queryKey }) => VisionAPI.fetchFloorPlans({ branch: queryKey[1] as number }),
    {
      enabled: !!selectedBranch && formik.values.type === "Zone",
    }
  )

  const adjustSubcategories = (categoryNumber: any) => {
    if (isEdit || categoryNumber !== formik.values.category) {
      const selectedCategory: any = categories?.find((category: any) => category.id === categoryNumber)
      if (selectedCategory) {
        setSubcategories(selectedCategory?.subcategories)
        !isEdit && formik.setFieldValue("subcategory", undefined)
        formik.setFieldTouched("subcategory", false)
      }
    }
  }

  // adjust subcategories of selected category in isEdit mode
  useEffect(() => {
    if (
      isEdit &&
      (formik.values.type === "Tenant" || formik.values.type === "Dwelling Area") &&
      categories &&
      !isLoadingCategories
    ) {
      adjustSubcategories(formik.values?.category)
    }
    // eslint-disable-next-line
  }, [isEdit, formik.values.category, formik.values.type, categories, isLoadingCategories])

  return (
    <Card className={styles.entityFormCard}>
      <div className={styles.EntityFormContent}>
        <Grid container spacing={1}>
          <Grid item md={["Corridor", "Tenant"].includes(formik.values.type) ? 6 : 12} sm={12}>
            <InputText
              value={formik?.values.name}
              id="name"
              required
              label="Entity Name"
              placeholder={getEntityPlaceholder(formik?.values.type)}
              fullWidth
              handleBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name) && formik.errors.name}
              handleChange={formik.handleChange}
            />
          </Grid>
          {(formik.values.type === "Corridor" || formik.values.type === "Tenant") && (
            <Grid item md={6} sm={12}>
              <InputText
                value={formik?.values.area || ""}
                id="area"
                type="number"
                label="Area"
                placeholder="E.g. 500"
                fullWidth
                handleBlur={formik.handleBlur}
                error={formik.touched.area && Boolean(formik.errors.area) && formik.errors.area}
                handleChange={formik.handleChange}
              />
            </Grid>
          )}
          {(formik.values.type === "Tenant" || formik.values.type === "Dwelling Area") && (
            <Grid item md={6} sm={12}>
              <Select
                value={formik?.values.category}
                loading={isLoadingCategories}
                id="category"
                label="Category"
                key={`selected_${formik?.values.category}`}
                required
                placeholder="Select a category"
                fullWidth
                handleBlur={formik.handleBlur}
                error={
                  !formik.values.category &&
                  formik.touched.category &&
                  Boolean(formik.errors.category) &&
                  formik.errors.category
                }
                handleChange={(e) => {
                  const selectedCategory = e.target.value
                  formik.setFieldValue("category", selectedCategory)
                  adjustSubcategories(selectedCategory)
                  // Reset subcategory field when a new category is selected
                  formik.setFieldValue("subcategory", undefined)
                  formik.setFieldTouched("subcategory", false)
                }}
                optionsWithValues={categories?.map((category: Category) => {
                  return {
                    label: category.name,
                    value: category.id!,
                  }
                })}
              />
            </Grid>
          )}

          {(formik.values.type === "Tenant" || formik.values.type === "Dwelling Area") && (
            <Grid item md={6} sm={12}>
              <Select
                value={formik?.values.subcategory}
                loading={isLoadingCategories}
                key={`subcategories_of_${formik.values.category}`}
                id="subcategory"
                disabled={!formik?.values.category}
                label="Sub-Category"
                required
                placeholder="Select a subcategory"
                fullWidth
                handleBlur={formik.handleBlur}
                error={formik.touched.subcategory && Boolean(formik.errors.subcategory) && formik.errors.subcategory}
                handleChange={(e) => {
                  formik.setFieldValue("subcategory", e.target.value)
                }}
                optionsWithValues={
                  subcategories &&
                  subcategories?.map((subcategory) => {
                    return {
                      label: subcategory.name,
                      value: subcategory.id,
                    }
                  })
                }
                disabledTipTitle="Please Select A Category First"
                disabledToolTipPlacement="bottom"
              />
            </Grid>
          )}
          {formik.values.type === "Zone" && (
            <Grid item md={6} sm={12}>
              <Select
                value={formik?.values.floor}
                id="floor"
                label="Floor"
                key={`selected_${formik?.values.floor}`}
                required
                placeholder="Select a Floor"
                fullWidth
                handleBlur={formik.handleBlur}
                handleChange={(e) => {
                  formik.setFieldValue("floor", e.target.value)
                }}
                loading={isFloorsLoading}
                error={
                  !formik.values.floor && formik.touched.floor && Boolean(formik.errors.floor) && formik.errors.floor
                }
                optionsWithValues={floors?.map((floor: Floor) => {
                  return {
                    label: floor.floor_name,
                    value: floor.id!,
                  }
                })}
              />
            </Grid>
          )}
          {formik.values.type === "Zone" && (
            <Grid item md={6} sm={12}>
              <InputText
                value={formik?.values.chairs_count || ""}
                id="chairs_count"
                type="number"
                label="Chairs Count"
                placeholder="E.g. 500"
                fullWidth
                handleBlur={formik.handleBlur}
                error={formik.touched.chairs_count && Boolean(formik.errors.chairs_count) && formik.errors.chairs_count}
                handleChange={formik.handleChange}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </Card>
  )
}
export default EntityForm
