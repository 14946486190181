import { useState, useEffect, createRef } from "react"
import { useQuery } from "react-query"
import { useLocation } from "react-router-dom"

import BarChartIcon from "@mui/icons-material/BarChart"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import GetAppIcon from "@mui/icons-material/GetApp"
import GridOnIcon from "@mui/icons-material/GridOn"
import { CircularProgress } from "@mui/material"

import { Button, Switch, Typography } from "@synapse-analytics/synapse-ui"
import { DateRangePicker } from "@synapse-analytics/synapse-ui"
import intervalToDuration from "date-fns/intervalToDuration"
import domtoimage from "dom-to-image"
import FileSaver from "file-saver"
import JSZip from "jszip"
import moment from "moment"
import { BooleanParam, useQueryParams, withDefault } from "use-query-params"
import { shallow } from "zustand/shallow"

import AnalyticsTargetsFilter from "../../components/AnalyticsTargetsFilter"
import DropDownButtons from "../../components/DropDownButtons"
import TogglePages from "../../components/TogglePages"
import { useDateQuery } from "../../hooks/useDateQuery"
import { useBranchesStore } from "../../store"
import { ExportReport } from "../../utils/counterUtils"
import { exportFetchedDataToCSV } from "../../utils/genericHelpers"
import { StaffParam } from "../../utils/queryParamCustomConfigs"
import CounterGates from "./components/CounterGates"

import styles from "./EntranceGates.module.scss"

type refs = {
  lineGraphRef: any
  gatesGraphRef: any
  avgGraphRef: any
  hourlyAvgGraphRef: any
  heatmapGraphRef: any
  visitorsRef: any
  occupancyRef: any
}
const now = moment()

const EntranceGates = () => {
  const location = useLocation()
  const isLive = location?.pathname?.includes("analytics-gates-live")

  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery({ isHidden: isLive })

  const [query, setQuery] = useQueryParams({
    target: withDefault(StaffParam, "visitors"),
    shouldIncludeMissingData: withDefault(BooleanParam, false),
  })

  const staffFilterValue = query.target === "visitors" ? false : query.target === "staff" ? true : null

  const [timeGrain, setTimeGrain] = useState<"hour" | "day" | null>(null)
  const [interval, setInterval] = useState<Duration>({
    years: 0,
    months: 0,
    weeks: 0,
    days: 1,
  })
  const [isLoading, setIsLoading] = useState(true)

  const [selectedBranch] = useBranchesStore(
    (state: { selectedBranch: number | null }) => [state.selectedBranch],
    shallow
  )

  const refs: refs = {
    lineGraphRef: createRef(),
    gatesGraphRef: createRef(),
    avgGraphRef: createRef(),
    hourlyAvgGraphRef: createRef(),
    heatmapGraphRef: createRef(),
    visitorsRef: createRef(),
    occupancyRef: createRef(),
  }

  // calculating the interval and time grain based on start & end dates picked.
  // every time start and end dates are changed
  useEffect(() => {
    if (!!endDate && !!startDate!) {
      const interval = intervalToDuration({
        start: startDate.toDate(),
        end: endDate.toDate(),
      })
      setInterval(interval)
      if (interval.days! > 1 || interval.months! >= 1) {
        setTimeGrain("day")
      } else {
        setTimeGrain("hour")
      }
    }
  }, [startDate, endDate, timeGrain])
  // on change between history and live
  // set the start date and end date to represent start/end of day or week as a default for history
  const handleTabChange = (page: string) => {
    if (page === "live") {
      setTimeGrain("hour")
    } else {
      setTimeGrain("day")
    }
  }

  const { data: gatesReport, isLoading: gatesReportLoading } = useQuery(
    [
      "fetchGatesReport",
      isLive ? now?.format("YYYY-MM-DD") : startDate?.format("YYYY-MM-DD"),
      isLive ? now?.format("YYYY-MM-DD") : endDate?.format("YYYY-MM-DD"),
      staffFilterValue,
      query.shouldIncludeMissingData,
      selectedBranch,
    ],
    ({ queryKey }) =>
      ExportReport({
        startDate: queryKey[1],
        endDate: queryKey[2],
        staff: queryKey[3],
        shouldIncludeMissingData: queryKey[4],
        branch: queryKey[5],
      }),
    {
      enabled: !!startDate && !!endDate,
    }
  )

  // export graphs in form of images captured from DOM
  const exportGraphs = () => {
    if (startDate && endDate && !isLoading) {
      const arrayOfRefs = [refs.lineGraphRef, refs.gatesGraphRef, refs.visitorsRef]
      if (!isLive && !!endDate && !!timeGrain && !!interval && (interval!.days! >= 28 || interval!.months! >= 1)) {
        arrayOfRefs.push(refs.avgGraphRef, refs.hourlyAvgGraphRef, refs.heatmapGraphRef)
      }
      if (isLive) {
        arrayOfRefs.push(refs.occupancyRef)
      }
      let zip = new JSZip()
      let images: any[] = []
      let tmp = 0
      arrayOfRefs.forEach((item) => {
        if (item !== undefined) {
          domtoimage
            .toBlob(item.current)
            .then((blob: any) => {
              images.push(blob)
            })
            .then(() => {
              zip.file(item?.current?.children[0].childNodes[0].innerHTML + ".jpeg", images[tmp], { binary: true })
              tmp++
              if (tmp === arrayOfRefs.length)
                zip.generateAsync({ type: "blob" }).then((blob) => {
                  FileSaver.saveAs(
                    blob,
                    `Gates Analytics - From  ${startDate.format("DD-MM-YYYY hh-mm A")} To ${endDate.format(
                      "MM-DD-YYYY hh-mm A"
                    )}.zip`
                  )
                })
            })
        }
      })
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Typography
          variant="h2-regular"
          tooltip={
            isLive
              ? "All you need to know about the amount of visitors going in and out."
              : "Gates history and their related information"
          }
          tooltipPlacement="right"
          tooltipIconSize={22}
          variantColor={2}
        >
          {isLive ? "Gates Live" : "Gates History"}
        </Typography>
        <DropDownButtons
          trigger={
            <Button
              variant="secondary"
              startIcon={<GetAppIcon fontSize="small" />}
              endIcon={<ExpandMoreIcon fontSize="small" />}
            >
              Export
            </Button>
          }
          menu={[
            <Button
              variant="secondary"
              style={{
                width: "100% !important",
              }}
              fullWidth
              startIcon={<BarChartIcon fontSize="small" />}
              onClick={() => exportGraphs()}
              disabled={!startDate || !endDate || isLoading}
            >
              Charts
            </Button>,
            <Button
              disabled={!gatesReport || gatesReportLoading}
              variant="secondary"
              style={{
                width: "100% !important",
              }}
              fullWidth
              onClick={() => {
                if (gatesReport && !gatesReportLoading) {
                  exportFetchedDataToCSV(
                    gatesReport,
                    isLive
                      ? `Gates-report-for-today-${now.format("YYYY-MM-DD")}`
                      : `Gates-report-from-${startDate?.format("YYYY-MM-DD")}-to-${endDate?.format("YYYY-MM-DD")}`
                  )
                }
              }}
            >
              {gatesReportLoading ? (
                <CircularProgress size={20} className={styles.loadingReport} />
              ) : (
                <GridOnIcon fontSize="small" style={{ marginRight: 4 }} />
              )}
              CSV
            </Button>,
          ]}
        />
      </div>

      <TogglePages
        pages={["Live", "History"]}
        firstPage={
          <CounterGates
            isLive
            startDate={now?.format("YYYY-MM-DD")}
            endDate={now?.format("YYYY-MM-DD")}
            timeGrain={"hour"}
            setLoadingState={setIsLoading}
            shouldIncludeMissingData={false}
            staff={staffFilterValue}
            refs={refs}
          />
        }
        firstPath="analytics-gates-live"
        pathsParent="analytics-entrance-gates"
        secondPage={
          <CounterGates
            startDate={startDate?.format("YYYY-MM-DD")}
            endDate={endDate?.format("YYYY-MM-DD")}
            timeGrain={timeGrain as "hour" | "day"}
            interval={interval}
            setLoadingState={setIsLoading}
            isLive={false}
            shouldIncludeMissingData={query.shouldIncludeMissingData}
            staff={staffFilterValue}
            refs={refs}
          />
        }
        secondPath="analytics-gates-history"
        liveHistory
        setLiveHistoryStatus={handleTabChange}
        headerItems={
          <div className={styles.headerActions}>
            <div className={styles.dateAndFilters}>
              {!isLive && (
                <DateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  onEndDateChange={setEndDate}
                  onStartDateChange={setStartDate}
                  disableFuture
                  disabled={isLoading}
                />
              )}
              <AnalyticsTargetsFilter value={query.target} setValue={(target) => setQuery({ target: target })} />
            </div>
            {!isLive && (
              <div className={styles.switch}>
                <Switch
                  checked={query.shouldIncludeMissingData}
                  onChange={(event) => setQuery({ shouldIncludeMissingData: event.target.checked })}
                  label="Include missing data"
                />
              </div>
            )}
          </div>
        }
      />
    </div>
  )
}
export default EntranceGates
