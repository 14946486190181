import React, { useState, Fragment, FC, useMemo } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import DnsIcon from "@mui/icons-material/Dns"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import LinkOffIcon from "@mui/icons-material/LinkOff"
import LoyaltyIcon from "@mui/icons-material/Loyalty"
import NearbyErrorIcon from "@mui/icons-material/NearbyError"
import { Divider } from "@mui/material"
import Grid from "@mui/material/Grid"

import { Button, NotificationUtils, Skeleton, Tag, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { format } from "date-fns"
import moment from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import ServiceCard from "../../../components/ServiceEventTag"
import WarningDialog from "../../../components/WarningDialog"
import { routes } from "../../../routes/routes"
import { definitions } from "../../../types/Generated/apiTypes"
import EditCamera from "../components/CameraAddEdit"
import CameraHealth from "../components/CameraHealth"
import CameraStatus from "../components/CameraStatus"

import styles from "./CameraInfo.module.scss"

type Camera = definitions["CameraRetrieveUpdate"]
type ServiceType = definitions["CameraRetrieveUpdate"]["services"]

interface Props {
  camera?: Camera
  isLoading?: boolean
}

// Placeholders
const loadingPlaceholders = new Array(5).fill(null).map((_, i) => (
  <Grid item xs={12} key={i}>
    <Skeleton variant="rectangular" height={44} width="100%" />
  </Grid>
))

/**
 * Extracts people, security, and car services from a camera's services.
 *
 * @param {Camera} camera - The camera object containing services.
 * @returns {Object} An object containing three arrays: peopleServices, securityServices, and carServices.
 */
function extractServices(camera?: Camera) {
  const peopleServices: ServiceType = []
  const securityServices: ServiceType = []
  const carServices: ServiceType = []

  camera?.services?.forEach((service) => {
    if (["counter", "heatmap", "mcp", "face", "occupancy"].includes(service)) {
      peopleServices.push(service)
    } else if (["motion", "intrusion", "violence", "fire", "stray_animals"].includes(service)) {
      securityServices.push(service)
    } else if (service === "car") {
      carServices.push(service)
    }
  })

  return {
    peopleServices,
    securityServices,
    carServices,
  }
}

const CameraInfo: FC<Props> = ({ camera, isLoading }) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isEditCameraOpen, setIsEditCameraOpen] = useState(false)
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { peopleServices, securityServices, carServices } = useMemo(() => extractServices(camera), [camera])

  const handleCloseEdit = () => {
    setIsEditCameraOpen(false)
  }

  const handleOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false)
  }

  const { mutate: deleteCamera, isLoading: isLoadingCameraDelete } = useMutation(
    () => VisionAPI.deleteCamera({ id: camera?.id }),
    {
      onSuccess: async () => {
        await queryClient?.invalidateQueries("fetchCamerasPaginated")
        NotificationUtils.toast("Camera deleted successfully", {
          severity: "success",
        })
        navigate(`/${routes?.cameraList}`, { replace: true })
      },
    }
  )

  return (
    <Fragment>
      <div className={styles.wrapper}>
        {isLoading ? (
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <Typography variant="p" variantColor={2}>
                Overview
              </Typography>
            </Grid>
            {loadingPlaceholders}
          </Grid>
        ) : (
          <Fragment>
            <div className={styles.header}>
              <div className={styles.headerTitle}>
                <Typography variant="h3-bold" variantColor={2}>
                  Overview
                </Typography>
                {camera?.updated_at && (
                  <Tooltip title={format(new Date(camera?.updated_at ?? ""), "dd/MM/yyyy, p")} placement="right">
                    <Typography variant="label-regular" variantColor={2} style={{ width: "fit-content" }}>
                      Updated {moment(camera?.updated_at).fromNow()}
                    </Typography>
                  </Tooltip>
                )}
              </div>

              <div className={styles.buttons}>
                <Button
                  onClick={() => setIsEditCameraOpen(true)}
                  size="small"
                  variant="secondary"
                  style={{ marginRight: 4 }}
                >
                  <EditOutlinedIcon fontSize="small" />
                </Button>
                <Button variant="secondary" onClick={handleOpenDeleteDialog} size="small">
                  <DeleteOutlineIcon fontSize="small" />
                </Button>
                <Divider orientation="vertical" flexItem className={styles.verticalDivider} />
                <CameraStatus camera={camera} />
              </div>
            </div>
            <Divider orientation="horizontal" flexItem className={styles.horizontalDivider} />

            <div className={styles.section}>
              <Typography variant="a" variantColor={2} className={styles.halfGutter}>
                URL
              </Typography>
              {camera?.url ? (
                <Typography variant="p" noWrap title={camera?.url}>
                  {camera?.url}
                </Typography>
              ) : (
                <Typography variant="p" variantColor={2} className={styles.noData}>
                  <LinkOffIcon className={styles.noDataIcon} />
                  No URL added yet
                </Typography>
              )}
            </div>

            {/* URL */}
            <Grid container spacing={1}>
              <Grid item md={7} xs={12} display="flex" flexDirection="column" gap={1.5} mt={1.5}>
                <div className={styles.section}>
                  <Typography variant="a" variantColor={2} className={styles.halfGutter}>
                    Tags
                  </Typography>
                  {camera?.tags && camera?.tags?.length > 0 ? (
                    <Grid container spacing={1} alignItems="center">
                      {camera?.tags?.map((tag, i) => (
                        <Grid item key={i}>
                          <Tag color="gray" size="small">
                            {tag}
                          </Tag>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Typography variant="p" variantColor={2} className={styles.noData}>
                      <LoyaltyIcon className={styles.noDataIcon} />
                      No tags added yet
                    </Typography>
                  )}
                </div>
                <div className={styles.section}>
                  <Typography variant="a" variantColor={2} className={styles.halfGutter}>
                    Node
                  </Typography>
                  {camera?.node_info ? (
                    <Typography variant="p">{camera?.node_info.name}</Typography>
                  ) : (
                    <Typography variant="p" variantColor={2} className={styles.noData}>
                      <DnsIcon className={styles.noDataIcon} />
                      No node selected yet
                    </Typography>
                  )}
                </div>
              </Grid>
              {/* camera Health */}
              {camera?.id && (
                <Grid item md={5} xs={12} sx={{ margin: "auto" }}>
                  <CameraHealth cameraId={camera?.id} />
                </Grid>
              )}
            </Grid>
            {/* URL */}

            {/* services */}
            <div className={styles.section} style={{ marginTop: 12 }}>
              <Typography variant="a" variantColor={2} className={styles.halfGutter}>
                Services
              </Typography>
              <div className={styles.servicesSection}>
                <div className={styles.serviceSection}>
                  <Typography variant="span-regular" variantColor={2}>
                    People service
                  </Typography>
                  {peopleServices && peopleServices?.length > 0 ? (
                    <Grid container className={styles.services} spacing={1}>
                      {peopleServices?.map((service) => (
                        <Grid item style={{ marginBottom: 2 }}>
                          <ServiceCard key={service} serviceEventType={service} size="small" />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Typography variant="span-regular" variantColor={2} className={styles.noData}>
                      <NearbyErrorIcon className={styles.noDataIcon} />
                      No people services selected yet
                    </Typography>
                  )}
                </div>
                <div className={styles.serviceSection}>
                  <Typography variant="span-regular" variantColor={2}>
                    Security service
                  </Typography>
                  {securityServices && securityServices?.length > 0 ? (
                    <Grid container className={styles.services} spacing={1}>
                      {securityServices?.map((service) => (
                        <Grid item style={{ marginBottom: 2 }}>
                          <ServiceCard key={service} serviceEventType={service} size="small" />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Typography variant="span-regular" variantColor={2} className={styles.noData}>
                      <NearbyErrorIcon className={styles.noDataIcon} />
                      No security services selected yet
                    </Typography>
                  )}
                </div>
                <div className={styles.serviceSection}>
                  <Typography variant="span-regular" variantColor={2}>
                    Vehicle service
                  </Typography>
                  {carServices && carServices?.length > 0 ? (
                    <Grid container className={styles.services} spacing={1}>
                      {carServices?.map((service) => (
                        <Grid item style={{ marginBottom: 2 }}>
                          <ServiceCard key={service} serviceEventType={service} size="small" />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Typography variant="span-regular" variantColor={2} className={styles.noData}>
                      <NearbyErrorIcon className={styles.noDataIcon} />
                      No vehicle services selected yet
                    </Typography>
                  )}
                </div>
              </div>
            </div>
            {/* Edit Camera */}
            {isEditCameraOpen && (
              <EditCamera isEdit handleClose={handleCloseEdit} isOpen camera={camera} key={camera?.id} />
            )}
            {/* Delete Camera */}
          </Fragment>
        )}

        <WarningDialog
          isOpen={isDeleteDialogOpen}
          isLoading={isLoadingCameraDelete}
          actionTitle="Delete"
          content="Be aware by deleting this camera this action can't be undone."
          onConfirm={deleteCamera}
          onCancel={handleCloseDeleteDialog}
          dialogTitle={`Delete “${camera?.name!}” camera?`}
        />
      </div>
    </Fragment>
  )
}

export default CameraInfo
