import { FC, useEffect } from "react"

import SyncIcon from "@mui/icons-material/Sync"
import WallpaperIcon from "@mui/icons-material/Wallpaper"
import { Grid, CircularProgress } from "@mui/material"

import { Button, Typography } from "@synapse-analytics/synapse-ui"

import { useWebSocketTask } from "../../../hooks/useWebSocketTask"
import CameraPlaceholder from "../assets/cameraPlaceholder.svg?react"

import styles from "./ImagePreview.module.scss"

/**
 * Props for the ImagePreview component.
 */
interface Props {
  url?: string
  isEdit?: boolean
}

/**
 * WebSocket response for frame capture.
 */
interface CaptureFrameResult {
  frame: string
}

const ImagePreview: FC<Props> = ({ url = "", isEdit }) => {
  const { isLoading, parsedMessage, triggerTask } = useWebSocketTask<CaptureFrameResult>({
    taskName: "capture.sample.frame",
    params: { url },
    notificationLabel: "Capture sample frame",
  })

  // trigger task to run once on mount
  useEffect(() => {
    if (url.trim() && isEdit) {
      triggerTask()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.titleAndButton}>
        <Typography variant="label-bold">Camera Preview</Typography>
        <Button
          onClick={triggerTask}
          size="small"
          variant="secondary"
          className={styles.previewButton}
          disabled={isLoading || !url?.trim()}
          isLoading={isLoading}
          startIcon={
            parsedMessage ? <SyncIcon className={styles.buttonIcon} /> : <WallpaperIcon className={styles.buttonIcon} />
          }
        >
          {parsedMessage ? "Reload" : "Load"} preview
        </Button>
      </div>
      <div className={styles.wrapper}>
        {isLoading ? (
          <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: "100%" }}>
            <CircularProgress />
          </Grid>
        ) : parsedMessage?.success ? (
          <img
            src={`data:image/jpeg;base64,${parsedMessage.result.frame}`}
            alt="cameraPreview"
            className={styles.imagePreviewCamera}
          />
        ) : (
          <div className={styles.noData}>
            <CameraPlaceholder />
            <Typography className={styles.noFrame} variant="span-bold" variantColor={2}>
              Please add a valid URL
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export default ImagePreview
