import { FC } from "react"
import { useQueryClient } from "react-query"
import { ReadyState } from "react-use-websocket"

import CachedIcon from "@mui/icons-material/Cached"
import { CircularProgress } from "@mui/material"

import { Button } from "@synapse-analytics/synapse-ui"

import { useWebSocketTask } from "../../../hooks/useWebSocketTask"

/**
 * Props for the RefreshFrame component.
 */
interface Props {
  isLoading?: boolean
  hasNoStream?: boolean
  cameraId?: number
}

const RefreshFrame: FC<Props> = ({ hasNoStream, isLoading, cameraId }) => {
  const queryClient = useQueryClient()

  const {
    isLoading: isRefreshing,
    triggerTask,
    readyState,
  } = useWebSocketTask<null>({
    taskName: "update.camera.frame",
    params: { camera_id: cameraId },
    notificationLabel: "Update camera frame",
    handleOnSuccess: () => {
      // Invalidate queries to fetch the updated data after successful frame update
      queryClient.invalidateQueries("fetchSingleCamera")
      queryClient.invalidateQueries("fetchCamerasPaginated")
    },
  })

  return (
    <Button
      onClick={triggerTask}
      disabled={hasNoStream || isLoading || isRefreshing}
      size="regular"
      variant="secondary"
    >
      {readyState === ReadyState.CONNECTING || isRefreshing ? ( // ReadyState.CONNECTING
        <CircularProgress size={20} style={{ marginRight: 4 }} />
      ) : (
        <CachedIcon fontSize="small" />
      )}
      Refresh sample frame
    </Button>
  )
}

export default RefreshFrame
