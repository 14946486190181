import moment from "moment"

export function calculateShopsSumPerTime(shopLogs) {
  const arr = []
  const timeDict = {}
  shopLogs.forEach((elem) => {
    elem.logs.forEach((log) => {
      if (log.timestamp in timeDict) {
        const existingObj = arr[timeDict[log.timestamp]]
        existingObj["Count In"] += log.count_in_sum
        existingObj["Count Out"] += log.count_out_sum
        existingObj["Male Count"] += log.male_count_in
        existingObj["Female Count"] += log.female_count_in
        existingObj["Adult Count"] += log.adult_count
        existingObj["Child Count"] += log.child_count
      } else {
        const newObj = {}
        newObj.timestamp = log.timestamp
        newObj["Count In"] = log.count_in_sum
        newObj["Count Out"] = log.count_out_sum
        newObj["Male Count"] = log.male_count_in
        newObj["Female Count"] = log.female_count_in
        newObj["Adult Count"] = log.adult_count
        newObj["Child Count"] = log.child_count
        timeDict[newObj.timestamp] = arr.length
        arr.push(newObj)
      }
    })
  })
  // Sort timestamp
  arr.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
  return arr
}

// used in HourlyData of tenants
// converting data from logs with time stamps
// into a data object suitable to represent count in vs hours count , as lines in a line graph
// x => represents hours of the day , y => represents count (in) number for that hour of the day
// which is ready to be plotted using nivo line graph
export function convertShopsDataToHourlyLineGraph(counterLogs) {
  const arr = calculateShopsSumPerTime(counterLogs)
  let arr2 = [{ id: "Count In", data: [] }]
  arr.forEach((elem) => {
    arr2[0].data.push({
      x: new Date(elem.timestamp),
      y: elem["Count In"],
      maleCount: elem["Male Count"],
      femaleCount: elem["Female Count"],
      adultCount: elem["Adult Count"],
      childCount: elem["Child Count"],
    })
  })
  return arr2
}

// this function just renames the hours to include PM/AM suffix if hours,
// if days => put day value in timestamp property
// and convert logs object property names from python convention to what we use in nivo graphs acceptable data forms
export function reformatAvgLogs(logs, isDays) {
  let reformattedLogs = [
    { Hour: "", timestamp: "", "Male Count": 0, "Female Count": 0, "Adult Count": 0, "Children Count": 0 },
  ]
  reformattedLogs = []
  if (logs && logs.length > 0) {
    for (let log of logs) {
      // use moment to add AM/PM to the hour values
      const hourWithSuffix = isDays ? "" : moment(new Date().setHours(log.hour, 0, 0, 0)).format("hh A")

      const reformattedLog = {
        Hour: hourWithSuffix,
        timestamp: isDays ? log.day : "",
        "Male Count": log.male_count,
        "Female Count": log.female_count,
        "Adult Count": log.adult_count,
        "Children Count": log.child_count,
      }
      reformattedLogs.push(reformattedLog)
    }
  }

  return reformattedLogs
}

export function convertToHourlyAvgGraphLogs(logs) {
  let countHours = []
  let tmpMale = []
  let tmpFemale = []
  let tmpAdult = []
  let tmpChild = []

  let result = [{ Hour: "", "Male Count": 0, "Female Count": 0, "Adult Count": 0, "Children Count": 0 }]

  if (logs && logs.length > 0) {
    for (let i = 0; i < 24; i++) {
      if (i < 12) {
        if (i === 0) {
          result[i] = {
            Hour: i + 12 + " AM",
            "Male Count": 0,
            "Female Count": 0,
            "Adult Count": 0,
            "Children Count": 0,
          }
        } else {
          result[i] = { Hour: i + " AM", "Male Count": 0, "Female Count": 0, "Adult Count": 0, "Children Count": 0 }
        }
      } else {
        if (i > 12) {
          result[i] = {
            Hour: i - 12 + " PM",
            "Male Count": 0,
            "Female Count": 0,
            "Adult Count": 0,
            "Children Count": 0,
          }
        } else {
          result[i] = { Hour: i + " PM", "Male Count": 0, "Female Count": 0, "Adult Count": 0, "Children Count": 0 }
        }
      }
      tmpMale[i] = 0
      tmpFemale[i] = 0
      tmpChild[i] = 0
      tmpAdult[i] = 0
      countHours[i] = 0
    }

    let lastCountedHour = -1
    for (let i = 0; i < logs.length; i++) {
      tmpMale[new Date(logs[i].timestamp).getHours()] += logs[i]["Male Count"]
      tmpFemale[new Date(logs[i].timestamp).getHours()] += logs[i]["Female Count"]
      tmpChild[new Date(logs[i].timestamp).getHours()] += logs[i]["Children Count"]
      tmpAdult[new Date(logs[i].timestamp).getHours()] += logs[i]["Adult Count"]
      if (new Date(logs[i].timestamp).getHours() !== lastCountedHour) {
        countHours[new Date(logs[i].timestamp).getHours()] += 1
        lastCountedHour = new Date(logs[i].timestamp).getHours()
      }
    }

    if (countHours.includes(0)) {
      for (let i = 0; i < countHours.length; i++) {
        if (countHours[i] === 0) {
          countHours[i] = 1
        }
      }
    }

    for (let i = 0; i < result.length; i++) {
      result[i]["Male Count"] = parseInt((tmpMale[i] / countHours[i]).toFixed(2))
      result[i]["Female Count"] = parseInt((tmpFemale[i] / countHours[i]).toFixed(2))
      result[i]["Adult Count"] = parseInt((tmpAdult[i] / countHours[i]).toFixed(2))
      result[i]["Children Count"] = parseInt((tmpChild[i] / countHours[i]).toFixed(2))
    }

    for (let i = 0; i < 7; i++) {
      let first = result.splice(0, 1)
      result.push(first[0])
    }
    for (let i = 0; i < result.length; i++) {
      result[i]["Male Count"] = parseInt(tmpMale[i] / countHours[i])
      result[i]["Female Count"] = parseInt(tmpFemale[i] / countHours[i])
      result[i]["Adult Count"] = parseInt(tmpAdult[i] / countHours[i])
      result[i]["Children Count"] = parseInt(tmpChild[i] / countHours[i])
    }
  }
  return result
}
export const getEntityLogs = (allLogs, entityID) => {
  let tmp = []
  if (allLogs && allLogs.length > 0) {
    for (let i = 0; i < allLogs.length; i++) {
      if (allLogs[i].entity === entityID) {
        tmp.push(allLogs[i])
      }
    }
  }
  return tmp
}

export function calculateShopsTotalCounts(shopLogs) {
  const result = []
  let tmpCount = 0
  for (let i = 0; i < shopLogs.length; i++) {
    let newObj = {}
    // newObj.entity_name = shopLogs[i].entity_name
    newObj.entity = shopLogs[i].entity
    for (let j = 0; j < shopLogs[i].logs.length; j++) {
      tmpCount += shopLogs[i].logs[j].count_in_sum
    }
    newObj.count_in = tmpCount
    result.push(newObj)
    tmpCount = 0
  }
  return result
}

export function calculateShopsAverageDwellingTime(dwelling) {
  const result = []
  let tmpCount = 0
  let numberOfEntries = 0
  for (let i = 0; i < dwelling.length; i++) {
    let newObj = {}
    newObj.entity = dwelling[i].entity
    for (let j = 0; j < dwelling[i].logs.length; j++) {
      tmpCount += dwelling[i].logs[j].avg_duration
      numberOfEntries++
    }
    newObj.avg_duration = parseFloat((tmpCount / numberOfEntries).toFixed(2))
    result.push(newObj)
    tmpCount = 0
    numberOfEntries = 0
  }
  return result
}

export function calculateTableData(
  shopsTableData,
  todaysShopsCounts,
  todaysShopsDwellingTime,
  dwellingConvergenceLogs
) {
  let tmp = mapCountsAndDwellingtoShops(
    shopsTableData,
    todaysShopsCounts,
    todaysShopsDwellingTime,
    dwellingConvergenceLogs
  )
  calculateCountOfAllCategories(tmp)
  calculateCountOfArea(tmp)
  return tmp
}

export function convertToLineGraphData(dailyCountData) {
  let tmp = [{ id: "", color: "", data: [{ x: new Date(), y: 0 }] }]
  tmp = []
  for (let log of dailyCountData) {
    if (log.category) {
      let randomNum = Math.random() * (1 - 0.1) + 0.1
      const randomColor = "#" + Math.floor((randomNum !== 0 ? randomNum : 1) * 16777215).toString(16)
      const logPointsArray = []
      for (let point of log.logs) {
        logPointsArray.push({ x: new Date(point.day), y: point.total_count })
      }
      const formattedObj = {
        id: log.category,
        color: randomColor,
        data: logPointsArray,
      }
      tmp.push(formattedObj)
    }
  }

  return tmp
}

export function mapCountstoShops(shopData, TotalCounts) {
  const clonedShopsData = Object.values(JSON.parse(JSON.stringify(shopData)))
  if (clonedShopsData?.length > 0 && clonedShopsData && TotalCounts?.length > 0 && TotalCounts) {
    for (let i = 0; i < clonedShopsData.length; i++) {
      for (let j = 0; j < TotalCounts.length; j++) {
        if (clonedShopsData[i].id === TotalCounts[j].entity) {
          clonedShopsData[i]["Count In"] = TotalCounts[j].count_in
        }
      }
    }
    // Tmp -> handle shops with no counts
    for (let i = 0; i < clonedShopsData.length; i++) {
      if (!clonedShopsData[i]["Count In"]) {
        clonedShopsData[i]["Count In"] = -1
      }
    }
  }
  return clonedShopsData
}

export function mapCountsAndDwellingtoShops(shopData, TotalCounts, Dwelling, dwellingConvergenceLogs) {
  const clonedShopsData = shopData
  if (clonedShopsData?.length > 0 && clonedShopsData && TotalCounts?.length > 0 && TotalCounts) {
    for (let i = 0; i < clonedShopsData.length; i++) {
      for (let j = 0; j < TotalCounts.length; j++) {
        if (clonedShopsData[i].id === TotalCounts[j].entity) {
          clonedShopsData[i]["Count In"] = TotalCounts[j].count_in
        }
      }
    }
    // Tmp -> handle shops with no counts
    for (let i = 0; i < clonedShopsData?.length; i++) {
      if (!clonedShopsData[i]["Count In"]) {
        clonedShopsData[i]["Count In"] = -1
      }
    }
  }
  if (Dwelling?.length > 0 && Dwelling) {
    for (let i = 0; i < clonedShopsData?.length; i++) {
      for (let j = 0; j < Dwelling?.length; j++) {
        if (clonedShopsData[i].id === Dwelling[j].entity) {
          clonedShopsData[i].dwelling = Dwelling[j].avg_duration
        }
      }
    }
  }
  if (dwellingConvergenceLogs && dwellingConvergenceLogs.length > 0) {
    for (let i = 0; i < clonedShopsData.length; i++) {
      for (let j = 0; j < dwellingConvergenceLogs.length; j++) {
        if (clonedShopsData[i].name === dwellingConvergenceLogs[j].shop) {
          clonedShopsData[i].dwellingConvergence = dwellingConvergenceLogs[j].dwelling_convergence_rate
        }
      }
    }
  }
  return clonedShopsData
}

export function convertToTop10BarChartLabels(logs) {
  logs.sort((a, b) => (a["Count In"] > b["Count In"] ? -1 : 1))
  if (logs?.length > 10) {
    return logs.slice(0, 10)
  } else {
    return logs
  }
}

export function convertToLowest10BarChartLabels(logs) {
  logs.sort((a, b) => (a["Count In"] > b["Count In"] ? 1 : -1))
  if (logs?.length > 10) {
    return logs.slice(0, 10)
  } else {
    return logs
  }
}

export function calculateCountOfAllCategories(shopsTableData) {
  let tmpCount = 0
  if (!shopsTableData) return
  for (let i = 0; i < shopsTableData?.length; i++) {
    for (let j = 0; j < shopsTableData.length; j++) {
      if (shopsTableData[i].category === shopsTableData[j].category && shopsTableData[j]["Count In"] !== -1) {
        tmpCount += shopsTableData[j]["Count In"]
      }
    }
    shopsTableData[i].category_count = tmpCount
    tmpCount = 0
  }

  for (let i = 0; i < shopsTableData.length; i++) {
    for (let j = 0; j < shopsTableData.length; j++) {
      if (shopsTableData[i].subcategory === shopsTableData[j].subcategory && shopsTableData[j]["Count In"] !== -1) {
        tmpCount += shopsTableData[j]["Count In"]
      }
    }
    shopsTableData[i].subcategory_count = tmpCount
    tmpCount = 0
  }
}
/**
 * @typedef {Object} CategorizedEntities
 * @property {number} totalCount - The sum of all in_count of all entities of that category.
 * @property {number} totalArea - The sum of all area of all entities of that category.
 */

/**
 * Categorizes entities by category and returns an object with keys as the categories' names and values as objects with two properties: category_in_count and category_whole_area.
 * @param {Array<Object>} entities - An array of objects with the following form: { entity_id: number, name: string, category: string, subcategory: string, area?: number, in_count: number, dwelling_convergence_rate: number, avg_dwelling_duration?: number, occupancy_rate: number }.
 * @returns {Object.<string, CategorizedEntities>} An object with keys as the categories' names and values as objects with two properties: category_in_count and category_whole_area.
 */
// this function calculates the total area and total counts in of categories of shop logs in a given time range
export function categorizeEntities(entities) {
  const categories = {}
  entities.forEach((entity) => {
    const { category, area, in_count } = entity
    if (category)
      if (category in categories) {
        categories[category].totalCount += in_count
        categories[category].totalArea += area || 0
      } else {
        categories[category] = {
          totalCount: in_count,
          totalArea: area || 0,
        }
      }
  })
  return categories
}

export function calculateCountOfArea(shopsTableData) {
  let tmpCount = 0
  if (!shopsTableData) return
  for (let i = 0; i < shopsTableData.length; i++) {
    for (let j = 0; j < shopsTableData.length; j++) {
      if (shopsTableData[i].category === shopsTableData[j].category && shopsTableData[j]["Count In"] !== -1) {
        tmpCount += shopsTableData[j].area
      }
    }
    shopsTableData[i].area_of_category = tmpCount
    tmpCount = 0
  }

  for (let i = 0; i < shopsTableData.length; i++) {
    for (let j = 0; j < shopsTableData.length; j++) {
      if (shopsTableData[i].subcategory === shopsTableData[j].subcategory && shopsTableData[j]["Count In"] !== -1) {
        tmpCount += shopsTableData[j].area
      }
    }
    shopsTableData[i].area_of_subcategory = tmpCount
    tmpCount = 0
  }
}

export function adjustCategoriesAndSubcategories(shops, categories) {
  let subcategories = []
  categories?.forEach((item) => {
    item?.subcategories?.forEach((item) => {
      subcategories.push(item)
    })
  })
  shops?.forEach((item) => {
    categories?.forEach((elem) => {
      if (elem?.id === item?.category) {
        item.category = elem.name
      }
    })
  })
  shops?.forEach((item) => {
    subcategories?.forEach((elem) => {
      if (elem?.id === item?.subcategory) {
        item.subcategory = elem.name
      }
    })
  })
  return shops
}

/**
 * Retrieves the category and subcategory names based on their IDs.
 *
 * @param {Array<{ id?: number; name: string; subcategories: { id?: number; name: string; }[]; }>} [categories=[]] - The array of category objects. Each category object should have the properties:
 *                             - id: a unique identifier for the category.
 *                             - name: the name of the category.
 *                             - subcategories: an array of subcategory objects, each containing:
 *                               - id: a unique identifier for the subcategory.
 *                               - name: the name of the subcategory.
 * @param {number | undefined} category_id - The unique identifier of the category.
 * @param {number | undefined}  subcategory_id - The unique identifier of the subcategory.
 * @returns {Array<string>} An array containing the category name and subcategory name as strings. If not found, returns [null, null].
 */
export function getCategoryAndSubcategoryNames(categories = [], category_id, subcategory_id) {
  let [categoryName, subcategoryName] = [null, null]
  for (let category of categories) {
    if (category.id === category_id) {
      categoryName = category.name
      for (let subcategory of category.subcategories) {
        if (subcategory.id === subcategory_id) {
          subcategoryName = subcategory.name
        }
      }
    }
  }
  return [categoryName, subcategoryName]
}
