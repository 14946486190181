import { FC, ChangeEvent } from "react"

import { Checkbox } from "@synapse-analytics/synapse-ui"
import { SwiperSlide } from "swiper/react"

import SwiperContainer from "../../../components/SwiperContainer"
import { definitions } from "../../../types/Generated/apiTypes"

import styles from "./CampaignEntity.module.scss"

type Gate = definitions["Gate"]

interface Props {
  entities: any[]
  allEntities?: any
  handleEntities?: (e: ChangeEvent<HTMLInputElement>, id: number, name: string) => void
  filterEntities?: any[]
  entityName?: string
  searchEntities?: string
}

const CampaignEntity: FC<Props> = ({ entities, filterEntities, handleEntities, entityName, allEntities }) => {
  const checkFilterEntities = entityName ? filterEntities : entities
  return (
    <SwiperContainer slidesPerView={3} spaceBetweenSlides={8} key={checkFilterEntities?.length}>
      {entities &&
        entities?.length > 0 &&
        checkFilterEntities?.map((ent: Gate, index) => (
          <SwiperSlide key={index}>
            <div key={ent?.id} className={`${styles.entity} ${!entityName ? styles.selectedEntity : ""}`}>
              <Checkbox
                checked={entityName ? !!allEntities![ent?.name] : true}
                name={`${ent?.name}${!entityName ? "_Selected" : ""}`}
                label={ent?.name}
                onChange={entityName ? (e: any) => handleEntities!(e, ent?.id!, ent?.name!) : undefined}
              />
            </div>
          </SwiperSlide>
        ))}
    </SwiperContainer>
  )
}

export default CampaignEntity
