import { useEffect, useMemo, useRef } from "react"

import { DebouncedFunc, debounce } from "lodash"

/**
 * Custom hook to create a debounced version of a callback function.
 *
 * @template T - The type of the callback function.
 * @param {T} callback - The callback function to debounce.
 * @param {number} [timer=1000] - The debounce delay in milliseconds. Defaults to 1000ms.
 * @returns {DebouncedFunc<T>} - The debounced version of the callback function.
 */
export const useDebounce = <T extends (...args: any[]) => void>(
  callback: T,
  timer: number = 1000
): DebouncedFunc<T> => {
  const ref = useRef<T | null>(null)

  useEffect(() => {
    ref.current = callback
  }, [callback])

  const debouncedCallback = useMemo(() => {
    const func = (...args: Parameters<T>): void => {
      ref.current?.(...args)
    }

    return debounce(func, timer)
  }, [timer])

  return debouncedCallback
}
