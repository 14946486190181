import React, { FC, useState, useContext, Fragment } from "react"
import { useMutation, useQueryClient } from "react-query"

import Timeline from "@mui/lab/Timeline"
import { Paper } from "@mui/material"

import { Button, InputChangeEvent, NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"

import { VisionAPI } from "../../../API/VisionAPI"
import Placeholder from "../../../assets/placeholder.svg"
import VerticalSlidingData from "../../../components/VerticalSlidingData"
import { definitions } from "../../../types/Generated/apiTypes"
import { CampaignContext } from "../CampaignContext/CampaignContext"
import CampaignPopup from "../components/CampaignPopup"
import CampaignGoalProgress from "./CampaignGoalProgress"
import CampaignNotes from "./CampaignNotes"
import CampaignTimeline from "./CampaignTimeline"

import styles from "./CampaignStatus.module.scss"

type CampaignDetail = definitions["CampaignDetail"]
interface Props {
  campaign: CampaignDetail
  title: string
  chosenCampaign: boolean
}

const CampaignStatus: FC<Props> = ({ campaign, title, chosenCampaign }) => {
  const { compare } = useContext(CampaignContext)
  const [openAddNote, setOpenAddNote] = useState(false)
  const [noteValue, setNoteValue] = useState<string>("")
  const queryClient = useQueryClient()

  const handleNoteValue = (e: InputChangeEvent) => {
    setNoteValue(e.target.value as string)
  }

  const handleOpenAdd = () => {
    setOpenAddNote(true)
  }

  const handleCloseAdd = () => {
    setOpenAddNote(false)
  }

  const { mutate: addNote, isLoading: loadingAddNote } = useMutation(
    () => VisionAPI.addCampaignNote({ campaign: campaign?.id!, body: noteValue }),
    {
      onSuccess: () => {
        setOpenAddNote(false)
        setNoteValue("")
        if (chosenCampaign) {
          queryClient.invalidateQueries("fetchChosenCampaign")
        } else {
          queryClient.invalidateQueries("fetchSingleCampaign")
        }
        queryClient.invalidateQueries("fetchWeatherSingleCampaign")
        NotificationUtils.toast("Note Saved Successfully", {
          severity: "success",
        })
      },
    }
  )

  return (
    <Fragment>
      <Paper
        elevation={0}
        className={styles.wrapper}
        sx={{
          height: compare ? "auto" : "95%",
        }}
      >
        {/* Status */}
        <CampaignGoalProgress campaign={campaign} title={title} />

        {/* Notes */}
        <Typography variant="a" variantColor={2} className={styles.title}>
          Notes
        </Typography>

        {/* add new note */}
        <Button onClick={handleOpenAdd} fullWidth variant="secondary">
          + Add new note
        </Button>

        {/* List of notes */}
        {campaign!?.notes?.length > 0 ? (
          <div className={styles.notesWrapper}>
            {/* notes timeline */}
            <Timeline
              sx={{
                "&.MuiTimeline-root": { position: "relative", top: 55 },
              }}
            >
              {campaign?.notes?.slice(0, 2).map((note) => (
                <CampaignTimeline key={note?.id!} note={note} />
              ))}
            </Timeline>

            {/* notes */}

            <div className={styles.noteWrapper}>
              {/*A React Elastic Carousel was used here */}
              <VerticalSlidingData
                data={campaign?.notes!}
                count={campaign?.notes?.length}
                dots={false}
                infinite={false}
                slidesToScroll={2}
                type="notes"
                slidesToShow={2}
              >
                {campaign?.notes?.map((note) => (
                  <CampaignNotes key={note?.id!} note={note} chosenCampaign={chosenCampaign} />
                ))}
              </VerticalSlidingData>
            </div>
          </div>
        ) : (
          // NO Notes
          <Fragment>
            <img src={Placeholder} alt="No Notes" className={styles.noNotes} />
            <Typography variant="label-regular" align="center" variantColor={2}>
              Nothing to show here
            </Typography>
          </Fragment>
        )}
      </Paper>

      {/* Add Notes */}
      <CampaignPopup
        type="addNote"
        name=""
        loading={loadingAddNote}
        open={openAddNote}
        handleAction={addNote}
        handleClose={handleCloseAdd}
        handleNoteValue={handleNoteValue}
        noteValue={noteValue}
      />
    </Fragment>
  )
}

export default CampaignStatus
