import { FC } from "react"

import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import { Box } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"

import { SelectedEntity } from "../../../../../types/Custom/Interfaces"

import styles from "./EntityBox.module.scss"

interface Props {
  isSelected?: boolean
  entity?: SelectedEntity
  selectionsCount?: number
  entityType?: "Gates" | "Corridors" | "Tenants"
  isTypeSelection?: boolean
  handleSelectType?: (type: "Gates" | "Corridors" | "Tenants") => void
  handleSelectEntity?: (entity: SelectedEntity) => void
  isFilled?: boolean
  isErrorFilling?: boolean
}
const EntityBox: FC<Props> = ({
  entityType,
  entity,
  handleSelectType,
  handleSelectEntity,
  selectionsCount,
  isTypeSelection,
  isSelected,
  isFilled,
  isErrorFilling,
}) => {
  return (
    <div
      className={styles.wrapper}
      style={{
        borderColor: isSelected ? "var(--blue-border-1)" : "",
      }}
      onClick={() => (isTypeSelection ? handleSelectType?.(entityType!) : handleSelectEntity?.(entity!))}
    >
      <Box display="flex" alignItems="center">
        {!isTypeSelection && !isErrorFilling && (
          <CheckIcon
            className={styles.success}
            sx={{
              visibility: isFilled ? "visible" : "hidden",
            }}
          />
        )}
        {!isTypeSelection && isErrorFilling && <CloseIcon className={styles.errorIcon} />}
        <Typography
          variant={isSelected ? "a" : "p"}
          variantColor={isSelected ? 2 : 1}
          color={isSelected ? "important" : "base"}
          title={isTypeSelection ? entityType : entity?.name}
        >
          {isTypeSelection
            ? entityType
            : entity?.name && entity?.name?.length > 16
            ? entity?.name?.slice(0, 16) + ".."
            : entity?.name}
        </Typography>
      </Box>
      {isTypeSelection && (
        <Typography
          variant="label-regular"
          variantColor={2}
          color={isSelected ? "important" : "base"}
          className={styles.selectionCount}
        >
          {selectionsCount} selected
        </Typography>
      )}
    </div>
  )
}
export default EntityBox
