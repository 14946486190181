import React, { useState, useContext, useEffect, Fragment } from "react"
import { useQueryClient } from "react-query"

import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { Grid } from "@mui/material"
import IconButton from "@mui/material/IconButton"

import { Typography, Tooltip } from "@synapse-analytics/synapse-ui"

import { FloorsContext } from "../../floorsContext/FloorsContext"

import styles from "./BoardSidebar.module.scss"

const BoardSidebar = () => {
  const locatingRef = React.useRef<HTMLCanvasElement>(null)
  const mappingRef = React.useRef<HTMLCanvasElement>(null)
  const [locatingFulfilled, setLocatingFulfilled] = useState(false)
  const [mappingFulfilled, setMappingFulfilled] = useState(false)
  const queryClient = useQueryClient()
  const {
    formik,
    activeStep,
    setActiveStep,
    cameraProperties,
    savedMappedCameras,
    setCameraProperties,
    addUpdateCamerasLocating,
  } = useContext(FloorsContext)

  const removeLocatedCameras = () => {
    setCameraProperties([])
  }
  const moveToStep = (step: number) => {
    if (step === activeStep) {
      return
    }
    // move to floor mapping
    if (step === 3) {
      // fetch mapped cameras if select next to mapping cameras step
      queryClient.invalidateQueries("fetchCameraMappingCoors")
      addUpdateCamerasLocating()
    }
    // move back to camera locating
    setActiveStep(step)
  }

  //   checking locating fulfillment status
  useEffect(() => {
    if (cameraProperties && cameraProperties.length === formik.values.ValidCameras.length) {
      setLocatingFulfilled(true)
    } else {
      setLocatingFulfilled(false)
    }
  }, [formik.values.ValidCameras, cameraProperties])

  //   checking mapping fulfillment status
  useEffect(() => {
    if (savedMappedCameras && savedMappedCameras.length === formik.values.ValidCameras.length) {
      setMappingFulfilled(true)
    } else {
      setMappingFulfilled(false)
    }
  }, [formik.values.ValidCameras, savedMappedCameras])

  useEffect(() => {
    const floorImage = new Image()
    floorImage.src = formik.values.floorPreview
    floorImage.onload = () => {
      const locatingCanvas = locatingRef.current
      const mappingCanvas = mappingRef.current
      const locatingCtx = locatingCanvas?.getContext("2d")
      const mappingCtx = mappingCanvas?.getContext("2d")
      if (locatingCtx) {
        locatingCtx.drawImage(floorImage, 0, 0, 866, 660)
      }
      if (mappingCtx) {
        mappingCtx.drawImage(floorImage, 0, 0, 866, 660)
      }
    }
  }, [locatingRef, mappingRef, formik, cameraProperties])

  return (
    <Fragment>
      <Grid item className={styles.sideBar}>
        {/* Step 2 - Locating Cameras Thumbnail */}
        <div className={styles.sideBarItem} key="Camera Locate">
          <div className={styles.stepTitle}>
            <Typography variant="h3-bold">Step 1</Typography>
            <Typography variant="p">Locating Cameras</Typography>
          </div>
          <div
            className={styles.thumbnailContainer}
            style={{
              border: locatingFulfilled
                ? "3px solid var(--green-background-2)"
                : activeStep === 2
                ? "3px solid var(--purple-background-1)"
                : "",
            }}
          >
            <canvas
              ref={locatingRef}
              className={styles.stepThumbnail}
              style={{
                border: "1px dotted var(--base-border-1)",
                cursor: "pointer",
                opacity: locatingFulfilled ? 0.4 : 1,
              }}
              onClick={() => moveToStep(2)}
              width="860px"
              height="660px"
            />
            {locatingFulfilled && (
              <div className={styles.fulfilledWrapper}>
                <Typography variant="h3-bold" color="positive" variantColor={2} className={styles.fulfillmentStatus}>
                  <CheckCircleIcon />
                  Done
                </Typography>
              </div>
            )}
            {/* deleting all located cameras logic to button */}
            {cameraProperties.length > 0 && activeStep === 2 && (
              <Tooltip title="Delete All Located Cameras">
                <IconButton className={styles.overlayButton} onClick={removeLocatedCameras} size="large">
                  <DeleteOutlineIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        {/* Step 3 - Mapping Cameras Thumbnail */}
        {/* disabled if no cameras are located yet */}
        <div
          className={styles.sideBarItem}
          key="Camera Map"
          style={{
            opacity: mappingFulfilled || cameraProperties.length < 1 ? 0.4 : 1,
          }}
        >
          <div className={styles.stepTitle}>
            <Typography variant="h3-bold" noWrap>
              Step 2
            </Typography>
            <Typography variant="p" noWrap>
              Mapping Cameras
            </Typography>
          </div>
          <div
            className={styles.thumbnailContainer}
            style={{
              border: mappingFulfilled
                ? "3px solid var(--green-background-2)"
                : activeStep === 3
                ? "3px solid var(--purple-background-1)"
                : "",
              opacity: cameraProperties.length > 0 ? 1 : 0.4,
            }}
          >
            <canvas
              ref={mappingRef}
              className={styles.stepThumbnail}
              style={{
                border: "1px dotted var(--base-border-1)",
                cursor: cameraProperties.length < 1 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (cameraProperties.length > 0) {
                  moveToStep(3)
                }
              }}
              width="860"
              height="660"
            />
          </div>
        </div>
      </Grid>
    </Fragment>
  )
}
export default BoardSidebar
