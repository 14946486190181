import { forwardRef, FC, ReactElement } from "react"

import HighlightOffIcon from "@mui/icons-material/Close"
import { Card, Dialog, DialogContent, Slide, SlideProps } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"

import styles from "./ViewFrameDialog.module.scss"

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
  handleCloseDialog: () => void
  open: boolean
  frame: string
  name?: string
  caption?: string
  infoItem?: ReactElement
}

const ViewFrameDialog: FC<Props> = ({ handleCloseDialog, open, frame, name, caption, infoItem }) => {
  return (
    <Dialog
      open={open}
      maxWidth="md"
      TransitionComponent={Transition}
      onClose={handleCloseDialog}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <DialogContent className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>
            <Typography variant="h2-bold">{name}</Typography>
            {caption && (
              <Typography variant="h3-bold" variantColor={2}>
                {caption}
              </Typography>
            )}
          </div>

          <HighlightOffIcon onClick={handleCloseDialog} className={styles.iconContainer} />
        </div>
        {infoItem && infoItem}
        <Card className={styles.frameCard}>
          <img src={frame} alt="frame" className={styles.frame} />
        </Card>
      </DialogContent>
    </Dialog>
  )
}
export default ViewFrameDialog
