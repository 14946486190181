import React, { FC, useState, useContext } from "react"
import { useQuery } from "react-query"

import { Grid } from "@mui/material"

import { Typography, InputText, DateRangePicker } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import moment, { Moment } from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import TagsList from "../../../components/TagsList"
import { definitions } from "../../../types/Generated/apiTypes"
import { CampaignContext } from "../CampaignContext/CampaignContext"

import styles from "./CampaignFirstStep.module.scss"

type Tag = definitions["Tag"]
interface Props {
  activeStep: number
  open: boolean
  edit: boolean
}

const CampaignFirstStep: FC<Props> = ({ activeStep, open, edit }) => {
  const { formik } = useContext(CampaignContext)
  const [startDate, setStartDate] = useState<Moment | null>(formik?.values?.startDate!)
  const [endDate, setEndDate] = useState<Moment | null>(formik?.values?.endDate!)

  const handleStartDateChange = (startDate: Moment | null) => {
    formik?.setFieldValue("startDate", moment(startDate))
    setStartDate(startDate)
  }
  const handleEndDateChange = (endDate: Moment | null) => {
    formik?.setFieldValue("endDate", moment(endDate))
    setEndDate(endDate)
  }

  const { data: tags, isLoading: isTagsLoading } = useQuery<Tag[], AxiosError>(
    "fetchCampaignTags",
    VisionAPI.fetchCampaignTags,
    {
      enabled: open && activeStep === 0 ? true : false,
    }
  )

  return (
    <div className={styles.infoWrapper}>
      <div className={styles.section}>
        <Typography variant="a" variantColor={2}>
          <span className={styles.step}>1</span>
          Pick Date Range*
        </Typography>
        <div className={styles.datePicker}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={handleStartDateChange}
            onEndDateChange={handleEndDateChange}
            disableFuture={false}
          />
        </div>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} className={styles.section}>
          <Typography variant="a" variantColor={2}>
            <span className={styles.step}>2</span>
            Campaign Name*
          </Typography>
          <InputText
            id="name"
            fullWidth
            handleBlur={formik!.handleBlur}
            handleChange={formik!.handleChange}
            value={formik?.values?.name}
            error={formik!.touched.name && Boolean(formik!.errors.name)}
            placeholder="E.g. Campaign name"
            description={formik!.errors.name ? formik!.errors.name : "Add a name from 8 to 40 characters"}
          />
        </Grid>
        <Grid item xs={12} md={6} className={styles.section}>
          <Typography variant="a" variantColor={2}>
            <span className={styles.step}>3</span>
            Campaign Tags
          </Typography>
          <TagsList
            formik={formik}
            placeholder="Choose tags from the dropdown"
            options={tags}
            selectedTags={formik?.values?.tags!}
            isLoading={isTagsLoading}
            fullWidth
          />
        </Grid>
      </Grid>
      <div className={styles.section}>
        <Typography variant="a" variantColor={2}>
          <span className={styles.step}>4</span>
          Description
        </Typography>
        <InputText
          id="description"
          fullWidth
          handleBlur={formik!.handleBlur}
          handleChange={formik!.handleChange}
          value={formik?.values?.description}
          description="Insert information regarding topic"
          placeholder="E.g. DETAIL"
        />
      </div>
    </div>
  )
}

export default CampaignFirstStep
