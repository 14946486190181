import moment from "moment"

import { Mark } from "../types/Custom/Interfaces"
import { definitions } from "../types/Generated/apiTypes"

type Log = definitions["HeatMapHourly"]

/**
 * Generates an array of hour marks based on the hours present in the provided logs.
 * @param logs - An array of `HeatMapV2HourlyLogs` objects, each containing an `hour` property (0–23).
 * @returns An array of `HourMark` objects for the hours present in the logs.
 */
export const generateHourMarks = (logs: Log[]): Mark[] => {
  // Extract unique hours from the logs
  const uniqueHours = Array.from(
    new Set(
      logs.map((log) => {
        return { hour: log.hour, isPeak: Boolean(log.is_peak) }
      })
    )
  )

  // Sort the hours in ascending order
  uniqueHours.sort((a, b) => a.hour - b.hour)

  // Generate hour marks for the unique hours
  return uniqueHours.map((hour) => ({
    value: hour.hour,
    label: moment().hour(hour.hour).minute(0).format("h A"), // Format as 12-hour time with AM/PM
    isPeak: hour.isPeak,
  }))
}
