import { FC, useState, useEffect, Fragment } from "react"
import { useQuery } from "react-query"

import { NotificationUtils } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import Placeholder from "../../../components/Placeholder"
import { Mark } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"
import { dayMarks, DynamicHeatmapMarks } from "../../../utils/heatmapUtils"
import HeatmapTimeline from "../components/HeatmapTimeline"

type FloorPlanVersionList = definitions["FloorPlanVersionList"]
type OldHeatMapHourly = definitions["OldHeatMapHourly"] & { version_no?: number }
interface Props {
  startDate?: string
  endDate?: string
  loadingFloorVersions: boolean
  getFloorLoadingState: (loading: boolean) => void
  floorPlanVersions?: FloorPlanVersionList[]
  floorId?: number | null
  timeGrain: "hourly" | "daily"
}

const HeatmapLogs: FC<Props> = ({
  floorPlanVersions,
  loadingFloorVersions,
  startDate,
  endDate,
  getFloorLoadingState,
  floorId,
  timeGrain,
}) => {
  const [marks, setMarks] = useState<Mark[]>()
  const [firstLogs, setFirstLogs] = useState<OldHeatMapHourly[]>()
  const [secondLogs, setSecondLogs] = useState<OldHeatMapHourly[]>()

  useEffect(() => {
    if (startDate && endDate) {
      const dateDiff = moment(endDate).diff(moment(startDate), "days")
      // daily
      if (timeGrain === "hourly") {
        setMarks(dayMarks!)
      } else {
        // Monthly
        const marks = DynamicHeatmapMarks(dateDiff + 1)
        setMarks(marks!)
      }
    }
  }, [startDate, endDate, timeGrain])

  const fetchSingleVersion = async () => {
    if (!floorPlanVersions || floorPlanVersions?.length < 1) return

    const data = await VisionAPI.fetchHeatmapVersionLogs({
      floor_version_id: floorPlanVersions[0]?.id as number,
      from_date: startDate as string,
      to_date: endDate as string,
    })
    const allRes1 = data?.map((res: OldHeatMapHourly) => {
      return { ...res, version_no: floorPlanVersions[0]?.version_no }
    })

    setFirstLogs(allRes1)
    setSecondLogs([])
    return allRes1
  }

  const fetchMultipleVersions = async () => {
    if (!floorPlanVersions || floorPlanVersions?.length < 1) return

    const versionOneEndDate = moment(floorPlanVersions[0]?.archived_at)?.format("YYYY-MM-DD")

    const res1 = await VisionAPI.fetchHeatmapVersionLogs({
      floor_version_id: floorPlanVersions[0]?.id as number,
      from_date: startDate as string,
      to_date: versionOneEndDate,
    })
    const res2 = await VisionAPI.fetchHeatmapVersionLogs({
      floor_version_id: floorPlanVersions[1]?.id as number,
      from_date: versionOneEndDate,
      to_date: endDate as string,
    })

    const allRes1 = res1?.map((res: OldHeatMapHourly) => {
      return { ...res, version_no: floorPlanVersions[0]?.version_no }
    })

    setFirstLogs(allRes1)
    const allRes2 = res2?.map((res: OldHeatMapHourly) => {
      return { ...res, version_no: floorPlanVersions[1]?.version_no }
    })
    setSecondLogs(allRes2)

    return [...allRes1, ...allRes2]
  }

  const fetchHeatmapVersionLogs = async () => {
    if (floorPlanVersions?.length === 1) {
      return fetchSingleVersion()
    } else {
      return fetchMultipleVersions()
    }
  }

  // fetch heatmap for floor plan versions
  const { data: heatmapLogs, isFetching: isLoadingLogs } = useQuery(
    "fetchHeatmapVersionLogs",
    fetchHeatmapVersionLogs,
    {
      enabled: [startDate, !!endDate, !loadingFloorVersions, floorPlanVersions && floorPlanVersions?.length > 0].every(
        (check) => !!check
      ),
      onSettled: (data) => {
        if (data && data.length < 1) {
          NotificationUtils.toast("Floor's heatmap logs not found for selected date range", {
            severity: "warning",
          })
        }
      },
    }
  )

  useEffect(() => {
    if (getFloorLoadingState) getFloorLoadingState(isLoadingLogs)
    //eslint-disable-next-line
  }, [isLoadingLogs, getFloorLoadingState])

  return (
    <Fragment>
      {floorPlanVersions && floorPlanVersions.length > 0 && heatmapLogs && heatmapLogs.length > 0 ? (
        <HeatmapTimeline
          timeGrain={timeGrain}
          firstLogs={firstLogs!}
          secondLogs={secondLogs!}
          marks={marks! && marks!}
          floorPlanVersions={floorPlanVersions}
          heatmapLogs={heatmapLogs!}
          key={`Heatmap_Of_${floorId}_${timeGrain}`}
        />
      ) : (
        <Placeholder isScreenPlaceholder isLoading={loadingFloorVersions || isLoadingLogs} />
      )}
    </Fragment>
  )
}

export default HeatmapLogs
