import { ChangeEvent, FC, Fragment, KeyboardEvent, useRef, useState } from "react"
import { useMutation } from "react-query"

import SendIcon from "@mui/icons-material/ArrowForward"
import CloseIcon from "@mui/icons-material/ExpandMore"
import StopIcon from "@mui/icons-material/Stop"

import { Button, TextArea, Typography } from "@synapse-analytics/synapse-ui"

import { VisionAPI } from "../../../API/VisionAPI"
import auth from "../../../utils/auth"
import BotAvatar from "../assets/BotAvatar.svg?react"
import Message from "./Message"

import styles from "./ChatWindow.module.scss"

/**
 * Props for the ChatWindow component.
 */
interface ChatWindowProps {
  /** Determines if the chat window is open. */
  isOpen: boolean
  /** Function to handle minimizing the chatbot. */
  handleMinimizeChatbot: () => void
  /** Optional session UUID for the chat session. */
  sessionUUID?: string
  /** Function to handle starting a new chat session. */
  handleStartNewChat: () => void
}

/**
 * Represents a message object with a sender and content.
 */
type MessageObj = { sender: "user" | "bot"; content: string }

/**
 * ChatWindow component provides a user interface for interacting with the Azka Bot.
 * It allows users to send messages and receive responses from the bot.
 */
const ChatWindow: FC<ChatWindowProps> = ({
  isOpen,
  handleMinimizeChatbot,
  handleStartNewChat,
  sessionUUID,
}: ChatWindowProps) => {
  const startingMessages: MessageObj[] = [
    { sender: "bot", content: `Hi ${auth?.getFullName()} 👋, I'm Azka Bot 🤖 , An automated assistant.` },
    { sender: "bot", content: "How can I help you today?" },
  ]

  const messagesContainerRef = useRef<HTMLDivElement>(null)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const [messages, setMessages] = useState<MessageObj[]>(startingMessages)
  const [userMessage, setUserMessage] = useState<string>("")
  const [hasError, setHasError] = useState<boolean>(false)
  const [isTextStreaming, setIsTextStreaming] = useState(false)
  const [shouldStopStream, setShouldStopStream] = useState(false)

  const { mutate: sendMessageToChatbot, isLoading } = useMutation(
    (message: string) => VisionAPI.sendMessageToChatbot({ chat_uuid: sessionUUID as string, message }),
    {
      onSuccess: (response) => {
        setShouldStopStream(false)
        setMessages((prevMessages) => [...prevMessages, { sender: "bot", content: response }])
      },
      onError: () => {
        setHasError(true)
      },
    }
  )

  /**
   * Scrolls the messages container to the bottom smoothly.
   * This function uses a timeout to ensure the scroll happens after the new message is rendered.
   */
  const scrollToBottom = () => {
    setTimeout(() => {
      const messagesContainer = messagesContainerRef.current
      if (messagesContainer) {
        messagesContainer.scrollTo({
          top: messagesContainer.scrollHeight,
          behavior: "smooth",
        })
      }
    }, 200)
  }

  /**
   * Handles sending a user message.
   * If the user message is not empty, it adds the message to the messages state,
   * clears the user message input, and scrolls the messages container to the bottom.
   */
  const handleSendMessage = () => {
    setShouldStopStream(true)
    setIsTextStreaming(false)
    if (userMessage.trim() !== "") {
      setMessages((prevMessages) => [...prevMessages, { sender: "user", content: userMessage }])
      sendMessageToChatbot(userMessage)
      setUserMessage("")
      scrollToBottom()
    }
    // Focus the text area after stopping the stream
    textAreaRef?.current?.focus()
  }

  /**
   * Initiates a new chat session by resetting the messages array to its initial state,
   * clearing any error state, and starting a new chat session with a new UUID.
   */
  const handleNewChat = () => {
    // Reset the messages array to contain only the starting messages
    setMessages(startingMessages)
    // Clear any error state that may have occurred in the previous chat session
    setHasError(false)
    // Start a new chat session with a new UUID
    handleStartNewChat()
  }

  /**
   * Handles the key down event for the text area.
   * Sends the message when the Enter key is pressed without the Shift key.
   * @param e - The keyboard event.
   */
  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // send user message
      handleSendMessage()
      // Prevent the default line break behavior
      e.preventDefault()
      // Ensure the message is not sent twice by stopping the event propagation
      e.stopPropagation()
    }
  }

  if (!isOpen) return

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          <BotAvatar />
          <div className={styles.titleText}>
            <Typography variant="h3-regular">Chat with</Typography>
            <Typography variant="h2-bold">Azka Bot</Typography>
          </div>
        </div>
        <CloseIcon onClick={handleMinimizeChatbot} className={styles.close} />
      </div>
      <div className={styles.messagesContainer} ref={messagesContainerRef}>
        {messages.map((message, index) => (
          <Message
            key={`${message.sender}_message_${index}`}
            sender={message.sender}
            content={message.content}
            isLatest={index === messages.length - 1}
            scrollToBottom={scrollToBottom}
            setIsTextStreaming={setIsTextStreaming}
            shouldStopStream={shouldStopStream}
            textAreaRef={textAreaRef}
          />
        ))}
        {isLoading && <Message sender="bot" isLoading />}
        {hasError && (
          <Fragment>
            <Message sender="bot" hasError />
            <Button variant="secondary" onClick={handleNewChat}>
              New Chat
            </Button>
          </Fragment>
        )}
      </div>
      <div className={styles.inputContainer}>
        <TextArea
          placeholder="Enter your message..."
          heightControl={{ lines: 1, isExpandable: true, maxRowsBeforeScroll: 3 }}
          hideDescription
          width={360}
          value={userMessage}
          disabled={isLoading || hasError}
          ref={textAreaRef}
          handleChange={(e: ChangeEvent<HTMLTextAreaElement>) => setUserMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          // To Reset the text area after user enters the message
          key={messages?.length}
        />
        <div className={styles.sendButton} onClick={handleSendMessage}>
          {isTextStreaming ? (
            <StopIcon fontSize="small" className={styles.sendIcon} />
          ) : (
            <SendIcon fontSize="small" className={styles.sendIcon} />
          )}
        </div>
      </div>
    </div>
  )
}

export default ChatWindow
