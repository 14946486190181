import { useState, useEffect, useRef } from "react"

import GetAppIcon from "@mui/icons-material/GetApp"

import { Button, DateRangePicker, Typography } from "@synapse-analytics/synapse-ui"
import intervalToDuration from "date-fns/intervalToDuration"
import { useQueryParam, withDefault } from "use-query-params"

import AnalyticsTargetsFilter from "../../components/AnalyticsTargetsFilter"
import { useDateQuery } from "../../hooks/useDateQuery"
import { exportGraphs } from "../../utils/genericHelpers"
import { StaffParam } from "../../utils/queryParamCustomConfigs"
import ShopStatistics from "./components/ShopStatistics"

import styles from "./Tenants.module.scss"

const Tenants = () => {
  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()
  const [analyticsTarget, setAnalyticsTarget] = useQueryParam("target", withDefault(StaffParam, "visitors"))
  const staffFilterValue = analyticsTarget === "visitors" ? false : analyticsTarget === "staff" ? true : null

  const [timeGrain, setTimeGrain] = useState<"hour" | "day" | null>(null)
  const [interval, setInterval] = useState<Duration>()
  const [isLoading, setIsLoading] = useState(true)
  const cardsRefs = {
    categoriesOverTimeRef: useRef(),
    categoriesPerformanceRef: useRef(),
    subcategoriesPerformanceRef: useRef(),
    hourlyAvgRef: useRef(),
    weekDayAvgRef: useRef(),
  }

  useEffect(() => {
    if (endDate !== null && startDate !== null) {
      const interval = intervalToDuration({
        start: startDate!.toDate(),
        end: endDate!.toDate(),
      })
      setInterval(interval)
      if (interval.days! > 1 || interval.months! >= 1) {
        setTimeGrain("day")
      } else {
        setTimeGrain("hour")
      }
    }
  }, [startDate, endDate, timeGrain])

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Typography
          variant="h2-regular"
          tooltip="Comprehensive analytics dashboard showcasing counts and trends across various categories and subcategories."
          tooltipPlacement="right"
          tooltipIconSize={22}
          variantColor={2}
        >
          Categories
        </Typography>
        <Button
          startIcon={<GetAppIcon fontSize="small" />}
          variant="secondary"
          onClick={() => exportGraphs(cardsRefs, startDate, endDate, "Tenants")}
          disabled={!startDate || !endDate || isLoading}
        >
          Export
        </Button>
      </div>

      <div className={styles.filters}>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          disabled={isLoading}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          disableFuture
        />
        <AnalyticsTargetsFilter value={analyticsTarget} setValue={(target) => setAnalyticsTarget(target)} />
      </div>

      <ShopStatistics
        startDate={startDate}
        endDate={endDate}
        interval={interval!}
        setLoadingState={setIsLoading}
        timeGrain={timeGrain!}
        refs={cardsRefs}
        staffFilterValue={staffFilterValue}
      />
    </div>
  )
}
export default Tenants
