import React, { useState, useContext, useEffect, Fragment } from "react"

import BrushIcon from "@mui/icons-material/Brush"
import DeleteIcon from "@mui/icons-material/Delete"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import UndoIcon from "@mui/icons-material/Undo"
import { Grid, Button, Snackbar } from "@mui/material"
import MuiAlert, { AlertProps } from "@mui/material/Alert"

import { Button as SUIButton } from "@synapse-analytics/synapse-ui"

import { FloorsContext } from "../../floorsContext/FloorsContext"
import BoardSidebar from "./BoardSidebar"
import LocateCameraFloor from "./LocateCameraFloor"
import MapCameraFloor from "./MapCameraFloor"

import styles from "./FloorsArtboard.module.scss"

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const FloorsArtboard = () => {
  const {
    activeStep,
    selectedCamera,
    selectedMappingCamera,
    handleOnSelectCamera,
    handleUndoCamerasPoints,
    handleSaveCameraChanges,
    cameraMappingProperties,
    saveCameraChangesAlert,
    camerasLocatingAlert,
    cameraMappingAlert,
    setCamerasLocatingAlert,
    setCameraMappingAlert,
  } = useContext(FloorsContext)

  const [wiggleAnimation, setWiggleAnimation] = useState(false)
  const handleSaveCamerasDisabled = () => {
    const notValidFloorPoints = cameraMappingProperties?.points?.every((camera) => camera?.x_floor !== undefined)
    if (cameraMappingProperties?.points?.length < 4 || !notValidFloorPoints) {
      return true
    } else {
      return false
    }
  }
  const handleInvalidCameraClick = () => {
    setWiggleAnimation(true)
    setTimeout(() => {
      setWiggleAnimation(false)
    }, 300)
  }
  useEffect(() => {
    if (saveCameraChangesAlert) {
      handleInvalidCameraClick()
    }
  }, [saveCameraChangesAlert])
  const handleCloseCamerasLocatingAlert = () => (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    setCamerasLocatingAlert(false)
  }
  const handleCloseCameraMappingAlert = () => (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    setCameraMappingAlert(false)
  }

  return (
    <div className={styles.artBoard}>
      <div className={styles.drawingTools}>
        <Button className={styles.drawingButton} style={{ background: "var(--primary-background-default)" }}>
          {activeStep === 2 ? (
            <span className={styles.buttonContent}>
              <LocationOnIcon />
              Locate Camera
            </span>
          ) : (
            <span className={styles.buttonContent}>
              <BrushIcon />
              Map Camera
            </span>
          )}
        </Button>
        <Button
          className={styles.drawingButton}
          style={{ padding: "8px 32px" }}
          onClick={() =>
            activeStep === 2
              ? handleOnSelectCamera(
                  false,
                  selectedCamera.cameraId,
                  selectedCamera.colorCode,
                  selectedCamera.cameraName,
                  selectedCamera.cameraSrc
                )
              : handleOnSelectCamera(
                  false,
                  selectedMappingCamera.cameraId,
                  selectedMappingCamera.colorCode,
                  selectedMappingCamera.cameraName,
                  selectedMappingCamera.cameraSrc
                )
          }
        >
          <span className={styles.buttonContent}>
            <DeleteIcon />
            Delete
          </span>
        </Button>
        {activeStep === 3 && (
          <Fragment>
            <Button
              className={styles.drawingButton}
              style={{ padding: "8px 32px" }}
              onClick={() => handleUndoCamerasPoints(selectedMappingCamera.cameraId)}
            >
              <span className={styles.buttonContent}>
                <UndoIcon />
                Undo
              </span>
            </Button>
            <div className={styles.drawingCta}>
              <SUIButton
                className={`${wiggleAnimation && styles.wiggleAnimation}`}
                disabled={handleSaveCamerasDisabled()}
                onClick={handleSaveCameraChanges}
                variant="secondary"
              >
                Save Camera
              </SUIButton>
            </div>
          </Fragment>
        )}
      </div>
      <Grid container justifyContent={activeStep === 3 ? "center" : "flex-start"}>
        {activeStep === 2 && <BoardSidebar />}
        {/* Drawing Area */}
        {/* Step 2 => Locating Cameras */}
        {/* Step 3 => Mapping Cameras */}
        <Grid item style={{ padding: activeStep === 2 ? "28px" : "28px 0px", position: "relative" }}>
          {activeStep === 2 ? <LocateCameraFloor /> : <MapCameraFloor />}
        </Grid>
      </Grid>
      {/* Successful Locating */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={camerasLocatingAlert}
        autoHideDuration={400}
        onClose={handleCloseCamerasLocatingAlert}
      >
        <div>
          <Alert onClose={handleCloseCamerasLocatingAlert} severity="success">
            Cameras Locations Saved Successfully
          </Alert>
        </div>
      </Snackbar>
      {/* Successful Mapping */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={cameraMappingAlert}
        autoHideDuration={400}
        onClose={handleCloseCameraMappingAlert}
      >
        <div>
          <Alert onClose={handleCloseCameraMappingAlert} severity="success">
            Camera - {selectedMappingCamera.cameraName} Mapping Saved Successfully
          </Alert>
        </div>
      </Snackbar>
    </div>
  )
}
export default FloorsArtboard
