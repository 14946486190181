import { useState, Fragment } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"

import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined"
import EditIcon from "@mui/icons-material/EditOutlined"

import { Action } from "@material-table/core"
import { NotificationUtils, Tag, Typography } from "@synapse-analytics/synapse-ui"
import { StringParam, useQueryParam, withDefault } from "use-query-params"

import { VisionAPI } from "../../API/VisionAPI"
import Search from "../../components/Search"
import WarningDialog from "../../components/WarningDialog"
import UsersTable from "../../components/tables/GenericTable"
import { routes } from "../../routes/routes"
import { TableColumn } from "../../types/Custom/Types"
import { definitions } from "../../types/Generated/apiTypes"
import ActionButton from "./components/ActionButton"

import styles from "./AllUsers.module.scss"

type UserList = definitions["UserList"]

const AllUsers = () => {
  const queryClient = useQueryClient()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [searchValue, setSearchValue] = useQueryParam("search", withDefault(StringParam, ""))
  const [userId, setUserId] = useState<number | null>(null)
  const [deletedUserName, setDeletedUserName] = useState("")
  const navigate = useNavigate()
  const handleSearch = (value: string) => {
    setSearchValue(value)
  }

  // get all users
  const { data: allUsers, isLoading: usersLoading } = useQuery<UserList[]>("fetchUsers", VisionAPI.fetchUsers)

  // open dialog
  const handleOpenDeleteDialog = (id: number | null, username: string) => {
    setIsDeleteDialogOpen(true)
    setUserId(id)
    setDeletedUserName(username)
  }

  // close dialog
  const handleCloseDeleteDialog = () => setIsDeleteDialogOpen(false)

  const { mutate } = useMutation((id: number) => VisionAPI.deleteUser(id), {
    onSuccess: () => {
      NotificationUtils.toast(`User ${deletedUserName} has been successfully deleted.`, {
        severity: "success",
      })
      queryClient.invalidateQueries("fetchUsers")
      setIsDeleteDialogOpen(false)
    },
  })

  // Filter users based on search value
  const filteredData = allUsers?.filter(
    (user) =>
      user?.username?.toLowerCase().includes(searchValue.toLowerCase()) ||
      user?.email?.toLowerCase().includes(searchValue.toLowerCase())
  )

  const tableColumns: TableColumn[] = [
    {
      title: "Name",
      field: "username",
      searchable: false,
    },
    {
      title: "E-mail",
      field: "email",
      searchable: false,
      render: (rowData: UserList) => <Typography variant="p">{rowData.email || "__"}</Typography>,
    },
    {
      title: "State",
      field: "is_active",
      searchable: false,
      render: (rowData: UserList) => (
        <Tag variant="filled" size="xsmall" color={rowData.is_active ? "green" : "red"}>
          {rowData.is_active ? "Active" : "Inactive"}
        </Tag>
      ),
    },
  ]

  const tableActions: Action<UserList>[] = [
    {
      icon: () => <EditIcon />,
      tooltip: "Edit User",
      onClick: (_e: any, data: UserList) =>
        navigate(`/${routes?.users}/${data?.id!}`, {
          state: { edit: true },
        }),
    },
    {
      icon: () => <DeleteIcon />,
      tooltip: "Delete User",
      onClick: (_e: any, data: UserList) => handleOpenDeleteDialog(data?.id!, data?.username!),
    },
  ]

  const isEmptyTable = (allUsers && allUsers?.length < 1) || !allUsers

  return (
    <Fragment>
      <Typography
        variant="h2-regular"
        tooltip="Manage users and permissions"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        Users List
      </Typography>

      <div className={styles.header}>
        <Search
          placeholder="E.g. Ahmed, Mohamed"
          handleSearch={handleSearch}
          loading={usersLoading}
          searchValue={searchValue}
        />
        <div>
          <ActionButton
            title="New User"
            isLoading={usersLoading}
            onClick={() =>
              navigate(`/${routes?.createUser}`, {
                state: { edit: false },
              })
            }
          />
        </div>
      </div>

      {/* Users Table */}
      <UsersTable
        data={filteredData}
        actions={tableActions}
        columns={tableColumns}
        isLoading={usersLoading}
        title="Users"
        isEmpty={isEmptyTable}
        hasExport={false}
        hasPagination
        height="auto"
      />

      <WarningDialog
        isOpen={isDeleteDialogOpen}
        onConfirm={() => mutate(userId as number)}
        onCancel={handleCloseDeleteDialog}
        dialogTitle={`Are you sure to delete user “${deletedUserName}”?`}
        content={`You're about to delete user “${deletedUserName}”, Be aware when deleting users this action can't be undone!`}
        actionTitle="Delete"
        cancelTitle="Cancel"
        isDangerousAction
      />
    </Fragment>
  )
}

export default AllUsers
