import React, { FC, ReactNode, useEffect, useRef, useState, useMemo } from "react"
import Slider from "react-slick"

import ArrowDown from "@mui/icons-material/KeyboardArrowDown"
import ArrowUp from "@mui/icons-material/KeyboardArrowUp"
import { Box, CircularProgress } from "@mui/material"

import styles from "./VerticalSlidingData.module.scss"

interface Props {
  type: string
  data?: any[]
  count: number
  children?: ReactNode
  dots?: boolean
  infinite?: boolean
  slidesToShow?: number
  slidesToScroll?: number
  slidesPerRow?: number
  rows?: number
  nextPage?: () => void
  prevPage?: () => void
  prefetchNext?: (nextPage: number) => void
  loading?: boolean
  responsiveSlides?: any
  carouselRef?: React.MutableRefObject<any>
}

const VerticalSlidingData: FC<Props> = ({
  count,
  dots,
  infinite,
  slidesToShow,
  slidesToScroll,
  slidesPerRow,
  rows,
  children,
  type,
  nextPage,
  prevPage,
  loading,
  prefetchNext,
  responsiveSlides,
  carouselRef,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const sliderRef = useRef<any>(null)
  // calculate current page number (used for disable next button state)
  const currentPage = useMemo(() => count - slidesToShow!, [slidesToShow, count])
  // calculate max number pages
  const maxPages = useMemo(() => Math.ceil(count - slidesToShow!), [count, slidesToShow])

  const settings = {
    dots,
    speed: 200,
    infinite,
    arrows: false,
    slidesToShow,
    vertical: true,
    swipeToSlide: false,
    draggable: false,
    slidesToScroll,
    afterChange: (current: number) => setCurrentSlide(current),
    responsive: responsiveSlides ? responsiveSlides : undefined,
  }

  const fetchData = () => {
    nextPage!()
    const toPrefetch = currentPage + 1
    prefetchNext!(toPrefetch)
  }

  useEffect(() => {
    if (type === "paginated") {
      fetchData()
    }
    //eslint-disable-next-line
  }, [])

  const handleNext = () => {
    if (type === "paginated") {
      fetchData()
    }
    if (!loading) {
      if (type === "drawing board") {
        carouselRef!?.current!?.slickNext()
      } else {
        sliderRef!?.current!?.slickNext()
      }
    }
  }
  const handlePrev = () => {
    if (type === "paginated") {
      prevPage!()
    }
    if (!loading) {
      if (type === "drawing board") {
        carouselRef!?.current!?.slickPrev()
      } else {
        sliderRef!?.current!?.slickPrev()
      }
    }
  }
  // checking if this is last page or not
  const hasMore = useMemo(() => {
    if (currentSlide < maxPages) {
      return true
    } else {
      return false
    }
  }, [currentSlide, maxPages])

  return (
    <Box
      sx={{
        "& .slick-initialized .slick-slide": {
          boxSizing: "border-box",
        },
      }}
    >
      {/* prev */}
      <ArrowUp
        className={`${type === "drawing board" ? styles.dottedArrow : styles.arrow} ${
          currentSlide < 1 ? styles.disabled : ""
        }`}
        onClick={!loading && currentSlide > 0 ? handlePrev : undefined}
        style={{
          backgroundColor: currentSlide < 1 ? (type === "drawing board" ? "" : "var(--base-background-3)") : "",
        }}
      />
      {/* main slider */}

      <Box
        sx={{
          margin: 0,
          // important to disable overflow from slider container [to make elements absolute]
          "& .slick-track": {
            maxHeight: 285,
          },
        }}
      >
        <Slider ref={type === "drawing board" ? carouselRef : sliderRef} {...settings}>
          {children}
        </Slider>
      </Box>
      {/* next */}
      {loading ? (
        <div className={styles.arrow} style={{ display: "flex", alignItems: "center" }}>
          <CircularProgress size={24} />
        </div>
      ) : (
        <ArrowDown
          className={`${type === "drawing board" ? styles.dottedArrow : styles.arrow} ${
            !hasMore ? styles.disabled : ""
          }`}
          style={{
            marginTop: 4,
            backgroundColor: !hasMore ? (type === "drawing board" ? "" : "var(--base-background-3)") : "",
          }}
          onClick={!loading && hasMore ? handleNext : undefined}
        />
      )}
    </Box>
  )
}

export default VerticalSlidingData
