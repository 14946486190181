import { FC, useState } from "react"

import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import ClockIcon from "@mui/icons-material/QueryBuilderOutlined"
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined"
import { CardMedia } from "@mui/material"

import { Typography, Button } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import ViewFrameDialog from "../../../components/ViewFrameDialog"
import { definitions } from "../../../types/Generated/apiTypes"
import EventTypeChip from "./EventTypeChip"

import styles from "./EventCard.module.scss"

type EventsPaginated = definitions["SecurityViolationList"]
/**
 * @interface Props
 */
interface Props {
  /**
   * The event log data.
   * @type {EventsPaginated}
   */
  eventLog: EventsPaginated
}

const EventCard: FC<Props> = ({ eventLog }) => {
  const [isHoveringOnFrame, setIsHoveringOnFrame] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  return (
    <div className={styles.eventCard}>
      <EventTypeChip type={eventLog?.type!} />
      {!eventLog?.image ? (
        <div className={styles.media}>
          <Typography variant="label-regular" variantColor={2} className={styles.noFrame}>
            No Frame Captured
          </Typography>
        </div>
      ) : (
        <div
          onMouseEnter={() => setIsHoveringOnFrame(true)}
          onMouseLeave={() => setIsHoveringOnFrame(false)}
          className={styles.media}
        >
          {isHoveringOnFrame && (
            <div className={styles.backdrop}>
              <Button variant="secondary" onClick={() => setIsDialogOpen(true)} size="small">
                View
              </Button>
            </div>
          )}
          <CardMedia className={styles.media} image={eventLog?.image!} title={eventLog?.camera_name!} />
          <ViewFrameDialog
            frame={eventLog?.image!}
            handleCloseDialog={() => {
              setIsHoveringOnFrame(false)
              setIsDialogOpen(false)
            }}
            open={isDialogOpen}
            name={`${eventLog?.camera_name}`}
            caption={moment(eventLog?.timestamp!).format("DD/MM/YYYY h:mm A")}
            infoItem={
              <div>
                <EventTypeChip type={eventLog?.type!} />
              </div>
            }
          />
        </div>
      )}
      <div className={styles.details}>
        <Typography variant="p" variantColor={2} className={styles.detail}>
          <CalendarTodayIcon fontSize="small" />
          {moment(eventLog?.timestamp!).format("DD/MM/YYYY")}
        </Typography>
        <Typography variant="p" variantColor={2} className={styles.detail}>
          <ClockIcon fontSize="small" />
          {moment(eventLog?.timestamp!).format("h:mm:ss A")}
        </Typography>
      </div>
      <Typography variant="p" variantColor={2} title={eventLog?.camera_name!} className={styles.detail} noWrap>
        <VideocamOutlinedIcon fontSize="small" />
        <span className={styles.cameraName} title={eventLog?.camera_name!}>
          {eventLog?.camera_name}
        </span>
      </Typography>
    </div>
  )
}
export default EventCard
