import { FC, useState, useEffect } from "react"

import HeatmapJS from "keli-heatmap.js"
import moment from "moment"

import HeatmapPlayer from "../../../components/HeatmapPlayer"
import { Mark } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"
import { calculateDailyLogs, calculateHourlyLogs, updateSliderMarks } from "../../../utils/heatmapUtils"

type OldHeatMapHourly = definitions["OldHeatMapHourly"] & { version_no?: number }
type OldHeatMapLogs = definitions["OldHeatMapLogs"]
type FloorPlanVersionList = definitions["FloorPlanVersionList"]
interface allData {
  version_no?: number
  timestamp: string
  logs: OldHeatMapLogs[]
}

interface Props {
  marks: Mark[]
  timeGrain?: "hourly" | "daily"
  firstLogs?: OldHeatMapHourly[]
  secondLogs?: OldHeatMapHourly[]
  heatmapLogs: OldHeatMapHourly[] | undefined
  floorPlanVersions?: FloorPlanVersionList[]
  isCameraHeatmap?: Boolean
  cameraSampleFrame?: string
}

const HeatmapTimeline: FC<Props> = ({
  heatmapLogs,
  timeGrain,
  marks,
  firstLogs,
  secondLogs,
  floorPlanVersions,
  isCameraHeatmap,
  cameraSampleFrame,
}) => {
  const [play, setPlay] = useState(false)
  const [sliderValue, setSliderValue] = useState(0)
  const [versionNumber, setVersionNumber] = useState(
    firstLogs && firstLogs?.length > 0
      ? firstLogs[0].version_no
      : secondLogs && secondLogs?.length > 0
      ? secondLogs[0].version_no
      : 1
  )
  const [allData, setAllData] = useState<allData[]>()
  const [allMarks, setAllMarks] = useState<Mark[]>()
  const [heatmapInstance, setHeatmapInstance] = useState<any>()
  const [maxValue, setMaxValue] = useState(0)
  const [currentFloorPlan, setCurrentFloorPlan] = useState<FloorPlanVersionList>()
  const [peakMarkIndex, setPeakMarkIndex] = useState<number | null>(null)

  /**
   * Updates allMarks state when marks prop changes.
   */
  useEffect(() => {
    if (marks && marks?.length > 0) setAllMarks(marks)
  }, [marks])

  const handleSliderChange = (value: any) => {
    if (allMarks && allMarks?.length > 1) {
      setSliderValue(allMarks!.findIndex((mark) => mark.value === value))
      // Get version number of floor plan
      setVersionNumber(allData![value]?.version_no!)
    }
  }

  const handleClick = () => {
    if (heatmapLogs && heatmapLogs.length > 0 && allMarks && allMarks?.length > 0) {
      setPlay(!play)
      if (sliderValue === 24 || sliderValue >= 30) {
        setSliderValue(0)
      }
    }
  }

  /**
   * Initializes the heatmap instance after a delay.
   */
  useEffect(() => {
    setTimeout(() => {
      setHeatmapInstance(
        HeatmapJS.create({
          container: document.getElementById("heatmap")!,
          radius: 25,
          backgroundColor: "rgba(55, 100, 222, 0.4)",
        })
      )
      setSliderValue(0)
    }, 500)
    setSliderValue(1)
  }, [])

  /**
   * Updates heatmap data and slider marks based on heatmapLogs and other dependencies.
   */
  useEffect(() => {
    if (heatmapInstance) {
      if (heatmapInstance.getData().data && heatmapInstance.getData().data.length > 0) {
        heatmapInstance.setData({ data: [] })
      }
      // Handle heatmap data
      if (heatmapLogs && heatmapLogs.length > 0) {
        let allData = timeGrain === "hourly" ? calculateHourlyLogs(heatmapLogs) : calculateDailyLogs(heatmapLogs)
        const filterData = allData?.filter((data) => (isCameraHeatmap ? data.timestamp : data?.version_no > -1))
        setAllData(filterData)
        updateSliderMarks(allMarks, filterData, timeGrain)

        let dataToDraw = filterData && filterData?.length > 0 ? filterData[sliderValue]?.logs : []
        let dataMax = 0
        filterData.forEach((elem: OldHeatMapHourly) => {
          elem.logs.forEach((elem: OldHeatMapLogs) => {
            if (dataMax < elem.value) {
              dataMax = elem.value
            }
          })
        })
        setMaxValue(dataMax)
        // Update heatmap
        let data = {
          max: dataMax * (allMarks && allMarks.length === 24 ? 0.2 : 1),
          data: dataToDraw,
        }

        if (dataToDraw && dataToDraw?.length > 0 && data) {
          heatmapInstance.setData(data)
        }
      }
    }
  }, [allMarks, heatmapInstance, heatmapLogs, sliderValue, timeGrain, isCameraHeatmap])

  /**
   * Filters out invalid marks based on timeGrain.
   */
  useEffect(() => {
    if (heatmapInstance) {
      setAllMarks(
        allMarks?.filter((marks) =>
          timeGrain === "hourly" ? marks?.label !== "Invalid date" && marks?.label : marks?.label !== "" && marks?.label
        )
      )
    }
    // eslint-disable-next-line
  }, [heatmapInstance, timeGrain])

  /**
   * Handles the play functionality of the timeline.
   */
  useEffect(() => {
    if (play && allMarks?.length !== 0 && allMarks !== undefined) {
      const timer = setInterval(() => {
        if (sliderValue === allData!.length - 1) {
          setSliderValue(0)
        } else {
          setSliderValue(sliderValue + 1)
        }
      }, 650)
      // Get version number of floor plan
      setVersionNumber(allData![sliderValue]?.version_no!)
      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMarks, play, sliderValue])

  const handleNextMark = () => {
    if (sliderValue === allData!.length - 1) {
      setSliderValue(0)
      // Get version number of floor plan
      setVersionNumber(allData![0]?.version_no!)
    } else {
      setSliderValue((prevValue) => prevValue + 1)
      setVersionNumber(allData![sliderValue + 1]?.version_no!)
    }
  }

  const handlePreviousMark = () => {
    if (sliderValue === 0) {
      setSliderValue(allData!.length - 1)
      // Get version number of floor plan
      setVersionNumber(allData![allData!.length - 1]?.version_no!)
    } else {
      setSliderValue((prevValue) => prevValue - 1)
      setVersionNumber(allData![sliderValue - 1]?.version_no!)
    }
  }

  /**
   * Updates the current floor plan based on the version number.
   */
  useEffect(() => {
    const desiredFloorPlan = floorPlanVersions?.find((plan) => plan.version_no === versionNumber)
    setCurrentFloorPlan(desiredFloorPlan)
  }, [floorPlanVersions, versionNumber])

  /**
   * Determines the peak mark index based on the maximum data timestamp.
   */
  useEffect(() => {
    if (allData && allData.length > 0 && allMarks) {
      // Determine the time grain format
      const timeGrainFormat = timeGrain === "hourly" ? "h A" : "D MMM"

      // Find the timestamp corresponding to the max value
      const maxData = allData.reduce(
        (prev, current) => {
          const formattedTimestamp = moment(current.timestamp).format(timeGrainFormat)
          return formattedTimestamp > prev.timestamp ? { timestamp: formattedTimestamp } : prev
        },
        { timestamp: "" }
      )

      // Find the peak index using the formatted timestamp
      const peakIndex = allMarks.findIndex((mark) => mark.label === maxData.timestamp)
      setPeakMarkIndex(peakIndex)
    }
  }, [allData, allMarks, timeGrain])

  return (
    <HeatmapPlayer
      maxValue={maxValue?.toLocaleString()}
      image={isCameraHeatmap ? cameraSampleFrame : currentFloorPlan?.image}
      isFloorsHeatmap={!isCameraHeatmap}
      versioning={{
        activeVersion: versionNumber,
        availableVersions: [
          (firstLogs && firstLogs[0]?.version_no) ?? null,
          (secondLogs && secondLogs[0]?.version_no) ?? null,
        ],
      }}
      slider={{
        value: sliderValue,
        onChange: handleSliderChange,
        isPlaying: play,
        onPlayPause: handleClick,
        onPreviousMark: handlePreviousMark,
        onNextMark: handleNextMark,
        marks: allMarks,
        peakMarkIndex: peakMarkIndex,
      }}
      timeGrain={timeGrain}
      heatmapInstance={heatmapInstance}
    />
  )
}

export default HeatmapTimeline
