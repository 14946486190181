import { Fragment, FC, useState, forwardRef, useContext } from "react"

import CloseIcon from "@mui/icons-material/Close"
import { Dialog, Slide, SlideProps, DialogContent, MobileStepper, CircularProgress } from "@mui/material"

import { Typography, Button } from "@synapse-analytics/synapse-ui"

import WarningDialog from "../../../../components/WarningDialog"
import { IMissingData } from "../../../../types/Custom/Interfaces"
import { MissingDataContext } from "../MissingDataContext/MissingDataContext"
import FirstStep from "./FirstStep/FirstStep"
import SecondStep from "./SecondStep/SecondStep"

import styles from "./MissingDataSetup.module.scss"

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
  handleClose: () => void
  open: boolean
}
const MissingDataSetup: FC<Props> = ({ handleClose, open }) => {
  const [cancelMessageOpen, setCancelMessageOpen] = useState(false)
  const [warningMessageOpen, setWarningMessageOpen] = useState(false)
  const {
    formik,
    selectedEntities,
    activeStep,
    isSubmittingNewEntry,
    isUpdatingExistingEntry,
    isEdit,
    setActiveStep,
    handleReset,
    checkEntitiesForMissingData,
    handleSubmitEntries,
  } = useContext<IMissingData>(MissingDataContext)

  // Empty All States
  const handleCloseDialog = () => {
    setCancelMessageOpen(false)
    setWarningMessageOpen(false)
    setActiveStep(0)
    handleReset()
    handleClose()
  }
  // closing confirmation message pop up
  const handleTriggerCloseConfirmation = () => {
    setCancelMessageOpen(true)
  }
  const handleSubmission = () => {
    handleSubmitEntries()
    if (!isSubmittingNewEntry && !isUpdatingExistingEntry) {
      handleCloseDialog()
    }
  }

  const handleOnAddData = async () => {
    const isFulfilled = checkEntitiesForMissingData()
    if (isFulfilled) {
      handleSubmission()
    } else {
      setWarningMessageOpen(true)
    }
  }
  const handleNext = () => {
    if (activeStep === 0) {
      if (isEdit) {
        formik?.submitForm()
        return
      } else {
        setActiveStep(activeStep + 1)
      }
    }

    if (activeStep === 1) {
      handleOnAddData()
    }
  }
  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  return (
    <Fragment>
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            overflow: "visible",
            maxWidth: "980px",
          },
        }}
        TransitionComponent={Transition}
        onClose={handleTriggerCloseConfirmation}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className={styles.wrapper} style={{ paddingTop: 32, overflow: "visible" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h2-bold" className={styles.title}>
              {isEdit ? "Edit" : "Add"} Missing Data
            </Typography>
            <CloseIcon onClick={handleTriggerCloseConfirmation} className={styles.iconContainer} />
          </div>
          {/* Content */}
          {/* Step 1 => Missing data file name and entities selection */}
          {activeStep === 0 ? <FirstStep /> : <SecondStep />}
          {/* Step 2 => Filling missing data and submitting file */}
          {/* Mobile Stepper pagination*/}
          <MobileStepper
            variant="dots"
            steps={2}
            position="static"
            activeStep={activeStep}
            className={styles.stepper}
            nextButton={
              <Button
                size="regular"
                variant="primary"
                onClick={handleNext}
                style={{ visibility: activeStep === 2 ? "hidden" : "visible" }}
                disabled={
                  formik?.values.name === "" || selectedEntities.filter((element) => !!element).length < 1
                    ? true
                    : false
                }
              >
                <Fragment>
                  <span
                    style={{
                      marginRight: [formik?.isSubmitting, isSubmittingNewEntry, isUpdatingExistingEntry].some(
                        (state) => state === true
                      )
                        ? 10
                        : 0,
                    }}
                  >
                    {activeStep === 1 ? (isEdit ? "Update data" : "Add data") : "Next"}
                  </span>
                  {[formik?.isSubmitting, isSubmittingNewEntry, isUpdatingExistingEntry].some(
                    (state) => state === true
                  ) && <CircularProgress size={20} />}
                </Fragment>
              </Button>
            }
            backButton={
              <Button
                size="regular"
                variant="secondary"
                onClick={handleBack}
                disabled={activeStep === 0}
                style={{ visibility: activeStep === 1 ? "visible" : "hidden" }}
              >
                Back
              </Button>
            }
          />
        </DialogContent>
      </Dialog>
      <WarningDialog
        isOpen={cancelMessageOpen}
        onConfirm={() => handleCloseDialog()}
        onCancel={() => setCancelMessageOpen(false)}
        dialogTitle="Confirm Cancellation"
        cancelTitle="Continue"
        actionTitle="Cancel process"
        content={` Are you sure you want to cancel missing data ${isEdit ? "editing" : "creation"} process?`}
      />
      <WarningDialog
        isOpen={warningMessageOpen}
        onConfirm={handleSubmission}
        onCancel={() => setWarningMessageOpen(false)}
        actionTitle="Add data anyway"
        content="If you press “add data” before filling the rest of the selected entities, they will be lost."
      />
    </Fragment>
  )
}
export default MissingDataSetup
